import React from 'react';
import { useSelector } from '../../_redux/_Store';
import { OverflowList } from 'react-overflow-list';
import { ApiTag } from '../../_api/_ApiModels';

interface MessageThreadTagPillListProps {
    tagIdList: Array<String>;
}

const MessageThreadTagPillList = React.memo<MessageThreadTagPillListProps>(({ tagIdList }) => {
    const apiTagList = useSelector((state) => state.data.apiTagList);
    const [displayApiTagList, setDisplayApiTagList] = React.useState<Array<ApiTag>>([]);
    const OverflowRenderer = (items: any) => {
        return <span className="messageThreadTagPillListCount">+{items.length}</span>;
    };
    const TagRenderer = (item: ApiTag) => {
        return (
            <span key={item.tagId} aria-haspopup="true" className={item.name === 'emergency' ? 'messageThreadTagPillList emergency' : 'messageThreadTagPillList'}>
                {item.name}
            </span>
        );
    };
    React.useEffect(() => {
        setDisplayApiTagList(Object.values(apiTagList).filter((obj) => tagIdList.includes(obj.tagId)));
    }, [tagIdList, apiTagList]);
    return <OverflowList collapseFrom="end" minVisibleItems={1} items={displayApiTagList} itemRenderer={TagRenderer} overflowRenderer={OverflowRenderer} />;
});

export default MessageThreadTagPillList;
