import produce from 'immer';
import { WEBSOCKET_PAYLOAD_TYPE } from '../providers';
import { ApiMessageThread, ApiTyping, ApiUser, ApiHashtag, ApiOrganization, ApiMessage, ApiTag, ApiUserHashtag } from '../_api/_ApiModels';

export interface WebSocketPayload {
    type: WEBSOCKET_PAYLOAD_TYPE;
}

/*
export interface WebSocketPayloadMessage extends WebSocketPayload {
    apiMessageThread: ApiMessageThread;
    apiMessage: ApiMessage;
}
*/

export interface WebSocketPayloadMessageThread extends WebSocketPayload {
    apiMessageThread: ApiMessageThread;
}

/*
export interface WebSocketPayloadMessageUpdated extends WebSocketPayload {
    messageThreadId: string;
    apiMessage: ApiMessage;
}

export interface WebSocketPayloadMessageThreadStarred extends WebSocketPayload {
    messageThreadId: string;
    isStarred: boolean;
}

export interface WebSocketPayloadMessageThreadArchived extends WebSocketPayload {
    messageThreadId: string;
    isArchived: boolean;
}

export interface WebSocketPayloadMessageThreadSubject extends WebSocketPayload {
    messageThreadId: string;
    subject: string;
}

export interface WebSocketPayloadMessageThreadSeen extends WebSocketPayload {
    messageThreadId: string;
}
*/

export interface WebSocketPayloadHashtag extends WebSocketPayload {
    apiHashtag: ApiHashtag;
}

export interface WebSocketPayloadUserHashtag extends WebSocketPayload {
    apiUserHashtag: ApiUserHashtag;
}

export interface WebSocketPayloadTag extends WebSocketPayload {
    apiTag: ApiTag;
}

export interface WebSocketPayloadTag extends WebSocketPayload {
    apiTag: ApiTag;
}

export interface WebSocketPayloadUser extends WebSocketPayload {
    apiUser: ApiUser;
}

export interface WebSocketPayloadUserOnline extends WebSocketPayload {
    userId: string;
    isOnline: boolean;
}

export interface WebSocketPayloadOrganization extends WebSocketPayload {
    apiOrganization: ApiOrganization;
}

export interface WebSocketPayloadTyping extends WebSocketPayload {
    apiTyping: ApiTyping;
}

export interface WebSocketPayloadNewMessageNotification extends WebSocketPayload {
    apiMessageThread: ApiMessageThread;
    apiMessage: ApiMessage;
    text: string;
}

export enum WEB_SOCKET_ACTION_TYPE {
    WEB_SOCKET_SET_PAYLOAD = 'WEB_SOCKET_SET_PAYLOAD'
}

export interface WebSocketAction {
    type: WEB_SOCKET_ACTION_TYPE;
    payload:
        | WebSocketPayloadMessageThread
        //| WebSocketPayloadMessage
        //| WebSocketPayloadMessageUpdated
        //| WebSocketPayloadMessageThreadStarred
        //| WebSocketPayloadMessageThreadArchived
        //| WebSocketPayloadMessageThreadSubject
        //| WebSocketPayloadMessageThreadSeen
        | WebSocketPayloadHashtag
        | WebSocketPayloadTag
        | WebSocketPayloadUser
        | WebSocketPayloadUserOnline
        | WebSocketPayloadOrganization
        | WebSocketPayloadTyping;
}

export const setPayload = (
    webSocketPayload:
        | WebSocketPayloadMessageThread
        | WebSocketPayloadHashtag
        | WebSocketPayloadTag
        | WebSocketPayloadUser
        | WebSocketPayloadUserOnline
        | WebSocketPayloadOrganization
        | WebSocketPayloadTyping
) => {
    return {
        type: WEB_SOCKET_ACTION_TYPE.WEB_SOCKET_SET_PAYLOAD,
        payload: webSocketPayload
    };
};

export interface WebSocketState {
    webSocketPayloadMessageThread?: WebSocketPayloadMessageThread;
    /*
    webSocketPayloadMessage?: WebSocketPayloadMessage;
    webSocketPayloadMessageUpdated?: WebSocketPayloadMessageUpdated;
    webSocketPayloadMessageThreadStarred?: WebSocketPayloadMessageThreadStarred;
    webSocketPayloadMessageThreadArchived?: WebSocketPayloadMessageThreadArchived;
    webSocketPayloadMessageThreadSubject?: WebSocketPayloadMessageThreadSubject;
    webSocketPayloadMessageThreadSeen?: WebSocketPayloadMessageThreadSeen;
    */
    webSocketPayloadHashtag?: WebSocketPayloadHashtag;
    webSocketPayloadTag?: WebSocketPayloadTag;
    webSocketPayloadUser?: WebSocketPayloadUser;
    webSocketPayloadUserOnline?: WebSocketPayloadUserOnline;
    webSocketPayloadOrganization?: WebSocketPayloadOrganization;
}

const initWebSocketState: WebSocketState = {};

export const WebSocketReducer = (state = initWebSocketState, action: WebSocketAction) => {
    switch (action.type) {
        case WEB_SOCKET_ACTION_TYPE.WEB_SOCKET_SET_PAYLOAD: {
            const webSocketPayload = action.payload as WebSocketPayload;
            switch (webSocketPayload.type) {
                case WEBSOCKET_PAYLOAD_TYPE.MESSAGE_THREAD: {
                    return produce(state, (draft) => {
                        draft.webSocketPayloadMessageThread = action.payload as WebSocketPayloadMessageThread;
                    });
                }
                default:
                    return state;
            }
        }
        default:
            return state;
    }
};
