import React from 'react';
import { Button } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { Inbox as InboxIcon, FilterListOutlined, Search as SearchIcon, Cancel as CancelIcon, EditOutlined, OpenInNewOutlined } from '@mui/icons-material';
import { SCREEN_TYPE, QUERY_STRING_TYPE, LAYOUT_TYPE } from '../../_global/Route';
import { MESSAGE_THREAD_SEARCH_FILTER_TYPE } from '../../_api/_ApiModels';
import { useSelector } from '../../_redux/_Store';
import { useDrawer } from '../../providers/Drawer';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import HashtagCalloutInput, { MENTION_TYPE } from '../common/HashtagCalloutInput';
import MessageThreadSearchModal from './MessageThreadSearchModal';
import EditMessageThreadSearchModal from './EditMessageThreadSearchModal';
import MessageThreadIcon from '../../assets/images/icons/message.svg';
import MessageThreadFilterPillList from './MessageThreadFilterPillList';

interface InboxHeaderProps {
    isSearch: boolean;
}

const InboxHeader = React.memo<InboxHeaderProps>(({ isSearch }) => {
    const drawer = useDrawer();
    const location = useLocation();
    const messageThreadFilter = useMessageThreadFilter();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const [search, setSearch] = React.useState('');
    const [isShortcutOpen, setIsShortcutOpen] = React.useState(false);
    const [isEditShortcutOpen, setIsEditShortcutOpen] = React.useState(false);
    const [headerTitle, setHeaderTitle] = React.useState('Inbox');
    const [messageThreadSearchId, setMessageThreadSearchId] = React.useState<string | null>(null);
    const onAddCallback = React.useCallback(
        (id: string, type: MENTION_TYPE) => {
            messageThreadFilter.addFilterListValue(type === MENTION_TYPE.CALLOUT_USER_ID ? MESSAGE_THREAD_SEARCH_FILTER_TYPE.USER_ID_LIST : MESSAGE_THREAD_SEARCH_FILTER_TYPE.HASHTAG_ID_LIST, id);
            //Remove the hashtag/callout text from the search input
            if (type === MENTION_TYPE.CALLOUT_USER_ID) {
                setSearch(search.replaceAll(/@(\w+)?/g, ''));
            }
            if (type === MENTION_TYPE.HASHTAG_ID) {
                setSearch(search.replaceAll(/#(\w+)?/g, ''));
            }
        },
        [messageThreadFilter, search]
    );
    const onTextSearch = React.useCallback(() => {
        const newFilterList = messageThreadFilter.filterList ? [...messageThreadFilter.filterList].filter((obj) => obj.type !== MESSAGE_THREAD_SEARCH_FILTER_TYPE.TEXT) : [];
        newFilterList.push({
            type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.TEXT,
            value: search
        });
        messageThreadFilter.navigateToSearch(newFilterList);
    }, [messageThreadFilter, search]);
    const onClearSearch = React.useCallback(() => {
        setSearch('');
        if (!location.pathname.match(`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.MAIN}`)) {
            const newFilterList = messageThreadFilter.filterList ? [...messageThreadFilter.filterList].filter((obj) => obj.type !== MESSAGE_THREAD_SEARCH_FILTER_TYPE.TEXT) : [];
            messageThreadFilter.navigateToSearch(newFilterList);
        }
    }, [location.pathname, messageThreadFilter]);
    const onFilterDelete = React.useCallback(
        (type: MESSAGE_THREAD_SEARCH_FILTER_TYPE, value?: string) => {
            switch (type) {
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.DATE_RANGE_TYPE: {
                    messageThreadFilter.removeFilter([MESSAGE_THREAD_SEARCH_FILTER_TYPE.DATE_RANGE_TYPE, MESSAGE_THREAD_SEARCH_FILTER_TYPE.START_DATE, MESSAGE_THREAD_SEARCH_FILTER_TYPE.END_DATE]);
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.USER_ID_LIST:
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.HASHTAG_ID_LIST:
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.TAG_ID_LIST: {
                    if (value) messageThreadFilter.removeFilterListValue(type, value);
                    break;
                }
                default: {
                    messageThreadFilter.removeFilter([type]);
                    break;
                }
            }
        },
        [messageThreadFilter]
    );
    React.useEffect(() => {
        const textFilter = messageThreadFilter.filterList?.find((obj) => obj.type === MESSAGE_THREAD_SEARCH_FILTER_TYPE.TEXT);
        setSearch(textFilter ? (textFilter.value as string) : '');
    }, [messageThreadFilter]);
    React.useEffect(() => {
        if (!isSearch) {
            setHeaderTitle('Inbox');
            return;
        }
        if (!loggedInUserId) return;
        const loggedInApiUser = apiUserList[loggedInUserId];
        const queryParams = new URLSearchParams(location.search);
        const messageThreadSearch = loggedInApiUser.apiMessageThreadSearchList.find((obj) => obj.messageThreadSearchId === queryParams.get(QUERY_STRING_TYPE.MESSAGE_THREAD_SEARCH_ID));
        setMessageThreadSearchId(messageThreadSearch ? messageThreadSearch.messageThreadSearchId : null);
        setHeaderTitle(messageThreadSearch ? messageThreadSearch.name : 'All Threads');
    }, [apiUserList, isSearch, location.search, loggedInUserId, messageThreadFilter.filterList, messageThreadSearchId]);
    return (
        <div className="inbox_header">
            <div className="commonTop_headingInfo">
                <div className="commonTop_heading">
                    {isSearch ? <img src={MessageThreadIcon} alt="Message_Icon" /> : <InboxIcon />}
                    <h2 className={isSearch ? 'inboxHeader_maxWidth' : ''}>{headerTitle}</h2>
                    {messageThreadSearchId && (
                        <button
                            onClick={() => {
                                setIsEditShortcutOpen(true);
                            }}
                            className="unStyledButton editShortcut"
                        >
                            <EditOutlined />
                        </button>
                    )}
                </div>

                <div className="commonTop_filter">
                    {isSearch && (
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => {
                                setIsShortcutOpen(true);
                            }}
                            disabled={messageThreadSearchId !== null || !(messageThreadFilter.filterList?.length !== 0)} /*double negate as displayCount can be undefined*/
                            className="commonTop_filterBtn mr8"
                        >
                            <OpenInNewOutlined /> Add Shortcut
                        </Button>
                    )}
                    <Button
                        variant={messageThreadFilter.displayCount !== 0 ? 'contained' : 'outlined'}
                        onClick={() => {
                            drawer.openMessageThreadFilter();
                        }}
                        className="commonTop_filterBtn"
                    >
                        <FilterListOutlined /> Filter
                    </Button>
                </div>
            </div>
            <div className="commonTop_searchBar">
                <SearchIcon className="commonTop_searchIcon" />
                <HashtagCalloutInput
                    value={search}
                    placeholder="Search threads"
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            onTextSearch();
                        }
                    }}
                    onAddCallback={onAddCallback}
                    allowSuggestionsAboveCursor={true}
                    singleLine={true}
                    className="commonTop_Search"
                />
                {search?.length > 0 && (
                    <button onClick={onClearSearch} className="drawerCloseBtn commonTop_closeIcon">
                        <CancelIcon />
                    </button>
                )}
            </div>
            {messageThreadFilter.filterList !== null && messageThreadFilter.displayCount !== undefined && messageThreadFilter.displayCount > 0 && (
                <div className="commonTop_searchResult">
                    <div className="commonTop_filter">
                        <span>Filters ({messageThreadFilter.displayCount}):</span>
                    </div>
                    <div className="commonTop_pillsList">
                        <MessageThreadFilterPillList apiMessageThreadSearchFilterList={messageThreadFilter.filterList} onDelete={onFilterDelete} displayClearAll={true} />
                    </div>
                </div>
            )}
            <MessageThreadSearchModal
                isOpen={isShortcutOpen}
                onClose={() => {
                    setIsShortcutOpen(false);
                }}
            />
            {messageThreadSearchId && (
                <EditMessageThreadSearchModal
                    messageThreadSearchId={messageThreadSearchId}
                    isOpen={isEditShortcutOpen}
                    setIsOpen={setIsEditShortcutOpen}
                    onClose={() => {
                        setIsEditShortcutOpen(false);
                    }}
                />
            )}
        </div>
    );
});

export default InboxHeader;
