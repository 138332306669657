import React from 'react';
import { ApiGif } from '../../_api/_ApiModels';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';

interface MessageReplyGifImageProps {
    gif: ApiGif
    onImageRemoved?: () => void;
    autoPlay?: boolean;
};

const MAX_IMAGE_WIDTH = 300;
const VIDEO_PLAY_SECONDS = 15

const MessageGifImage = React.memo<MessageReplyGifImageProps>(({ gif, onImageRemoved, autoPlay }) => {
    const [imageSource, setImageSource] = React.useState(gif.imageUrl);

    const onImageClick = React.useCallback(() => {
        if (imageSource === gif.videoUrl) {
            setImageSource(gif.imageUrl);
        } else {
            setImageSource(gif.videoUrl);
        }
    }, [gif, imageSource]);

    React.useEffect(() => {
        let timerId = 0;
        if (autoPlay) {
            setImageSource(gif.videoUrl);
            timerId = window.setTimeout(() => setImageSource(gif.imageUrl), VIDEO_PLAY_SECONDS * 1000);
        }

        return () => window.clearTimeout(timerId);

    }, [gif, autoPlay]);

    if (gif.height <= 0 || gif.width <= 0) {
        return <div>Invalid Image</div>;
    }

    const width = Math.min(gif.width, MAX_IMAGE_WIDTH);

    return <>
        <img alt={gif.name} src={imageSource} width={width} onClick={onImageClick} />
        {onImageRemoved ?
            <div className="gif_cancel_btn" onClick={() => onImageRemoved()}>
                <CloseOutlinedIcon />
            </div>
            : null}
    </>;

});

export default MessageGifImage;
