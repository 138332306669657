import React from 'react';
import { Button, Modal } from '@mui/material';
import { useSelector } from '../../_redux/_Store';
import { Close } from '@mui/icons-material';
import NotificationIcon from '../../assets/images/notifications.svg';

interface ConfirmNotificationsModalProps {
    hashtagId: string;
    onYesCallback: Function;
    onNoCallback: Function;
    onCancelCallback: Function;
    isOpen: boolean;
}

const ConfirmNotificationsModal = React.memo<ConfirmNotificationsModalProps>(({ hashtagId, onYesCallback, onNoCallback, onCancelCallback, isOpen }) => {
    const apiHashtagList = useSelector((state) => state.data.apiHashtagList);
    const [hashtagName, setHashtagName] = React.useState<string>('');
    React.useEffect(() => {
        const apiHashtag = apiHashtagList[hashtagId];
        if (apiHashtag) setHashtagName(apiHashtag.name);
    }, [apiHashtagList, hashtagId]);
    return (
        <>
            <Modal open={isOpen}>
                <div className="commonModal_wrapper confirm">
                    <div className="commonModal_header">
                        <button
                            className="commonModal_headerClose"
                            type="button"
                            onClick={() => {
                                onCancelCallback();
                            }}
                        >
                            <Close />
                        </button>
                    </div>
                    <div className="commonModal_body">
                        <div className="commonConfirmBox">
                            <img src={NotificationIcon} alt="notification" />
                            <h2>Turn on Notifications?</h2>
                            <p className="">Do you want to receive notifications for the hashtag {hashtagName}?</p>
                        </div>
                    </div>
                    <div className="commonModal_footer textCenter">
                        <Button color="primary" variant="contained" className="ml16" onClick={() => onYesCallback()}>
                            Yes
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                onNoCallback();
                            }}
                        >
                            No
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
});

export default ConfirmNotificationsModal;
