import React from 'react';
import { Button } from '@mui/material';
import { USER_HASHTAG_STATE_TYPE } from '../../../_global/_Enums';
import { useSelector } from '../../../_redux/_Store';
import Hashtag from '../../common/Hashtag';
import { ArrowForward, EmailOutlined } from '@mui/icons-material';
import { useApi } from '../../../providers';
import HashtagDetails from '../HashtagDetails';
import moment from 'moment';
import { MONTH_DAY_FORMAT } from '../../../_global/Constants';
import ConfirmNotificationsModal from '../ConfirmNotificationsModal';

export interface UserHashtagControlRequestProps {
    stateType?: USER_HASHTAG_STATE_TYPE;
    updateTime?: number;
    hashtagId: string;
    userId: string;
}

const UserHashtagControlRequest = React.memo<UserHashtagControlRequestProps>(({ stateType, updateTime, hashtagId, userId }) => {
    const api = useApi();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const [displayControls, setDisplayControls] = React.useState<boolean>(false);
    const [isConfirmOpen, setIsConfirmOpen] = React.useState<boolean>(false);
    const onSendRequestClicked = React.useCallback(
        (notifications: boolean) => {
            setIsConfirmOpen(false);
            api.createUserHashtagRequest({
                hashtagId: hashtagId,
                notifications: notifications
            });
        },
        [api, hashtagId]
    );
    React.useEffect(() => {
        if (!loggedInUserId) return;
        setDisplayControls(userId === loggedInUserId);
    }, [userId, loggedInUserId]);
    return (
        <div className="commonSubscriptionBox">
            <div className="commonSubscriptionBox_heading">
                <EmailOutlined />
                Request Access
            </div>
            <div className="commonSubscriptionBox_message">
                Send admins a request to subscribe to <Hashtag hashtagId={hashtagId} />.
                {updateTime && (
                    <>
                        {stateType === USER_HASHTAG_STATE_TYPE.DENIED ? (
                            <>Your request to subscribe was declined on {moment.unix(updateTime).format(MONTH_DAY_FORMAT)}.</>
                        ) : stateType === USER_HASHTAG_STATE_TYPE.DECLINED ? (
                            <>You declined your invitation to subscribe on {moment.unix(updateTime).format(MONTH_DAY_FORMAT)}.</>
                        ) : stateType === USER_HASHTAG_STATE_TYPE.DEACTIVATED ? (
                            <>Your subscription was deactivated on {moment.unix(updateTime).format(MONTH_DAY_FORMAT)}.</>
                        ) : (
                            <></>
                        )}
                    </>
                )}
            </div>
            <div className="commonSubscriptionBox_hashtags">
                <HashtagDetails hashtagId={hashtagId} />
            </div>
            {displayControls && (
                <div className="commonSubscriptionBox_action">
                    <Button
                        color="primary"
                        size="small"
                        startIcon={<ArrowForward />}
                        variant="contained"
                        onClick={() => {
                            setIsConfirmOpen(true);
                        }}
                    >
                        Send Request
                    </Button>
                </div>
            )}
            <ConfirmNotificationsModal
                isOpen={isConfirmOpen}
                hashtagId={hashtagId}
                onCancelCallback={() => {
                    setIsConfirmOpen(false);
                }}
                onNoCallback={() => {
                    onSendRequestClicked(false);
                }}
                onYesCallback={() => {
                    onSendRequestClicked(true);
                }}
            />
        </div>
    );
});

export default UserHashtagControlRequest;
