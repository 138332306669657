import React from 'react';
import { ApiUserHashtag } from '../../../_api/_ApiModels';
import { USER_HASHTAG_STATE_TYPE } from '../../../_global/_Enums';
import { USER_HASHTAG_CONTROL_TYPE } from '../../subscriptions/UserHashtagControl';
import UserHashtagControlInvited from '../../subscriptions/userHashtagStates/UserHashtagControlInvited';
import UserHashtagControlRequest from '../../subscriptions/userHashtagStates/UserHashtagControlRequest';
import UserHashtagControlRequested from '../../subscriptions/userHashtagStates/UserHashtagControlRequested';
import UserHashtagControlSubscribed from '../../subscriptions/userHashtagStates/UserHashtagControlSubscribed';

interface MessageThreadObjectUserHashtagProps {
    apiUserHashtag: ApiUserHashtag;
}

const MessageThreadObjectUserHashtag = React.memo<MessageThreadObjectUserHashtagProps>(({ apiUserHashtag }) => {
    const [stateType, setStateType] = React.useState<USER_HASHTAG_STATE_TYPE>();
    const [updateTime, setUpdateTime] = React.useState<number>();
    const [controlType, setControlType] = React.useState<USER_HASHTAG_CONTROL_TYPE>();
    React.useEffect(() => {
        setStateType(apiUserHashtag.stateType);
        setUpdateTime(apiUserHashtag.updateTime);
        switch (apiUserHashtag.stateType) {
            case USER_HASHTAG_STATE_TYPE.DEACTIVATED:
            case USER_HASHTAG_STATE_TYPE.DECLINED:
            case USER_HASHTAG_STATE_TYPE.DENIED: {
                setControlType(USER_HASHTAG_CONTROL_TYPE.REQUEST);
                break;
            }
            case USER_HASHTAG_STATE_TYPE.INVITED: {
                setControlType(USER_HASHTAG_CONTROL_TYPE.INVITED);
                break;
            }
            case USER_HASHTAG_STATE_TYPE.REQUESTED: {
                setControlType(USER_HASHTAG_CONTROL_TYPE.REQUESTED);
                break;
            }
            case USER_HASHTAG_STATE_TYPE.SUBSCRIBED: {
                setControlType(USER_HASHTAG_CONTROL_TYPE.SUBSCRIBED);
                break;
            }
        }
    }, [apiUserHashtag.hashtagId, apiUserHashtag.stateType, apiUserHashtag.updateTime, apiUserHashtag.userId]);
    return (
        <>
            {controlType === USER_HASHTAG_CONTROL_TYPE.SUBSCRIBED ? (
                <UserHashtagControlSubscribed hashtagId={apiUserHashtag.hashtagId} userId={apiUserHashtag.userId} />
            ) : controlType === USER_HASHTAG_CONTROL_TYPE.REQUESTED ? (
                <UserHashtagControlRequested hashtagId={apiUserHashtag.hashtagId} userId={apiUserHashtag.userId} userHashtagId={apiUserHashtag.userHashtagId} />
            ) : controlType === USER_HASHTAG_CONTROL_TYPE.INVITED ? (
                <UserHashtagControlInvited hashtagId={apiUserHashtag.hashtagId} userId={apiUserHashtag.userId} userHashtagId={apiUserHashtag.userHashtagId} />
            ) : controlType === USER_HASHTAG_CONTROL_TYPE.REQUEST ? (
                <UserHashtagControlRequest hashtagId={apiUserHashtag.hashtagId} userId={apiUserHashtag.userId} stateType={stateType} updateTime={updateTime} />
            ) : (
                <>INVALID</>
            )}
        </>
    );
});

export default MessageThreadObjectUserHashtag;
