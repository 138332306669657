import React from 'react';
import { useSelector } from '../../_redux/_Store';

interface UserNameProps {
    userId: string | undefined;
    displayLastName?: boolean;
}

const UserName = React.memo<UserNameProps>(({ userId, displayLastName = true }) => {
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const [userName, setUserName] = React.useState<string>();
    React.useEffect(() => {
        if (!userId) return;
        const apiUser = apiUserList[userId];
        if (apiUser) {
            if (displayLastName) {
                setUserName(`${apiUser.firstName} ${apiUser.lastName}`);
            } else {
                setUserName(`${apiUser.firstName}`);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userId, displayLastName]);
    return <>{userName}</>;
});

export default UserName;
