import React from 'react';
import Callout from '../../common/Callout';
import Hashtag from '../../common/Hashtag';
import { Link } from 'react-router-dom';
import { LAYOUT_TYPE, SCREEN_TYPE } from '../../../_global/Route';
import { ArrowForward } from '@mui/icons-material';
import { useSelector } from '../../../_redux/_Store';

export interface UserHashtagControlSubscribedProps {
    userId: string;
    hashtagId: string;
}

const UserHashtagControlSubscribed = React.memo<UserHashtagControlSubscribedProps>(({ userId, hashtagId }) => {
    const loggedInUserId = useSelector((state) => state.data.userId);
    return (
        <div className="commonSubscriptionBox">
            <div className="commonSubscriptionBox_heading">Subscription Confirmed</div>
            <div className="commonSubscriptionBox_hashtags">
                <Callout userId={userId} /> is subscribed to <Hashtag hashtagId={hashtagId} />.
            </div>
            {loggedInUserId === userId && (
                <div className="commonSubscriptionBox_action">
                    <Link to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SUBSCRIPTIONS}`} className="commonSubscriptionBox_actionLink">
                        <ArrowForward />
                        Manage Subscriptions
                    </Link>
                </div>
            )}
        </div>
    );
});

export default UserHashtagControlSubscribed;
