export enum IDENTITY_TYPE {
    EMAIL = 'EMAIL',
    PHONE = 'PHONE'
}

export enum FILE_IMAGE_TYPE {
    THUMBNAIL = 'THUMBNAIL',
    MESSAGE_LIST = 'MESSAGE_LIST'
}

export enum REACTION_TYPE {
    LIKE = 'LIKE',
    DISLIKE = 'DISLIKE',
    HEART = 'HEART',
    LAUGHING = 'LAUGHING',
    SURPRISE = 'SURPRISE'
}

export enum USER_HASHTAG_STATE_TYPE {
    SUBSCRIBED = 'SUBSCRIBED',
    DEACTIVATED = 'DEACTIVATED',
    REQUESTED = 'REQUESTED',
    DENIED = 'DENIED',
    INVITED = 'INVITED',
    DECLINED = 'DECLINED'
}

export enum USER_HASHTAG_STATE_FILTER_TYPE {
    INVITED = 'INVITED',
    REQUESTED = 'REQUESTED',
    SUBSCRIBED = 'SUBSCRIBED',
    NOT_SUBSCRIBED = 'NOT_SUBSCRIBED',
    DECLINED = 'DECLINED',
    DEACTIVATED = 'DEACTIVATED'
}

export enum USER_ROLE_TYPE {
    ADMIN = 'ADMIN'
}
