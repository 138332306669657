import React, { useReducer } from 'react';
import { AxiosResponse } from 'axios';
import moment from 'moment';
import { GetMessageThreadListResponse, useApi } from '../../providers/Api';
import { useWebSocket } from '../../providers/WebSocket';
import { useSelector } from '../../_redux/_Store';
import { useLocation, useNavigate, useOutlet, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { Divider } from '@mui/material';
import InboxHeader from './InboxHeader';
import MessageThreadList from './MessageThreadList';
import { Create } from '@mui/icons-material';
import NoMessageIcon from '../../assets/images/no_message.svg';
import PlaceholderIcon from '../../assets/images/placeholder-thread.svg';
import { MESSAGE_THREAD_NAV_TYPE, useMessageThreadNav } from '../../providers/MessageThreadNav';
import { INBOX_PARAM_TYPE, SCREEN_TYPE } from '../../_global/Route';
import { appendApiMessageThreadList, initInboxMessageThreadListState, MessageThreadListReducer, setApiMessageThreadList, webSocketMessageThread } from '../../_reducers/MessageThreadListReducer';
import { useDrawer } from '../../providers/Drawer';

const InboxMessageThreadList = React.memo(() => {
    const api = useApi();
    const webSocket = useWebSocket();
    const params = useParams<INBOX_PARAM_TYPE>();
    const location = useLocation();
    const navigate = useNavigate();
    const drawer = useDrawer();
    const messageThreadNav = useMessageThreadNav();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const webSocketPayloadMessageThread = useSelector((state) => state.webSocket?.webSocketPayloadMessageThread);
    const outlet = useOutlet();
    const [messageThreadListReducer, dispatch] = useReducer(MessageThreadListReducer, initInboxMessageThreadListState);
    const [hasMore, setHasMore] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const navigateToMessageThread = React.useCallback(
        (type: MESSAGE_THREAD_NAV_TYPE) => {
            if (!messageThreadListReducer) return;
            messageThreadNav.navigateToMessageThread(navigate, type, messageThreadListReducer.apiMessageThreadList, params.MESSAGE_THREAD_ID, SCREEN_TYPE.MAIN, location.search);
        },
        [location.search, messageThreadListReducer, messageThreadNav, navigate, params.MESSAGE_THREAD_ID]
    );
    const initMessageThreadList = React.useCallback(() => {
        setLoading(true);
        api.getMessageThreadList({ lastSortTime: moment.now() }, (axiosResponse: AxiosResponse) => {
            const getMessageThreadListResponse: GetMessageThreadListResponse = axiosResponse.data;
            setHasMore(getMessageThreadListResponse.hasMore);
            dispatch(setApiMessageThreadList(getMessageThreadListResponse.apiMessageThreadList));
            setLoading(false);
        });
    }, [api]);
    const loadMoreMessageThreads = React.useCallback(() => {
        if (!hasMore || !messageThreadListReducer) return;
        api.getMessageThreadList({ lastSortTime: messageThreadListReducer.minSortTime }, (axiosResponse: AxiosResponse) => {
            const getMessageThreadListResponse: GetMessageThreadListResponse = axiosResponse.data;
            setHasMore(getMessageThreadListResponse.hasMore);
            dispatch(appendApiMessageThreadList(getMessageThreadListResponse.apiMessageThreadList));
        });
    }, [api, hasMore, messageThreadListReducer]);
    React.useEffect(() => {
        initMessageThreadList();
    }, [initMessageThreadList]);
    React.useEffect(() => {
        if (!webSocketPayloadMessageThread || !loggedInUserId) return;
        dispatch(webSocketMessageThread(webSocketPayloadMessageThread));
    }, [webSocketPayloadMessageThread, loggedInUserId]);
    React.useEffect(() => {
        messageThreadNav.registerCallback(navigateToMessageThread);
        return () => {
            messageThreadNav.unregisterCallback(navigateToMessageThread);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageThreadListReducer, params.MESSAGE_THREAD_ID, location.search]);
    React.useEffect(() => {
        webSocket.addOnConnectCallback(initMessageThreadList);
        return () => {
            webSocket.removeOnConnectCallback(initMessageThreadList);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div className="messageThreadList_inboxWrapper">
                <InboxHeader isSearch={false} />
                {loading || !messageThreadListReducer ? (
                    <div className="commonProgress">
                        <CircularProgress />
                    </div>
                ) : (
                    <>
                        {messageThreadListReducer.length > 0 ? (
                            <MessageThreadList isSearch={false} apiMessageThreadList={messageThreadListReducer.apiMessageThreadList} loadMoreCallback={loadMoreMessageThreads} />
                        ) : (
                            <div className="messageThreadList_noMessage">
                                <div className="textCenter">
                                    <img src={NoMessageIcon} alt="no_message" />
                                    <div className="messageThreadList_noMessageInner">
                                        <h2>Congratulations!</h2>
                                        <p className="messageThreadList_noMessage_subInfo">You have no messages in your inbox.</p>
                                    </div>
                                    <div>
                                        <button
                                            className="commonComposeBtn"
                                            onClick={() => {
                                                drawer.openCompose();
                                            }}
                                        >
                                            <Create /> Compose Message
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
            <Divider orientation="vertical" />
            <div className="messageThreadList_messageThreadWrapper">
                {outlet || (
                    <div className="messageThreadList_placeholder">
                        <img src={PlaceholderIcon} alt="placeholder-message-thread" />
                    </div>
                )}
            </div>
        </>
    );
});

export default InboxMessageThreadList;
