import { EmojiPattern, FILE_TYPES_FOR_ICONS } from './Constants';

export const UNKNOWN_FILE_THUMB = '/image/filetype/unknown.png';

export const isImage = (contentType: string) => {
    return contentType.includes('image/') && contentType !== 'image/vnd.adobe.photoshop' && contentType !== 'image/tiff';
};

export const getReadableBytes = (bytes: number) => {
    let readableBytes = '0 KB';
    if (bytes < Math.pow(1024, 2)) {
        readableBytes = (bytes / Math.pow(1024, 1)).toFixed(2) + ' KB';
    } else if (bytes < Math.pow(1024, 3)) {
        readableBytes = (bytes / Math.pow(1024, 2)).toFixed(2) + ' MB';
    } else if (bytes < Math.pow(1024, 4)) {
        readableBytes = (bytes / Math.pow(1024, 3)).toFixed(2) + ' GB';
    }
    return readableBytes;
};

export const isPreviewableImageType = (contentType: string) => {
    const mimeTypeList = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/webp', 'image/bmp', 'image/gif', 'image/vnd.microsoft.icon'];
    return mimeTypeList.includes(contentType);
};

export const isVideo = (contentType: string) => {
    return contentType.includes('video/');
};

export const isAudio = (contentType: string) => {
    return contentType.includes('audio/');
};

export const isOffice = (contentType: string) => {
    return (
        contentType === 'application/vnd.ms-excel' ||
        contentType === 'application/msword' ||
        contentType === 'application/vnd.ms-powerpoint' ||
        contentType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        contentType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        contentType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
    );
};

export const isPdf = (contentType: string) => {
    return contentType === 'application/pdf';
};

export const isDirectDownloadableFile = (contentType: string) => {
    return contentType === 'application/zip' || 
           contentType === 'application/x-zip-compressed' ||
            contentType === 'application/octet-stream' ||
           contentType === 'text/plain';
};

export const isThumbnailable = (contentType: string) => {
    return isPreviewableImageType(contentType)
            || isOffice(contentType)
            || isPdf(contentType);
}

export const getFileThumb = (contentType: string) => {
    const i = FILE_THUMB_LIST.findIndex((type) => {
        return type.contentType === contentType;
    });
    if (i > -1) {
        return FILE_THUMB_LIST[i].image;
    } else {
        return UNKNOWN_FILE_THUMB;
    }
};

export const FILE_THUMB_LIST = [
    { contentType: 'text/csv', image: '/image/filetype/CSV.png' },
    { contentType: 'application/pdf', image: '/image/filetype/pdf.png' },
    { contentType: 'text/plain', image: '/image/filetype/txt.png' },

    { contentType: 'video/1d-interleaved-parityfec', image: '/image/filetype/video.png' },
    { contentType: 'video/3gpp', image: '/image/filetype/video.png' },
    { contentType: 'video/3gpp2', image: '/image/filetype/video.png' },
    { contentType: 'video/3gpp-tt', image: '/image/filetype/video.png' },
    { contentType: 'video/BMPEG', image: '/image/filetype/video.png' },
    { contentType: 'video/BT656', image: '/image/filetype/video.png' },
    { contentType: 'video/CelB', image: '/image/filetype/video.png' },
    { contentType: 'video/DV', image: '/image/filetype/video.png' },
    { contentType: 'video/encaprtp', image: '/image/filetype/video.png' },
    { contentType: 'video/example', image: '/image/filetype/video.png' },
    { contentType: 'video/flexfec', image: '/image/filetype/video.png' },
    { contentType: 'video/H261', image: '/image/filetype/video.png' },
    { contentType: 'video/H263', image: '/image/filetype/video.png' },
    { contentType: 'video/H263-1998', image: '/image/filetype/video.png' },
    { contentType: 'video/H263-2000', image: '/image/filetype/video.png' },
    { contentType: 'video/H264', image: '/image/filetype/video.png' },
    { contentType: 'video/H264-RCDO', image: '/image/filetype/video.png' },
    { contentType: 'video/H264-SVC', image: '/image/filetype/video.png' },
    { contentType: 'video/H265', image: '/image/filetype/video.png' },
    { contentType: 'video/iso.segment', image: '/image/filetype/video.png' },
    { contentType: 'video/JPEG', image: '/image/filetype/video.png' },
    { contentType: 'video/jpeg2000', image: '/image/filetype/video.png' },
    { contentType: 'video/mj2', image: '/image/filetype/video.png' },
    { contentType: 'video/MP1S', image: '/image/filetype/video.png' },
    { contentType: 'video/MP2P', image: '/image/filetype/video.png' },
    { contentType: 'video/MP2T', image: '/image/filetype/video.png' },
    { contentType: 'video/mp4', image: '/image/filetype/video.png' },
    { contentType: 'video/MP4V-ES', image: '/image/filetype/video.png' },
    { contentType: 'video/MPV', image: '/image/filetype/video.png' },
    { contentType: 'video/mpeg4-generic', image: '/image/filetype/video.png' },
    { contentType: 'video/nv', image: '/image/filetype/video.png' },
    { contentType: 'video/ogg', image: '/image/filetype/video.png' },
    { contentType: 'video/pointer', image: '/image/filetype/video.png' },
    { contentType: 'video/quicktime', image: '/image/filetype/video.png' },
    { contentType: 'video/raptorfec', image: '/image/filetype/video.png' },
    { contentType: 'video/raw', image: '/image/filetype/video.png' },
    { contentType: 'video/rtp-enc-aescm128', image: '/image/filetype/video.png' },
    { contentType: 'video/rtploopback', image: '/image/filetype/video.png' },
    { contentType: 'video/rtx', image: '/image/filetype/video.png' },
    { contentType: 'video/smpte291', image: '/image/filetype/video.png' },
    { contentType: 'video/SMPTE292M', image: '/image/filetype/video.png' },
    { contentType: 'video/ulpfec', image: '/image/filetype/video.png' },
    { contentType: 'video/vc1', image: '/image/filetype/video.png' },
    { contentType: 'video/vc2', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.CCTV', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.dece.hd', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.dece.mobile', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.dece.mp4', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.dece.pd', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.dece.sd', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.dece.video', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.directv.mpeg', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.directv.mpeg-tts', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.dlna.mpeg-tts', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.dvb.file', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.fvt', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.hns.video', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.iptvforum.1dparityfec-1010', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.iptvforum.1dparityfec-2005', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.iptvforum.2dparityfec-1010', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.iptvforum.2dparityfec-2005', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.iptvforum.ttsavc', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.iptvforum.ttsmpeg2', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.motorola.video', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.motorola.videop', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.mpegurl', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.ms-playready.media.pyv', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.nokia.interleaved-multimedia', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.nokia.mp4vr', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.nokia.videovoip', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.objectvideo', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.radgamettools.bink', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.radgamettools.smacker', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.sealed.mpeg1', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.sealed.mpeg4', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.sealed.swf', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.sealedmedia.softseal.mov', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.uvvu.mp4', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.youtube.yt', image: '/image/filetype/video.png' },
    { contentType: 'video/vnd.vivo', image: '/image/filetype/video.png' },
    { contentType: 'video/VP8', image: '/image/filetype/video.png' },

    { contentType: 'audio/mpeg', image: '/image/filetype/video.png' },
    { contentType: 'audio/x-ms-wma', image: '/image/filetype/video.png' },
    { contentType: 'audio/vnd.rn-realaudio', image: '/image/filetype/video.png' },
    { contentType: 'audio/x-wav', image: '/image/filetype/video.png' },

    { contentType: 'image/jpeg', image: '/image/filetype/image.png' },
    { contentType: 'image/png', image: '/image/filetype/image.png' },
    { contentType: 'image/svg+xml', image: '/image/filetype/image.png' },
    { contentType: 'image/tiff', image: '/image/filetype/image.png' },
    { contentType: 'image/webp', image: '/image/filetype/image.png' },
    { contentType: 'image/bmp', image: '/image/filetype/image.png' },
    { contentType: 'image/gif', image: '/image/filetype/image.png' },
    { contentType: 'image/vnd.microsoft.icon', image: '/image/filetype/image.png' },
    { contentType: 'image/heic', image: '/image/filetype/image.png' },

    { contentType: 'application/vnd.ms-excel', image: '/image/filetype/excel.png' },
    { contentType: 'application/msword', image: '/image/filetype/word.png' },
    { contentType: 'application/vnd.ms-powerpoint', image: '/image/filetype/powerpoint.png' },
    { contentType: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', image: '/image/filetype/excel.png' },
    { contentType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', image: '/image/filetype/word.png' },
    { contentType: 'application/vnd.openxmlformats-officedocument.presentationml.presentation', image: '/image/filetype/powerpoint.png' }
];

export const getFileTypeIcon = (contentType: string) => {
    if (contentType.split('/')[0] === 'application') {
        switch (contentType.split('/')[1]) {
            case FILE_TYPES_FOR_ICONS.APPLICATON_TYPES.PDF:
                return '/image/fileTypeIcons/fileTypePdf.svg';
            case FILE_TYPES_FOR_ICONS.APPLICATON_TYPES.DOC:
            case FILE_TYPES_FOR_ICONS.APPLICATON_TYPES.WORD:
            case FILE_TYPES_FOR_ICONS.APPLICATON_TYPES.WORD2:
                return '/image/fileTypeIcons/fileTypeDoc.svg';
            case FILE_TYPES_FOR_ICONS.APPLICATON_TYPES.EXCEL:
            case FILE_TYPES_FOR_ICONS.APPLICATON_TYPES.EXCEL2:
                return '/image/fileTypeIcons/fileTypeXls.svg';
            case FILE_TYPES_FOR_ICONS.APPLICATON_TYPES.PPT:
            case FILE_TYPES_FOR_ICONS.APPLICATON_TYPES.PPT2:
                return '/image/fileTypeIcons/fileTypePPT.svg';
            case FILE_TYPES_FOR_ICONS.APPLICATON_TYPES.ZIP:
            case FILE_TYPES_FOR_ICONS.APPLICATON_TYPES.ZIP2:
                return '/image/fileTypeIcons/fileTypeZip.svg';
            default:
                return '/image/fileTypeIcons/fileTypeGeneric.svg';
        }
    }
    switch (contentType.split('/')[0]) {
        case FILE_TYPES_FOR_ICONS.AUDIO:
            return '/image/fileTypeIcons/fileTypeAudio.svg';
        case FILE_TYPES_FOR_ICONS.IMAGE:
            return '/image/fileTypeIcons/fileTypeImg.svg';
        case FILE_TYPES_FOR_ICONS.VIDEO:
            return '/image/fileTypeIcons/fileTypeVideo.svg';
        default:
            return '/image/fileTypeIcons/fileTypeGeneric.svg';
    }
};

export const formatSizeUnits = (bytes: number | any) => {
    if (bytes >= 1073741824) {
        bytes = (bytes / 1073741824).toFixed(2) + ' GB';
    } else if (bytes >= 1048576) {
        bytes = (bytes / 1048576).toFixed(2) + ' MB';
    } else if (bytes >= 1024) {
        bytes = (bytes / 1024).toFixed(2) + ' KB';
    } else if (bytes > 1) {
        bytes = bytes + ' bytes';
    } else if (bytes === 1) {
        bytes = bytes + ' byte';
    } else {
        bytes = '0 bytes';
    }
    return bytes;
};

export const countEmojis = (str: string) => {
    return new Promise((resolve) => {
        const emojiPattern = EmojiPattern;
        const isContainsCharacters = (str || '').match(/[a-z|A-Z|$&+,:;=?@#|'<>.^*()%!-]/g);
        const emojiCount = ((str || '').match(emojiPattern) || []).length;
        if (isContainsCharacters) {
            resolve({ onlyEmoji: false, emojiCount: emojiCount });
        } else {
            resolve({ onlyEmoji: true, emojiCount: emojiCount });
        }
    })

}