import React from 'react';
import { Chip, Typography } from '@mui/material';
import { ApiHashtag } from '../../_api/_ApiModels';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import Callout from './Callout';
import UserAvatar from './UserAvatar';
import { NotificationsOffOutlined } from '@mui/icons-material';
import { useSelector } from '../../_redux/_Store';

interface HashtagTooltipProps {
    apiHashtag: ApiHashtag;
    onClickHandler?: Function;
}

interface UserHashtag {
    userId: string;
    notifications: boolean;
    name: string;
}

const HashtagTooltip = React.memo<HashtagTooltipProps>(({ apiHashtag, onClickHandler }) => {
    const [userHashtagList, setUserHashtagList] = React.useState<Array<UserHashtag>>([]);
    const apiUserList = useSelector((state) => state.data.apiUserList);
    React.useEffect(() => {
        setUserHashtagList([
            ...apiHashtag.subscriptionUserIdList
                .map((userId) => {
                    return { userId: userId, name: apiUserList[userId]?.callout, notifications: apiHashtag.notificationUserIdList.findIndex((obj) => obj === userId) > -1 };
                })
                .sort((a, b) => {
                    return a.name.localeCompare(b.name);
                })
        ]);
    }, [apiHashtag.notificationUserIdList, apiHashtag.subscriptionUserIdList, apiUserList]);
    return (
        <div className="commonPopover_Wrapper">
            <div className="commonPopover_Header">
                {/* <Typography variant="body2">{apiHashtag.description}</Typography> */}
                <Typography variant="h2" sx={{ cursor: 'pointer' }} onClick={() => onClickHandler && onClickHandler()}>
                    {apiHashtag.name}
                </Typography>
            </div>
            <Typography variant="h3">Subscribers ({apiHashtag.subscriptionUserIdList.length})</Typography>
            <ul className="commonPopover_list">
                {userHashtagList.map((userHashtag) => (
                    <li key={userHashtag.userId}>
                        <div className="commonPopover_userDetails">
                            <UserAvatar userId={userHashtag.userId} displayOnlineIndicator={false} size={24} />
                            {<Chip label={<Callout userId={userHashtag.userId} />} />}
                        </div>
                        <button className="unStyledButton">{userHashtag.notifications ? <NotificationsActiveIcon fontSize="small" /> : <NotificationsOffOutlined fontSize="small" />}</button>
                    </li>
                ))}
            </ul>
        </div>
    );
});

export default HashtagTooltip;
