import React from 'react';
import ReconnectingWebSocket from 'reconnecting-websocket';
import { useAuth } from './Auth';
import { getWebSocketUrl } from '../env';
import { log } from '../_global/Helpers';
import { updateHashtag, updateTag, updateUser, updateUserHashtag, userIdIsOffline, userIdIsOnline } from '../_redux/DataReducer';
import { addTyping, removeTyping } from '../_redux/TypingReducer';
import { TYPING_TIMEOUT_MILLISECONDS } from '../_global/Constants';
import {
    setPayload,
    WebSocketPayload,
    WebSocketPayloadHashtag,
    WebSocketPayloadNewMessageNotification,
    WebSocketPayloadTag,
    WebSocketPayloadTyping,
    WebSocketPayloadUser,
    WebSocketPayloadUserHashtag,
    WebSocketPayloadUserOnline
} from '../_redux/WebSocketReducer';
import { useNotification } from './Notification';
import { useDispatch } from 'react-redux';
import { useSelector } from '../_redux/_Store';

interface WebSocket {
    webSocket?: ReconnectingWebSocket;
    addOnConnectCallback: (callback: Function) => void;
    removeOnConnectCallback: (callback: Function) => void;
}

interface WebSocketProps {
    children?: React.ReactNode;
}

const WebSocketContext = React.createContext<WebSocket>({
    addOnConnectCallback: () => {},
    removeOnConnectCallback: () => {}
});

export interface WebSocketPayloadPing extends WebSocketPayload {
    interval: number;
}

export interface WebSocketPayloadPong extends WebSocketPayload {
    interval: number;
}

export enum WEBSOCKET_PAYLOAD_TYPE {
    PING = 'PING',
    PONG = 'PONG',
    MESSAGE = 'MESSAGE',
    MESSAGE_THREAD = 'MESSAGE_THREAD',
    NEW_MESSAGE_NOTIFICATION = 'NEW_MESSAGE_NOTIFICATION',
    MESSAGE_UPDATED = 'MESSAGE_UPDATED',
    MESSAGE_THREAD_SUBJECT = 'MESSAGE_THREAD_SUBJECT',
    MESSAGE_THREAD_SEEN = 'MESSAGE_THREAD_SEEN',
    MESSAGE_THREAD_STARRED = 'MESSAGE_THREAD_STARRED',
    MESSAGE_THREAD_ARCHIVED = 'MESSAGE_THREAD_ARCHIVED',
    HASHTAG = 'HASHTAG',
    TAG = 'TAG',
    USER_HASHTAG = 'USER_HASHTAG',
    USER = 'USER',
    USER_ONLINE = 'USER_ONLINE',
    TYPING = 'TYPING'
}

export const WebSocketProvider = React.memo<WebSocketProps>((websocketProps) => {
    const auth = useAuth();
    const dispatch = useDispatch();
    const notification = useNotification();
    const pingMilliseconds = 15000; //every 15 seconds so that the connection isn't disconnected due to inactivity
    const pongTimeoutMilliseconds = 3000;
    const [reconnectingWebSocket, setReconnectingWebSocket] = React.useState<ReconnectingWebSocket>();
    const [pingInterval, setPingInterval] = React.useState<NodeJS.Timeout>();
    const [onConnectCallbackList, setOnConnectCallbackList] = React.useState<Array<Function>>([]);
    const isOnline = useSelector((state) => state.data.isOnline);
    //const [isConnected, setIsConnected] = React.useState(false);
    const setCallbacks = React.useCallback(
        (reconnectingWebSocket: ReconnectingWebSocket) => {
            reconnectingWebSocket.onmessage = (res) => {
                const webSocketPayloadMessage: WebSocketPayload = JSON.parse(res.data);
                switch (webSocketPayloadMessage.type) {
                    case WEBSOCKET_PAYLOAD_TYPE.PONG: {
                        const webSocketPayloadPong: WebSocketPayloadPong = JSON.parse(res.data);
                        clearTimeout(webSocketPayloadPong.interval);
                        break;
                    }
                    case WEBSOCKET_PAYLOAD_TYPE.USER: {
                        const webSocketPayloadUser: WebSocketPayloadUser = JSON.parse(res.data);
                        dispatch(updateUser(webSocketPayloadUser.apiUser));
                        break;
                    }
                    case WEBSOCKET_PAYLOAD_TYPE.TAG: {
                        const webSocketPayloadTag: WebSocketPayloadTag = JSON.parse(res.data);
                        dispatch(updateTag(webSocketPayloadTag.apiTag));
                        break;
                    }
                    case WEBSOCKET_PAYLOAD_TYPE.HASHTAG: {
                        const webSocketPayloadHashtag: WebSocketPayloadHashtag = JSON.parse(res.data);
                        dispatch(updateHashtag(webSocketPayloadHashtag.apiHashtag));
                        break;
                    }
                    case WEBSOCKET_PAYLOAD_TYPE.USER_HASHTAG: {
                        const webSocketPayloadUserHashtag: WebSocketPayloadUserHashtag = JSON.parse(res.data);
                        dispatch(updateUserHashtag(webSocketPayloadUserHashtag.apiUserHashtag));
                        break;
                    }
                    case WEBSOCKET_PAYLOAD_TYPE.USER_ONLINE: {
                        const webSocketPayloadUserOnline: WebSocketPayloadUserOnline = JSON.parse(res.data);
                        if (webSocketPayloadUserOnline.isOnline) {
                            dispatch(userIdIsOnline(webSocketPayloadUserOnline.userId));
                        } else {
                            dispatch(userIdIsOffline(webSocketPayloadUserOnline.userId));
                        }
                        break;
                    }
                    case WEBSOCKET_PAYLOAD_TYPE.TYPING: {
                        const webSocketPayloadTyping: WebSocketPayloadTyping = JSON.parse(res.data);
                        dispatch(addTyping(webSocketPayloadTyping.apiTyping));
                        setTimeout(() => {
                            if (webSocketPayloadTyping.apiTyping) {
                                dispatch(removeTyping(webSocketPayloadTyping.apiTyping));
                            }
                        }, TYPING_TIMEOUT_MILLISECONDS);
                        break;
                    }
                    case WEBSOCKET_PAYLOAD_TYPE.NEW_MESSAGE_NOTIFICATION: {
                        const webSocketPayloadNewMessageNotification: WebSocketPayloadNewMessageNotification = JSON.parse(res.data);
                        notification.displayApiMessage(webSocketPayloadNewMessageNotification);
                        //dispatch(removeTyping({ groupId: apiMessage.groupId, userId: apiMessage.userId, name: '' }));
                        break;
                    }
                    default: {
                        dispatch(setPayload(JSON.parse(res.data)));
                        break;
                    }
                }
            };
        },
        [dispatch, notification]
    );
    React.useEffect(() => {
        if (!auth.isTokenSet || reconnectingWebSocket) return;
        log('init web socket');
        const openWebsocket = new ReconnectingWebSocket(`${getWebSocketUrl()}/sock?t=${auth.getToken()}`);
        openWebsocket.onclose = () => {
            log(`webSocket.current.onclose called ${pingInterval}`);
            //setIsConnected(false);
            if (pingInterval != null) {
                clearInterval(pingInterval);
            }
        };
        openWebsocket.onopen = () => {
            setPingInterval(
                setInterval(() => {
                    if (openWebsocket.readyState === WebSocket.OPEN) {
                        const pongInterval = setTimeout(() => {
                            log('ERROR pong not received');
                            openWebsocket.reconnect();
                        }, pongTimeoutMilliseconds);
                        openWebsocket?.send(JSON.stringify({ type: 'PING', interval: pongInterval }));
                    }
                }, pingMilliseconds)
            );
            //setIsConnected(true);
            log('webSocket.current.onopen called');
        };
        setCallbacks(openWebsocket);
        setReconnectingWebSocket(openWebsocket);
        /*
        } else if (reconnectingWebSocket) {
            log('web socket closed due to logout');
            if (pingInterval) {
                clearInterval(pingInterval);
            }
            reconnectingWebSocket.close();
            setReconnectingWebSocket(undefined);
        }
        */
        return () => {
            try {
                if (openWebsocket) {
                    log('websocket closed');
                    openWebsocket.close();
                    setReconnectingWebSocket(undefined);
                }
            } catch (err) {
                log(err);
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth.isTokenSet]);
    React.useEffect(() => {
        if (!reconnectingWebSocket) return;
        const onReconnect = () => {
            if (reconnectingWebSocket.retryCount > 0) {
                log(`webSocket reconnected`);
                log(`${onConnectCallbackList.length} callbacks registered`);
                onConnectCallbackList.forEach((callback) => {
                    try {
                        callback();
                    } catch (err) {
                        log(err);
                    }
                });
            }
        };
        reconnectingWebSocket.addEventListener('open', onReconnect);
        return () => {
            reconnectingWebSocket.removeEventListener('open', onReconnect);
        };
    }, [onConnectCallbackList, reconnectingWebSocket]);
    React.useEffect(() => {
        if (!isOnline && reconnectingWebSocket) {
            reconnectingWebSocket.reconnect();
        }
    }, [isOnline, reconnectingWebSocket]);
    const webSocket = React.useMemo<WebSocket>(() => {
        return {
            webSocket: reconnectingWebSocket,
            addOnConnectCallback: (callback) => {
                setOnConnectCallbackList((state) => [...state, callback]);
                log('callback added');
            },
            removeOnConnectCallback: (callback) => {
                setOnConnectCallbackList((state) => [...state].filter((obj) => obj !== callback));
                log('callback removed');
            }
        };
    }, [reconnectingWebSocket]);
    return <WebSocketContext.Provider value={webSocket}>{websocketProps.children}</WebSocketContext.Provider>;
});

export const useWebSocket = () => React.useContext(WebSocketContext);
