import produce from 'immer';
import { ApiTyping } from '../_api/_ApiModels';

export enum TYPING_ACTION_TYPE {
    TYPING_CLEAR = 'TYPING_CLEAR',
    TYPING_ADD = 'TYPING_ADD',
    TYPING_REMOVE = 'TYPING_REMOVE'
}

export interface TypingAction {
    type: TYPING_ACTION_TYPE;
    payload: ApiTyping;
}

export const clearTyping = () => {
    return {
        type: TYPING_ACTION_TYPE.TYPING_CLEAR
    };
};

export const addTyping = (apiTyping: ApiTyping) => {
    return {
        type: TYPING_ACTION_TYPE.TYPING_ADD,
        payload: apiTyping
    };
};

export const removeTyping = (apiTyping: ApiTyping) => {
    return {
        type: TYPING_ACTION_TYPE.TYPING_REMOVE,
        payload: apiTyping
    };
};

export interface TypingState {
    apiTypingList: Array<ApiTyping>;
}

const initTypingState: TypingState = {
    apiTypingList: []
};

export const TypingReducer = (state = initTypingState, action: TypingAction) => {
    switch (action.type) {
        case TYPING_ACTION_TYPE.TYPING_CLEAR: {
            return initTypingState;
        }
        case TYPING_ACTION_TYPE.TYPING_ADD: {
            const apiTyping = action.payload as ApiTyping;
            return produce(state, (draft) => {
                const i = draft.apiTypingList.findIndex((obj) => {
                    return obj.userId === apiTyping.userId && obj.messageThreadId === apiTyping.messageThreadId;
                });
                if (i > -1) {
                    draft.apiTypingList.splice(i, 1);
                }
                draft.apiTypingList.push(apiTyping);
            });
        }
        case TYPING_ACTION_TYPE.TYPING_REMOVE: {
            const apiTyping = action.payload as ApiTyping;
            return produce(state, (draft) => {
                const i = draft.apiTypingList.findIndex((obj) => {
                    return obj.typingId === apiTyping.typingId;
                });
                if (i > -1) {
                    draft.apiTypingList.splice(i, 1);
                }
            });
        }
        default:
            return state;
    }
};
