import React from 'react';
import { AxiosResponse } from 'axios';
import { GetMessageProfileResponse, useApi } from '../../providers/Api';
import UserAvatar from '../common/UserAvatar';
import moment from 'moment';
import { useSelector } from '../../_redux/_Store';
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import MessageThreadMessage from '../inbox/MessageThreadMessage';
import UserName from '../common/UserName';
import { useDrawer } from '../../providers/Drawer';
import { Reaction, ReactionList } from '../../_global/Constants';
import { ApiReaction } from '../../_api/_ApiModels';

const CALENDAR_FORMATS = {
    sameDay: '[Today] h:mm A',
    lastDay: '[Yesterday] h:mm A',
    lastWeek: 'ddd h:mm A',
    lastElse: 'ddd M/D h:mm A',
    sameElse: 'ddd M/D h:mm A'
};

interface MessageProfileProps {
    messageThreadId: string;
    messageId: string;
}

const MessageProfile = React.memo<MessageProfileProps>(({ messageThreadId, messageId }) => {
    const api = useApi();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const drawer = useDrawer();
    const [messageProfile, setMessageProfile] = React.useState<GetMessageProfileResponse>();
    React.useEffect(() => {
        if (messageThreadId && messageId) {
            api.getMessageProfile({ messageThreadId: messageThreadId, messageId: messageId }, (axiosResponse: AxiosResponse) => {
                const getMessageProfileResponse: GetMessageProfileResponse = axiosResponse.data;
                setMessageProfile(getMessageProfileResponse);
            });
        }
    }, [api, messageThreadId, messageId]);
    return (
        <>
            {messageProfile && messageId && messageThreadId ? (
                <div className="messageProfile_wrapper">
                    <div className="messageProfile_messageHeader">
                        <button
                            onClick={() => {
                                drawer.close();
                            }}
                            className="drawerCloseBtn"
                        >
                            <CloseIcon />
                        </button>
                        <h2>Message Info</h2>
                    </div>
                    <div className="messageProfile_body">
                        <div className="messageProfile_messageData">
                            <div className="messageThread_chatBubble">
                                <MessageThreadMessage
                                    messageThreadId={messageThreadId}
                                    messageId={messageId}
                                    createTime={messageProfile.apiMessage.createTime}
                                    createdUserId={messageProfile.apiMessage.createdUserId}
                                    text={messageProfile.apiMessage.text}
                                    editTime={messageProfile.apiMessage.editTime}
                                    apiReactionList={messageProfile.apiMessage.apiReactionList}
                                    apiFileList={messageProfile.apiMessage.apiFileList}
                                    apiGifList={messageProfile.apiMessage.apiGifList}
                                    isDeleted={messageProfile.apiMessage.deleted}
                                    isSent={true}
                                    isSameUser={messageProfile.apiMessage.createdUserId === loggedInUserId}
                                    displayMenuButton={false}
                                    isFirstInGroup={false}
                                    isInGroup={false}
                                    isLastInGroup={false}
                                    isSeen={messageProfile.apiMessage.isSeen}
                                />
                            </div>
                        </div>
                        <div className="messageProfile_seenList">
                            <div className="messageProfile_listHead">
                                <VisibilityOutlined />
                                Seen <span className="count">({messageProfile.apiMessageSeenList.length - 1})</span>
                            </div>
                            <ul className="commonList">
                                {messageProfile.apiMessageSeenList
                                    .filter((obj) => obj.userId !== messageProfile.apiMessage.createdUserId)
                                    .map((apiMessageSeen) => (
                                        <li key={apiMessageSeen.userId}>
                                            <div className="messageProfile_nameAvatar">
                                                <div>
                                                    <UserAvatar userId={apiMessageSeen.userId} />
                                                </div>
                                                <div className="messageProfile_userName ellip">
                                                    <UserName userId={apiMessageSeen.userId} />
                                                </div>
                                            </div>
                                            <div className="messageProfile_reactionDetails">
                                                <div className="messageProfile_reactionWrap">
                                                    <MessageProfileUserReactionList apiReactionList={messageProfile.apiMessage.apiReactionList.filter((obj) => obj.userId === apiMessageSeen.userId)} />
                                                </div>
                                                <div className="messageProfile_seenTime">{moment.unix(apiMessageSeen.seenTime).calendar(CALENDAR_FORMATS)}</div>
                                            </div>
                                        </li>
                                    ))}
                            </ul>
                        </div>
                        <div className="messageProfile_unseenList">
                            <div className="messageProfile_listHead">
                                <VisibilityOffOutlined />
                                Not Seen <span className="count">({messageProfile.notSeenUserIdList.length})</span>
                            </div>
                            <ul className="commonList">
                                {messageProfile.notSeenUserIdList.map((userId) => (
                                    <li key={userId}>
                                        <div className="messageProfile_nameAvatar">
                                            <div>
                                                <UserAvatar userId={userId} />
                                            </div>
                                            <div>
                                                <UserName userId={userId} />
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
});

interface MessageProfileUserReactionListProps {
    apiReactionList: Array<ApiReaction>;
}

const MessageProfileUserReactionList = React.memo<MessageProfileUserReactionListProps>(({ apiReactionList }) => {
    const [reactionList, setReactionList] = React.useState<Array<Reaction>>([]);
    React.useEffect(() => {
        const tempReactionList = [] as Array<Reaction>;
        ReactionList.forEach((reaction) => {
            const apiReaction = apiReactionList.find((obj) => obj.type === reaction.type);
            if (apiReaction) {
                tempReactionList.push(reaction);
            }
        });
        setReactionList(tempReactionList);
    }, [apiReactionList]);
    return (
        <>
            {reactionList.map((reaction) => (
                <div className="messageProfile_reaction" key={reaction.type}>
                    <img src={reaction.loggedInUserImage} alt="emoji" />
                </div>
            ))}
        </>
    );
});

export default MessageProfile;
