import { Divider } from '@mui/material';
import React from 'react';
import { useOutlet } from 'react-router-dom';
import PlaceholderIcon from '../../assets/images/placeholder-thread.svg';
import SubscriptionsHeader from './SubscriptionsHeader';
import UserHashtagList from './UserHashtagList';

const Subscriptions = React.memo(() => {
    const outlet = useOutlet();
    return (
        <>
            <div className="subscriptions_Wrapper">
                <SubscriptionsHeader />
                <div className="subscriptions_contents">
                    <UserHashtagList />
                </div>
            </div>
            <Divider orientation="vertical" />
            <div className="messageThreadList_messageThreadWrapper">
                {outlet || (
                    <div className="messageThreadList_placeholder">
                        <img src={PlaceholderIcon} alt="placeholder-message-thread" />
                    </div>
                )}
            </div>
        </>
    );
});

export default Subscriptions;
