import React from 'react';
import { useSelector } from '../../_redux/_Store';
import { ApiUser } from '../../_api/_ApiModels';
import UserName from '../common/UserName';

interface TypingProps {
    messageThreadId: string;
}

const Typing = React.memo<TypingProps>(({ messageThreadId }) => {
    const MAX_USERS_TO_DISPLAY = 2;
    const apiTypingList = useSelector((state) => state.typing.apiTypingList);
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const loggedInUserId = useSelector((state) => state.data.userId);
    const [typingApiUserList, setTypingApiUserList] = React.useState<Array<ApiUser>>([]);
    React.useEffect(() => {
        const tempTypingApiUserList = [] as Array<ApiUser>;
        apiTypingList
            .filter((obj) => {
                return obj.messageThreadId === messageThreadId && obj.userId !== loggedInUserId;
            })
            .forEach((apiTyping) => {
                tempTypingApiUserList.push(apiUserList[apiTyping.userId]);
            });
        setTypingApiUserList(tempTypingApiUserList);
    }, [apiTypingList, apiUserList, loggedInUserId, messageThreadId]);
    return (
        <>
            {typingApiUserList && (
                <div>
                    {typingApiUserList.slice(0, MAX_USERS_TO_DISPLAY).map((apiUser, index) => (
                        <span key={apiUser.userId}>
                            {index === 1 ? ' and ' : ''}
                            <UserName userId={apiUser.userId} />
                        </span>
                    ))}
                    {typingApiUserList.length > 2 ? ' and ' + (typingApiUserList.length - 2) + ' others' : ''}
                    {typingApiUserList.length === 1 ? ' is ' : typingApiUserList.length > 1 ? ' are ' : ''}
                    {typingApiUserList.length > 0 ? 'typing' : ''}
                </div>
            )}
        </>
    );
});

export default Typing;
