import React from 'react';
import { FilterListOutlined, Cancel } from '@mui/icons-material';
import HashtagCalloutInput from '../common/HashtagCalloutInput';
import SearchIcon from '@mui/icons-material/Search';
import ManageSubscriptionIcon from '../../assets/images/subscriptions.svg';
import { useDrawer } from '../../providers/Drawer';
import { Button } from '@mui/material';
import UserHashtagFilterPillList from './UserHashtagFilterPillList';
import { USER_HASHTAG_FILTER_TYPE, useUserHashtagFilter } from '../../providers/UserHashtagFilter';

const SubscriptionsHeader = React.memo(() => {
    const drawer = useDrawer();
    const [search, setSearch] = React.useState('');
    const userHashtagFilter = useUserHashtagFilter();
    const onTextSearch = React.useCallback(() => {
        const newFilterList = userHashtagFilter.filterList ? [...userHashtagFilter.filterList].filter((obj) => obj.type !== USER_HASHTAG_FILTER_TYPE.TEXT) : [];
        newFilterList.push({
            type: USER_HASHTAG_FILTER_TYPE.TEXT,
            value: search
        });
        userHashtagFilter.navigateToSearch(newFilterList);
    }, [userHashtagFilter, search]);
    const onClearSearch = React.useCallback(() => {
        setSearch('');
        const newFilterList = userHashtagFilter.filterList ? [...userHashtagFilter.filterList].filter((obj) => obj.type !== USER_HASHTAG_FILTER_TYPE.TEXT) : [];
        userHashtagFilter.navigateToSearch(newFilterList);
    }, [userHashtagFilter]);
    const onFilterDelete = React.useCallback(
        (type: USER_HASHTAG_FILTER_TYPE, value?: string) => {
            switch (type) {
                case USER_HASHTAG_FILTER_TYPE.TAG_ID_LIST: {
                    if (value) userHashtagFilter.removeFilterListValue(type, value);
                    break;
                }
                default: {
                    userHashtagFilter.removeFilter([type]);
                    break;
                }
            }
        },
        [userHashtagFilter]
    );
    React.useEffect(() => {
        const textFilter = userHashtagFilter.filterList?.find((obj) => obj.type === USER_HASHTAG_FILTER_TYPE.TEXT);
        setSearch(textFilter ? (textFilter.value as string) : '');
    }, [userHashtagFilter]);
    return (
        <React.Fragment>
            <div className="commonTop_headingInfo">
                <div className="commonTop_heading">
                    <img src={ManageSubscriptionIcon} alt="manage-subscription-icon" />
                    <h2>Manage Subscriptions</h2>
                </div>
                <div className="commonTop_filter">
                    <Button
                        variant="outlined"
                        className="commonTop_filterBtn"
                        onClick={() => {
                            drawer.openSubscriptionThreadFilter();
                        }}
                    >
                        <FilterListOutlined /> Filter
                    </Button>
                </div>
            </div>
            <div className="commonTop_searchBar mb16">
                <SearchIcon className="commonTop_searchIcon" />
                <HashtagCalloutInput
                    placeholder="Search hashtags"
                    allowSuggestionsAboveCursor={true}
                    className="commonTop_Search"
                    value={search}
                    onKeyPress={(e) => {
                        if (e.key === 'Enter' && !e.shiftKey) {
                            e.preventDefault();
                            onTextSearch();
                        }
                    }}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                    singleLine={true}
                />
                {search?.length > 0 && (
                    <button onClick={onClearSearch} className="drawerCloseBtn commonTop_closeIcon">
                        <Cancel />
                    </button>
                )}
            </div>
            {userHashtagFilter.filterList && userHashtagFilter.filterList.length > 0 && (
                <div className="commonTop_searchResult">
                    <div className="commonTop_filter">
                        <span>Filters ({userHashtagFilter.displayCount}):</span>
                    </div>
                    <div className="commonTop_pillsList">
                        <UserHashtagFilterPillList userHashtagFilterList={userHashtagFilter.filterList} onDelete={onFilterDelete} displayClearAll={true} />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
});

export default SubscriptionsHeader;
