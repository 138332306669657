import React from "react";
import fileZip from '../../assets/images/icons/file-zip.svg';
import { formatSizeUnits } from "../../_global/FileUtils";
interface UnknownFilesProps{
fileName: string;
fileSize: number;
}
const UnknownFiles = React.memo<UnknownFilesProps>(({fileName, fileSize}) => {
        return (
            <div className="commonSingleFileWrapper" title={fileName}>
                <div className="iconType">
                    <img src={fileZip} alt="file-type-icon" />
                </div>
                <div className="fileData">
                    <div className="fileName" >{fileName}</div>
                    <div className="fileSize">{formatSizeUnits(fileSize)}</div>
                </div>
            </div>
        )
});
export default UnknownFiles;