import React from 'react';
import { Alert, Button, MenuItem, Modal, Select, TextField } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from '../../_redux/_Store';
import { useApi } from '../../providers';
import ConfirmationModal from '../common/NotificationModal';
import SuccessSvg from '../../assets/images/success.svg';

interface CreateTopicModalProps {
    isOpen: boolean;
    onCloseCallback: () => void;
}

const CreateTagModal = React.memo<CreateTopicModalProps>(({ isOpen, onCloseCallback }) => {
    const { apiTagCategoryList, apiTagList } = useSelector((state) => state.data);
    const api = useApi();
    const [isDuplciate, setIsDuplicate] = React.useState(false);
    const [name, setName] = React.useState('');
    const [tagCategoryId, setTagCategoryId] = React.useState<string>('-1');
    const [isConfirmationModal, setIsConfirmationModal] = React.useState<{isShow: boolean, name: string | null, tagCategoryName: string | null}>({isShow: false, name: null, tagCategoryName: null});;
    React.useEffect(() => {
        setIsDuplicate(Object.values(apiTagList).findIndex((obj) => obj.name === name) > -1 ? true : false);
    }, [apiTagList, name]);
    const handleCloseCreateTag = React.useCallback(() => {
        setIsDuplicate(false);
        setName('');
        setTagCategoryId('-1');
        onCloseCallback();
    }, [onCloseCallback]);
    const onSaveClicked = React.useCallback(() => {
        if (!tagCategoryId) return;
        api.createTag({ name: name, tagCategoryId: tagCategoryId }, () => {
            setIsConfirmationModal({isShow: true, name, tagCategoryName: apiTagCategoryList[tagCategoryId]?.name});
            handleCloseCreateTag();
        });
    }, [api, name, tagCategoryId, apiTagCategoryList, handleCloseCreateTag]);
    return (
        <>
            <Modal open={isOpen} onClose={handleCloseCreateTag}>
                <div className="commonModal_wrapper createTag">
                    <div className="commonModal_header">
                        <span className="commonModal_headerText">Create Topic</span>
                        <button type="button" className="commonModal_headerClose" onClick={handleCloseCreateTag}>
                            <CloseIcon />
                        </button>
                    </div>
                    <div className="commonModal_body">
                        <div className="common_form_input">
                            <label className="common_form_label">Name</label>
                            {isDuplciate && (
                                <Alert severity="error" className="error">
                                    A topic with this name already exists.
                                </Alert>
                            )}
                            <TextField
                                variant="outlined"
                                className={"common-input" + (isDuplciate ?  " error" : "")}
                                type="text"
                                value={name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </div>
                        <div className="mt16 mb16">
                            <label className="common_form_label">Category</label>
                            <Select
                                className="common-select"
                                value={tagCategoryId}
                                onChange={(e) => {
                                    setTagCategoryId(e.target.value);
                                }}
                            >
                                <MenuItem value="-1">Select Category</MenuItem>
                                {Object.values(apiTagCategoryList).map((apiTagCategory) => (
                                    <MenuItem value={apiTagCategory.tagCategoryId} key={apiTagCategory.tagCategoryId}>
                                        {apiTagCategory.name}
                                    </MenuItem>
                                ))}
                            </Select>
                        </div>
                    </div>
                    <div className="commonModal_footer">
                        <Button color="primary" variant="contained" onClick={onSaveClicked} disabled={!name.length || isDuplciate || tagCategoryId === '-1'}>
                            Save
                        </Button>
                        <Button color="secondary" variant="contained" onClick={handleCloseCreateTag}>
                            Cancel
                        </Button>
                    </div>
                </div>
            </Modal>
            <ConfirmationModal
                isOpen={isConfirmationModal.isShow}
                title="Topic Created!"
                imgSrc={SuccessSvg}
                content={
                    <div>
                        <p>
                            {isConfirmationModal.tagCategoryName ? (
                                <>
                                    A new topic <strong>{`"${isConfirmationModal.name}"`}</strong> has been created in the category <strong>{isConfirmationModal.tagCategoryName}</strong>
                                </>
                            ) : (
                                <>
                                    A new topic <strong>{`"${isConfirmationModal.name}"`}</strong> has been created.
                                </>
                            )}
                        </p>
                    </div>
                }
                onCloseCallback={() => {
                    setIsConfirmationModal({isShow: false, name: null, tagCategoryName: null});
                }}
            />
        </>
    );
});

export default CreateTagModal;
