import React from 'react';
import reactStringReplace from 'react-string-replace';
import Hashtag from '../common/Hashtag';
import Callout from '../common/Callout';
import Linkify from 'linkify-react';

interface MessageTextProps {
    text: string;
    linkify?: boolean;
}

const MessageText = React.memo<MessageTextProps>(({ text, linkify = false }) => {
    const [renderedMessageNodeArray, setRenderedMessageNodeArray] = React.useState<React.ReactNodeArray>();
    React.useEffect(() => {
        setRenderedMessageNodeArray(
            reactStringReplace(
                reactStringReplace(text, /{{hid=(\w+)}}/g, (hashtagId, i) => <Hashtag key={`h${i}`} hashtagId={hashtagId} />),
                /{{cid=(\w+)}}/g,
                (userId, i) => {
                    return <Callout key={`c${i}`} userId={userId} />;
                }
            )
        );
    }, [text]);
    return (
        <>
            {renderedMessageNodeArray && (
                <>
                    {linkify ? (
                        <Linkify
                            options={{
                                target: '_blank'
                            }}
                        >
                            {renderedMessageNodeArray}
                        </Linkify>
                    ) : (
                        <>{renderedMessageNodeArray}</>
                    )}
                </>
            )}
        </>
    );
});

export default MessageText;
