import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import { NavLink, useLocation } from 'react-router-dom';
import { ListChildComponentProps } from 'react-window';
import { useApi } from '../../providers/Api';
import { useSnackbar } from '../../providers/Snackbar';
import { useMessageThreadNav } from '../../providers/MessageThreadNav';
import { INBOX_MESSAGE_THREAD_CALENDAR_FORMATS } from '../../_global/Constants';
import { LAYOUT_TYPE, SCREEN_TYPE } from '../../_global/Route';
import { useSelector } from '../../_redux/_Store';
import UserAvatar from '../common/UserAvatar';
import MessageText from './MessageText';
import { Star, StarOutline, Attachment, NotificationsActive, Inbox, NotificationsOffOutlined } from '@mui/icons-material';
import InboxOff from '../../assets/images/icons/inbox_slash.svg';
import UserName from '../common/UserName';
import { TOOLTIP_TEXTS } from '../../_global/Constants';
import MessageThreadTagPillList from './MessageThreadTagPillList';

export enum DATE_SEPARATOR_TYPE {
    TODAY,
    YESTERDAY,
    LAST_WEEK,
    MONTH
}

export interface InboxMessageThreadProps {
    messageThreadId: string;
    subject: string;
    isStarred: boolean;
    isSeen: boolean;
    isInbox: boolean;
    isNotification: boolean;
    sortTime: number;
    messageCount: number;
    fileCount: number;
    lastMessageId: string;
    lastMessageCreatedUserId: string;
    lastMessageText: string;
    lastMessageCreateTime: number;
    lastMessageIsDeleted: boolean;
    dateSeparatorType?: DATE_SEPARATOR_TYPE;
    userIdList: Array<string>;
    tagIdList: Array<string>;
}

export interface MessageThreadProps {
    isFirstMessageThread: boolean;
    isLastMessageThread: boolean;
}

const InboxMessageThread = React.memo((listChildComponentProps: ListChildComponentProps<Array<InboxMessageThreadProps>>) => {
    const api = useApi();
    const snackbar = useSnackbar();
    const location = useLocation();
    const messageThreadNav = useMessageThreadNav();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const typing = useSelector((state) => state.typing.apiTypingList);
    const [inboxMessageThreadProps, setInboxMessageThreadProps] = React.useState<InboxMessageThreadProps>();
    const [messageThreadUrl, setMessageThreadUrl] = React.useState('');
    const [isSeen, setIsSeen] = React.useState(false);
    const [isStarred, setIsStarred] = React.useState(false);
    const [isInbox, setIsInbox] = React.useState(false);
    const [isNotification, setIsNotification] = React.useState(false);
    const [isTyping, setIsTyping] = React.useState(false);
    //const [dateSeparator, setDateSeparator] = React.useState<string>();
    const [messageThreadProps, setMessageThreadProps] = React.useState<MessageThreadProps>();
    const onStarClicked = React.useCallback(
        (event) => {
            event.preventDefault();
            if (!inboxMessageThreadProps) return;
            setIsStarred((state) => !state);
            api.updateStarred({
                messageThreadId: inboxMessageThreadProps.messageThreadId,
                starred: !inboxMessageThreadProps.isStarred
            });
        },
        [inboxMessageThreadProps, api]
    );
    const onUndoInboxClick = React.useCallback(
        (event) => {
            event.preventDefault();
            if (!inboxMessageThreadProps) return;
            api.toggleInbox({ messageThreadId: inboxMessageThreadProps.messageThreadId, inbox: inboxMessageThreadProps.isInbox });
        },
        [api, inboxMessageThreadProps]
    );
    const onInboxClicked = React.useCallback(
        (event) => {
            event.preventDefault();
            if (!inboxMessageThreadProps) return;
            setIsInbox((state) => !state);
            api.toggleInbox({ messageThreadId: inboxMessageThreadProps.messageThreadId, inbox: !inboxMessageThreadProps.isInbox }, () => {
                if (location.pathname.match(`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.MAIN}`)) {
                    snackbar.displayInfo({
                        message: 'Thread Removed from Inbox',
                        leftIcon: <img src={InboxOff} alt="InboxOff" />,
                        action: {
                            label: 'Undo',
                            callback: onUndoInboxClick
                        }
                    });
                    messageThreadNav.next();
                }
            });
        },
        [inboxMessageThreadProps, api, location.pathname, snackbar, onUndoInboxClick, messageThreadNav]
    );
    const onNotificationClicked = React.useCallback(
        (event) => {
            event.preventDefault();
            if (!inboxMessageThreadProps) return;
            setIsNotification((state) => !state);
            api.toggleNotification({ messageThreadId: inboxMessageThreadProps.messageThreadId, notification: !inboxMessageThreadProps.isNotification });
        },
        [api, inboxMessageThreadProps]
    );
    React.useEffect(() => {
        //There appears to be a scenario where the below must be checked when the underlying list doesn't contain the index value briefly (...javascript)
        if (listChildComponentProps.data.length <= listChildComponentProps.index) return;
        const inboxMessageThreadProps = listChildComponentProps.data[listChildComponentProps.index];
        if (listChildComponentProps.index === 0) {
            setMessageThreadProps({ isFirstMessageThread: true, isLastMessageThread: false });
        } else if (listChildComponentProps.index === listChildComponentProps.data.length - 1) {
            setMessageThreadProps({ isFirstMessageThread: false, isLastMessageThread: true });
        } else {
            setMessageThreadProps({ isFirstMessageThread: false, isLastMessageThread: false });
        }
        if (!inboxMessageThreadProps || !loggedInUserId) return;
        setInboxMessageThreadProps(inboxMessageThreadProps);
        setIsInbox(inboxMessageThreadProps.isInbox);
        setIsNotification(inboxMessageThreadProps.isNotification);
        setIsStarred(inboxMessageThreadProps.isStarred);
        setIsSeen(inboxMessageThreadProps.isSeen);
        /*
        switch (inboxMessageThreadProps.dateSeparatorType) {
            case DATE_SEPARATOR_TYPE.TODAY: {
                setDateSeparator('Today');
                break;
            }
            case DATE_SEPARATOR_TYPE.YESTERDAY: {
                setDateSeparator('Yesterday');
                break;
            }
            case DATE_SEPARATOR_TYPE.LAST_WEEK: {
                setDateSeparator('Last Week');
                break;
            }
            case DATE_SEPARATOR_TYPE.MONTH: {
                const startOfYear = moment().startOf('year');
                if (moment.unix(inboxMessageThreadProps.sortTime).isBefore(startOfYear)) {
                    setDateSeparator(moment.unix(inboxMessageThreadProps.sortTime).format('MMM YYYY'));
                    break;
                }
                setDateSeparator(moment.unix(inboxMessageThreadProps.sortTime).format('MMMM'));
                break;
            }
        }
        */
    }, [listChildComponentProps.data, listChildComponentProps.index, loggedInUserId]);
    React.useEffect(() => {
        if (!inboxMessageThreadProps) return;
        if (location.pathname.includes(`${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.MAIN}`)) {
            //TODO: This impl doesn't feel good...
            setMessageThreadUrl(`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.MAIN}/${inboxMessageThreadProps.messageThreadId}${location.search}`);
        } else if (location.pathname.includes(`${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SEARCH}`)) {
            setMessageThreadUrl(`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SEARCH}/${inboxMessageThreadProps.messageThreadId}${location.search}`);
        }
    }, [location, inboxMessageThreadProps]);
    React.useEffect(() => {
        if (!inboxMessageThreadProps) return;
        setIsTyping(
            typing.findIndex((obj) => {
                return obj.userId !== loggedInUserId && obj.messageThreadId === inboxMessageThreadProps.messageThreadId;
            }) > -1
        );
    }, [inboxMessageThreadProps, loggedInUserId, typing]);

    return (
        <>
            {loggedInUserId && inboxMessageThreadProps && (
                <NavLink
                    key={inboxMessageThreadProps.messageThreadId}
                    to={{ pathname: messageThreadUrl }}
                    style={listChildComponentProps.style}
                    state={{ isFirstMessageThread: messageThreadProps?.isFirstMessageThread, isLastMessageThread: messageThreadProps?.isLastMessageThread }}
                    className={`inboxMessageThread_linkItem ${inboxMessageThreadProps.lastMessageIsDeleted ? 'deleted' : ''}`}
                >
                    {/* {dateSeparator && (
                        <div className="messageThreadList_seperator hide">
                            <div className="messageThreadList_borderLine"></div>
                            <div className="messageThreadList_messageUpdateTime">{dateSeparator}</div>
                        </div>
                    )} */}
                    {inboxMessageThreadProps.lastMessageCreatedUserId &&
                        inboxMessageThreadProps.messageThreadId &&
                        inboxMessageThreadProps.lastMessageCreateTime &&
                        inboxMessageThreadProps.lastMessageText &&
                        inboxMessageThreadProps.userIdList && (
                            <li key={inboxMessageThreadProps.messageThreadId}>
                                <div className="inboxMessageThread_itemDV">
                                    <div className="inboxMessageThread_avatar">
                                        <UserAvatar userId={inboxMessageThreadProps.lastMessageCreatedUserId} displayOnlineIndicator={true} />
                                    </div>
                                    <div className="inboxMessageThread_messageInfo">
                                        <div className="inboxMessageThread_users">
                                            <div className="leftPane">
                                                <span className="inboxMessageThread_usersList" style={{ fontWeight: isSeen ? 'normal' : 'bold', color: isSeen ? '#5B5B5B' : '#000' }}>
                                                    {inboxMessageThreadProps.userIdList.map((userId, index) => (
                                                        <React.Fragment key={userId}>
                                                            <UserName userId={userId} displayLastName={index === 0} />
                                                            {index < inboxMessageThreadProps.userIdList.length - 1 ? ', ' : ''}
                                                        </React.Fragment>
                                                    ))}
                                                </span>
                                            </div>
                                            <div className="rightPane">
                                                {inboxMessageThreadProps.fileCount > 0 && <Attachment />}
                                                <span className="inboxMessageThread_timeData" style={{ fontWeight: isSeen ? '500' : 'bold', color: isSeen ? '#5B5B5B' : '#000' }}>
                                                    {moment.unix(inboxMessageThreadProps.lastMessageCreateTime).calendar(INBOX_MESSAGE_THREAD_CALENDAR_FORMATS)}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="inboxMessageThread_title">
                                            <span style={{ fontWeight: isSeen ? '500' : 'bold' }}>
                                                {inboxMessageThreadProps.subject && inboxMessageThreadProps.subject.length > 0 ? inboxMessageThreadProps.subject : 'No Subject'}
                                            </span>
                                        </div>
                                        <div className="inboxMessageThread_subTitle">
                                            {isTyping ? (
                                                <div className="inboxMessageThread_typingIndicator">
                                                    <span></span>
                                                    <span></span>
                                                    <span></span>
                                                </div>
                                            ) : (
                                                <div className="leftPane ellip">
                                                    <MessageText text={inboxMessageThreadProps.lastMessageText} />
                                                </div>
                                            )}

                                            <div className="rightPane">
                                                <Tooltip title={isNotification ? TOOLTIP_TEXTS.turnOffNotification : TOOLTIP_TEXTS.turnOnNotification} placement="bottom-start" arrow>
                                                    <IconButton onClick={onNotificationClicked}>
                                                        {isNotification ? <NotificationsActive className="active" /> : <NotificationsOffOutlined />}
                                                    </IconButton>
                                                </Tooltip>
                                                <Tooltip title={isInbox ? TOOLTIP_TEXTS.removeThread : TOOLTIP_TEXTS.moveThread} placement="bottom-start" arrow>
                                                    <IconButton onClick={onInboxClicked}>{isInbox ? <Inbox className="active" /> : <img src={InboxOff} alt="InboxOff" />}</IconButton>
                                                </Tooltip>
                                                <Tooltip title={isStarred ? TOOLTIP_TEXTS.removeStar : TOOLTIP_TEXTS.addStar} placement="bottom-start" arrow>
                                                    <IconButton onClick={onStarClicked}>{isStarred ? <Star className="starred" /> : <StarOutline />}</IconButton>
                                                </Tooltip>
                                            </div>
                                        </div>
                                        <div className="inboxMessageThread_hashtagsAdded">
                                            <MessageThreadTagPillList tagIdList={inboxMessageThreadProps.tagIdList} />
                                        </div>
                                    </div>
                                </div>
                            </li>
                        )}
                </NavLink>
            )}
        </>
    );
});

export default InboxMessageThread;
