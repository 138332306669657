import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { SUBSCRIPTION_PARAM_TYPE } from '../../_global/Route';
import { useSelector } from '../../_redux/_Store';
import UserName from '../common/UserName';
import UserAvatar from '../common/UserAvatar';
import UserHashtagControl from './UserHashtagControl';
import { InfoOutlined, NotificationsActive, NotificationsOffOutlined, PeopleOutline, RemoveCircleOutline, Chat } from '@mui/icons-material';
import { sortUserIdList } from '../../_global/Helpers';
import { useApi } from '../../providers';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import { MESSAGE_THREAD_SEARCH_FILTER_TYPE } from '../../_api/_ApiModels';

const UserHashtagInfo = React.memo(() => {
    const params = useParams<SUBSCRIPTION_PARAM_TYPE>();
    const api = useApi();
    const messageThreadFilter = useMessageThreadFilter();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const apiHashtagList = useSelector((state) => state.data.apiHashtagList);
    const apiUserHashtagList = useSelector((state) => state.data.apiUserHashtagList);
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const [userHashtagId, setUserHashtagId] = React.useState<string>();
    const [hashtagId, setHashtagId] = React.useState<string>();
    const [hashtagName, setHashtagName] = React.useState<string>('');
    const [hashtagDescription, setHashtagDescription] = React.useState<string>('');
    const [subscriptionUserIdList, setSubscriptionUserIdList] = React.useState<Array<string>>([]);
    const [notificationUserIdList, setNotificationUserIdList] = React.useState<Array<string>>([]);
    const [adminUserIdList, setAdminUserIdList] = React.useState<Array<string>>([]);
    const [isSubscribed, setIsSubscribed] = React.useState<boolean>(false);
    const [isNotification, setIsNotification] = React.useState<boolean>(false);
    const onNotificationClicked = React.useCallback(() => {
        if (!hashtagId) return;
        api.toggleHashtagNotification({
            hashtagId: hashtagId
        });
    }, [api, hashtagId]);
    const onUnsubscribeClicked = React.useCallback(() => {
        if (!hashtagId || !loggedInUserId) return;
        api.unsubscribeHashtag({
            userId: loggedInUserId,
            hashtagId: hashtagId
        });
    }, [api, hashtagId, loggedInUserId]);
    React.useEffect(() => {
        const hashtagId = params.HASHTAG_ID;
        if (!hashtagId || !loggedInUserId) return;
        const apiHashtag = apiHashtagList[hashtagId];
        setHashtagId(apiHashtag.hashtagId);
        setHashtagName(apiHashtag.name);
        setHashtagDescription(apiHashtag.description);
        setSubscriptionUserIdList(sortUserIdList(apiHashtag.subscriptionUserIdList, apiUserList));
        setNotificationUserIdList(apiHashtag.notificationUserIdList);
        setAdminUserIdList(apiHashtag.adminUserIdList);
        setIsSubscribed(apiHashtag.subscriptionUserIdList.includes(loggedInUserId));
        setIsNotification(apiHashtag.notificationUserIdList.includes(loggedInUserId));
        const apiUserHashtag = Object.values(apiUserHashtagList).find((obj) => obj.hashtagId === hashtagId && obj.userId === loggedInUserId);
        setUserHashtagId(apiUserHashtag ? apiUserHashtag.userHashtagId : undefined);
    }, [apiHashtagList, apiUserHashtagList, apiUserList, loggedInUserId, params.HASHTAG_ID]);
    return (
        <div className="userHashtagInfo_wrapper">
            {hashtagId && (
                <>
                    {isSubscribed && (
                        <div className="userHashtagInfo_subscribed_action">
                            <span onClick={onNotificationClicked}>
                                {isNotification ? <NotificationsActive className="active" /> : <NotificationsOffOutlined />}Notifications {isNotification ? 'On' : 'Off'}
                            </span>
                            <Link
                                to={messageThreadFilter.getUrl([
                                    {
                                        type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.HASHTAG_ID_LIST,
                                        value: [hashtagId]
                                    }
                                ])}
                            >
                                <Chat /> View Threads
                            </Link>
                            <span onClick={onUnsubscribeClicked}>
                                <RemoveCircleOutline /> Unsubscribe
                            </span>
                        </div>
                    )}
                    <div className="userHashtagInfo_header">
                        <h2>#{hashtagName}</h2>
                    </div>
                    <div className="userHashtagInfo_body">
                        {!isSubscribed && (
                            <div className="userHashtagInfo_summary">
                                <UserHashtagControl hashtagId={hashtagId} userHashtagId={userHashtagId} />
                            </div>
                        )}
                        <div className="userHashtagInfo_info">
                            <div className="userHashtagInfo_heading">
                                <InfoOutlined /> Info
                            </div>
                            <div className="userHashtagInfo_title">Description:</div>
                            <div className="userHashtagInfo_text">{hashtagDescription}</div>
                            <div className="userHashtagInfo_title mt8">Admin(s):</div>
                            <div className="userHashtagInfo_callouts">
                                {adminUserIdList.map((userId) => (
                                    <div className="admin_hashtag_card" key={userId}>
                                        <UserAvatar size={18} userId={userId} displayOnlineIndicator={false} />
                                        &#64;<UserName userId={userId} />
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="userHashtagInfo_subscriber">
                            <div className="userHashtagInfo_heading">
                                <PeopleOutline /> Subscribers ({subscriptionUserIdList.length})
                            </div>
                            <ul className="commonList">
                                {subscriptionUserIdList.map((userId) => {
                                    return (
                                        <li key={userId}>
                                            <div className="userHashtagInfo_nameAvatar">
                                                <UserAvatar userId={userId} displayOnlineIndicator={false} />
                                                <span className="userHashtagInfo_userName">
                                                    <UserName userId={userId} />
                                                </span>
                                            </div>

                                            <div className="userHashtagInfo_userNotifStatus">{notificationUserIdList.includes(userId) ? <NotificationsActive /> : <NotificationsOffOutlined />}</div>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
});

export default UserHashtagInfo;
