import React from 'react';
import { ApiGif } from '../_api/_ApiModels';
import { useApi, CreateMessageRequest, CreateThreadRequest as ApiCreateThreadRequest } from './Api';
import { useFileUpload, FileInTransit, FILE_STATE_TYPE } from './FileUpload';

interface Message {
    createMessage: (messageThreadId: string, messageId: string, text: string, fileIdList: Array<string>, apiGifList: Array<ApiGif>) => void;
    createThread: (messageThreadId: string, messageId: string, subject: string, text: string, fileIdList: Array<string>, apiGifList: Array<ApiGif>, callback?: Function) => void;
}

const MessageContext = React.createContext<Message>({
    createMessage: () => {},
    createThread: () => {}
});

export interface MessageProps {
    children?: React.ReactNode;
}

interface CreateThreadRequest extends ApiCreateThreadRequest {
    callback?: Function;
}

export const MessageProvider = React.memo<MessageProps>((messageProps) => {
    const api = useApi();
    const fileUpload = useFileUpload();
    const [createMessageRequestList, setCreateMessageRequestList] = React.useState<Array<CreateMessageRequest>>([]);
    const [createThreadRequestList, setCreateThreadRequestList] = React.useState<Array<CreateThreadRequest>>([]);
    const createMessage = React.useCallback((messageThreadId: string, messageId: string, text: string, fileIdList: Array<string>, apiGifList: Array<ApiGif>) => {
        setCreateMessageRequestList((state) => [
            ...state,
            {
                messageThreadId: messageThreadId,
                messageId: messageId,
                text: text,
                fileIdList: fileIdList,
                gifList: apiGifList
            }
        ]);
    }, []);
    const createThread = React.useCallback((messageThreadId: string, messageId: string, subject: string, text: string, fileIdList: Array<string>, apiGifList: Array<ApiGif>, callback?: Function) => {
        setCreateThreadRequestList((state) => [
            ...state,
            {
                messageThreadId: messageThreadId,
                messageId: messageId,
                subject: subject,
                text: text,
                fileIdList: fileIdList,
                gifList: apiGifList,
                callback: callback //TODO: eh... feels hacky
            }
        ]);
    }, []);
    const canSend = React.useCallback((messageId: string, fileInTransitList: Array<FileInTransit>) => {
        let send = true;
        fileInTransitList
            .filter((fileInTransit) => {
                return fileInTransit.messageId === messageId;
            })
            .forEach((fileInTransit) => {
                if (fileInTransit.stateType !== FILE_STATE_TYPE.UPLOADED) {
                    send = false;
                }
            });
        return send;
    }, []);
    React.useEffect(() => {
        createMessageRequestList.forEach((createMessageRequest) => {
            if (canSend(createMessageRequest.messageId, fileUpload.fileInTransitList)) {
                fileUpload.clear(createMessageRequest.messageId);
                setCreateMessageRequestList((state) =>
                    state.filter((obj) => {
                        return obj.messageId !== createMessageRequest.messageId;
                    })
                );
                api.createMessage(createMessageRequest);
            }
        });
    }, [api, canSend, createMessageRequestList, fileUpload, fileUpload.fileInTransitList]);
    React.useEffect(() => {
        createThreadRequestList.forEach((createThreadRequest) => {
            if (canSend(createThreadRequest.messageId, fileUpload.fileInTransitList)) {
                fileUpload.clear(createThreadRequest.messageId);
                setCreateThreadRequestList((state) =>
                    state.filter((obj) => {
                        return obj.messageId !== createThreadRequest.messageId;
                    })
                );
                api.createThread(createThreadRequest, createThreadRequest.callback);
            }
        });
    }, [api, canSend, createThreadRequestList, fileUpload, fileUpload.fileInTransitList]);
    const message = React.useMemo<Message>(() => {
        return {
            createMessage: createMessage,
            createThread: createThread
        };
    }, [createMessage, createThread]);
    return <MessageContext.Provider value={message}>{messageProps.children}</MessageContext.Provider>;
});

export const useMessage = () => React.useContext(MessageContext);
