import React from 'react';
import { Button, Modal, TextField, Typography } from '@mui/material';
import { useApi } from '../../providers';
import { useSelector } from '../../_redux/_Store';
import ModalPrompt from '../common/ModalPrompt';
import { Close, Cancel, DeleteOutline } from '@mui/icons-material';
import { Controller, FieldValues, useForm, useWatch } from 'react-hook-form';

interface EditMessageThreadSearchModalProps {
    messageThreadSearchId: string;
    onClose: Function;
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MAX_MESSAGE_THREAD_SEARCH_NAME_LENGTH = 24;

const EditMessageThreadSearchModal = React.memo<EditMessageThreadSearchModalProps>(({ messageThreadSearchId, onClose, isOpen, setIsOpen }) => {
    const loggedInUserId = useSelector((state) => state.data.userId);
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const api = useApi();
    const { handleSubmit, control, setValue } = useForm();
    const nameWatch = useWatch({ control, name: 'name' });
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = React.useState(false);
    const [originalName, setOriginalName] = React.useState('');
    const onSave = React.useCallback(
        (data: FieldValues) => {
            api.editMessageThreadSearchName({
                messageThreadSearchId: messageThreadSearchId,
                name: data.name
            });
            if (onClose) onClose({}, 'escapeKeyDown');
        },
        [api, messageThreadSearchId, onClose]
    );
    const onDelete = React.useCallback(
        (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
            api.deleteMessageThreadSearch({
                messageThreadSearchId: messageThreadSearchId
            });
            setIsConfirmDeleteDialogOpen(false);
            if (onClose) onClose(e, 'escapeKeyDown');
        },
        [api, messageThreadSearchId, onClose]
    );
    React.useEffect(() => {
        if (!loggedInUserId) return;
        const loggedInApiUser = apiUserList[loggedInUserId];
        const messageThreadSearch = loggedInApiUser.apiMessageThreadSearchList.find((obj) => obj.messageThreadSearchId === messageThreadSearchId);
        if (!messageThreadSearch) return;
        setValue('name', messageThreadSearch.name);
        setOriginalName(messageThreadSearch.name);
    }, [apiUserList, loggedInUserId, messageThreadSearchId, setValue, isOpen]);
    return (
        <>
            <Modal
                open={isOpen}
                onClose={() => {
                    onClose();
                }}
            >
                <form onSubmit={handleSubmit(onSave)}>
                    <div className="commonModal_wrapper messageThreadSearchModal_shortcutEdit">
                        <div className="commonModal_header">
                            <span className="commonModal_headerText">Edit Shortcut</span>
                            <button
                                className="commonModal_headerClose"
                                type="button"
                                onClick={() => {
                                    onClose();
                                }}
                            >
                                <Close />
                            </button>
                        </div>
                        <div className="commonModal_body">
                            <Typography variant="body2" component="p" className="bold">
                                Edit Name:
                            </Typography>
                            <div className="commonInputControlWithClear">
                                <Controller
                                    name="name"
                                    control={control}
                                    rules={{
                                        required: true,
                                        maxLength: MAX_MESSAGE_THREAD_SEARCH_NAME_LENGTH
                                    }}
                                    render={({ field, fieldState: { error } }) => (
                                        <TextField
                                            inputProps={{ maxLength: MAX_MESSAGE_THREAD_SEARCH_NAME_LENGTH }}
                                            fullWidth
                                            size="small"
                                            color="info"
                                            autoFocus={true}
                                            inputRef={field.ref}
                                            value={field.value}
                                            onChange={field.onChange}
                                        />
                                    )}
                                />
                                {nameWatch && nameWatch.length > 0 && (
                                    <button
                                        onClick={() => {
                                            setValue('name', '');
                                        }}
                                        className="clearButton"
                                        type="button"
                                    >
                                        <Cancel />
                                    </button>
                                )}
                            </div>
                        </div>
                        <div className="commonModal_footer">
                            <Button
                                variant="contained"
                                color="secondary"
                                type="button"
                                onClick={(e) => {
                                    if (onClose) onClose(e, 'escapeKeyDown');
                                }}
                            >
                                Cancel
                            </Button>
                            <Button color="primary" variant="contained" className="ml16" type="submit">
                                Update
                            </Button>
                            <div className="messageThreadSearchModal_shortcutEditDelete mt24">
                                <button
                                    className="unStyledButton delete"
                                    type="button"
                                    onClick={() => {
                                        setIsConfirmDeleteDialogOpen(true);
                                    }}
                                >
                                    <DeleteOutline />
                                    Delete Shortcut
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </Modal>
            <ModalPrompt
                isOpen={isConfirmDeleteDialogOpen}
                title="Are you sure?"
                prompt={
                    <>
                        Are you sure you want to delete
                        <br />
                        <b>"{originalName}"</b>
                    </>
                }
                onYesCallback={onDelete}
                onCloseCallback={() => {
                    setIsConfirmDeleteDialogOpen(false);
                    setIsOpen(true);
                }}
            />
        </>
    );
});

export default EditMessageThreadSearchModal;
