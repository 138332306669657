import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { ApiMessageThread } from '../_api/_ApiModels';
import { SCREEN_TYPE, LAYOUT_TYPE } from '../_global/Route';

interface MessageThreadNav {
    prev: () => void;
    next: () => void;
    registerCallback: (callback: Function) => void;
    unregisterCallback: (callback: Function) => void;
    navigateToMessageThread: (
        navigate: NavigateFunction,
        type: MESSAGE_THREAD_NAV_TYPE,
        apiMessageThreadList: Array<ApiMessageThread>,
        currentMessageThreadId: string | undefined,
        inboxType: SCREEN_TYPE,
        queryString: string
    ) => void;
}

export enum MESSAGE_THREAD_NAV_TYPE {
    PREV,
    NEXT
}

const MessageThreadNavContext = React.createContext<MessageThreadNav>({
    prev: () => {
        return null;
    },
    next: () => {
        return null;
    },
    registerCallback: () => {
        return null;
    },
    unregisterCallback: () => {
        return null;
    },
    navigateToMessageThread: () => {
        return null;
    }
});

export interface MessageThreadNavProps {
    children?: React.ReactNode;
}

export const MessageThreadNavProvider = React.memo<MessageThreadNavProps>((messageThreadNavProps) => {
    const [callbackList, setCallbackList] = React.useState<Array<Function>>([]);
    const messageThreadNav = React.useMemo<MessageThreadNav>(() => {
        return {
            prev: () => {
                callbackList.forEach((callback) => callback(MESSAGE_THREAD_NAV_TYPE.PREV));
            },
            next: () => {
                callbackList.forEach((callback) => callback(MESSAGE_THREAD_NAV_TYPE.NEXT));
            },
            registerCallback: (callback) => {
                setCallbackList((state) => [...state, callback]);
            },
            unregisterCallback: (callback) => {
                setCallbackList((state) => [...state].filter((obj) => obj !== callback));
            },
            navigateToMessageThread: (
                navigate: NavigateFunction,
                type: MESSAGE_THREAD_NAV_TYPE,
                apiMessageThreadList: Array<ApiMessageThread>,
                currentMessageThreadId: string | undefined,
                inboxType: SCREEN_TYPE,
                queryString: string
            ) => {
                const currentMessageThreadIndex = apiMessageThreadList.findIndex((obj) => obj.messageThreadId === currentMessageThreadId);
                if (currentMessageThreadIndex === -1) return;
                const length = apiMessageThreadList.length;
                if (type === MESSAGE_THREAD_NAV_TYPE.NEXT && currentMessageThreadIndex < length - 1) {
                    const nextMessageThreadId = apiMessageThreadList[currentMessageThreadIndex + 1].messageThreadId;
                    navigate(`/${LAYOUT_TYPE.INBOX}/${inboxType}/${nextMessageThreadId}${queryString}`, {
                        state: { isFirstMessageThread: false, isLastMessageThread: currentMessageThreadIndex + 1 > length - 2 ? true : false }
                    });
                }
                if (type === MESSAGE_THREAD_NAV_TYPE.PREV && currentMessageThreadIndex > 0) {
                    const prevMessageThreadId = apiMessageThreadList[currentMessageThreadIndex - 1].messageThreadId;
                    navigate(`/${LAYOUT_TYPE.INBOX}/${inboxType}/${prevMessageThreadId}${queryString}`, {
                        state: { isFirstMessageThread: currentMessageThreadIndex < 2 ? true : false, isLastMessageThread: false }
                    });
                }
            }
        };
    }, [callbackList]);
    return <MessageThreadNavContext.Provider value={messageThreadNav}>{messageThreadNavProps.children}</MessageThreadNavContext.Provider>;
});

export const useMessageThreadNav = () => React.useContext(MessageThreadNavContext);
