import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material/styles';
import theme from './providers/Theme';
import { Provider } from 'react-redux';
import store from './_redux/_Store';
import { ApiProvider, AuthProvider, FileUploadProvider, MessageProvider, SnackbarProvider, WebSocketProvider } from './providers';
import '@fontsource/roboto';
import 'react-circular-progressbar/dist/styles.css';
import 'emoji-mart/css/emoji-mart.css';
import './assets/styles/main.css';
import { NotificationProvider } from './providers/Notification';

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <ThemeProvider theme={theme}>
                <AuthProvider>
                    <ApiProvider>
                        <SnackbarProvider>
                            <NotificationProvider>
                                <WebSocketProvider>
                                    <FileUploadProvider>
                                        <MessageProvider>
                                            <App />
                                        </MessageProvider>
                                    </FileUploadProvider>
                                </WebSocketProvider>
                            </NotificationProvider>
                        </SnackbarProvider>
                    </ApiProvider>
                </AuthProvider>
            </ThemeProvider>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
