import React from 'react';
import { useSelector } from '../../_redux/_Store';
import { useDrawer } from '../../providers/Drawer';
import { Cancel, Circle, Close, Star, StarOutline } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import StarIcon from '@mui/icons-material/Star';
import GroupIcon from '@mui/icons-material/Group';
import HashtagCalloutInput from '../common/HashtagCalloutInput';
import UserAvatar from '../common/UserAvatar';
import UserName from '../common/UserName';
import { IconButton, Tooltip } from '@mui/material';
import { MONTH_DAY_FORMAT, TOOLTIP_TEXTS } from '../../_global/Constants';
import MessageIcon from '@mui/icons-material/Message';
import EditIcon from '@mui/icons-material/Edit';
import moment from 'moment';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import { ApiUser, MESSAGE_THREAD_SEARCH_FILTER_TYPE } from '../../_api/_ApiModels';
import { NavLink } from 'react-router-dom';
import { useApi } from '../../providers';

const Contacts = React.memo(() => {
    const drawer = useDrawer();
    const apiUsers = useSelector((state) => state.data.apiUserList);
    const loggedInUserId = useSelector((state) => state.data.userId);
    const [filteredApiUserList, setFilteredApiUserList] = React.useState<Array<ApiUser>>([]);
    const [filteredStarredApiUserList, setFilteredStarredApiUserList] = React.useState<Array<ApiUser>>([]);
    const [search, setSearch] = React.useState('');
    const onClearSearch = React.useCallback(() => {
        if (search) {
            setSearch('');
        }
    }, [search]);
    React.useEffect(() => {
        if (!loggedInUserId) return;
        let tempApiUserList = [] as Array<ApiUser>;
        if (search.length) {
            tempApiUserList = Object.values(apiUsers)
                .filter((user) => user.userId !== loggedInUserId)
                .filter((apiUser) => (apiUser.firstName + ' ' + apiUser.lastName + ' ' + apiUser.title).match(new RegExp(search, 'i')));
        } else {
            tempApiUserList = Object.values(apiUsers).filter((user) => user.userId !== loggedInUserId);
        }
        setFilteredApiUserList(tempApiUserList);
        setFilteredStarredApiUserList(tempApiUserList.filter((obj) => obj.isStarred === true));
    }, [search, loggedInUserId, apiUsers]);
    return (
        <>
            <div className="userContacts_wrapper">
                <div className="userContacts_wrapper_header">
                    <button
                        type="button"
                        onClick={() => {
                            drawer.close();
                        }}
                        className="drawerCloseBtn"
                    >
                        <Close />
                    </button>
                    <h2>Contacts</h2>
                    <div className="commonTop_searchBar">
                        <SearchIcon className="commonTop_searchIcon" />
                        <HashtagCalloutInput
                            placeholder="Search contacts"
                            allowSuggestionsAboveCursor={true}
                            className="commonTop_Search"
                            value={search}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                }
                            }}
                            onChange={(e) => {
                                setSearch(e.target.value);
                            }}
                            singleLine={true}
                        />
                        {search?.length > 0 && (
                            <button onClick={onClearSearch} className="drawerCloseBtn commonTop_closeIcon">
                                <Cancel />
                            </button>
                        )}
                    </div>
                </div>
                <div className="userContacts_wrapper_body">
                    {filteredStarredApiUserList.length ? (
                        <>
                            <div className="userContacts_wrapper_body_headings">
                                <StarIcon /> <h2>Starred Contacts ({filteredStarredApiUserList.length})</h2>
                            </div>
                            <div>
                                <ul className="userContacts_wrapper_body_listing">
                                    {filteredStarredApiUserList.map((apiUser) => (
                                        <li>
                                            <UserDisplay userId={apiUser.userId} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </>
                    ) : null}

                    <div className="userContacts_wrapper_body_headings">
                        <GroupIcon /> <h2>All Contacts ({filteredApiUserList.length})</h2>
                    </div>
                    <div>
                        <ul className="userContacts_wrapper_body_listing">
                            {filteredApiUserList.map((apiUser) => (
                                <li>
                                    <UserDisplay userId={apiUser.userId} />
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    );
});

interface UserDisplayProps {
    userId: string;
}
const UserDisplay = React.memo<UserDisplayProps>(({ userId }) => {
    const messageThreadFilter = useMessageThreadFilter();
    const api = useApi();
    const drawer = useDrawer();
    const { apiUserList } = useSelector((state) => state.data);
    const [isStarred, setIsStarred] = React.useState<boolean>(false);
    const [title, setTitle] = React.useState<string>('');
    const [isOnline, setIsOnline] = React.useState<boolean>(false);
    const [lastOnlineTime, setLastOnlineTime] = React.useState<number>(0);
    const onStarClicked = React.useCallback(
        (event) => {
            api.toggleStarredUser({
                userId: userId,
                starred: !isStarred
            });
            event.preventDefault();
        },
        [api, isStarred, userId]
    );
    const onComposeClicked = React.useCallback(() => {
        drawer.openCompose(userId);
    }, [drawer, userId]);
    React.useEffect(() => {
        const apiUser = apiUserList[userId];
        if (!apiUser) return;
        setIsStarred(apiUser.isStarred);
        setTitle(apiUser.title);
        setIsOnline(apiUser.online);
        setLastOnlineTime(apiUser.lastOnlineTime);
    }, [apiUserList, userId]);
    return (
        <div className="userContacts_wrapper_body_userDisplay">
            <div className="userContacts_wrapper_body_userDisplay_info">
                <div
                    className="userContacts_wrapper_body_userDisplay_info_avatar"
                    onClick={() => {
                        drawer.openUserProfile(userId);
                    }}
                >
                    <UserAvatar userId={userId} displayOnlineIndicator={false} />
                </div>
                <div className="userContacts_wrapper_body_userDisplay_info_title">
                    <div className="userContacts_wrapper_body_userDisplay_info_title_nameBox">
                        <UserName userId={userId} />
                        <Tooltip title={isStarred ? TOOLTIP_TEXTS.removeUser : TOOLTIP_TEXTS.addUser} placement="bottom-start" arrow>
                            <IconButton onClick={onStarClicked}>{isStarred ? <Star className="starred" /> : <StarOutline />}</IconButton>
                        </Tooltip>
                    </div>

                    <div className="userContacts_wrapper_body_userDisplay_info_title_role">{title}</div>

                    <div className="userContacts_wrapper_body_userDisplay_info_title_onlineStatus">
                        <Circle sx={{ fontSize: 8, color: `${isOnline ? '#33cc33' : '#ADADAD'}` }} />
                        {isOnline ? <>Online now</> : `Last Online ${moment.unix(lastOnlineTime).format(MONTH_DAY_FORMAT)}`}
                    </div>
                </div>
            </div>

            <div className="userContacts_wrapper_body_userDisplay_actions">
                <Tooltip title={TOOLTIP_TEXTS.viewMsgwithUser} placement="bottom-start" arrow>
                    <NavLink
                        className="roundBg"
                        to={messageThreadFilter.getUrl([
                            {
                                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.USER_ID_LIST,
                                value: [userId]
                            }
                        ])}
                    >
                        <MessageIcon />
                    </NavLink>
                </Tooltip>
                <Tooltip title={TOOLTIP_TEXTS.composeMsgtoUser} placement="bottom-start" arrow>
                    <IconButton onClick={onComposeClicked}>
                        <span className="roundBg">
                            <EditIcon />
                        </span>
                    </IconButton>
                </Tooltip>
            </div>
        </div>
    );
});

export default Contacts;
