import React from 'react';
import { NotificationsActive, NotificationsOffOutlined } from '@mui/icons-material';
import { useDrawer } from '../../providers/Drawer';
import UserAvatar from '../common/UserAvatar';
import MessageThreadTagPillList from './MessageThreadTagPillList';
import { IconButton, Tooltip } from '@mui/material';
import UserName from '../common/UserName';
import { Create, Chat, PermContactCalendar } from '@mui/icons-material';
import { useSelector } from '../../_redux/_Store';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import { MESSAGE_THREAD_SEARCH_FILTER_TYPE } from '../../_api/_ApiModels';
import TagsnPeopleIcon from '../../assets/images/icons/tagsnpeoplegrey.svg';

interface MessageThreadHeaderProps {
    messageThreadId: string;
    userIdList: Array<string>;
    tagIdList: Array<string>;
    notificationUserIdList: Array<string>;
}

const DISPLAY_USER_COUNT = 5;

const MessageThreadHeader = React.memo<MessageThreadHeaderProps>(({ messageThreadId, userIdList, tagIdList, notificationUserIdList }) => {
    const drawer = useDrawer();
    const messageThreadFilter = useMessageThreadFilter();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const [displayUserIdList, setDisplayUserIdList] = React.useState<Array<string>>([]);
    React.useEffect(() => {
        setDisplayUserIdList(userIdList.slice(0, DISPLAY_USER_COUNT));
    }, [userIdList]);
    return (
        <>
            <img src={TagsnPeopleIcon} alt="tag_icon" className="messageThreadHeader_tagIcon mr8" />
            {displayUserIdList.map((userId) => (
                <Tooltip
                    key={userId}
                    title={
                        <div className="messageThreadHeader_calloutTooltip">
                            <div className="messageThreadHeader_calloutIcon">
                                <UserAvatar userId={userId} size={56} displayOnlineIndicator={false} />

                                <span>
                                    <UserName userId={userId} />
                                </span>
                                {notificationUserIdList.includes(userId) ? <NotificationsActive className="active" /> : <NotificationsOffOutlined />}
                            </div>
                            {loggedInUserId !== userId && (
                                <div className="messageThreadHeader_selfCalloutActions">
                                    <IconButton
                                        className="greyCircle"
                                        onClick={() => {
                                            drawer.openCompose(userId);
                                        }}
                                    >
                                        <Create />
                                    </IconButton>
                                    <IconButton
                                        className="greyCircle"
                                        onClick={() => {
                                            messageThreadFilter.navigateToSearch([{ type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.USER_ID_LIST, value: [userId] }]);
                                        }}
                                    >
                                        <Chat />
                                    </IconButton>
                                    <IconButton
                                        className="greyCircle"
                                        onClick={() => {
                                            drawer.openUserProfile(userId);
                                        }}
                                    >
                                        <PermContactCalendar />
                                    </IconButton>
                                </div>
                            )}
                        </div>
                    }
                    placement="bottom-start"
                    arrow
                >
                    <div className="messageThreadHeader_userAvatarList">
                        <UserAvatar userId={userId} size={24} displayOnlineIndicator={false} />
                    </div>
                </Tooltip>
            ))}
            {userIdList.length > DISPLAY_USER_COUNT && <div className="messageThreadHeader_userCount">{`+${userIdList.length - DISPLAY_USER_COUNT}`}</div>}
            <div className="messageThreadHeader_countAction">
                <div className="messageThreadHeader_tagList">
                    <MessageThreadTagPillList tagIdList={tagIdList} />
                </div>
                <button
                    className="unStyledButton messageThreadHeader_viewDetails"
                    onClick={() => {
                        drawer.openMessageThreadInfo(messageThreadId);
                    }}
                >
                    View Details
                </button>
            </div>
        </>
    );
});

export default MessageThreadHeader;
