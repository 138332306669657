import { createTheme } from '@mui/material/styles';

const Theme = createTheme({
    palette: {
        mode: 'light',
        common: {
            black: '#000000',
            white: '#ffffff'
        },
        primary: {
            main: '#F16431',
            contrastText: '#ffffff'
        },
        secondary: {
            main: '#8990A3',
            contrastText: '#ffffff'
        },
        error: {
            main: '#e94545',
            contrastText: '#ffffff'
        },
        warning: {
            main: '#ff9500',
            contrastText: '#ffffff'
        },
        info: {
            main: '#007aff',
            contrastText: '#D6D6D6'
        },
        success: {
            main: '#041723',
            contrastText: '#ffffff'
        },
        grey: {
            50: '#FAFAFA',
            100: '#5b5b5b',
            800: '#041723',
            900: '#000000'
        },
        text: {
            primary: '#000000',
            secondary: '#848484'
        },
        background: {
            paper: '#ffffff',
            default: '#cccccc'
        }

        // action: {
        //     active: '#E5F2FF',
        //     activatedOpacity: 0.1,
        //     hover: '#E5F2FF',
        //     hoverOpacity: 0.1,
        //     selected: '#E5F2FF',
        //     selectedOpacity: 0.1,
        //     disabled: '#d6d6d6',
        //     disabledBackground: '#d6d6d6',
        //     disabledOpacity: 0.1,
        //     focus: '#E5F2FF',
        //     focusOpacity: 0.1
        // }
    },
    components: {
        MuiTypography: {
            defaultProps: {
                variantMapping: {
                    h1: 'h1',
                    h2: 'h2',
                    h3: 'h3',
                    h4: 'h4',
                    h5: 'h5',
                    h6: 'h6',
                    subtitle1: 'div',
                    subtitle2: 'div',
                    body1: 'p',
                    body2: 'span'
                }
            }
        },
        MuiToggleButton: {
            styleOverrides: {
                root: {
                    '&.MuiButtonBase-root': {
                        color: '#5b5b5b',
                        // color: Theme.palette.grey[100],
                        '& > svg': {
                            color: '#5b5b5b'
                        }
                    },
                    '&.Mui-selected': {
                        backgroundColor: '#E5F2FF',
                        color: '#007AFF',
                        '& > svg': {
                            color: '#007AFF'
                        }
                    }
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    'MuiCheckbox-root': {
                        color: '#f00'
                    },
                    '&.Mui-checked': {
                        color: '#5b5b5b'
                    },
                    '& + .MuiFormControlLabel-label': {
                        fontSize: '0.875rem',
                        color: '#5b5b5b'
                    }
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.MuiButtonBase-root:hover': {
                        boxShadow: 'none'
                    },

                    '&.MuiButton-contained': {
                        borderRadius: '4px',
                        textTransform: 'none',
                        boxShadow: 'none',
                        '&.Mui-disabled': {
                            opacity: 0.7,
                            color: '#848484'
                        }
                    },
                    '&.MuiButton-outlined': {
                        borderRadius: '4px',
                        textTransform: 'none',
                        boxShadow: 'none',
                        '& > svg': {
                            fontSize: '1.2rem',
                            marginRight: '4px'
                        }
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    '&.MuiInputLabel-root.Mui-focused': {
                        color: '#007AFF'
                    }
                }
            }
        }
    },
    typography: {
        htmlFontSize: 16,
        fontFamily: 'Inter',
        fontSize: 14,
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        h1: {
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '2rem',
            color: '#000000'
        },
        h2: {
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '1.125rem',
            color: '#000000'
        },
        h3: {
            fontFamily: 'Inter',
            fontWeight: 600,
            fontSize: '1.125rem',
            color: '#000000'
        },
        h4: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '1rem',
            color: '#000000'
        },
        subtitle1: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '1rem',
            color: '#000000'
        },
        subtitle2: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '1rem',
            color: '#000000'
        },
        body1: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '1rem',
            color: '#000',
            letterSpacing: '-0.2px'
        },
        body2: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '1rem',
            color: '#323232',
            letterSpacing: '-0.2px'
        },
        caption: {
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: '0.75rem',
            color: '#848484'
        }
    }
});

export default Theme;
