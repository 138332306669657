import React from 'react';
import { Button, TableContainer, Table, Paper, TableRow, TableCell, TableBody } from '@mui/material';
import { Search as SearchIcon, Cancel as CancelIcon, SellOutlined as SellOutlinedIcon } from '@mui/icons-material';
import { useSelector } from '../../_redux/_Store';
import CreateTagModal from './CreateTagModal';
import TableHeader, { CellProps, OrderCellProps, sortString, sortNumber } from '../common/TableHeader';
import { LAYOUT_TYPE, SCREEN_TYPE } from '../../_global/Route';
import { NavLink } from 'react-router-dom';

export interface TagTableData {
    tagId: string;
    tagName: string;
    tagCategoryName: string;
    hashtagsCount: number;
}

const cellArray: Array<CellProps<TagTableData>> = [
    {
        cellId: 'tagName',
        displayName: 'Title',
        sortFn: sortString
    },
    {
        cellId: 'tagCategoryName',
        displayName: 'Category',
        sortFn: sortString,
        sx: { width: '100px', textAlign: 'center' }
    },
    {
        cellId: 'hashtagsCount',
        displayName: 'Hashtags',
        sortFn: sortNumber,
        sx: { width: '100px', textAlign: 'center' }
    }
];

const TagList = React.memo(() => {
    const { apiTagList, apiTagCategoryList, apiHashtagList } = useSelector((state) => state.data);
    const [tagTableDataList, setTagTableDataList] = React.useState<Array<TagTableData>>([]);
    const [search, setSearch] = React.useState('');
    const [orderCell, setOrderCell] = React.useState<OrderCellProps<TagTableData>>({ orderBy: 'tagName', isAsc: true });
    const [isCreateTagModalOpen, setIsCreateTagModalOpen] = React.useState<boolean>(false);
    const onClearSearch = React.useCallback(() => {
        if (search) {
            setSearch('');
        }
    }, [search]);
    const onSort = React.useCallback(
        (cellId: keyof TagTableData) => {
            if (cellId === orderCell.orderBy) {
                setOrderCell((oldState) => {
                    return { ...oldState, isAsc: !oldState.isAsc };
                });
            } else {
                setOrderCell({ orderBy: cellId, isAsc: true });
            }
        },
        [orderCell]
    );
    React.useEffect(() => {
        let tagListSortAndFilter: Array<TagTableData> = [];
        Object.values(apiTagList).forEach((apiTag) => {
            if (!search.length || apiTag.name.match(search)) {
                tagListSortAndFilter.push({
                    tagId: apiTag.tagId,
                    tagName: apiTag.name,
                    tagCategoryName: apiTagCategoryList[apiTag.tagCategoryId]?.name ? apiTagCategoryList[apiTag.tagCategoryId]?.name : '',
                    hashtagsCount: Object.values(apiHashtagList).filter((apiHashtag) => apiHashtag.tagIdList.includes(apiTag?.tagId)).length
                });
            }
        });
        const orderByCell = cellArray.find((obj) => obj.cellId === orderCell.orderBy);
        const orderByFn = orderByCell ? orderByCell.sortFn : undefined;
        if (orderByCell && orderByFn) {
            tagListSortAndFilter.sort((a, b) => {
                return orderCell.isAsc ? orderByFn(a[orderCell.orderBy], b[orderCell.orderBy]) : orderByFn(b[orderCell.orderBy], a[orderCell.orderBy]);
            });
        }
        setTagTableDataList(tagListSortAndFilter);
    }, [apiTagList, apiTagCategoryList, apiHashtagList, search, orderCell]);
    return (
        <div className="topicTableDetails_wrapper">
            <div className="hashtags_searchBar">
                <SearchIcon className="hashtags_searchIcon" />
                <input
                    className="hashtags_Search_input"
                    placeholder="Search Topic"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                {search?.length > 0 && (
                    <button type="button" onClick={onClearSearch} className="drawerCloseBtn hashtags_closeIcon">
                        <CancelIcon />
                    </button>
                )}
            </div>
            <div className="topicTableDetails_heading">
                <h3>
                    <SellOutlinedIcon className="rotateIcon" /> Topics
                </h3>
                <Button variant="outlined" size="small" onClick={() => setIsCreateTagModalOpen(true)}>
                    Create Topic
                </Button>
                <CreateTagModal isOpen={isCreateTagModalOpen} onCloseCallback={() => setIsCreateTagModalOpen(false)} />
            </div>
            <TableContainer component={Paper} className="topicTableDetails_table">
                <Table stickyHeader>
                    <TableHeader cellArray={cellArray} orderCell={orderCell} onSort={onSort} />
                    <TableBody className="topicTableDetails_body">
                        {tagTableDataList.map((tagTableData: TagTableData) => (
                            <TableRow key={tagTableData.tagId}>
                                <TableCell component="td" scope="row" className="topicTableDetails_name">
                                    <NavLink to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.TAGS}/${tagTableData.tagId}`}>{tagTableData.tagName}</NavLink>
                                </TableCell>
                                <TableCell sx={{ width: '100px' }} className="topicTableDetails_category">
                                    {tagTableData.tagCategoryName}
                                </TableCell>
                                <TableCell sx={{ width: '100px' }} className="topicTableDetails_hashtags">
                                    {tagTableData.hashtagsCount}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
});

export default TagList;
