import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel, SxProps, Theme } from '@mui/material';

export interface CellProps<T> {
    cellId: keyof T;
    displayName?: string;
    sortFn?: Function;
    sx?: SxProps<Theme>;
}

export interface OrderCellProps<T> {
    orderBy: keyof T;
    isAsc: boolean;
}

export const sortString = (a?: string, b?: string) => {
    if (!a) return -1;
    if (!b) return +1;
    return a.localeCompare(b);
};

export const sortNumber = (a?: number, b?: number) => {
    if (!a) return -1;
    if (!b) return +1;
    return a - b;
};

interface TableHeaderProps<T> {
    cellArray: Array<CellProps<T>>;
    orderCell: OrderCellProps<T>;
    onSort: (cellId: keyof T) => void;
}

export default function TableHeader<T>({ cellArray, orderCell, onSort }: TableHeaderProps<T>) {
    return (
        <TableHead>
            <TableRow>
                {cellArray.map((cell) => (
                    <TableCell key={cell.cellId as string} sortDirection={orderCell.orderBy === cell.cellId ? (orderCell.isAsc ? 'asc' : 'desc') : false} sx={cell.sx}>
                        {cell.sortFn ? (
                            <TableSortLabel
                                hideSortIcon
                                active={orderCell.orderBy === cell.cellId}
                                direction={orderCell.orderBy === cell.cellId ? (orderCell.isAsc ? 'asc' : 'desc') : 'asc'}
                                onClick={() => {
                                    onSort(cell.cellId);
                                }}
                            >
                                {cell.displayName?.toUpperCase()}
                            </TableSortLabel>
                        ) : (
                            cell.displayName?.toUpperCase()
                        )}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}
