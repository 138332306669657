import React from 'react';
import { Button, Modal, TextField, Typography } from '@mui/material';
import FilterPillList from './MessageThreadFilterPillList';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import { useApi } from '../../providers';
import { MESSAGE_THREAD_SEARCH_FILTER_TYPE } from '../../_api/_ApiModels';
import { Close, Cancel, Search, FilterListOutlined } from '@mui/icons-material';
import { Controller, FieldValues, useForm, useWatch } from 'react-hook-form';
import ObjectID from 'bson-objectid';
import { useNavigate, useLocation } from 'react-router';
import { QUERY_STRING_TYPE } from '../../_global/Route';
import { MAX_MESSAGE_THREAD_SEARCH_NAME_LENGTH } from './EditMessageThreadSearchModal';

interface MessageThreadSearchModalProps {
    isOpen: boolean;
    onClose: Function;
}

const MessageThreadSearchModal = React.memo<MessageThreadSearchModalProps>(({ isOpen, onClose }) => {
    const messageThreadFilter = useMessageThreadFilter();
    const navigate = useNavigate();
    const location = useLocation();
    const api = useApi();
    const [textSearch, setTextSearch] = React.useState('');
    const { handleSubmit, control, setValue, reset } = useForm({ defaultValues: { name: '' } });
    const nameWatch = useWatch({ control, name: 'name' });
    const onSave = React.useCallback(
        (data: FieldValues) => {
            console.log('here');
            if (!messageThreadFilter.filterList) return;
            const messageThreadSearchId = ObjectID().toHexString();
            api.createMessageThreadSearch(
                {
                    messageThreadSearchId: messageThreadSearchId,
                    name: data.name,
                    apiMessageThreadSearchFilterList: messageThreadFilter.filterList
                },
                () => {
                    const queryParams = new URLSearchParams(location.search);
                    queryParams.set(QUERY_STRING_TYPE.MESSAGE_THREAD_SEARCH_ID, messageThreadSearchId);
                    navigate({ search: queryParams.toString() });
                }
            );
            reset();
            onClose();
        },
        [api, location.search, messageThreadFilter.filterList, navigate, onClose, reset]
    );
    React.useEffect(() => {
        const textSearch = messageThreadFilter.filterList?.find((obj) => obj.type === MESSAGE_THREAD_SEARCH_FILTER_TYPE.TEXT);
        setTextSearch(textSearch ? (textSearch.value as string) : '');
    }, [messageThreadFilter.filterList]);
    return (
        <Modal
            open={isOpen}
            onClose={() => {
                reset();
                onClose();
            }}
        >
            <form
                onSubmit={handleSubmit((e) => {
                    onSave(e);
                })}
            >
                <div className="commonModal_wrapper messageThreadSearchModal_shortcut">
                    <div className="commonModal_header">
                        <span className="commonModal_headerText">Add Shortcut</span>
                        <button
                            className="commonModal_headerClose"
                            type="button"
                            onClick={(e) => {
                                onClose();
                            }}
                        >
                            <Close />
                        </button>
                    </div>
                    <div className="commonModal_body">
                        <Typography variant="body2" component="p" className="bold">
                            Name
                        </Typography>
                        <div className="commonInputControlWithClear">
                            <Controller
                                name="name"
                                control={control}
                                rules={{
                                    required: true,
                                    maxLength: 30
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <TextField
                                        inputProps={{ maxLength: MAX_MESSAGE_THREAD_SEARCH_NAME_LENGTH }}
                                        fullWidth
                                        size="small"
                                        color="info"
                                        autoFocus={true}
                                        inputRef={field.ref}
                                        value={field.value}
                                        onBlur={field.onBlur}
                                        onChange={field.onChange}
                                    />
                                )}
                            />
                            {nameWatch && nameWatch.length > 0 && (
                                <button
                                    type="button"
                                    onClick={() => {
                                        setValue('name', '');
                                    }}
                                    className="clearButton"
                                >
                                    <Cancel />
                                </button>
                            )}
                        </div>
                        <div className="mt2">
                            <Typography variant="caption" component="span">
                                Note: limit {MAX_MESSAGE_THREAD_SEARCH_NAME_LENGTH} characters{/*, no special characters*/}
                            </Typography>
                        </div>
                        <div className="messageThreadSearchModal_pillHead mt24">Filters and Search Terms Included</div>
                        {messageThreadFilter.displayCount !== 0 && (
                            <div className="messageThreadSearchModal_pillWrap">
                                <div className="messageThreadSearchModal_pillIcon">
                                    <FilterListOutlined />
                                </div>
                                <div className="commonTop_pillsList">{messageThreadFilter.filterList && <FilterPillList apiMessageThreadSearchFilterList={messageThreadFilter.filterList} />}</div>
                            </div>
                        )}
                        {textSearch && textSearch.length > 0 && (
                            <div className="messageThreadSearchModal_searchWrap">
                                <div className="messageThreadSearchModal_searchIcon">
                                    <Search />
                                </div>
                                <div className="messageThreadSearchModal_searchData">{`"${textSearch}"`}</div>
                            </div>
                        )}
                    </div>
                    <div className="commonModal_footer">
                        <Button
                            type="button"
                            variant="outlined"
                            color="secondary"
                            onClick={(e) => {
                                onClose();
                            }}
                        >
                            Cancel
                        </Button>
                        <Button color="primary" variant="contained" className="ml16" type="submit">
                            Save
                        </Button>
                    </div>
                </div>
            </form>
        </Modal>
    );
});

export default MessageThreadSearchModal;
