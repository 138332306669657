import { FILE_IMAGE_TYPE, REACTION_TYPE, USER_HASHTAG_STATE_TYPE, USER_ROLE_TYPE } from '../_global/_Enums';

export interface ApiOrganization {
    organizationId: string;
    name: string;
    cloudfrontUrl: string;
}

export interface ApiUser {
    userId: string;
    organizationId: string;
    firstName: string;
    lastName: string;
    roleType?: USER_ROLE_TYPE;
    callout: string;
    title: string;
    phone: string;
    email: string;
    avatarKey: string;
    location: string;
    active: boolean;
    isStarred: boolean;
    sideNavTagCategoryIdList: Array<string>;
    displayTagCategoryIdList: Array<string>;
    inboxNotSeenCount: number;
    starredCount: number;
    apiMessageThreadSearchList: Array<ApiMessageThreadSearch>;
    online: boolean;
    lastOnlineTime: number;
}

export interface ApiTagCategory {
    tagCategoryId: string;
    organizationId: string;
    name: string;
}

export interface ApiTag {
    tagId: string;
    createTime: number;
    organizationId: string;
    tagCategoryId: string;
    name: string;
}

export interface ApiHashtag {
    hashtagId: string;
    organizationId: string;
    tagIdList: Array<string>;
    description: string;
    active: boolean;
    adminUserIdList: Array<string>;
    subscriptionUserIdList: Array<string>;
    notificationUserIdList: Array<string>;
    name: string; //calculated
    updateTime: number;
}

export type ApiMessageThread = {
    messageThreadId: string;
    createdUserId: string;
    hashtagIdList: Array<string>;
    tagIdList: Array<string>;
    calloutUserIdList: Array<string>;
    accessUserIdList: Array<string>;
    notificationUserIdList: Array<string>;
    isInbox: boolean;
    isNotification: boolean;
    isStarred: boolean;
    isSeen: boolean;
    subject: string;
    fileCount: number;
    updateTime: number;
    sortTime: number;
    apiMessageList: Array<ApiMessage>;
    apiMessageThreadObject: ApiMessageThreadObject;
};

export interface ApiMessage {
    messageId: string;
    createdUserId: string;
    createTime: number;
    text: string;
    deleted: boolean;
    deleteTime?: number;
    deletedUserId?: string;
    editTime?: number;
    hashtagIdList: Array<string>;
    tagIdList: Array<string>;
    calloutUserIdList: Array<string>;
    isSeen: boolean;
    apiFileList: Array<ApiFile>;
    fileIdList?: Array<string>; //For Files in Transit for unsent messages
    apiGifList: Array<ApiGif>;
    apiReactionList: Array<ApiReaction>;
}

export interface ApiFile {
    fileId: string;
    messageId: string;
    userId: string;
    createTime: number;
    name: string;
    displayName: string;
    contentType: string;
    key: string;
    byteCount: number;
    imageList: Array<ApiFileImage>;
}

export interface ApiFileImage {
    fileImageId: string;
    fileImageType: FILE_IMAGE_TYPE;
    key: string;
    byteCount: number;
    height: number;
    width: number;
}

export interface ApiGif {
    gifId: string;
    giphyId: string;
    url: string;
    name: string;
    imageUrl: string;
    videoUrl: string;
    height: number;
    width: number;
}

export interface ApiReaction {
    reactionId: string;
    userId: string;
    type: REACTION_TYPE;
    createTime: number;
}

export interface ApiMessageSeen {
    userId: string;
    seenTime: number;
}

export interface ApiTyping {
    typingId: string;
    messageThreadId: string;
    userId: string;
}

export interface ApiUserHashtag {
    userHashtagId: string;
    hashtagId: string;
    messageThreadId: string;
    userId: string;
    stateType: USER_HASHTAG_STATE_TYPE;
    previousStateType: USER_HASHTAG_STATE_TYPE;
    notifications: boolean;
    updateTime: number;
}

export interface ApiMessageThreadSearchFilter {
    type: MESSAGE_THREAD_SEARCH_FILTER_TYPE;
    value: string | Array<string> | boolean | number | DATE_RANGE_TYPE;
}

export enum MESSAGE_THREAD_SEARCH_FILTER_TYPE {
    TAG_ID_LIST = 'TAG_ID_LIST',
    HASHTAG_ID_LIST = 'HASHTAG_ID_LIST',
    USER_ID_LIST = 'USER_ID_LIST',
    IS_STARRED = 'IS_STARRED',
    IS_CALLED_OUT = 'IS_CALLED_OUT', //mentions me
    IS_NOT_SEEN = 'IS_NOT_SEEN', //unread
    IS_NOT_INBOX = 'IS_NOT_INBOX', //Not in Inbox
    IS_SENT_BY_USER = 'IS_SENT_BY_USER',
    IS_USER_HASHTAG = 'IS_USER_HASHTAG', //Subscriptions
    HAS_ATTACHMENTS = 'HAS_ATTACHMENTS',
    DATE_RANGE_TYPE = 'DATE_RANGE_TYPE',
    START_DATE = 'START_DATE',
    END_DATE = 'END_DATE',
    TEXT = 'TEXT'
}

export enum DATE_RANGE_TYPE {
    LAST_7_DAYS = 'LAST_7_DAYS',
    LAST_30_DAYS = 'LAST_30_DAYS',
    LAST_3_MONTHS = 'LAST_3_MONTHS',
    THIS_YEAR = 'THIS_YEAR',
    CUSTOM = 'CUSTOM'
}

export interface ApiMessageThreadSearch {
    messageThreadSearchId: string;
    name: string;
    apiMessageThreadSearchFilterList: Array<ApiMessageThreadSearchFilter>;
}

export interface ApiMessageThreadObject {
    messageThreadObjectId: string;
    apiUserHashtag: ApiUserHashtag;
}
