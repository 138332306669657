import React from 'react';
import { Button, TextField, Typography } from '@mui/material';
import MuiPhoneNumber from 'material-ui-phone-number';
import ReactCodeInput from 'react-code-input';
import { useAuth } from '../../providers/Auth';
import { SubmitIdentityResponse, SubmitPinResponse, useApi } from '../../providers/Api';
import { useSnackbar } from '../../providers/Snackbar';
import { IDENTITY_TYPE } from '../../_global/_Enums';
import { useNavigate } from 'react-router-dom';
import { AxiosResponse } from 'axios';
import { SCREEN_TYPE, LAYOUT_TYPE } from '../../_global/Route';
import { Phone, Email, ArrowBackIosNewOutlined } from '@mui/icons-material';
import { FieldValues, useForm, Controller } from 'react-hook-form';
import phone from 'phone';
import CakeLogo from '../../assets/images/logo.svg';

enum SIGN_IN_STATE {
    CHOOSE,
    PHONE,
    EMAIL,
    PIN
}

const Auth = React.memo(() => {
    const PIN_LENGTH = 6;
    const navigate = useNavigate();
    const auth = useAuth();
    const api = useApi();
    const snackbar = useSnackbar();
    const [authIdentityId, setAuthIdentityId] = React.useState<string>();
    const [signInState, setSignInState] = React.useState<SIGN_IN_STATE>(SIGN_IN_STATE.CHOOSE);
    const [pinError, setPinError] = React.useState(false);
    const [isInvalidAuthIdentity, setIsInvalidAuthIdentity] = React.useState(false);
    const pinRef = React.useRef() as React.MutableRefObject<ReactCodeInput>;
    const onSubmitPin = React.useCallback(
        (pin: string) => {
            setPinError(false);
            if (authIdentityId && pin && pin.length === PIN_LENGTH) {
                api.submitPin({ authIdentityId: authIdentityId, pin: pin }, (axiosResponse: AxiosResponse) => {
                    const submitPinResponse: SubmitPinResponse = axiosResponse.data;
                    if (submitPinResponse.token) {
                        auth.setToken(submitPinResponse.token);
                        navigate(`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.MAIN}`);
                    } else {
                        setPinError(true);
                    }
                });
            }
        },
        [api, auth, authIdentityId, navigate]
    );
    const onChangePin = React.useCallback(
        (pin: string) => {
            if (pin && pin.length === 6) {
                onSubmitPin(pin);
            }
        },
        [onSubmitPin]
    );
    const onResendClicked = React.useCallback(() => {
        if (authIdentityId) {
            api.resendPin({ authIdentityId: authIdentityId }, (axiosResponse: AxiosResponse) => {
                snackbar.displayInfo({ message: 'PIN resent' });
            });
        }
    }, [api, authIdentityId, snackbar]);
    const onEmailSubmit = React.useCallback(
        (data: FieldValues) => {
            setIsInvalidAuthIdentity(false);
            api.submitIdentity({ type: IDENTITY_TYPE.EMAIL, identity: data.email }, (axiosResponse: AxiosResponse) => {
                const submitIdentityResponse: SubmitIdentityResponse = axiosResponse.data;
                if (submitIdentityResponse.valid) {
                    setAuthIdentityId(submitIdentityResponse.authIdentityId);
                    setSignInState(SIGN_IN_STATE.PIN);
                } else {
                    setIsInvalidAuthIdentity(true);
                }
            });
        },
        [api]
    );
    const onPhoneSubmit = React.useCallback(
        (data: FieldValues) => {
            setIsInvalidAuthIdentity(false);
            const identity = phone(data.phone).phoneNumber;
            if (identity) {
                api.submitIdentity({ type: IDENTITY_TYPE.PHONE, identity: identity }, (axiosResponse: AxiosResponse) => {
                    const submitIdentityResponse: SubmitIdentityResponse = axiosResponse.data;
                    if (submitIdentityResponse.valid) {
                        setAuthIdentityId(submitIdentityResponse.authIdentityId);
                        setSignInState(SIGN_IN_STATE.PIN);
                    } else {
                        setIsInvalidAuthIdentity(true);
                    }
                });
            }
        },
        [api]
    );
    const {
        register,
        /*formState: { errors },*/
        handleSubmit,
        control
    } = useForm();
    return (
        <div className="auth_loginPage">
            <div className="auth_loginWrapper">
                <div className="auth_cakeLogo">
                    <img src={CakeLogo} alt="Cake Logo" />
                </div>
                {signInState === SIGN_IN_STATE.CHOOSE ? (
                    <div className="auth_commonSignIn">
                        <div>
                            <Button
                                startIcon={<Phone />}
                                variant="contained"
                                onClick={() => {
                                    setSignInState(SIGN_IN_STATE.PHONE);
                                }}
                            >
                                Sign in with Phone Number
                            </Button>
                        </div>
                        <div>
                            <Button
                                startIcon={<Email />}
                                variant="contained"
                                onClick={() => {
                                    setSignInState(SIGN_IN_STATE.EMAIL);
                                }}
                            >
                                Sign in with Email
                            </Button>
                        </div>
                    </div>
                ) : signInState === SIGN_IN_STATE.PHONE ? (
                    <form onSubmit={handleSubmit(onPhoneSubmit)}>
                        <Button
                            className="backButton"
                            variant="contained"
                            onClick={() => {
                                setSignInState(SIGN_IN_STATE.CHOOSE);
                            }}
                        >
                            <ArrowBackIosNewOutlined fontSize="medium" />
                        </Button>
                        <div className="auth_commonSignInNumber">
                            <Controller
                                name="phone"
                                control={control}
                                rules={{
                                    validate: (value) =>
                                        phone(value, {
                                            validateMobilePrefix: false
                                        })?.isValid
                                }}
                                render={({ field, fieldState: { error } }) => (
                                    <MuiPhoneNumber
                                        error={!!error || isInvalidAuthIdentity}
                                        label="Phone number"
                                        disableDropdown={true}
                                        countryCodeEditable={false}
                                        defaultCountry="us"
                                        onlyCountries={['us']}
                                        autoFocus={true}
                                        {...field}
                                    />
                                )}
                            />
                            {isInvalidAuthIdentity && <div className="errorMessage mt8">Invalid phone number</div>}
                        </div>
                        <div className="nextButtton">
                            <Button variant="contained" type="submit">
                                Next
                            </Button>
                        </div>
                    </form>
                ) : signInState === SIGN_IN_STATE.EMAIL ? (
                    <form onSubmit={handleSubmit(onEmailSubmit)}>
                        <Button
                            className="backButton"
                            variant="contained"
                            onClick={() => {
                                setSignInState(SIGN_IN_STATE.CHOOSE);
                            }}
                        >
                            <ArrowBackIosNewOutlined fontSize="medium" />
                        </Button>

                        <div className="auth_commonSignInFiled">
                            <TextField {...register('email', { required: true })} id="Email" label="Email address" variant="standard" />
                            {isInvalidAuthIdentity && <div className="errorMessage mt8">Invalid email address</div>}
                        </div>
                        <div className="nextButtton">
                            <Button variant="contained" type="submit">
                                Next
                            </Button>
                        </div>
                    </form>
                ) : signInState === SIGN_IN_STATE.PIN ? (
                    <div className="auth_commonSignInfo">
                        <Typography variant="body1" className="textCenter">
                            Enter the code that was sent to you
                        </Typography>
                        <div className="textCenter">
                            <Button onClick={onResendClicked}>Resend Code</Button>
                        </div>
                        <div className="loginPin">
                            <ReactCodeInput ref={pinRef} name="pin" type="text" fields={PIN_LENGTH} inputMode="numeric" autoFocus={true} onChange={onChangePin} />
                        </div>
                        {pinError && <div className="errorMessage">Oops. There was an issue with your pin!</div>}
                    </div>
                ) : (
                    <></>
                )}
                <div className="auth_commonPrivacyInfo">
                    By signing in, you are agreeing to the{' '}
                    <a href="https://www.teamcake.com/terms/" rel="noreferrer" target="_blank">
                        Terms of Use
                    </a>{' '}
                    and{' '}
                    <a href="https://www.teamcake.com/privacy/" rel="noreferrer" target="_blank">
                        Privacy Policy
                    </a>
                </div>
            </div>
        </div>
    );
});

export default Auth;
