import React from 'react';
import { Alert, Grid, IconButton, Tooltip } from '@mui/material';
import { AlternateEmailOutlined, AttachFile, SentimentSatisfiedAltOutlined, TagOutlined } from '@mui/icons-material';
import { BaseEmoji, Picker } from 'emoji-mart';
import SendIcon from '@mui/icons-material/Send';
import { FileInTransit, FILE_STATE_TYPE, useFileUpload } from '../../providers/FileUpload';
import { ApiGif } from '../../_api/_ApiModels';
import GifIcon from '../../assets/images/icons/gif.svg';
import MessageReplyFileList from './MessageReplyFileList';
import MessageGiphySelector from './MessageGiphySelector';
import { CALLOUT_KEY, HASHTAG_KEY } from '../../_global/Constants';
import DraftHashtagCalloutInput, { DraftDefaultValues } from '../common/DraftHashtagCalloutInput';
import { EditorRef } from '@draft-js-plugins/editor';
import MessageGifImage from './MessageGifImage';
import { ContentState, convertToRaw, EditorState, Modifier } from 'draft-js';
interface MessageReplyProps {
    messageThreadId: string;
    messageId: string;
    placeholder: string;
    sendMessageCallback?: Function;
    autoFocus?: boolean;
    onChange?: Function;
    validateHashtagCallout?: boolean;
    initialText?: string;
}

enum PICKER_TYPE {
    GIPIHY,
    EMOJI
}

const MessageReply = React.memo<MessageReplyProps>(({ messageThreadId, messageId, placeholder, autoFocus = true, sendMessageCallback, onChange, validateHashtagCallout = false, initialText }) => {
    const fileUpload = useFileUpload();
    const [text, setText] = React.useState<EditorState>(EditorState.createEmpty());
    const [pickerType, setPickerType] = React.useState<PICKER_TYPE | null>(null);
    const [fileInTransitList, setFileInTransitList] = React.useState<Array<FileInTransit>>([]);
    const [isFileUploading, setIsFileUploading] = React.useState(false);
    const [apiGifList, setApiGifList] = React.useState<Array<ApiGif>>([]);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const fileInputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const messageTextRef = React.useRef() as React.MutableRefObject<EditorRef>;
    const giphySelectorDivRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
    const emojiSelectorDivRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
    const onUploadFiles = React.useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                for (let i = 0; i < event.target.files.length; i++) {
                    fileUpload.upload(event.target.files[i], messageThreadId, messageId);
                }
            }
            messageTextRef.current.editor.focus();
        },
        [fileUpload, messageId, messageThreadId]
    );
    const insetDraftFragment = React.useCallback(
        (value: string) => {
            const selection = text.getSelection();
            const contentState = text.getCurrentContent();
            const modifyContentState = Modifier.insertText(contentState, selection, value);
            return EditorState.push(text, modifyContentState, DraftDefaultValues.INSERT_FRAGMENT);
        },
        [text]
    );
    const getEditorFormattedText = React.useCallback((editorState: EditorState) => {
        const rawEditorContent = convertToRaw(editorState.getCurrentContent());
        const markup = (mention: any) => (mention?.type === HASHTAG_KEY ? `{{hid=${mention.id}}}` : `{{cid=${mention.id}}}`);
        let plainText = editorState.getCurrentContent().getPlainText();
        Object.keys(rawEditorContent.entityMap).map((keys: string) => {
            const mention = rawEditorContent.entityMap[keys]?.data?.mention;
            const searchKey = `${mention?.type}${mention?.name}`;
            plainText = plainText.replace(searchKey, function (matched) {
                return markup(mention);
            });
        });
        return plainText;
    }, []);
    const onEmojiSelected = React.useCallback(
        (emojiData: BaseEmoji) => {
            setText(insetDraftFragment(emojiData.native));
        },
        [insetDraftFragment]
    );
    const isMessageEmpty = React.useCallback(() => {
        return !text.getCurrentContent().hasText() && apiGifList.length === 0 && fileUpload.fileInTransitList.length === 0;
    }, [apiGifList.length, fileUpload.fileInTransitList.length, text]);
    const onCreateMessage = React.useCallback(() => {
        const plainText = getEditorFormattedText(text);
        if (validateHashtagCallout && !plainText.match(/{{cid=\w+}}|{{hid=\w+}}/m)) {
            setErrorMessage('Message must include at least one callout (@person) or a hashtag (#topic) in order to be sent.');
            return;
        }
        if (isMessageEmpty()) {
            return;
        }
        if (sendMessageCallback) {
            const fileIdList = fileUpload.fileInTransitList.filter((obj) => obj.messageId === messageId).map((obj) => obj.fileId);
            sendMessageCallback(messageThreadId, plainText, fileIdList, apiGifList);
        }
        setText(EditorState.push(text, ContentState.createFromText(''), DraftDefaultValues.REMOVE_RANDE));
        setApiGifList([]);
        setFileInTransitList([]);
    }, [apiGifList, fileUpload.fileInTransitList, getEditorFormattedText, isMessageEmpty, messageId, messageThreadId, sendMessageCallback, text, validateHashtagCallout]);
    const onTyping = React.useCallback(
        (value) => {
            setText(value);
            if (onChange) {
                onChange(value);
            }
            if (errorMessage) {
                setErrorMessage(null);
            }
        },
        [onChange, errorMessage]
    );
    const onPaste = React.useCallback(
        (e: React.ClipboardEvent<HTMLDivElement>) => {
            const items = e.clipboardData.items;
            for (let i = 0; i < items.length; i++) {
                const item = items[i];
                if (item.kind === 'file') {
                    const file = item.getAsFile();
                    if (file) {
                        fileUpload.upload(file, messageThreadId, messageId);
                    }
                }
            }
        },
        [fileUpload, messageId, messageThreadId]
    );
    const onGifSelected = React.useCallback((apiGif: ApiGif) => {
        setApiGifList((state) => [...state, apiGif]);
        setPickerType(null);
    }, []);
    const onDeleteFileClicked = React.useCallback(
        (fileId: string) => {
            fileUpload.cancel(fileId);
        },
        [fileUpload]
    );
    React.useEffect(() => {
        setFileInTransitList(
            fileUpload.fileInTransitList.filter((obj) => {
                return obj.messageThreadId === messageThreadId && !obj.messageSent;
            })
        );
        const stateTypeUploading = fileUpload.fileInTransitList.filter((obj) => {
            return obj.stateType === FILE_STATE_TYPE.UPLOADING;
        });
        if (stateTypeUploading.length > 0) {
            setIsFileUploading(true);
        } else { setIsFileUploading(false) }
    }, [fileUpload.fileInTransitList, messageThreadId]);
    React.useEffect(() => {
        if (initialText) {
            //TODO: Debug
            setText(insetDraftFragment(initialText));
        }
        const onClickOutside = (event: MouseEvent) => {
            if (giphySelectorDivRef.current && !giphySelectorDivRef.current.contains(event.target as Element)) {
                setPickerType((state) => (state !== PICKER_TYPE.GIPIHY ? state : null));
            }
            if (emojiSelectorDivRef.current && !emojiSelectorDivRef.current.contains(event.target as Element)) {
                setPickerType((state) => (state !== PICKER_TYPE.EMOJI ? state : null));
            }
        };
        document.addEventListener('mousedown', onClickOutside);
        return () => {
            document.removeEventListener('mousedown', onClickOutside);
        };
    }, [initialText]);
    return (
        <div className="messageReply_emotions">
            <input
                ref={fileInputRef}
                type="file"
                multiple
                hidden={true}
                onChange={(e) => {
                    onUploadFiles(e);
                }}
            />
            <Grid container>
                {errorMessage && (
                    <Alert severity="error" className="error">
                        {errorMessage}
                    </Alert>
                )}
                <div className={errorMessage ? 'messageReply_Wrapper error' : 'messageReply_Wrapper'}>
                    <div className="common_draftWrap">
                        <DraftHashtagCalloutInput
                            editorRef={messageTextRef}
                            onChange={(e) => {
                                onTyping(e);
                            }}
                            editorState={text}
                            style={{ width: '100%', scrollbarWidth: 'thin', borderStyle: 'none', outline: '0', resize: 'none' }}
                            className="mentionStyle"
                            placeholder={placeholder}
                            autoFocus={autoFocus}
                            onKeyPress={(e) => {
                                if (e.key === 'Enter' && !e.shiftKey) {
                                    e.preventDefault();
                                    onCreateMessage();
                                    return DraftDefaultValues.HANDLED;
                                }
                                return DraftDefaultValues.NOT_HANDLED;
                            }}
                            onPaste={onPaste}
                        />
                    </div>
                    {fileInTransitList.length > 0 && <MessageReplyFileList fileInTransitList={fileInTransitList} onDeleteFileClicked={onDeleteFileClicked} />}
                    {apiGifList.length > 0 && (
                        <div className="messageReply_attachedImage">
                            <ul className="commonImageList">
                                {apiGifList.map((apiGif) => (
                                    <li key={apiGif.gifId}>
                                        <MessageGifImage gif={apiGif} onImageRemoved={() => setApiGifList(apiGifList.filter((m) => m.gifId !== apiGif.gifId))} autoPlay={true} />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                </div>
                <div ref={emojiSelectorDivRef} className="messageReply_emoji" style={{ display: pickerType === PICKER_TYPE.EMOJI ? 'block' : 'none' }}>
                    <Picker onClick={onEmojiSelected} />
                </div>
                <div ref={giphySelectorDivRef} className="messageReply_giphy" style={{ position: 'relative', display: pickerType === PICKER_TYPE.GIPIHY ? 'block' : 'none' }}>
                    <MessageGiphySelector onGifSelected={onGifSelected} />
                </div>
            </Grid>
            <Grid container className="messageReply_addMedia">
                <Grid item className="messageReply_addMediaLeftPane">
                    <Tooltip title="Add Hashtag" placement="top" arrow>
                        <IconButton
                            sx={{ paddingLeft: 0 }}
                            onClick={() => {
                                setText(insetDraftFragment(HASHTAG_KEY));
                                setTimeout(() => messageTextRef.current.editor.focus());
                            }}
                        >
                            <TagOutlined />
                        </IconButton>
                    </Tooltip>{' '}
                    <Tooltip title="Add Callout " placement="top" arrow>
                        <IconButton
                            onClick={() => {
                                setText(insetDraftFragment(CALLOUT_KEY));
                                setTimeout(() => messageTextRef.current.editor.focus());
                            }}
                        >
                            <AlternateEmailOutlined />
                        </IconButton>
                    </Tooltip>{' '}
                    <Tooltip title="Attach File" placement="top" arrow>
                        <IconButton
                            onClick={() => {
                                fileInputRef.current.click();
                            }}
                        >
                            <AttachFile />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Add GIF" placement="top" arrow>
                        <IconButton
                            onClick={() => {
                                setPickerType(PICKER_TYPE.GIPIHY);
                            }}
                        >
                            <img src={GifIcon} alt="gif_icon" />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Emoji" placement="top" arrow>
                        <IconButton
                            onClick={() => {
                                setPickerType(PICKER_TYPE.EMOJI);
                            }}
                        >
                            <SentimentSatisfiedAltOutlined />
                        </IconButton>
                    </Tooltip>
                </Grid>
                <Grid item className="messageReply_addMediaRightPane">
                    {isMessageEmpty() || isFileUploading ? (
                        <IconButton disabled>
                            <SendIcon />
                        </IconButton>
                    ) : (
                        <Tooltip title="Send" placement="top" arrow>
                            <IconButton onClick={onCreateMessage}>
                                <SendIcon />
                            </IconButton>
                        </Tooltip>
                    )}
                </Grid>
            </Grid>
        </div>
    );
});

export default MessageReply;
