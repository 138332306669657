import React from 'react';
import moment from 'moment';
import { InfoOutlined as InfoOutlinedIcon, TagOutlined as TagOutlinedIcon } from '@mui/icons-material';
import { useSelector } from '../../_redux/_Store';
import { ApiHashtag } from '../../_api/_ApiModels';
import { MONTH_DAY_YEAR_FORMAT } from '../../_global/Constants';
import { TAGS_PARAM_TYPE } from '../../_global/Route';
import { useParams } from 'react-router-dom';
import Hashtag from '../common/Hashtag';

const TagInfo = React.memo(() => {
    const { apiTagList, apiTagCategoryList, apiHashtagList } = useSelector((state) => state.data);
    const params = useParams<TAGS_PARAM_TYPE>();
    const [tagId, setTagId] = React.useState(params.TAG_ID);
    const [name, setName] = React.useState<string>('');
    const [tagCategoryName, setTagCategoryName] = React.useState<string>('');
    const [createTime, setCreateTime] = React.useState<number>(0);
    const [tagApiHashtagList, setTagApiHashtagList] = React.useState<Array<ApiHashtag>>([]);
    React.useEffect(() => {
        setTagId(params.TAG_ID);
    }, [params.TAG_ID]);
    React.useEffect(() => {
        if (!tagId) return;
        const apiTag = apiTagList[tagId];
        if (!apiTag) return;
        setName(apiTag.name);
        if (apiTag.tagCategoryId) {
            const apiTagCategory = apiTagCategoryList[apiTag.tagCategoryId];
            setTagCategoryName(apiTagCategory.name);
        }
        setCreateTime(apiTag.createTime);
        setTagApiHashtagList(Object.values(apiHashtagList).filter((apiHashtag) => apiHashtag.tagIdList.includes(tagId)).sort((a, b) => {
            return a.tagIdList.length - b.tagIdList.length;
        }));
    }, [apiHashtagList, apiTagCategoryList, apiTagList, tagId]);
    return (
        <div className="commonHashtagDrawer_wrapper">
            <div className="commonHashtagDrawer_messageHeader">
                <div className="headerTitle">Topic Details</div>
            </div>
            <div className="commonHashtagDrawer_header">
                <h2>{name}</h2>
            </div>
            <div className="commonHashtagDrawer_body">
                <div className="commonHashtagDrawer_info">
                    <div className="commonHashtagDrawer_heading">
                        <InfoOutlinedIcon /> Info
                    </div>
                    <div className="commonHashtagDrawer_title">Category</div>
                    <div className="commonHashtagDrawer_text">{tagCategoryName}</div>
                    <div className="commonHashtagDrawer_title mt8">Created</div>
                    <div className="commonHashtagDrawer_text">{moment.unix(createTime).format(MONTH_DAY_YEAR_FORMAT)}</div>
                </div>
                <div className="commonHashtagDrawer_subscriber">
                    <div className="commonHashtagDrawer_headingInner">
                        <div className="commonHashtagDrawer_headingInnerLeft">
                            <TagOutlinedIcon /> Hashtags ({tagApiHashtagList.length})
                        </div>
                    </div>
                    <ul className="commonList">
                        {tagApiHashtagList.map((apiHashtag) => (
                            <li key={apiHashtag.hashtagId}>
                                <Hashtag hashtagId={apiHashtag.hashtagId} />
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    );
});

export default TagInfo;
