import { ApiMessageThread } from '../_api/_ApiModels';
import { WebSocketPayloadMessageThread } from '../_redux/WebSocketReducer';
import produce from 'immer';

export enum MESSAGE_THREAD_LIST_ACTION_TYPE {
    SET_API_MESSAGE_THREAD_LIST = 'SET_API_MESSAGE_THREAD_LIST',
    APPEND_API_MESSAGE_THREAD_LIST = 'APPEND_API_MESSAGE_THREAD_LIST',
    WEB_SOCKET_MESSAGE_THREAD = 'WEB_SOCKET_MESSAGE_THREAD'
}

export interface MessageThreadListAction {
    type: MESSAGE_THREAD_LIST_ACTION_TYPE;
    payload: Array<ApiMessageThread> | WebSocketPayloadMessageThread;
}

interface MessageThreadListState {
    apiMessageThreadList: Array<ApiMessageThread>;
    minSortTime?: number;
    length: number;
    isSearch: boolean; //Indicates
}

export const initInboxMessageThreadListState: MessageThreadListState = {
    apiMessageThreadList: [] as Array<ApiMessageThread>,
    length: 0,
    isSearch: false
};

export const initSearchMessageThreadListState: MessageThreadListState = {
    apiMessageThreadList: [] as Array<ApiMessageThread>,
    length: 0,
    isSearch: true
};

export const setApiMessageThreadList = (apiMessageThreadList: Array<ApiMessageThread>) => {
    return {
        type: MESSAGE_THREAD_LIST_ACTION_TYPE.SET_API_MESSAGE_THREAD_LIST,
        payload: apiMessageThreadList
    };
};

export const appendApiMessageThreadList = (apiMessageThreadList: Array<ApiMessageThread>) => {
    return {
        type: MESSAGE_THREAD_LIST_ACTION_TYPE.APPEND_API_MESSAGE_THREAD_LIST,
        payload: apiMessageThreadList
    };
};

export const webSocketMessageThread = (webSocketPayloadMessageThread: WebSocketPayloadMessageThread) => {
    return {
        type: MESSAGE_THREAD_LIST_ACTION_TYPE.WEB_SOCKET_MESSAGE_THREAD,
        payload: webSocketPayloadMessageThread
    };
};

const sortApiMessageThreadList = (a: ApiMessageThread, b: ApiMessageThread) => {
    const sort = b.sortTime - a.sortTime;
    if (sort) return sort;
    return b.messageThreadId.localeCompare(a.messageThreadId);
};

const getMinSortTime = (apiMessageThreadList: Array<ApiMessageThread>) => {
    return apiMessageThreadList.length > 0
        ? apiMessageThreadList.reduce((a, b) => {
              return a.sortTime < b.sortTime ? a : b;
          }).sortTime
        : 0;
};

export const MessageThreadListReducer = (state = initInboxMessageThreadListState, action: MessageThreadListAction) => {
    switch (action.type) {
        case MESSAGE_THREAD_LIST_ACTION_TYPE.SET_API_MESSAGE_THREAD_LIST: {
            const apiMessageThreadList = action.payload as Array<ApiMessageThread>;
            return produce(state, (draft) => {
                draft.apiMessageThreadList = apiMessageThreadList;
                draft.minSortTime = getMinSortTime(draft.apiMessageThreadList);
                draft.length = draft.apiMessageThreadList.length;
            });
        }
        case MESSAGE_THREAD_LIST_ACTION_TYPE.APPEND_API_MESSAGE_THREAD_LIST: {
            const apiMessageThreadList = action.payload as Array<ApiMessageThread>;
            return produce(state, (draft) => {
                draft.apiMessageThreadList = [...draft.apiMessageThreadList, ...apiMessageThreadList].sort(sortApiMessageThreadList);
                draft.minSortTime = getMinSortTime(draft.apiMessageThreadList);
                draft.length = draft.apiMessageThreadList.length;
            });
        }
        case MESSAGE_THREAD_LIST_ACTION_TYPE.WEB_SOCKET_MESSAGE_THREAD: {
            const webSocketPayloadMessageThread = action.payload as WebSocketPayloadMessageThread;
            return produce(state, (draft) => {
                const apiMessageThread = webSocketPayloadMessageThread.apiMessageThread;
                const i = draft.apiMessageThreadList.findIndex((obj) => obj.messageThreadId === apiMessageThread.messageThreadId);
                if (i > -1) {
                    draft.apiMessageThreadList.splice(i, 1);
                }
                if ((i > -1 && draft.isSearch) || (!draft.isSearch && apiMessageThread.isInbox)) {
                    draft.apiMessageThreadList = [...draft.apiMessageThreadList, webSocketPayloadMessageThread.apiMessageThread].sort(sortApiMessageThreadList);
                }
                draft.minSortTime = getMinSortTime(draft.apiMessageThreadList);
                draft.length = draft.apiMessageThreadList.length;
            });
        }
        default: {
            break;
        }
    }
};
