import { ApiHashtag, ApiMessage, ApiTag, ApiTagCategory, ApiUser } from '../_api/_ApiModels';
import { CLOUDFRONT_KEY_TAG, TIME_FORMAT } from './Constants';
import moment from 'moment';

export const getCloudfrontUrl = (cloudfrontUrl: string, key: string) => {
    return cloudfrontUrl.replace(CLOUDFRONT_KEY_TAG, key);
};

export const getInitials = (apiUser: ApiUser) => {
    const first = apiUser.firstName != null ? apiUser.firstName.charAt(0).toLowerCase() : '';
    const last = apiUser.lastName != null ? apiUser.lastName.charAt(0).toLowerCase() : '';
    return first + last;
};

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

export const log = (log: any) => {
    console.log(`${moment().format(TIME_FORMAT)} ${log}`);
};

export const getMessageText = (apiMessage: ApiMessage) => {
    if (apiMessage.deleted) return 'This message has been deleted';
    if (apiMessage.text && apiMessage.text.trim().length > 0) return apiMessage.text;
    if (apiMessage.apiFileList.length === 1 && apiMessage.apiGifList.length === 1) return `1 file and 1 gif were added.`;
    if (apiMessage.apiFileList.length > 1 && apiMessage.apiGifList.length === 1) return `${apiMessage.apiFileList.length} files and 1 gif were added.`;
    if (apiMessage.apiFileList.length === 1 && apiMessage.apiGifList.length > 1) return `1 file and ${apiMessage.apiGifList.length} gifs were added.`;
    if (apiMessage.apiFileList.length === 1) return `1 file was added`;
    if (apiMessage.apiFileList.length > 1) return `${apiMessage.apiFileList.length} files were added`;
    if (apiMessage.apiGifList.length === 1) return `1 gif was added`;
    if (apiMessage.apiGifList.length) return `${apiMessage.apiGifList.length} gifs were added`;
    return 'this message is empty'; //shouldn't happen;
};

export const calculateHashtagName = (apiTagList: Array<ApiTag>, apiTagCategoryList: Record<string, ApiTagCategory>, hashtag: ApiHashtag, apiUser?: ApiUser) => {
    let name = '';
    if (!apiUser) return '';
    const displayApiTagCategoryList = sortApiTagCategoryList(apiTagCategoryList, apiUser.displayTagCategoryIdList);
    const hashtagApiTagList = apiTagList
        .filter((obj) => {
            return hashtag.tagIdList.findIndex((tagId) => tagId === obj.tagId) > -1;
        })
        .sort((a, b) => {
            const i = displayApiTagCategoryList.findIndex((obj) => obj.tagCategoryId === a.tagCategoryId);
            const j = displayApiTagCategoryList.findIndex((obj) => obj.tagCategoryId === b.tagCategoryId);
            return i - j;
        });
    for (let i = 0; i < hashtagApiTagList.length; i++) {
        name = name.concat((i > 0 ? '+' : '').concat(hashtagApiTagList[i].name));
    }
    return name;
};

export const arraysAreEqual = (_array1: Array<string>, _array2: Array<string>) => {
    let equal = true;
    if (_array1.length === _array2.length) {
        const array1 = [..._array1].sort();
        const array2 = [..._array2].sort();
        for (let i = 0; i < array1.length; i++) {
            if (array1[i] !== array2[i]) {
                equal = false;
                break;
            }
        }
    } else {
        equal = false;
    }
    return equal;
};

export const sortApiTagCategoryList = (apiTagCategoryList: Record<string, ApiTagCategory>, apiTagCategoryIdList: Array<string>) => {
    return [...Object.values(apiTagCategoryList)].sort((a, b) => {
        const i = apiTagCategoryIdList.findIndex((obj) => obj === a.tagCategoryId);
        const j = apiTagCategoryIdList.findIndex((obj) => obj === b.tagCategoryId);
        return i - j;
    });
};

export const sortUserIdList = (userIdList: Array<string>, apiUserList: Record<string, ApiUser>) => {
    return [...userIdList].sort((a, b) => {
        const aApiUser = apiUserList[a];
        const bApiUser = apiUserList[b];
        if (!aApiUser) return -1;
        if (!bApiUser) return +1;
        return `${aApiUser.firstName} ${aApiUser.lastName}`.localeCompare(`${bApiUser.firstName} ${bApiUser.lastName}`);
    });
};
