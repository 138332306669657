import React from 'react';
import { getFileTypeIcon, isAudio, isDirectDownloadableFile, UNKNOWN_FILE_THUMB } from '../../_global/FileUtils';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import { ClickAwayListener, IconButton, ListItemIcon, ListItemText, Menu, MenuItem } from '@mui/material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { GetPresignedDownloadUrlResponse, useApi } from '../../providers';
import { AxiosResponse } from 'axios';
import AudioPlayer from './AudioPlayer';
import { FileDownloadOutlined, VisibilityOutlined } from '@mui/icons-material';
import { useSelector } from '../../_redux/_Store';
import UnknownFiles from '../common/UnkownFiles';
import { QUERY_STRING_TYPE } from '../../_global/Route';
import { useLocation, useNavigate } from 'react-router-dom';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';
import { usePopupState, bindTrigger, bindToggle, bindMenu, bindPopper } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

interface MessageFileSentProps {
    fileId: string;
    src: string;
    contentType: string;
    displayName: string;
    byteCount: number;
    url: string;
    userId: string;
    messageId: string;
    messageThreadId: string;
}

const MessageFileSent = React.memo<MessageFileSentProps>(({ fileId, src, contentType, displayName, byteCount, url, userId, messageId, messageThreadId }) => {
    const api = useApi();
    const location = useLocation();
    const navigate = useNavigate();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const downloadElement = React.useRef() as React.MutableRefObject<HTMLAnchorElement>;
    const ellipsisPopupState = usePopupState({ variant: 'popover', popupId: `ellipsis-${fileId}` });
    const menuPopupState = usePopupState({ variant: 'popover', popupId: `menu-${fileId}` });
    const onDownloadClick = React.useCallback(() => {
        api.getPresignedDownloadUrl({ fileId: fileId }, (axiosResponse: AxiosResponse) => {
            const getPresignedDownloadUrlResponse: GetPresignedDownloadUrlResponse = axiosResponse.data;
            downloadElement.current.href = getPresignedDownloadUrlResponse.url;
            downloadElement.current.download = displayName;
            downloadElement.current.click();
        });
        menuPopupState.close();
        ellipsisPopupState.close();
    }, [api, displayName, ellipsisPopupState, fileId, menuPopupState]);

    const onPreviewClick = React.useCallback(() => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.set(QUERY_STRING_TYPE.FILE_ID, fileId);
        navigate({ search: queryParams.toString() });
        menuPopupState.close();
        ellipsisPopupState.close();
    }, [ellipsisPopupState, fileId, location.search, menuPopupState, navigate]);

    const onDeleteClick = React.useCallback(() => {
        api.deleteFile({
            messageThreadId: messageThreadId,
            messageId: messageId,
            fileId: fileId
        });
        menuPopupState.close();
        ellipsisPopupState.close();
    }, [api, messageThreadId, messageId, fileId, ellipsisPopupState, menuPopupState]);

    const isFileDirectDownload = isDirectDownloadableFile(contentType);

    return (
        <>
            <>
                {isAudio(contentType) ? (
                    <AudioPlayer fileName={displayName} byteCount={byteCount} url={url} />
                ) : isFileDirectDownload ? (
                    <UnknownFiles fileName={displayName} fileSize={byteCount} />
                ) : (
                    <>
                        <img
                            {...bindHover(ellipsisPopupState)}
                            src={src}
                            style={{ cursor: src !== UNKNOWN_FILE_THUMB ? 'pointer' : 'default' }}
                            loading="lazy"
                            alt="thumbnail"
                            onClick={() => onPreviewClick()}
                        />
                    </>
                )}

                <img src={getFileTypeIcon(contentType)} alt="file-type-icon" className="messageFileSent_overlayIcon" />

                <div>
                    <HoverPopover
                        {...bindPopover(ellipsisPopupState)}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right'
                        }}
                    >
                        <IconButton {...bindTrigger(menuPopupState)}>
                            <MoreHorizOutlinedIcon />
                        </IconButton>
                        <Menu
                            {...bindPopover(menuPopupState)}
                            onClose={() => {
                                menuPopupState.close();
                                ellipsisPopupState.close();
                            }}
                            className="commonPopoverDropdown zipPopoverDropdown"
                        >
                            {!isFileDirectDownload && (
                                <MenuItem onClick={onPreviewClick}>
                                    <ListItemIcon>
                                        <VisibilityOutlined />
                                    </ListItemIcon>
                                    <ListItemText>Preview File</ListItemText>
                                </MenuItem>
                            )}

                            <MenuItem onClick={onDownloadClick}>
                                <ListItemIcon>
                                    <FileDownloadOutlined />
                                </ListItemIcon>
                                <ListItemText>Download File</ListItemText>
                            </MenuItem>

                            {loggedInUserId === userId && (
                                <MenuItem onClick={onDeleteClick}>
                                    <ListItemIcon>
                                        <DeleteOutlinedIcon />
                                    </ListItemIcon>
                                    <ListItemText>Delete File</ListItemText>
                                </MenuItem>
                            )}
                        </Menu>
                    </HoverPopover>
                    <a ref={downloadElement} hidden={true} href="https://app.teamcake.com" download="download">
                        {' '}
                        download{' '}
                    </a>
                </div>
            </>
        </>
    );
});

export default MessageFileSent;
