import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SCREEN_TYPE, QUERY_STRING_TYPE, LAYOUT_TYPE } from '../_global/Route';
import { SearchMessageThreadListRequest } from './Api';
import isEqual from 'lodash/isEqual';
import { ApiMessageThreadSearchFilter, MESSAGE_THREAD_SEARCH_FILTER_TYPE } from '../_api/_ApiModels';

interface MessageThreadFilter {
    filterList: Array<ApiMessageThreadSearchFilter> | null;
    displayCount?: number;
    navigateToSearch: (filterList: Array<ApiMessageThreadSearchFilter>) => void;
    addFilterListValue: (
        filterType: MESSAGE_THREAD_SEARCH_FILTER_TYPE.TAG_ID_LIST | MESSAGE_THREAD_SEARCH_FILTER_TYPE.HASHTAG_ID_LIST | MESSAGE_THREAD_SEARCH_FILTER_TYPE.USER_ID_LIST,
        value: string
    ) => void;
    removeFilter: (typeList: Array<MESSAGE_THREAD_SEARCH_FILTER_TYPE>) => void;
    removeFilterListValue: (
        filterType: MESSAGE_THREAD_SEARCH_FILTER_TYPE.TAG_ID_LIST | MESSAGE_THREAD_SEARCH_FILTER_TYPE.HASHTAG_ID_LIST | MESSAGE_THREAD_SEARCH_FILTER_TYPE.USER_ID_LIST,
        value: string
    ) => void;
    removeAllFilters: () => void;
    getUrl: (filterList: Array<ApiMessageThreadSearchFilter>) => string;
    getSearchMessageThreadListRequest: (minSortTime?: number) => SearchMessageThreadListRequest;
}

const MessageThreadFilterContext = React.createContext<MessageThreadFilter>({
    filterList: null,
    navigateToSearch: () => {},
    addFilterListValue: () => {},
    removeFilter: () => {},
    removeFilterListValue: () => {},
    removeAllFilters: () => {},
    getUrl: () => '',
    getSearchMessageThreadListRequest: (minSortTime?: number) => {
        return {} as SearchMessageThreadListRequest;
    }
});

export interface MessageThreadFilterProps {
    children?: React.ReactNode;
}

export const MessageThreadFilterProvider = React.memo<MessageThreadFilterProps>((props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [filterList, setFilterList] = React.useState<Array<ApiMessageThreadSearchFilter> | null>(null);
    const [displayCount, setDisplayCount] = React.useState<number>();
    const navToSearch = React.useCallback(
        (newFilterList: Array<ApiMessageThreadSearchFilter>) => {
            const queryParams = new URLSearchParams(location.search);
            queryParams.delete(QUERY_STRING_TYPE.FILTER);
            queryParams.delete(QUERY_STRING_TYPE.MESSAGE_THREAD_SEARCH_ID);
            queryParams.delete(QUERY_STRING_TYPE.DRAWER_TYPE);
            if (newFilterList.length > 0) {
                queryParams.set(QUERY_STRING_TYPE.FILTER, JSON.stringify(newFilterList));
            }
            navigate({ pathname: `/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SEARCH}`, search: queryParams.toString() });
        },
        [location.search, navigate]
    );
    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const filterQueryString = queryParams.get(QUERY_STRING_TYPE.FILTER);
        const isSearching = location.pathname.includes(`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SEARCH}`);
        if (!isSearching) return;
        setFilterList((state) => {
            const newFilterList = filterQueryString ? JSON.parse(decodeURIComponent(filterQueryString)) : ([] as Array<ApiMessageThreadSearchFilter>);
            if (isEqual(state, newFilterList)) return state;
            return newFilterList;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);
    React.useEffect(() => {
        if (!filterList) {
            setDisplayCount(0);
            return;
        }
        const count = filterList.reduce((count, obj) => {
            switch (obj.type) {
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.DATE_RANGE_TYPE:
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.HAS_ATTACHMENTS:
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_CALLED_OUT:
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_NOT_INBOX:
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_NOT_SEEN:
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_SENT_BY_USER:
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_USER_HASHTAG:
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_STARRED: {
                    count++;
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.TAG_ID_LIST:
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.HASHTAG_ID_LIST:
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.USER_ID_LIST: {
                    count += (obj.value as Array<string>).length;
                    break;
                }
            }
            return count;
        }, 0);
        setDisplayCount(count);
    }, [filterList]);
    const messageThreadFilter = React.useMemo<MessageThreadFilter>(() => {
        return {
            filterList: filterList,
            displayCount: displayCount,
            navigateToSearch: (newFilterList: Array<ApiMessageThreadSearchFilter>) => {
                navToSearch(newFilterList);
            },
            addFilterListValue: (
                filterType: MESSAGE_THREAD_SEARCH_FILTER_TYPE.TAG_ID_LIST | MESSAGE_THREAD_SEARCH_FILTER_TYPE.HASHTAG_ID_LIST | MESSAGE_THREAD_SEARCH_FILTER_TYPE.USER_ID_LIST,
                value: string
            ) => {
                const newFilterList = filterList ? (JSON.parse(JSON.stringify([...filterList])) as ApiMessageThreadSearchFilter[]) : []; //need a deep copy here; can't use only spread
                let i = newFilterList.findIndex((obj) => obj.type === filterType);
                if (i === -1) {
                    newFilterList.push({
                        type: filterType,
                        value: []
                    });
                    i = newFilterList.findIndex((obj) => obj.type === filterType);
                }
                if (!(newFilterList[i].value as Array<string>).includes(value)) {
                    (newFilterList[i].value as Array<string>).push(value);
                }
                navToSearch(newFilterList);
            },
            removeFilter: (typeList: Array<MESSAGE_THREAD_SEARCH_FILTER_TYPE>) => {
                if (!filterList) return;
                navToSearch([...filterList].filter((obj) => !typeList.some((x) => x === obj.type)));
            },
            removeFilterListValue: (
                filterType: MESSAGE_THREAD_SEARCH_FILTER_TYPE.TAG_ID_LIST | MESSAGE_THREAD_SEARCH_FILTER_TYPE.HASHTAG_ID_LIST | MESSAGE_THREAD_SEARCH_FILTER_TYPE.USER_ID_LIST,
                value: string
            ) => {
                if (!filterList) return;
                const newFilterList = JSON.parse(JSON.stringify([...filterList])) as ApiMessageThreadSearchFilter[]; //need a deep copy here; can't use only spread
                const i = newFilterList.findIndex((obj) => obj.type === filterType);
                if (i > -1) {
                    const j = (newFilterList[i].value as Array<string>).findIndex((obj) => obj === value);
                    if (j > -1) {
                        (newFilterList[i].value as Array<string>).splice(j, 1);
                    }
                    if ((newFilterList[i].value as Array<string>).length === 0) {
                        newFilterList.splice(i, 1);
                    }
                }
                navToSearch(newFilterList);
            },
            removeAllFilters: () => {
                setFilterList(null);
            },
            getUrl: (filterList: Array<ApiMessageThreadSearchFilter>) => {
                return `/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SEARCH}?${QUERY_STRING_TYPE.FILTER}=${encodeURIComponent(JSON.stringify(filterList))}`;
            },
            getSearchMessageThreadListRequest: (minSortTime?: number) => {
                return {
                    lastSortTime: minSortTime,
                    apiMessageThreadSearchFilterList: filterList
                } as SearchMessageThreadListRequest;
            }
        };
    }, [filterList, displayCount, navToSearch]);
    return <MessageThreadFilterContext.Provider value={messageThreadFilter}>{props.children}</MessageThreadFilterContext.Provider>;
});

export const useMessageThreadFilter = () => React.useContext(MessageThreadFilterContext);
