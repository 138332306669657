import { REACTION_TYPE } from './_Enums';
import LikeIcon from '../assets/images/reactions/thumb_up.svg';
import HeartIcon from '../assets/images/reactions/heart.svg';
import DisLikeIcon from '../assets/images/reactions/thumb_down.svg';
import LaughIcon from '../assets/images/reactions/mood_laugh.svg';
import ShockIcon from '../assets/images/reactions/mood_shock.svg';
import LikeOffIcon from '../assets/images/reactions/thumb_up_off.svg';
import HeartOffIcon from '../assets/images/reactions/heart_off.svg';
import DisLikeOffIcon from '../assets/images/reactions/thumb_down_off.svg';
import LaughOffIcon from '../assets/images/reactions/mood_laugh_off.svg';
import ShockOffIcon from '../assets/images/reactions/mood_shock_off.svg';

export const CLOUDFRONT_KEY_TAG = '[[KEY]]';

export const TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss.SSS';
export const READ_TIME_FORMAT = 'MMM DD YYYY [at] HH:mm A';
export const TIME_ONLY_FORMAT = 'h:mm A';
export const MONTH_DAY_FORMAT = 'MMM DD';
export const MONTH_DAY_YEAR_FORMAT = 'MMM DD YYYY';
export const FILE_DAY_FORMAT_FORMAT = 'MMM DD [at] h:mm A';
export const FILE_DAY_YEAR_FORMAT_FORMAT = 'MMM DD YYYY [at] h:mm A';

export const HASHTAG_KEY = '#';
export const CALLOUT_KEY = '@';

export const HASHTAG_REGEX = /\{\{hid=(?<id>[0-9a-fA-F]{24})\}\}/g;
export const CALLOUT_REGEX = /\{\{cid=(?<id>[0-9a-fA-F]{24})\}\}/g;
export const EmojiPattern = /([\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2694-\u2697]|\uD83E[\uDD10-\uDD5D])/g;

export const TYPING_TIMEOUT_MILLISECONDS = 7000;
export const TYPING_SEND_MILLISECONDS = 5000;

export interface AvatarColor {
    background: string;
    text: string;
}
export const AVATAR_COLOR_LIST: AvatarColor[] = [
    { background: '#194AC2', text: '#95C4FF' },
    { background: '#282644', text: '#ACCACC' },
    { background: '#FEA0C4', text: '#DA2534' },
    { background: '#FFECAC', text: '#E96B28' },
    { background: '#E3C9D2', text: '#282644' },
    { background: '#A5BDBD', text: '#12503F' },
    { background: '#F6D5C1', text: '#BF6753' },
    { background: '#8EBBFF', text: '#290060' },
    { background: '#312A9E', text: '#D9A3FE' },
    { background: '#FBECDB', text: '#EB5926' },
    { background: '#F3F6D3', text: '#66A945' },
    { background: '#D2DFF1', text: '#515BA9' },
    { background: '#ECDFEC', text: '#7C54A3' },
    { background: '#DAF1FB', text: '#1E84C8' },
    { background: '#E0F1F4', text: '#1F9A8D' },
    { background: '#FBE3ED', text: '#DD1F78' },
    { background: '#ECEEF2', text: '#5D5E66' },
    { background: '#FEF7CE', text: '#DA792A' }
];

export const CALENDAR_FORMATS = {
    sameDay: '[Today]',
    lastDay: '[Yesterday]',
    lastWeek: 'M/D/YY',
    sameElse: 'M/D/YY',
    lastElse: 'M/D/YY'
};

export const INBOX_MESSAGE_THREAD_CALENDAR_FORMATS = {
    sameDay: 'h:mm A',
    lastDay: 'ddd h:mm A',
    lastWeek: 'ddd h:mm A',
    lastElse: 'ddd M/D',
    sameElse: 'ddd M/D'
};

export const MESSAGE_CALENDAR_FORMATS = {
    sameDay: 'h:mm A',
    lastDay: '[Yesterday] h:mm A',
    lastWeek: 'ddd h:mm A',
    lastElse: 'ddd M/D h:mm A',
    sameElse: 'ddd M/D h:mm A',
    sameYear: 'MMM DD'
};

export const TOOLTIP_TEXTS = {
    turnOnNotification: 'Turn notifications on for this thread',
    turnOffNotification: 'Turn notifications off for this thread',
    removeThread: 'Remove this thread from Inbox',
    moveThread: 'Move this thread to Inbox',
    removeStar: 'Remove from Starred Threads',
    addStar: 'Add to Starred Threads',
    viewPeopleInThread: 'View tags and people included in this thread',
    viewAttachment: 'View Attachments',
    viewPreviousThread: 'View previous thread',
    viewNextThread: 'View next thread',
    addUser: 'Add user to Starred Contacts',
    removeUser: 'Remove user from Starred Contacts',
    viewMsgwithUser: 'View messages with this user',
    composeMsgtoUser: 'Compose message to this user',
    threadInfoNotificationsOn: 'User has notifications on for this thread',
    threadInfoNotificationsOff: 'User has notifications off for this thread',
};

export interface Reaction {
    type: REACTION_TYPE;
    image: string;
    loggedInUserImage: string;
    defaultBackground: string;
}

export const ReactionList = [
    { type: REACTION_TYPE.LIKE, image: LikeOffIcon, loggedInUserImage: LikeIcon, defaultBackground: '#E5F2FF' },
    { type: REACTION_TYPE.HEART, image: HeartOffIcon, loggedInUserImage: HeartIcon, defaultBackground: '#FDECEC' },
    { type: REACTION_TYPE.DISLIKE, image: DisLikeOffIcon, loggedInUserImage: DisLikeIcon, defaultBackground: '#E5F2FF' },
    { type: REACTION_TYPE.LAUGHING, image: LaughOffIcon, loggedInUserImage: LaughIcon, defaultBackground: '#FFEACC' },
    { type: REACTION_TYPE.SURPRISE, image: ShockOffIcon, loggedInUserImage: ShockIcon, defaultBackground: '#FFEACC' }
] as Array<Reaction>;

export const FILE_TYPES_FOR_ICONS = {
    IMAGE: 'image',
    AUDIO: 'audio',
    VIDEO: 'video',
    APPLICATION: 'application',
    APPLICATON_TYPES: {
        PDF: 'pdf',
        DOC: 'doc',
        WORD: 'msword',
        WORD2: 'vnd.openxmlformats-officedocument.wordprocessingml.document',
        ZIP: 'zip',
        ZIP2: 'x-zip-compressed',
        EXCEL: 'vnd.ms-excel',
        EXCEL2: 'vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        PPT: 'vnd.openxmlformats-officedocument.presentationml.presentation',
        PPT2: 'vnd.ms-powerpoint'
    }
};
