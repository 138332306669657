import React from 'react';
import { PDFPageProxy } from 'react-pdf';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
import { CircularProgress } from '@mui/material';
import { PDFDocumentProxy } from 'react-pdf/node_modules/pdfjs-dist/types/src/display/api';
import { VariableSizeList } from 'react-window';

interface PdfViewerProps {
    url: string;
    width: number;
    height: number;
}

interface PageDimension {
    pageNumber: number;
    width: number;
    height: number;
}

const PdfViewer = React.memo<PdfViewerProps>(({ url, width, height }) => {
    const [pdf, setPdf] = React.useState<PDFDocumentProxy>();
    const [pageDimensions, setPageDimensions] = React.useState<Array<PageDimension>>();
    const variableSizeListRef = React.useRef() as React.MutableRefObject<VariableSizeList>;
    const onLoadSuccess = React.useCallback((pdfDocumentProxy: PDFDocumentProxy) => {
        setPdf(pdfDocumentProxy);
    }, []);
    const getRowHeight = React.useCallback(
        (index: number) => {
            let rowHeight = 720;
            if (pageDimensions) {
                const pageDimension = pageDimensions.find((pageDimension) => {
                    return pageDimension.pageNumber === index + 1;
                });
                if (pageDimension != null) {
                    return pageDimension.height * ((width / pageDimension.width) * 0.9) + 10;

                }
            }
            return rowHeight;
        },
        [pageDimensions, width]
    );
    React.useEffect(() => {
        if (pdf) {
            // eslint-disable-next-line @typescript-eslint/no-array-constructor
            /*
            const promiseList = Array.from({ length: pdf.numPages }, (v, i) => i + 1).map((pageNumber) => { return pdf.getPage(pageNumber); });
            */
            // eslint-disable-next-line @typescript-eslint/no-array-constructor
            const promiseList = new Array(); //TODO: Figure out the proper way to type this to: new Array<PDFPromise<PDFPageProxy>>();  doesn't seem to want to play nice with Promise.all...
            for (let i = 1; i <= pdf.numPages; i++) {
                promiseList.push(pdf.getPage(i));
            }
            const tempPageDimensions = new Array<PageDimension>();
            Promise.all(promiseList).then((pdfPageProxyList: Array<PDFPageProxy>) => {
                for (let pdfPageProxy of pdfPageProxyList) {
                    tempPageDimensions.push({
                        pageNumber: pdfPageProxy.pageNumber,
                        width: pdfPageProxy.view[2],
                        height: pdfPageProxy.view[3]
                    });
                }
                setPageDimensions(tempPageDimensions);
                variableSizeListRef.current.resetAfterIndex(0);
            });
        }
    }, [pdf]);
    return (
        <Document
            file={url}
            loading={
                <div className="commonProgress">
                    <CircularProgress />
                </div>
            }
            onLoadSuccess={(pdfDocumentProxy) => {
                onLoadSuccess(pdfDocumentProxy);
            }}
        >
            {pdf && (
                <VariableSizeList
                    ref={variableSizeListRef}
                    height={height}
                    width="100%"
                    itemCount={pdf.numPages}
                    overscanCount={2}
                    itemSize={getRowHeight}
                    itemData={{ width: width * 0.9, getRowHeight: getRowHeight }}
                >
                    {PdfPageRenderer}
                </VariableSizeList>
            )}
        </Document>
    );
});

interface PdfPageRendererProps {
    index: number;
    style: React.CSSProperties;
    data: any;
}

const PdfPageRenderer = React.memo<PdfPageRendererProps>(({ index, style, data }) => {
    const width = data.width;
    const getRowHeight = data.getRowHeight;
    const pageNumber = index + 1;
    return (
        <div style={style}>
            <Page scale={1} pageNumber={pageNumber} renderAnnotationLayer={false} width={width} height={getRowHeight(index)} onLoadError={(error) => console.error(error)} />
        </div>
    );
});

export default PdfViewer;
