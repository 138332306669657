import React from 'react';
import { GiphyFetch } from '@giphy/js-fetch-api';
import { Grid } from '@giphy/react-components';
import { getGiphyApiKey } from '../../env';
import { IGif } from '@giphy/js-types';
import { ApiGif } from '../../_api/_ApiModels';
import ObjectID from 'bson-objectid';

interface MessageGiphySelectorProps {
    onGifSelected: Function;
}

const MessageGiphySelector = React.memo<MessageGiphySelectorProps>(({ onGifSelected }) => {
    const giphyFetch = React.useMemo(() => {
        return new GiphyFetch(getGiphyApiKey());
    }, []);
    const onGifClicked = React.useCallback(
        (iGif: IGif) => {
            const apiGif: ApiGif = {
                gifId: ObjectID().toHexString(),
                giphyId: iGif.id.toString(),
                url: iGif.url,
                name: iGif.title,
                imageUrl: iGif.images.fixed_height_still.url,
                videoUrl: iGif.images.original.url,
                height: iGif.images.fixed_height_still.height,
                width: iGif.images.fixed_height_still.width
            };
            onGifSelected(apiGif);
        },
        [onGifSelected]
    );
    const fetchGifs = React.useCallback(
        (offset: number) => {
            return giphyFetch.trending({ offset, limit: 10 });
        },
        [giphyFetch]
    );
    return (
        <Grid
            width={490}
            columns={4}
            fetchGifs={fetchGifs}
            noLink={true}
            onGifClick={(iGif) => {
                onGifClicked(iGif);
            }}
            hideAttribution={true}
        />
    );
});

export default MessageGiphySelector;
