import React from 'react';
import { AxiosResponse } from 'axios';
import { GetMessageThreadResponse, GetPresignedDownloadUrlResponse, useApi } from '../../providers/Api';
import moment from 'moment';
import { useSelector } from '../../_redux/_Store';
import { FileDownloadOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useDrawer } from '../../providers/Drawer';
import { MESSAGE_CALENDAR_FORMATS } from '../../_global/Constants';
import { getCloudfrontUrl } from '../../_global/Helpers';
import { FILE_IMAGE_TYPE } from '../../_global/_Enums';
import { getFileThumb, getFileTypeIcon, isDirectDownloadableFile, isImage, isOffice, isPdf, isVideo } from '../../_global/FileUtils';
import { CircularProgress } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { QUERY_STRING_TYPE } from '../../_global/Route';
import UserName from '../common/UserName';

interface MessageThreadFileListProps {
    messageThreadId: string;
}

interface MessageThreadFile {
    fileId: string;
    userId: string;
    imageSource: string;
    displayName: string;
    contentType: string;
    fileName: string;
    createTime: number;
    key: string;
}

const MessageThreadFileList = React.memo<MessageThreadFileListProps>(({ messageThreadId }) => {
    const api = useApi();
    const drawer = useDrawer();
    const location = useLocation();
    const navigate = useNavigate();
    const cloudfrontUrl = useSelector((state) => state.data.apiOrganization?.cloudfrontUrl);
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const downloadElement = React.useRef() as React.MutableRefObject<HTMLAnchorElement>;
    const [isFileLoading, setIsFileLoading] = React.useState(true);
    const [messageThreadFileList, setMessageThreadFileList] = React.useState<Array<MessageThreadFile>>([]);
    const onFilePressed = React.useCallback(
        (messageThreadFile: MessageThreadFile) => {
            if (isVideo(messageThreadFile.contentType) || isImage(messageThreadFile.contentType) || isPdf(messageThreadFile.contentType) || isOffice(messageThreadFile.contentType)) {
                const queryParams = new URLSearchParams(location.search);
                queryParams.delete(QUERY_STRING_TYPE.DRAWER_TYPE);
                queryParams.delete(QUERY_STRING_TYPE.MESSAGE_THREAD_ID);
                queryParams.set(QUERY_STRING_TYPE.FILE_ID, messageThreadFile.fileId);
                navigate({ search: queryParams.toString() });
            }
        },
        [location.search, navigate]
    );
    const downloadFile = React.useCallback(
        (messageThreadFile: MessageThreadFile) => {
            api.getPresignedDownloadUrl({ fileId: messageThreadFile.fileId }, (axiosResponse: AxiosResponse) => {
                const getPresignedDownloadUrlResponse: GetPresignedDownloadUrlResponse = axiosResponse.data;
                downloadElement.current.href = getPresignedDownloadUrlResponse.url;
                downloadElement.current.download = messageThreadFile.displayName;
                downloadElement.current.click();
            });
        },
        [api]
    );
    React.useEffect(() => {
        if (!cloudfrontUrl) return;
        try {
            setIsFileLoading(true);
            api.getMessageThread({ messageThreadId: messageThreadId }, (axiosResponse: AxiosResponse) => {
                const getMessageThreadResponse: GetMessageThreadResponse = axiosResponse.data;
                const tempMessageThreadFileList = [] as Array<MessageThreadFile>;
                for (let i = 0; i < getMessageThreadResponse.apiMessageThread.apiMessageList.length; i++) {
                    const apiMessage = getMessageThreadResponse.apiMessageThread.apiMessageList[i];
                    if (apiMessage.deleted) continue;
                    const apiUser = apiUserList[apiMessage.createdUserId];
                    if (!apiUser) continue;
                    for (let j = 0; j < apiMessage.apiFileList.length; j++) {
                        const apiFile = apiMessage.apiFileList[j];
                        const apiFileImage = apiFile.imageList.find((obj) => obj.fileImageType === FILE_IMAGE_TYPE.THUMBNAIL);
                        tempMessageThreadFileList.push({
                            fileId: apiFile.fileId,
                            userId: apiFile.userId,
                            imageSource: apiFileImage ? getCloudfrontUrl(cloudfrontUrl, apiFileImage.key) : getFileThumb(apiFile.contentType),
                            createTime: apiMessage.createTime,
                            displayName: apiFile.displayName,
                            contentType: apiFile.contentType,
                            fileName: apiFile.name,
                            key: apiFile.key
                        });
                    }
                }
                setMessageThreadFileList(tempMessageThreadFileList);
                setIsFileLoading(false);
            });
        } catch (e) {
            setIsFileLoading(false);
        }
    }, [api, apiUserList, cloudfrontUrl, messageThreadId]);
    return (
        <div className="messageProfile_wrapper">
            <div className="messageProfile_messageHeader">
                <button
                    onClick={() => {
                        drawer.close();
                    }}
                    className="drawerCloseBtn"
                >
                    <CloseIcon />
                </button>
                <h2>Files &amp; Attachments</h2>
            </div>
            {isFileLoading ? (
                <div className="commonProgress">
                    <CircularProgress />
                </div>
            ) : (
                <div className="messageProfile_body">
                    <div className="filesAndAttachment_mediaList">
                        <a ref={downloadElement} hidden={true} href="https://app.teamcake.com" download="download">
                            download
                        </a>
                        <ul className="commonList">
                            {messageThreadFileList.map((messageThreadFile) => (
                                <li key={messageThreadFile.fileId}>
                                    <div className="filesAndAttachment_fileInfo">
                                        <div
                                            className={isDirectDownloadableFile(messageThreadFile.contentType) ? 'filesAndAttachment_fileType noCursor' : 'filesAndAttachment_fileType'}
                                            onClick={() => onFilePressed(messageThreadFile)}
                                        >
                                            <img src={messageThreadFile.imageSource} alt={messageThreadFile.displayName} />
                                            <img src={getFileTypeIcon(messageThreadFile.contentType)} alt="file-type-icon" className="messageFileSent_overlayIcon" />
                                        </div>
                                        <div className="filesAndAttachment_fileDetails">
                                            <div className="filesAndAttachment_fileName ellip">{messageThreadFile.displayName}</div>
                                            <div className="filesAndAttachment_fileAttachedBy ellip">
                                                Attached By <UserName userId={messageThreadFile.userId} />
                                            </div>
                                            <div className="filesAndAttachment_fileAddTime">{moment.unix(messageThreadFile.createTime).calendar(MESSAGE_CALENDAR_FORMATS)}</div>
                                        </div>
                                    </div>
                                    <button aria-label="Download" type="button" onClick={() => downloadFile(messageThreadFile)}>
                                        <FileDownloadOutlined />
                                    </button>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            )}
        </div>
    );
});

export default MessageThreadFileList;
