import React from 'react';
import { AxiosResponse } from 'axios';
import { GetMessageThreadResponse, useApi } from '../../providers/Api';
import UserAvatar from '../common/UserAvatar';
import { NotificationsActive, PeopleOutline, NotificationsOffOutlined, TagOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import UserName from '../common/UserName';
import { useDrawer } from '../../providers/Drawer';
import Hashtag from '../common/Hashtag';
import { useSelector } from '../../_redux/_Store';
import { sortUserIdList } from '../../_global/Helpers';
import { Tooltip } from '@mui/material';
import { TOOLTIP_TEXTS } from '../../_global/Constants';

interface MessageThreadInfoProps {
    messageThreadId: string;
}

const MessageThreadInfo = React.memo<MessageThreadInfoProps>(({ messageThreadId }) => {
    const api = useApi();
    const drawer = useDrawer();
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const [messageThread, setMessageThread] = React.useState<GetMessageThreadResponse>();
    const [accessUserIdList, setAccessUserIdList] = React.useState<Array<string>>([]);
    React.useEffect(() => {
        if (messageThreadId) {
            api.getMessageThread({ messageThreadId: messageThreadId }, (axiosResponse: AxiosResponse) => {
                const getMessageThreadResponse: GetMessageThreadResponse = axiosResponse.data;
                setAccessUserIdList(sortUserIdList([...getMessageThreadResponse.apiMessageThread.accessUserIdList], apiUserList));
                setMessageThread(getMessageThreadResponse);
            });
        }
    }, [api, apiUserList, messageThreadId]);
    return (
        <>
            {messageThread && messageThreadId ? (
                <div className="messageThreadInfo_wrapper">
                    <div className="messageThreadInfo_messageHeader">
                        <button
                            onClick={() => {
                                drawer.close();
                            }}
                            className="drawerCloseBtn"
                        >
                            <CloseIcon />
                        </button>
                        <h2>Thread Info</h2>
                    </div>
                    <div className="messageThreadInfo_body">
                        {messageThread.apiMessageThread.hashtagIdList.length > 0 && (
                            <div className="messageThreadInfo_hashtags">
                                <div className="messageThreadInfo_listHead">
                                    <TagOutlined />
                                    Hashtags <span className="count">({messageThread.apiMessageThread.hashtagIdList.length})</span>
                                </div>
                                <div className="messageThreadInfo_listSubHead">Hashtags included in this thread</div>
                                <ul className="commonList messageThreadInfo_labelList">
                                    {messageThread.apiMessageThread.hashtagIdList.map((hashtagId) => (
                                        <li key={hashtagId} className="messageThreadInfo_label">
                                            <Hashtag hashtagId={hashtagId} />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        )}
                        <div className="messageThreadInfo_people">
                            <div className="messageThreadInfo_listHead mt24">
                                <PeopleOutline />
                                People <span className="count">({messageThread.apiMessageThread.accessUserIdList.length})</span>
                            </div>
                            <div className="messageThreadInfo_listSubHead">People who can view and comment on this thread.</div>
                            <ul className="commonList">
                                {accessUserIdList.map((userId) => (
                                    <li key={userId}>
                                        <div className="messageThreadInfo_nameAvatar" onClick={() => drawer.openUserProfile(userId)}>
                                            <div>
                                                <UserAvatar userId={userId} />
                                            </div>
                                            <div className="messageThreadInfo_userName">
                                                <UserName userId={userId} />
                                            </div>
                                        </div>
                                        <Tooltip title={messageThread.apiMessageThread.notificationUserIdList.includes(userId) ? TOOLTIP_TEXTS.threadInfoNotificationsOn : TOOLTIP_TEXTS.threadInfoNotificationsOff} placement="bottom-end" arrow>
                                            <div className="messageThreadInfo_userNotifStatus">
                                                {messageThread.apiMessageThread.notificationUserIdList.includes(userId) ? <NotificationsActive className="active" /> : <NotificationsOffOutlined />}
                                            </div>
                                        </Tooltip>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    </div>
                </div>
            ) : (
                <></>
            )}
        </>
    );
});

export default MessageThreadInfo;
