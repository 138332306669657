import React from "react";
import { CircularProgressbar } from "react-circular-progressbar";
import { FileInTransit, FILE_STATE_TYPE, useFileUpload } from "../../providers/FileUpload";

interface MessageFileProps {
    fileId: string;
}

const MessageFile = React.memo<MessageFileProps>(({ fileId }) => {
    const fileInTransitList = useFileUpload().fileInTransitList;
    const [fileInTransit, setFileInTransit] = React.useState<FileInTransit>();
    React.useEffect(() => {
        setFileInTransit(fileInTransitList.find(obj => obj.fileId === fileId));
    }, [fileId, fileInTransitList]);
    return (
        <>
            {fileInTransit &&
                <>
                    <img src={fileInTransit.thumbUrl} loading="lazy" alt="thumbnail" />
                    <div className="MessageFile_progress">
                        {fileInTransit.stateType === FILE_STATE_TYPE.UPLOADING &&
                            <CircularProgressbar minValue={0} maxValue={fileInTransit.byteCount} value={fileInTransit.uploadedByteCount} />
                        }
                    </div>
                </>
            }
        </>
    );
});

export default MessageFile;