import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { useDrawer } from '../../providers/Drawer';
import supportPlaceholder from '../../assets/images/supportPlaceholder.svg';

const Support = React.memo(() => {
    const drawer = useDrawer();
    return (
        <div className="messageThreadFilter Support-wrapper">
            <div className="messageThreadFilter_header">
                <button
                    onClick={() => {
                        drawer.close();
                    }}
                    className="drawerCloseBtn"
                >
                    <CloseIcon />
                </button>
                <h2>Support</h2>
            </div>
            <div className="support-overflow-wrapper">
                <div className="support-bgm-wrapper">
                    <img src={supportPlaceholder} alt="supportPlaceholder" />
                </div>
                <div className="support-heading need-help">
                    <div className="need-help-head">Need Help?</div>
                    <div className="call-us-number">
                        Call us at <span>866-921-4517</span> or submit a support ticket.
                    </div>
                    <div className="submit-ticket-btn">
                        <a href="https://support.teamcake.com/kb-tickets/new" target="_blank" rel="noreferrer">
                            Submit Ticket
                        </a>
                    </div>
                </div>
                <div className="support-heading">
                    <div className="need-help-head">Cake Help Center</div>
                    <div className="support-heading-browse-faq">Browse FAQs and learn how to use Cake.</div>
                    <div className="submit-ticket-btn">
                        <a href="https://support.teamcake.com/" target="_blank" rel="noreferrer">
                            View Articles
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default Support;
