import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { SCREEN_TYPE, LAYOUT_TYPE } from '../../_global/Route';
import { useSelector } from '../../_redux/_Store';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import { Chip } from '@mui/material';
import { SellOutlined, StarOutline } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { UserHashtagFilter, USER_HASHTAG_FILTER_TYPE } from '../../providers/UserHashtagFilter';
import { USER_HASHTAG_STATE_FILTER_TYPE } from '../../_global/_Enums';
import { capitalizeFirstLetter } from '../../_global/Helpers';

interface UserHashtagFilterPillListProps {
    userHashtagFilterList: Array<UserHashtagFilter>;
    onDelete?: Function;
    displayClearAll?: boolean;
    displaySearchText?: boolean;
}

interface FilterPill {
    icon?: React.ReactElement;
    label: ReactNode;
    onDelete?: Function;
}

const UserHashtagFilterPillList = React.memo<UserHashtagFilterPillListProps>(({ userHashtagFilterList, onDelete, displayClearAll = false, displaySearchText = false }) => {
    const navigate = useNavigate();
    const messageThreadFilter = useMessageThreadFilter();
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const apiHashtagList = useSelector((state) => state.data.apiHashtagList);
    const apiTagList = useSelector((state) => state.data.apiTagList);
    const [filterPillList, setFilterPillList] = React.useState<Array<FilterPill>>([]);
    const [searchText, setSearchText] = React.useState<string>();
    React.useEffect(() => {
        if (!userHashtagFilterList) return;
        const tempFilterPillList = [] as Array<FilterPill>;
        for (var i = 0; i < userHashtagFilterList.length; i++) {
            const filter = userHashtagFilterList[i];
            switch (filter.type) {
                case USER_HASHTAG_FILTER_TYPE.TAG_ID_LIST: {
                    const tagIdList = filter.value as Array<string>;
                    for (let i = 0; i < tagIdList.length; i++) {
                        const tagId = tagIdList[i];
                        tempFilterPillList.push({
                            icon: <SellOutlined className="rotateIcon" />,
                            label: apiTagList[tagId].name,
                            onDelete: () => {
                                if (onDelete) onDelete(filter.type, tagId);
                            }
                        });
                    }
                    break;
                }
                case USER_HASHTAG_FILTER_TYPE.STATE_FILTER_TYPE: {
                    let icon: React.ReactElement;
                    switch (filter.value as USER_HASHTAG_STATE_FILTER_TYPE) {
                        case USER_HASHTAG_STATE_FILTER_TYPE.INVITED: {
                            icon = <StarOutline />;
                            break;
                        }
                        case USER_HASHTAG_STATE_FILTER_TYPE.REQUESTED: {
                            icon = <StarOutline />;
                            break;
                        }
                        case USER_HASHTAG_STATE_FILTER_TYPE.SUBSCRIBED: {
                            icon = <StarOutline />;
                            break;
                        }
                        case USER_HASHTAG_STATE_FILTER_TYPE.NOT_SUBSCRIBED: {
                            icon = <StarOutline />;
                            break;
                        }
                        case USER_HASHTAG_STATE_FILTER_TYPE.DECLINED: {
                            icon = <StarOutline />;
                            break;
                        }
                        case USER_HASHTAG_STATE_FILTER_TYPE.DEACTIVATED: {
                            icon = <StarOutline />;
                            break;
                        }
                    }
                    tempFilterPillList.push({
                        icon: icon,
                        label: capitalizeFirstLetter(filter.value as string),
                        onDelete: () => {
                            if (onDelete) onDelete(filter.type);
                        }
                    });
                    break;
                }
                case USER_HASHTAG_FILTER_TYPE.TEXT: {
                    setSearchText(filter.value as string);
                    break;
                }
            }
        }
        setFilterPillList(tempFilterPillList);
    }, [apiHashtagList, apiTagList, apiUserList, onDelete, userHashtagFilterList]);
    const onClearAllClicked = React.useCallback(() => {
        messageThreadFilter.removeAllFilters();
        navigate({ pathname: `/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SUBSCRIPTIONS}` });
    }, [messageThreadFilter, navigate]);
    return (
        <>
            {filterPillList.map((filterPill, index) => (
                <React.Fragment key={index}>
                    {onDelete ? (
                        <Chip
                            icon={filterPill.icon}
                            label={filterPill.label}
                            onDelete={() => {
                                if (filterPill.onDelete) {
                                    filterPill.onDelete();
                                }
                            }}
                            deleteIcon={<CloseIcon />}
                        />
                    ) : (
                        <Chip key={index} icon={filterPill.icon} label={filterPill.label} />
                    )}
                </React.Fragment>
            ))}
            {filterPillList.length > 0 && displayClearAll && <Chip key="clear_all" label="Clear All" onClick={onClearAllClicked} variant="outlined" className="filterPillList_clearAll" />}
            {displaySearchText && searchText && <>{`"${searchText}"`}</>}
        </>
    );
});

export default UserHashtagFilterPillList;
