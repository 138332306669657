import React from 'react';
import { useSelector } from '../../_redux/_Store';
import { ApiUser } from '../../_api/_ApiModels';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import PathNavLink from './PathNavLink';
import { QUERY_STRING_TYPE } from '../../_global/Route';

const SidebarMessageThreadSearchList = React.memo(() => {
    const loggedInUserId = useSelector((state) => state.data.userId);
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const messageThreadFilter = useMessageThreadFilter();
    const [loggedInApiUser, setLoggedInApiUser] = React.useState<ApiUser>();
    React.useEffect(() => {
        if (!loggedInUserId) {
            return;
        }
        setLoggedInApiUser(apiUserList[loggedInUserId]);
    }, [apiUserList, loggedInUserId]);
    return (
        <React.Fragment>
            <ul className="subscription_labelList">
                {loggedInApiUser?.apiMessageThreadSearchList.map((apiMessageThreadSearch) => (
                    <React.Fragment key={apiMessageThreadSearch.messageThreadSearchId}>
                        <PathNavLink
                            to={`${messageThreadFilter.getUrl(apiMessageThreadSearch.apiMessageThreadSearchFilterList)}&${QUERY_STRING_TYPE.MESSAGE_THREAD_SEARCH_ID}=${
                                apiMessageThreadSearch.messageThreadSearchId
                            }`}
                        >
                            <li>
                                {/* <SellOutlined className="subscription_tagIcon" /> */}
                                <span className="subscription_label">{apiMessageThreadSearch.name}</span>
                            </li>
                        </PathNavLink>
                    </React.Fragment>
                ))}
            </ul>
        </React.Fragment>
    );
});

export default SidebarMessageThreadSearchList;
