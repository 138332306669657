import React from 'react';
import { useSelector } from '../../_redux/_Store';
import Highlighter from 'react-highlight-words';
import UserAvatar from './UserAvatar';
import { DraftHandleValue, EditorState } from 'draft-js';
import Editor, { EditorRef } from '@draft-js-plugins/editor';
import createMentionPlugin, { MentionData, MentionPluginTheme } from '@draft-js-plugins/mention';
import '@draft-js-plugins/mention/lib/plugin.css';
import { CALLOUT_KEY, HASHTAG_KEY } from '../../_global/Constants';

export enum DraftDefaultValues {
    HANDLED = 'handled',
    NOT_HANDLED = 'not-handled',
    REMOVE_RANDE = 'remove-range',
    INSERT_FRAGMENT = 'insert-fragment'
}

interface DraftHashtagCalloutInputProps {
    onChange?: (editorState: EditorState) => void;
    editorState: EditorState;
    style?: React.CSSProperties;
    className?: string;
    placeholder?: string;
    autoFocus?: boolean;
    onKeyPress?: ((e: KeyboardEvent) => DraftHandleValue) | undefined;
    onPaste?: (event: React.ClipboardEvent<HTMLDivElement>) => void;
    editorRef: React.MutableRefObject<EditorRef>;
}

const DraftHashtagCalloutInput = React.memo<DraftHashtagCalloutInputProps>(({ onChange, onKeyPress, onPaste, editorState, editorRef, ...props }) => {
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const apiHashtagList = useSelector((state) => state.data.apiHashtagList);
    const [hashtagDataItemList, setHashtagDataItemList] = React.useState<Array<MentionData>>([]);
    const [tempHashtagList, setTempHashtagList] = React.useState<Array<MentionData>>([]);
    const [userDataItemList, setUserDataItemList] = React.useState<Array<MentionData>>([]);
    const [tempCalloutList, setTempCalloutList] = React.useState<Array<MentionData>>([]);
    const [isHashtagOpen, setIsHashTagOpen] = React.useState(false);
    const [isCalloutOpen, setIsCalloutOpen] = React.useState(false);
    React.useEffect(() => {
        const list: Array<MentionData> = [];
        Object.values(apiHashtagList).forEach((apiHashtag) => {
            list.push({
                id: apiHashtag.hashtagId,
                name: `${apiHashtag.name}`,
                type: HASHTAG_KEY
            });
        });
        setHashtagDataItemList(list);
        setTempHashtagList(list);
    }, [apiHashtagList]);
    React.useEffect(() => {
        const list: Array<MentionData> = [];
        Object.values(apiUserList).forEach((apiUser) => {
            list.push({
                id: apiUser.userId,
                name: `${apiUser.callout}`,
                type: CALLOUT_KEY
            });
        });
        setUserDataItemList(list);
        setTempCalloutList(list);
    }, [apiUserList]);
    const onSearchCalloutChange = React.useCallback(
        ({ trigger, value }: { trigger: string; value: string }) => {
            setUserDataItemList(
                tempCalloutList.filter((callout) => {
                    return callout.name.toLowerCase().includes(value.toLowerCase());
                })
            );
        },
        [tempCalloutList]
    );
    const onSearchHashtagChange = React.useCallback(
        ({ trigger, value }: { trigger: string; value: string }) => {
            setHashtagDataItemList(
                tempHashtagList.filter((hashtag) => {
                    return hashtag.name.toLowerCase().includes(value.toLowerCase());
                })
            );
        },
        [tempHashtagList]
    );
    const { HashtagSuggestion, hashtagPlugins } = React.useMemo(() => {
        const plugin = createMentionPlugin({
            mentionTrigger: HASHTAG_KEY,
            mentionPrefix: HASHTAG_KEY,
            supportWhitespace: true
        });
        return { hashtagPlugins: plugin, HashtagSuggestion: plugin.MentionSuggestions };
    }, []);
    const { CalloutSuggestion, calloutPlugins } = React.useMemo(() => {
        const plugin = createMentionPlugin({
            mentionTrigger: CALLOUT_KEY,
            mentionPrefix: CALLOUT_KEY,
            supportWhitespace: true,
            mentionComponent: (props) => (
                <span data-callout-key={`{{cid=${props?.mention?.id}}}`} className="draftHashtagCalloutInput_highlightPill">
                    @{props?.mention?.name}
                </span>
            )
        });
        return { calloutPlugins: plugin, CalloutSuggestion: plugin.MentionSuggestions };
    }, []);
    const onChangeHandler = React.useCallback(
        (editorState: EditorState) => {
            if (onChange) {
                onChange(editorState);
            }
        },
        [onChange]
    );
    const onPasteClipBoardHandler = React.useCallback(
        (e: React.ClipboardEvent<HTMLDivElement>) => {
            if (onPaste) {
                onPaste(e);
            }
        },
        [onPaste]
    );
    return (
        <>
            <div onClick={() => editorRef.current.editor.focus()} onPaste={onPasteClipBoardHandler}>
                <Editor
                    editorState={editorState}
                    onChange={onChangeHandler}
                    plugins={[calloutPlugins, hashtagPlugins]}
                    ref={editorRef as any}
                    {...props}
                    handleReturn={(e) => {
                        if (onKeyPress && !isCalloutOpen && !isHashtagOpen) {
                            return onKeyPress(e as any);
                        }
                        return DraftDefaultValues.NOT_HANDLED;
                    }}
                />
                <CalloutSuggestion
                    open={isCalloutOpen}
                    onOpenChange={setIsCalloutOpen}
                    suggestions={userDataItemList}
                    onSearchChange={onSearchCalloutChange}
                    entryComponent={(props) => <CalloutMentionList {...props} />}
                />
                <HashtagSuggestion
                    open={isHashtagOpen}
                    onOpenChange={setIsHashTagOpen}
                    suggestions={hashtagDataItemList}
                    onSearchChange={onSearchHashtagChange}
                    entryComponent={(props) => <HashtagMentionList {...props} />}
                />
            </div>
        </>
    );
});

interface SuggestionProps {
    className?: string;
    onMouseDown: React.MouseEventHandler<HTMLDivElement>;
    onMouseUp: React.MouseEventHandler<HTMLDivElement>;
    onMouseEnter: React.MouseEventHandler<HTMLDivElement>;
    role: string;
    id: string;
    'aria-selected'?: boolean | 'false' | 'true';
    theme?: MentionPluginTheme;
    mention: MentionData;
    isFocused: boolean;
    searchValue?: string;
    selectMention?: Function;
}

const CalloutMentionList = React.memo<SuggestionProps>((props) => {
    const { mention, searchValue, isFocused, selectMention, className, onMouseEnter, ...parentProps } = props;
    return (
        <div className={`${className} draftHashtagCalloutInput_List`} {...parentProps}>
            <UserAvatar userId={mention?.id as string} size={12} displayOnlineIndicator={false} />
            {CALLOUT_KEY}
            {mention.name && (
                <Highlighter searchWords={[searchValue ? searchValue : '']} autoEscape={true} textToHighlight={mention.name} className="hashtagCalloutInput_name">
                    {mention?.name}
                </Highlighter>
            )}
        </div>
    );
});

const HashtagMentionList = React.memo<SuggestionProps>((props) => {
    const { mention, searchValue, isFocused, selectMention, className, onMouseEnter, ...parentProps } = props;
    return (
        <div className={`${className} draftHashtagCalloutInput_List`} {...parentProps}>
            {HASHTAG_KEY}
            {mention.name && (
                <>
                    <Highlighter searchWords={[searchValue ? searchValue : '']} autoEscape={true} textToHighlight={mention.name} className="hashtagCalloutInput_name">
                        {mention.name}
                    </Highlighter>
                </>
            )}
        </div>
    );
});

export default DraftHashtagCalloutInput;
