import React from 'react';
import { Button, TableContainer, Table, Paper, TableRow, TableCell, TableBody } from '@mui/material';
import { Tag as TagIcon, Search as SearchIcon, Cancel as CancelIcon, Person, PersonOutlineOutlined } from '@mui/icons-material';
import { useSelector } from '../../_redux/_Store';
import { useDrawer } from '../../providers/Drawer';
import { USER_HASHTAG_STATE_TYPE } from '../../_global/_Enums';
import TableHeader, { CellProps, OrderCellProps, sortString, sortNumber } from '../common/TableHeader';
import { NavLink } from 'react-router-dom';
import { LAYOUT_TYPE, SCREEN_TYPE } from '../../_global/Route';
import { ApiHashtag } from '../../_api/_ApiModels';

export interface HashtagTableData {
    hashtagId: string;
    hashtagName: string;
    subscribedCount: number;
    pendingCount: number;
}

const cellArray: Array<CellProps<HashtagTableData>> = [
    {
        cellId: 'hashtagName',
        displayName: 'Title',
        sortFn: sortString
    },
    {
        cellId: 'subscribedCount',
        displayName: 'Subscribed',
        sortFn: sortNumber,
        sx: { width: '100px', textAlign: 'center' }
    },
    {
        cellId: 'pendingCount',
        displayName: 'Pending',
        sortFn: sortNumber,
        sx: { width: '100px', textAlign: 'center' }
    }
];

const HashtagList = React.memo(() => {
    const drawer = useDrawer();
    const { apiHashtagList, apiUserHashtagList, apiUserList, userId: loggedInUserId, isAdmin } = useSelector((state) => state.data);
    const [adminApiHashtagList, setAdminApiHashtagList] = React.useState<Array<ApiHashtag>>([]);
    const [hashtagTableDataList, setHashtagTableDataList] = React.useState<Array<HashtagTableData>>([]);
    const [search, setSearch] = React.useState('');
    const [orderCell, setOrderCell] = React.useState<OrderCellProps<HashtagTableData>>({ orderBy: 'hashtagName', isAsc: true });
    const onClearSearch = React.useCallback(() => {
        if (search) {
            setSearch('');
        }
    }, [search]);
    const onSort = React.useCallback(
        (cellId: keyof HashtagTableData) => {
            if (cellId === orderCell.orderBy) {
                setOrderCell((oldState) => {
                    return { ...oldState, isAsc: !oldState.isAsc };
                });
            } else {
                setOrderCell({ orderBy: cellId, isAsc: true });
            }
        },
        [orderCell]
    );
    React.useEffect(() => {
        if (!loggedInUserId) return;
        const loggedInApiUser = apiUserList[loggedInUserId];
        if (!loggedInApiUser) return;
        setAdminApiHashtagList(Object.values(apiHashtagList).filter((obj) => obj.adminUserIdList.includes(loggedInUserId) || isAdmin));
    }, [apiHashtagList, apiUserList, isAdmin, loggedInUserId]);
    React.useEffect(() => {
        const hashtagTableDataList: Array<HashtagTableData> = [];
        adminApiHashtagList.forEach((apiHashtag) => {
            if (!search.length || apiHashtag.name.match(search)) {
                hashtagTableDataList.push({
                    hashtagId: apiHashtag.hashtagId,
                    hashtagName: apiHashtag.name,
                    subscribedCount: apiHashtag.subscriptionUserIdList.length,
                    pendingCount: Object.values(apiUserHashtagList).filter(
                        (apiUserHashtag) => apiUserHashtag.hashtagId === apiHashtag.hashtagId && apiUserHashtag.stateType === USER_HASHTAG_STATE_TYPE.REQUESTED
                    ).length
                });
            }
        });
        const orderByCell = cellArray.find((obj) => obj.cellId === orderCell.orderBy);
        const orderByFn = orderByCell ? orderByCell.sortFn : undefined;
        if (orderByCell && orderByFn) {
            hashtagTableDataList.sort((a, b) => {
                return orderCell.isAsc ? orderByFn(a[orderCell.orderBy], b[orderCell.orderBy]) : orderByFn(b[orderCell.orderBy], a[orderCell.orderBy]);
            });
        }
        setHashtagTableDataList(hashtagTableDataList);
    }, [adminApiHashtagList, apiUserHashtagList, orderCell.isAsc, orderCell.orderBy, search]);
    React.useEffect(() => {
        const hashtagTableDataList: Array<HashtagTableData> = [];
        Object.values(apiHashtagList).forEach((apiHashtag) => {
            if (!search.length || apiHashtag.name.match(search)) {
                hashtagTableDataList.push({
                    hashtagId: apiHashtag.hashtagId,
                    hashtagName: apiHashtag.name,
                    subscribedCount: apiHashtag.subscriptionUserIdList.length,
                    pendingCount: Object.values(apiUserHashtagList).filter(
                        (apiUserHashtag) => apiUserHashtag.hashtagId === apiHashtag.hashtagId && apiUserHashtag.stateType === USER_HASHTAG_STATE_TYPE.REQUESTED
                    ).length
                });
            }
        });
        const orderByCell = cellArray.find((obj) => obj.cellId === orderCell.orderBy);
        const orderByFn = orderByCell ? orderByCell.sortFn : undefined;
        if (orderByCell && orderByFn) {
            hashtagTableDataList.sort((a, b) => {
                return orderCell.isAsc ? orderByFn(a[orderCell.orderBy], b[orderCell.orderBy]) : orderByFn(b[orderCell.orderBy], a[orderCell.orderBy]);
            });
        }
        setHashtagTableDataList(hashtagTableDataList);
    }, [apiHashtagList, apiUserHashtagList, search, orderCell]);
    return (
        <div className="hashtagsTableDetails_wrapper">
            <div className="hashtags_searchBar">
                <SearchIcon className="hashtags_searchIcon" />
                <input
                    className="hashtags_Search_input"
                    placeholder="Search Hashtag"
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                    }}
                />
                {search?.length > 0 && (
                    <button type="button" onClick={onClearSearch} className="drawerCloseBtn hashtags_closeIcon">
                        <CancelIcon />
                    </button>
                )}
            </div>
            <div className="hashtagsTableDetails_heading">
                <h3>
                    <TagIcon /> Hashtags
                </h3>
                {isAdmin && (
                    <Button
                        variant="outlined"
                        className="commonTop_filterBtn"
                        size="small"
                        onClick={() => {
                            drawer.openCreateHashtag();
                        }}
                    >
                        Create Hashtag
                    </Button>
                )}
            </div>
            <TableContainer component={Paper} className="hashtagsTableDetails_table">
                <Table stickyHeader>
                    <TableHeader cellArray={cellArray} orderCell={orderCell} onSort={onSort} />
                    <TableBody className="hashtagsTableDetails_body">
                        {hashtagTableDataList.map((hashtagTableData: HashtagTableData) => {
                            return (
                                <TableRow key={hashtagTableData.hashtagId}>
                                    <TableCell scope="row" component="td" className="hashtagsTableDetails_name">
                                        <NavLink to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.HASHTAGS}/${hashtagTableData.hashtagId}`}>{`#${hashtagTableData.hashtagName}`}</NavLink>
                                    </TableCell>
                                    <TableCell sx={{ width: '100px' }} className="hashtagsTableDetails_subscribed">
                                        <Person /> {hashtagTableData.subscribedCount}
                                    </TableCell>
                                    <TableCell sx={{ width: '100px' }} className="hashtagsTableDetails_pending">
                                        <PersonOutlineOutlined /> {hashtagTableData.pendingCount}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
});

export default HashtagList;
