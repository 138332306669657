import React from "react";
import ReactPlayer from "react-player";

interface VideoViewerProps {
    url: string;
}

const VideoViewer = React.memo<VideoViewerProps>(({ url }) => {
    return (
        <ReactPlayer playing={true} controls={true} url={url} width={"100%"} height={"100%"} style={{ margin: "0 auto" }} />
    );
});

export default VideoViewer;