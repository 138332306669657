import React from 'react';
import { USER_HASHTAG_STATE_TYPE } from '../../_global/_Enums';
import { useSelector } from '../../_redux/_Store';
import UserHashtagControlInvited from './userHashtagStates/UserHashtagControlInvited';
import UserHashtagControlRequest from './userHashtagStates/UserHashtagControlRequest';
import UserHashtagControlRequested from './userHashtagStates/UserHashtagControlRequested';
import UserHashtagControlSubscribed from './userHashtagStates/UserHashtagControlSubscribed';

export interface UserHashtagControlProps {
    hashtagId: string;
    userHashtagId?: string;
}

export enum USER_HASHTAG_CONTROL_TYPE {
    REQUEST,
    INVITED,
    REQUESTED,
    SUBSCRIBED
}

const UserHashtagControl = React.memo<UserHashtagControlProps>(({ hashtagId, userHashtagId }) => {
    const loggedInUserId = useSelector((state) => state.data.userId);
    const apiHashtagList = useSelector((state) => state.data.apiHashtagList);
    const apiUserHashtagList = useSelector((state) => state.data.apiUserHashtagList);
    const [stateType, setStateType] = React.useState<USER_HASHTAG_STATE_TYPE>();
    const [updateTime, setUpdateTime] = React.useState<number>();
    const [controlType, setControlType] = React.useState<USER_HASHTAG_CONTROL_TYPE>();
    React.useEffect(() => {
        if (!loggedInUserId) return;
        if (!userHashtagId) {
            const apiHashtag = apiHashtagList[hashtagId];
            setControlType(apiHashtag.subscriptionUserIdList.includes(loggedInUserId) ? USER_HASHTAG_CONTROL_TYPE.SUBSCRIBED : USER_HASHTAG_CONTROL_TYPE.REQUEST);
            setStateType(undefined);
            setUpdateTime(undefined);
            return;
        }
        const apiUserHashtag = apiUserHashtagList[userHashtagId];
        if (!apiUserHashtag) return;
        setStateType(apiUserHashtag.stateType);
        setUpdateTime(apiUserHashtag.updateTime);
        switch (apiUserHashtag.stateType) {
            case USER_HASHTAG_STATE_TYPE.DEACTIVATED:
            case USER_HASHTAG_STATE_TYPE.DECLINED:
            case USER_HASHTAG_STATE_TYPE.DENIED: {
                setControlType(USER_HASHTAG_CONTROL_TYPE.REQUEST);
                break;
            }
            case USER_HASHTAG_STATE_TYPE.INVITED: {
                setControlType(USER_HASHTAG_CONTROL_TYPE.INVITED);
                break;
            }
            case USER_HASHTAG_STATE_TYPE.REQUESTED: {
                setControlType(USER_HASHTAG_CONTROL_TYPE.REQUESTED);
                break;
            }
        }
    }, [apiHashtagList, apiUserHashtagList, hashtagId, loggedInUserId, userHashtagId]);
    return (
        <>
            {loggedInUserId && hashtagId && (
                <>
                    {controlType === USER_HASHTAG_CONTROL_TYPE.SUBSCRIBED ? (
                        <UserHashtagControlSubscribed userId={loggedInUserId} hashtagId={hashtagId} />
                    ) : controlType === USER_HASHTAG_CONTROL_TYPE.REQUESTED && userHashtagId ? (
                        <UserHashtagControlRequested hashtagId={hashtagId} userId={loggedInUserId} userHashtagId={userHashtagId} />
                    ) : controlType === USER_HASHTAG_CONTROL_TYPE.INVITED && userHashtagId ? (
                        <UserHashtagControlInvited hashtagId={hashtagId} userId={loggedInUserId} userHashtagId={userHashtagId} />
                    ) : controlType === USER_HASHTAG_CONTROL_TYPE.REQUEST ? (
                        <UserHashtagControlRequest hashtagId={hashtagId} userId={loggedInUserId} stateType={stateType} updateTime={updateTime} />
                    ) : (
                        <>INVALID</>
                    )}
                </>
            )}
        </>
    );
});

export default UserHashtagControl;
