import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import CallIcon from '@mui/icons-material/Call';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import CircleIcon from '@mui/icons-material/Circle';
import TagIcon from '@mui/icons-material/Tag';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import MessageIcon from '@mui/icons-material/Message';
import { Divider, IconButton, Tooltip } from '@mui/material';
import moment from 'moment';
import { useSelector } from '../../_redux/_Store';
import { ApiHashtag, MESSAGE_THREAD_SEARCH_FILTER_TYPE } from '../../_api/_ApiModels';
import UserAvatar from '../common/UserAvatar';
import { useDrawer } from '../../providers/Drawer';
import { NavLink } from 'react-router-dom';
import CropProfile from '../common/CropProfile';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import { LAYOUT_TYPE, SCREEN_TYPE } from '../../_global/Route';
import UserName from '../common/UserName';
import SettingIcon from '../../assets/images/icons/setting.svg';
import { TOOLTIP_TEXTS } from '../../_global/Constants';
import { Star, StarOutline } from '@mui/icons-material';
import { useApi } from '../../providers';

interface UserProfileProps {
    userId: string;
}

const UserProfile = React.memo<UserProfileProps>(({ userId }) => {
    const drawer = useDrawer();
    const api = useApi();
    const messageThreadFilter = useMessageThreadFilter();
    const { apiUserList, userId: loggedInUserId, apiHashtagList } = useSelector((state) => state.data);
    const [avatarFile, setAvatarFile] = React.useState<File | null>(null);
    const [base64, setBase64] = React.useState<string>('');
    const [isOpenEditProfileModal, setIsOpenEditProfileModal] = React.useState<boolean>(false);
    const [isLoggedInUser, setIsLoggedInUser] = React.useState<boolean>(false);
    const [isStarred, setIsStarred] = React.useState<boolean>(false);
    const [isOnline, setIsOnline] = React.useState<boolean>(false);
    const [callout, setCallout] = React.useState<string>('');
    const [title, setTitle] = React.useState<string>('');
    const [email, setEmail] = React.useState<string>('');
    const [phone, setPhone] = React.useState<string>('');
    const [location, setLocation] = React.useState<string>('');
    const [lastOnlineTime, setLastOnlineTime] = React.useState<number>(0);
    const [subscriptionApiHashtagList, setSubscriptionApiHashtagList] = React.useState<Array<ApiHashtag>>([]);
    const onSelectImage = React.useCallback((changeEvent: React.ChangeEvent<HTMLInputElement>) => {
        if (changeEvent.target.files != null && changeEvent.target.files.length === 1) {
            const file = changeEvent.target.files[0];
            setAvatarFile(file);
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = (e) => {
                if (e.target?.result) {
                    const base64String: string = e.target.result as string;
                    setBase64(base64String);
                }
            };
            changeEvent.target.value = '';
            setIsOpenEditProfileModal(true);
        }
    }, []);

    const onStarClicked = React.useCallback(
        (event) => {
            event.preventDefault();
            setIsStarred((isStarred) => !isStarred);
            api.toggleStarredUser({
                userId: userId,
                starred: !isStarred
            });
        },
        [api, isStarred, userId]
    );
    React.useEffect(() => {
        if (!loggedInUserId) return;
        const apiUser = apiUserList[userId];
        console.log('apiUser', apiUser);
        if (!apiUser) return;
        setIsLoggedInUser(loggedInUserId === userId);
        setIsStarred(apiUser.isStarred);
        setCallout(apiUser.callout);
        setTitle(apiUser.title);
        setEmail(apiUser.email);
        setPhone(apiUser.phone);
        setLocation(apiUser.location);
        setIsOnline(apiUser.online);
        setLastOnlineTime(apiUser.lastOnlineTime);
        setSubscriptionApiHashtagList(Object.values(apiHashtagList).filter((obj) => obj.subscriptionUserIdList.includes(userId)));
    }, [apiHashtagList, apiUserList, loggedInUserId, userId]);
    return (
        <div className="Profile-wrapper">
            <div className="Profile-heading-wrp">
                <div onClick={() => drawer.close()}>
                    <CloseIcon />
                </div>
                <h2>{isLoggedInUser ? 'My' : 'User'} Profile</h2>
            </div>
            <Divider />
            <CropProfile isOpen={isOpenEditProfileModal} userProfileImgSrc={avatarFile} base64={base64} onCloseCallback={() => setIsOpenEditProfileModal((state) => !state)} />
            <div className="Profile-detail-wrapper">
                <div className="Profile-detail-left-pannel">
                    <div className="Profile-img-wrp">
                        <UserAvatar userId={userId} size={120} displayOnlineIndicator={false} />
                        {isLoggedInUser && (
                            <label className="edit-icon-btn">
                                <EditIcon />
                                <input
                                    type="file"
                                    accept="image/x-png,image/gif,image/jpeg"
                                    style={{ display: 'none' }}
                                    onChange={(e) => {
                                        onSelectImage(e);
                                    }}
                                />
                            </label>
                        )}
                    </div>
                    {!isLoggedInUser && (
                        <div className="Profile-navigate-icon-wrp">
                            <NavLink
                                className="profile-thread-icon"
                                to={messageThreadFilter.getUrl([
                                    {
                                        type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.USER_ID_LIST,
                                        value: [userId]
                                    }
                                ])}
                            >
                                <MessageIcon />
                            </NavLink>

                            <div className="profile-thread-icon">
                                <EditIcon />
                            </div>
                        </div>
                    )}
                </div>
                <div className="Profile-detail-right-pannel">
                    {console.log('isStarred', isStarred)}
                    <h2 className="username">
                        <UserName userId={userId} />

                        <Tooltip title={isStarred ? TOOLTIP_TEXTS.removeStar : TOOLTIP_TEXTS.addStar} placement="bottom-start" arrow>
                            <IconButton onClick={onStarClicked}>{isStarred ? <Star className="starred" style={{ color: '#FF9500' }} /> : <StarOutline />}</IconButton>
                        </Tooltip>
                    </h2>
                    <h3 className="callout">@{callout}</h3>
                    <h3 className="title">{title}</h3>
                    <h3 className="number-mail-location-grp">
                        <CallIcon /> {phone}
                    </h3>
                    <h3 className="number-mail-location-grp">
                        <MailOutlineIcon /> {email}
                    </h3>
                    <h3 className="number-mail-location-grp">
                        <LocationOnIcon /> {location}
                    </h3>

                    <div className="last-online-info">
                        <CircleIcon style={isOnline ? { color: '#40BF62' } : { color: '#D6D6D6' }} />
                        {isOnline ? 'Online Now' : `Last Online ${moment.unix(lastOnlineTime).format('MMM DD YYYY')}`}
                    </div>
                </div>
            </div>
            <div className="Profile-subscription-heading">
                <h4>
                    <TagIcon /> Subscriptions ({subscriptionApiHashtagList.length})
                </h4>
                {isLoggedInUser && (
                    <NavLink className={'nav-to-manage-subscription'} to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SUBSCRIPTIONS}`}>
                        <img src={SettingIcon} alt="sorticon" />
                        <h5>Manage Subscriptions</h5>
                    </NavLink>
                )}
            </div>
            <div className="Profile-subscription-overflow-wrapper">
                {subscriptionApiHashtagList.map((apiHashtag: ApiHashtag) => {
                    return (
                        <div className="Profile-subscription-list-wrp">
                            {loggedInUserId && apiHashtag.subscriptionUserIdList.includes(loggedInUserId) ? (
                                <>
                                    {loggedInUserId && apiHashtag.subscriptionUserIdList.includes(loggedInUserId) ? (
                                        <NavLink
                                            className={'hastag-name'}
                                            style={{ color: '#007AFF' }}
                                            to={messageThreadFilter.getUrl([
                                                {
                                                    type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.HASHTAG_ID_LIST,
                                                    value: [apiHashtag.hashtagId]
                                                }
                                            ])}
                                        >
                                            <div>{`#${apiHashtag.name}`}</div>
                                        </NavLink>
                                    ) : (
                                        <div className={'hastag-name'} style={{ color: '#5B5B5B' }}>{`#${apiHashtag.name}`}</div>
                                    )}

                                    <Tooltip title={'View Hashtag Details'} placement="bottom" arrow>
                                        <NavLink to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SUBSCRIPTIONS}/${apiHashtag.hashtagId}`}>
                                            <div>{loggedInUserId && !apiHashtag.subscriptionUserIdList.includes(loggedInUserId) && <InfoOutlinedIcon />}</div>
                                        </NavLink>
                                    </Tooltip>
                                </>
                            ) : (
                                <>
                                    <div>{`#${apiHashtag.name}`}</div>
                                </>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
});

export default UserProfile;
