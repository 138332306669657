import React from 'react';
import { Typography } from '@mui/material';
import ObjectID from 'bson-objectid';
import { useMessage } from '../../providers/Message';
import { useFileUpload } from '../../providers/FileUpload';
import { useDropzone } from 'react-dropzone';
import { ApiGif } from '../../_api/_ApiModels';
import MessageReply from '../inbox/MessageReply';
import InsertPhotoOutlined from '@mui/icons-material/InsertPhotoOutlined';
import AddPhotoAlternateOutlined from '@mui/icons-material/AddPhotoAlternateOutlined';
import DocumentScannerOutlined from '@mui/icons-material/DocumentScannerOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { useDrawer } from '../../providers/Drawer';
import { useNavigate } from 'react-router-dom';
import { SCREEN_TYPE, LAYOUT_TYPE } from '../../_global/Route';

interface ComposeNewMessageThreadProps {
    userId?: string;
}

const ComposeNewMessageThread = React.memo<ComposeNewMessageThreadProps>(({ userId }) => {
    const message = useMessage();
    const navigate = useNavigate();
    const fileUpload = useFileUpload();
    const drawer = useDrawer();
    const [messageThreadId] = React.useState(ObjectID().toHexString());
    const [subject, setSubject] = React.useState('');
    const [initialText, setInitialText] = React.useState('');
    const [newMessageId, setNewMessageId] = React.useState(ObjectID().toHexString());
    const onSubjectChanged = React.useCallback((value: string) => {
        if (value.length > 60) return;
        setSubject(value);
    }, []);
    const onDrop = React.useCallback(
        (fileList: Array<File>) => {
            for (const file of fileList) {
                fileUpload.upload(file, messageThreadId, newMessageId);
            }
        },
        [fileUpload, messageThreadId, newMessageId]
    );
    const { getRootProps, isDragActive } = useDropzone({ onDrop, noClick: true });
    const onCreateThread = React.useCallback(
        (messageThreadId: string, text: string, fileIdList: Array<string>, apiGifList: Array<ApiGif>) => {
            fileUpload.setMessageSent(newMessageId);
            message.createThread(messageThreadId, newMessageId, subject, text, fileIdList, apiGifList, () => {
                drawer.close();
                navigate(`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.MAIN}/${messageThreadId}`);
                setNewMessageId(ObjectID().toHexString());
            });
        },
        [drawer, fileUpload, message, navigate, newMessageId, subject]
    );
    React.useEffect(() => {
        console.log(userId);
        if (userId) setInitialText(`{{cid=${userId}}}`);
    }, [userId]);
    return (
        <div {...getRootProps()} className="composeNewMessageThread_modalContentArea">
            <div
                className="common_uploadWrap"
                style={{
                    opacity: isDragActive ? 1 : 0,
                    visibility: isDragActive ? 'visible' : 'hidden',
                    transition: 'opacity 90ms ease-in'
                }}
            >
                <div className="common_uploadInner">
                    <div className="uploadIcons">
                        <InsertPhotoOutlined fontSize="large" />
                        <AddPhotoAlternateOutlined fontSize="large" />
                        <DocumentScannerOutlined fontSize="large" />
                    </div>
                    <Typography variant="h2">Upload Files</Typography>
                </div>
            </div>
            <div className="composeNewMessageThread_modalScrollArea">
                <div className="composeNewMessageThread_header">
                    <button
                        onClick={() => {
                            drawer.close();
                        }}
                        className="drawerCloseBtn"
                    >
                        <CloseIcon />
                    </button>
                    <h2>Create Message</h2>
                </div>

                <div className="composeNewMessageThread_tags">
                    <div>Subject</div>
                    <input
                        type="text"
                        value={subject}
                        autoFocus={true}
                        onChange={(e) => {
                            onSubjectChanged(e.target.value);
                        }}
                    />
                </div>
            </div>
            <div className="composeNewMessageThread_modalActionArea">
                <MessageReply
                    validateHashtagCallout={true}
                    messageThreadId={messageThreadId}
                    messageId={newMessageId}
                    autoFocus={false}
                    placeholder="Type a  message using at least one callout (@person) or a hashtag (#subject)"
                    sendMessageCallback={onCreateThread}
                    initialText={initialText}
                />
            </div>
        </div>
    );
});

export default ComposeNewMessageThread;
