import React from 'react';
import { MenuProps } from '@mui/material';
import { Menu } from '@mui/material';
import { useSelector } from '../../_redux/_Store';
import { ApiTagCategory } from '../../_api/_ApiModels';
import { List, arrayMove } from 'react-movable';
import { useApi } from '../../providers';
import { sortApiTagCategoryList } from '../../_global/Helpers';
import { Close, DragIndicator } from '@mui/icons-material';
import SortIcon from '../../assets/images/icons/sort-black.svg';

const TagCategoryMenu = React.memo<MenuProps>((menuProps) => {
    const api = useApi();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const apiTagCategoryList = useSelector((state) => state.data.apiTagCategoryList);
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const [willUpdate, setWillUpdate] = React.useState(false);
    const [userApiTagCategoryList, setUserApiTagCategoryList] = React.useState<Array<ApiTagCategory>>([]);
    React.useEffect(() => {
        if (menuProps.open === true) {
            setWillUpdate(true);
        }
    }, [menuProps.open]);
    React.useEffect(() => {
        if (willUpdate === true && menuProps.open === false) {
            api.updateSideNavTagCategoryIdList({
                tagCategoryIdList: userApiTagCategoryList.map((obj) => obj.tagCategoryId)
            });
            setWillUpdate(false);
        }
    }, [willUpdate, menuProps.open, api, userApiTagCategoryList]);
    React.useEffect(() => {
        if (!loggedInUserId) return;
        const apiUser = apiUserList[loggedInUserId];
        setUserApiTagCategoryList(sortApiTagCategoryList(apiTagCategoryList, apiUser.sideNavTagCategoryIdList));
    }, [apiTagCategoryList, apiUserList, loggedInUserId]);
    return (
        <Menu {...menuProps} className="tagCategoryMenu">
            <div className="tagCategoryMenu_header">
                <div className="tagCategoryMenu_headerLeft">
                    <img src={SortIcon} alt="sorticon" />
                    <span>Sort Order by Category</span>
                </div>
                <span
                    onClick={(e) => {
                        if (!menuProps.onClose) return;
                        menuProps.onClose(e, 'escapeKeyDown');
                    }}
                >
                    <Close />
                </span>
            </div>
            <List
                lockVertically
                values={userApiTagCategoryList}
                onChange={({ oldIndex, newIndex }) => {
                    setUserApiTagCategoryList(arrayMove(userApiTagCategoryList, oldIndex, newIndex));
                }}
                renderList={({ children, props }) => (
                    <ul className="commonList" {...props}>
                        {children}
                    </ul>
                )}
                renderItem={({ value, props }) => {
                    props.style = {
                        ...props.style,
                        zIndex: 999999999,
                        listStyleType: 'none'
                    }
                    return (
                        <li {...props}>
                            <DragIndicator /> {value.name}
                        </li>
                    )
                }}
            />
        </Menu>
    );
});

export default TagCategoryMenu;
