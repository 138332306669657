import React from 'react';
import { getApiUrl } from '../env';
import {
    ApiOrganization,
    ApiUser,
    ApiHashtag,
    ApiMessageThread,
    ApiFile,
    ApiGif,
    ApiMessage,
    ApiMessageSeen,
    ApiTagCategory,
    ApiTag,
    ApiUserHashtag,
    ApiMessageThreadSearchFilter
} from '../_api/_ApiModels';
import axios, { AxiosError, AxiosRequestConfig } from 'axios';
import { useAuth } from './Auth';
import { REACTION_TYPE, USER_HASHTAG_STATE_TYPE } from '../_global/_Enums';

export interface SubmitIdentityRequest {
    identity: string;
    type: string;
}

export interface SubmitIdentityResponse {
    valid: boolean;
    authIdentityId: string;
}

export interface SubmitPinRequest {
    authIdentityId: string;
    pin: string;
}

export interface SubmitPinResponse {
    token: string;
}

export interface ResendPinRequest {
    authIdentityId: string;
}

export interface BootstrapRequest {
    lastUpdateTime?: number;
}

export interface BootstrapResponse {
    userId: string;
    apiOrganization: ApiOrganization;
    apiUserList: Array<ApiUser>;
    apiTagCategoryList: Array<ApiTagCategory>;
    apiTagList: Array<ApiTag>;
    apiHashtagList: Array<ApiHashtag>;
    apiUserHashtagList: Array<ApiUserHashtag>;
}

export interface GetMessageThreadListRequest {
    lastSortTime?: number;
}

export interface GetMessageThreadListResponse {
    apiMessageThreadList: Array<ApiMessageThread>;
    hasMore: boolean;
}

export interface GetMessageThreadRequest {
    messageThreadId: string;
}

export interface GetMessageThreadResponse {
    apiMessageThread: ApiMessageThread;
}

export interface GetMessageProfileRequest {
    messageThreadId: string;
    messageId: string;
}

export interface GetMessageProfileResponse {
    apiMessage: ApiMessage;
    apiMessageSeenList: Array<ApiMessageSeen>;
    notSeenUserIdList: Array<string>;
}

export interface CreateMessageRequest {
    messageThreadId: string;
    messageId: string;
    text: string;
    fileIdList: Array<string>;
    gifList: Array<ApiGif>;
}

export interface CreateThreadRequest extends CreateMessageRequest {
    subject: string;
}

export interface UpdateStarredRequest {
    messageThreadId: string;
    starred: boolean;
}

export interface ToggleReactionRequest {
    messageThreadId: string;
    messageId: string;
    type: REACTION_TYPE;
}

export interface UpdateSubjectRequest {
    messageThreadId: string;
    subject: string;
}

export interface DeleteMessageRequest {
    messageThreadId: string;
    messageId: string;
}

export interface EditMessageRequest {
    messageThreadId: string;
    messageId: string;
    text: string;
}

export interface ToggleInboxRequest {
    messageThreadId: string;
    inbox: boolean;
}

export interface ToggleNotificationRequest {
    messageThreadId: string;
    notification: boolean;
}

export interface GetUrlRequest {
    fileId: string;
    contentType: string;
    name: string;
    method: string;
}

export interface GetUrlResponse {
    url: string;
}

export interface FileUploadedRequest {
    fileId: string;
}

export interface MessageSeenRequest {
    messageThreadId: string;
    messageId: string;
}

export interface MessageThreadSeenRequest {
    messageThreadId: string;
}

export interface GetFileListRequest {
    messageThreadId: string;
}

export interface GetFileListResponse {
    apiFileList: Array<ApiFile>;
}

export interface GetPresignedDownloadUrlRequest {
    fileId: string;
}

export interface GetPresignedDownloadUrlResponse {
    url: string;
}

export interface DeleteFileRequest {
    fileId: string;
    messageThreadId?: string;
    messageId?: string;
}

export interface DeleteGifRequest {
    gifId: string;
    messageThreadId?: string;
    messageId?: string;
}

export interface SearchMessageThreadListRequest {
    lastSortTime?: number;
    apiMessageThreadSearchFilterList: Array<ApiMessageThreadSearchFilter>;
}

export interface SearchMessageThreadListResponse {
    apiMessageThreadList: Array<ApiMessageThread>;
    hasMore: boolean;
}

export interface UpdateSideNavTagCategoryIdListRequest {
    tagCategoryIdList: Array<string>;
}

export interface UpdateDisplayTagCategoryIdListRequest {
    tagCategoryIdList: Array<string>;
}

export interface CreateUserHashtagRequestRequest {
    hashtagId: string;
    notifications: boolean;
}

export interface CreateMessageThreadSearchRequest {
    messageThreadSearchId: string;
    name: string;
    apiMessageThreadSearchFilterList: Array<ApiMessageThreadSearchFilter>;
}

export interface EditMessageThreadSearchNameRequest {
    messageThreadSearchId: string;
    name: string;
}

export interface DeleteMessageThreadSearchRequest {
    messageThreadSearchId: string;
}

export interface CreateHashtagRequest {
    tagIdList: Array<string>;
    description: string;
    adminUserIdList: Array<string>;
    inviteUserIdList: Array<string>;
    subscriptionUserIdList: Array<string>;
    notificationUserIdList: Array<string>;
}

export interface CreateTagRequest {
    name: string;
    tagCategoryId: string;
}

export interface UpdateHashtagDescriptionRequest {
    hashtagId: string;
    description: string;
}

export interface UpdateHashtagAdminUserIdListRequest {
    hashtagId: string;
    adminUserIdList: Array<string>;
}

export interface CreateUserHashtagRequestRequest {
    hashtagId: string;
    notifications: boolean;
}

export interface CreateUserHashtagInviteRequest {
    userId: string;
    hashtagId: string;
}

export interface ApproveUserHashtagRequestRequest {
    userHashtagId: string;
}

export interface DenyUserHashtagRequestRequest {
    userHashtagId: string;
}

export interface AcceptUserHashtagInviteRequest {
    userHashtagId: string;
    notifications: boolean;
}

export interface DeclineUserHashtagInviteRequest {
    userHashtagId: string;
}

export interface UnsubscribeHashtagRequest {
    userId: string;
    hashtagId: string;
}

export interface ToggleHashtagNotificationRequest {
    hashtagId: string;
}

export interface ToggleStarredUserRequest {
    userId: string;
    starred: boolean;
}

export interface UpdateAvatarRequest {
    base64Avatar: string;
    contentType: string;
}

export interface GetUserHashtagListRequest {
    hashtagId: string;
    stateType: USER_HASHTAG_STATE_TYPE;
}

export interface GetUserHashtagListResponse {
    userHashtagList: Array<ApiUserHashtag>;
}

interface Api {
    submitIdentity: (request: SubmitIdentityRequest, callback: Function) => void;
    submitPin: (request: SubmitPinRequest, callback: Function) => void;
    resendPin: (request: ResendPinRequest, callback: Function) => void;
    bootstrap: (request: BootstrapRequest, callback: Function, errorCallback: Function) => void;
    getMessageThreadList: (request: GetMessageThreadListRequest, callback: Function) => void;
    getMessageThread: (request: GetMessageThreadRequest, callback: Function) => void;
    getMessageProfile: (request: GetMessageProfileRequest, callback: Function) => void;
    createMessage: (request: CreateMessageRequest, callback?: Function) => void;
    createThread: (request: CreateThreadRequest, callback?: Function) => void;
    updateStarred: (request: UpdateStarredRequest, callback?: Function) => void;
    toggleReaction: (request: ToggleReactionRequest, callback?: Function) => void;
    updateSubject: (request: UpdateSubjectRequest, callback?: Function) => void;
    deleteMessage: (request: DeleteMessageRequest, callback?: Function) => void;
    editMessage: (request: EditMessageRequest, callback?: Function) => void;
    toggleInbox: (request: ToggleInboxRequest, callback?: Function) => void;
    toggleNotification: (request: ToggleNotificationRequest, callback?: Function) => void;
    messageSeen: (request: MessageSeenRequest, callback?: Function) => void;
    messageThreadSeen: (request: MessageThreadSeenRequest, callback?: Function) => void;
    getFileList: (request: GetFileListRequest, callback?: Function) => void;
    getUrl: (request: GetUrlRequest, callback?: Function) => void;
    fileUploaded: (request: FileUploadedRequest, callback?: Function) => void;
    getPresignedDownloadUrl: (request: GetPresignedDownloadUrlRequest, callback?: Function) => void;
    deleteFile: (request: DeleteFileRequest, callback?: Function) => void;
    deleteGif: (request: DeleteGifRequest, callback?: Function) => void;
    searchMessageThreadList: (request: SearchMessageThreadListRequest, callback?: Function) => void;
    updateSideNavTagCategoryIdList: (request: UpdateSideNavTagCategoryIdListRequest, callback?: Function) => void;
    updateDisplayTagCategoryIdList: (request: UpdateDisplayTagCategoryIdListRequest, callback?: Function) => void;
    createMessageThreadSearch: (request: CreateMessageThreadSearchRequest, callback?: Function) => void;
    editMessageThreadSearchName: (request: EditMessageThreadSearchNameRequest, callback?: Function) => void;
    deleteMessageThreadSearch: (request: DeleteMessageThreadSearchRequest, callback?: Function) => void;
    createTag: (request: CreateTagRequest, callback?: Function) => void;
    createHashtag: (request: CreateHashtagRequest, callback?: Function) => void;
    updateHashtagDescription: (request: UpdateHashtagDescriptionRequest, callback?: Function) => void;
    updateHashtagAdminUserIdList: (request: UpdateHashtagAdminUserIdListRequest, callback?: Function) => void;
    createUserHashtagRequest: (request: CreateUserHashtagRequestRequest, callback?: Function) => void;
    createUserHashtagInvite: (request: CreateUserHashtagInviteRequest, callback?: Function) => void;
    approveUserHashtagRequest: (request: ApproveUserHashtagRequestRequest, callback?: Function) => void;
    denyUserHashtagRequest: (request: DenyUserHashtagRequestRequest, callback?: Function) => void;
    acceptUserHashtagInvite: (request: AcceptUserHashtagInviteRequest, callback?: Function) => void;
    declineUserHashtagInvite: (request: DeclineUserHashtagInviteRequest, callback?: Function) => void;
    unsubscribeHashtag: (request: UnsubscribeHashtagRequest, callback?: Function) => void;
    toggleHashtagNotification: (request: ToggleHashtagNotificationRequest, callback?: Function) => void;
    toggleStarredUser: (request: ToggleStarredUserRequest, callback?: Function) => void;
    updateAvatar: (request: UpdateAvatarRequest, callback?: Function) => void;
    getUserHashtagList: (request: GetUserHashtagListRequest, callback?: Function) => void;
}

const ERROR = 'API Provider not initialized';

const ApiContext = React.createContext<Api>({
    submitIdentity: () => {
        throw ERROR;
    },
    submitPin: () => {
        throw ERROR;
    },
    resendPin: () => {
        throw ERROR;
    },
    bootstrap: () => {
        throw ERROR;
    },
    getMessageThreadList: () => {
        throw ERROR;
    },
    getMessageThread: () => {
        throw ERROR;
    },
    getMessageProfile: () => {
        throw ERROR;
    },
    createMessage: () => {
        throw ERROR;
    },
    createThread: () => {
        throw ERROR;
    },
    updateStarred: () => {
        throw ERROR;
    },
    toggleReaction: () => {
        throw ERROR;
    },
    updateSubject: () => {
        throw ERROR;
    },
    deleteMessage: () => {
        throw ERROR;
    },
    editMessage: () => {
        throw ERROR;
    },
    toggleInbox: () => {
        throw ERROR;
    },
    toggleNotification: () => {
        throw ERROR;
    },
    messageSeen: () => {
        throw ERROR;
    },
    messageThreadSeen: () => {
        throw ERROR;
    },
    getFileList: () => {
        throw ERROR;
    },
    getUrl: () => {
        throw ERROR;
    },
    fileUploaded: () => {
        throw ERROR;
    },
    getPresignedDownloadUrl: () => {
        throw ERROR;
    },
    deleteFile: () => {
        throw ERROR;
    },
    deleteGif: () => {
        throw ERROR;
    },
    searchMessageThreadList: () => {
        throw ERROR;
    },
    updateSideNavTagCategoryIdList: () => {
        throw ERROR;
    },
    updateDisplayTagCategoryIdList: () => {
        throw ERROR;
    },
    createMessageThreadSearch: () => {
        throw ERROR;
    },
    editMessageThreadSearchName: () => {
        throw ERROR;
    },
    deleteMessageThreadSearch: () => {
        throw ERROR;
    },
    createTag: () => {
        throw ERROR;
    },
    createHashtag: () => {
        throw ERROR;
    },
    updateHashtagDescription: () => {
        throw ERROR;
    },
    updateHashtagAdminUserIdList: () => {
        throw ERROR;
    },
    createUserHashtagRequest: () => {
        throw ERROR;
    },
    createUserHashtagInvite: () => {
        throw ERROR;
    },
    approveUserHashtagRequest: () => {
        throw ERROR;
    },
    denyUserHashtagRequest: () => {
        throw ERROR;
    },
    acceptUserHashtagInvite: () => {
        throw ERROR;
    },
    declineUserHashtagInvite: () => {
        throw ERROR;
    },
    unsubscribeHashtag: () => {
        throw ERROR;
    },
    toggleHashtagNotification: () => {
        throw ERROR;
    },
    toggleStarredUser: () => {
        throw ERROR;
    },
    updateAvatar: () => {
        throw ERROR;
    },
    getUserHashtagList: () => {
        throw ERROR;
    }
});

export interface ApiProps {
    children?: React.ReactNode;
}

export const ApiProvider = React.memo<ApiProps>((apiProps) => {
    const auth = useAuth();
    const apiAxios = axios.create();
    const getAxiosConfig = React.useCallback(() => {
        const axiosRequestConfig: AxiosRequestConfig = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json'
            }
        };
        if (axiosRequestConfig.headers && auth.getToken()) {
            axiosRequestConfig.headers['Authorization'] = `Bearer ${auth.getToken()}`;
        }
        return axiosRequestConfig;
    }, [auth]);
    const post = React.useCallback(
        (path: string, postData: Object, callback?: Function, errorCallback?: Function) => {
            apiAxios
                .post(`${getApiUrl()}${path}`, postData, getAxiosConfig())
                .then((axiosResponse) => {
                    if (callback) {
                        callback(axiosResponse);
                    }
                })
                .catch((error: AxiosError) => {
                    if (errorCallback) {
                        errorCallback(error);
                    }
                });
        },
        [apiAxios, getAxiosConfig]
    );
    const api = React.useMemo<Api>(() => {
        return {
            submitIdentity: (request: SubmitIdentityRequest, callback: Function) => {
                post('/auth/submitIdentity', request, callback);
            },
            submitPin: (request: SubmitPinRequest, callback: Function) => {
                post('/auth/submitPin', request, callback);
            },
            resendPin: (request: ResendPinRequest, callback: Function) => {
                post('/auth/resendPin', request, callback);
            },
            bootstrap: (request: BootstrapRequest, callback: Function, errorCallback: Function) => {
                post('/data/bootstrap', request, callback, errorCallback);
            },
            getMessageThreadList: (request: GetMessageThreadListRequest, callback: Function) => {
                post('/message/getMessageThreadList', request, callback);
            },
            getMessageThread: (request: GetMessageThreadRequest, callback: Function) => {
                post('/message/getMessageThread', request, callback);
            },
            getMessageProfile: (request: GetMessageProfileRequest, callback: Function) => {
                post('/message/getMessageProfile', request, callback);
            },
            createMessage: (request: CreateMessageRequest, callback?: Function) => {
                post('/message/createMessage', request, callback);
            },
            createThread: (request: CreateThreadRequest, callback?: Function) => {
                post('/message/createThread', request, callback);
            },
            updateStarred: (request: UpdateStarredRequest, callback?: Function) => {
                post('/message/updateStarred', request, callback);
            },
            toggleReaction: (request: ToggleReactionRequest, callback?: Function) => {
                post('/message/toggleReaction', request, callback);
            },
            updateSubject: (request: UpdateSubjectRequest, callback?: Function) => {
                post('/message/updateSubject', request, callback);
            },
            deleteMessage: (request: DeleteMessageRequest, callback?: Function) => {
                post('/message/deleteMessage', request, callback);
            },
            editMessage: (request: EditMessageRequest, callback?: Function) => {
                post('/message/editMessage', request, callback);
            },
            toggleInbox: (request: ToggleInboxRequest, callback?: Function) => {
                post('/message/toggleInbox', request, callback);
            },
            toggleNotification: (request: ToggleNotificationRequest, callback?: Function) => {
                post('/message/toggleNotification', request, callback);
            },
            messageSeen: (request: MessageSeenRequest, callback?: Function) => {
                post('/message/messageSeen', request, callback);
            },
            messageThreadSeen: (request: MessageThreadSeenRequest, callback?: Function) => {
                post('/message/messageThreadSeen', request, callback);
            },
            getFileList: (request: GetFileListRequest, callback?: Function) => {
                post('/message/getFileList', request, callback);
            },
            getUrl: (request: GetUrlRequest, callback?: Function) => {
                post('/file/getUrl', request, callback);
            },
            fileUploaded: (request: FileUploadedRequest, callback?: Function) => {
                post('/file/fileUploaded', request, callback);
            },
            getPresignedDownloadUrl: (request: GetPresignedDownloadUrlRequest, callback?: Function) => {
                post('/file/getPresignedDownloadUrl', request, callback);
            },
            deleteFile: (request: DeleteFileRequest, callback?: Function) => {
                post('/file/deleteFile', request, callback);
            },
            deleteGif: (request: DeleteGifRequest, callback?: Function) => {
                post('/file/deleteGif', request, callback);
            },
            searchMessageThreadList: (request: SearchMessageThreadListRequest, callback?: Function) => {
                post('/message/searchMessageThreadList', request, callback);
            },
            updateSideNavTagCategoryIdList: (request: UpdateSideNavTagCategoryIdListRequest, callback?: Function) => {
                post('/manage/updateSideNavTagCategoryIdList', request, callback);
            },
            updateDisplayTagCategoryIdList: (request: UpdateDisplayTagCategoryIdListRequest, callback?: Function) => {
                post('/manage/updateDisplayTagCategoryIdList', request, callback);
            },
            createMessageThreadSearch: (request: CreateMessageThreadSearchRequest, callback?: Function) => {
                post('/manage/createMessageThreadSearch', request, callback);
            },
            editMessageThreadSearchName: (request: EditMessageThreadSearchNameRequest, callback?: Function) => {
                post('/manage/editMessageThreadSearchName', request, callback);
            },
            deleteMessageThreadSearch: (request: DeleteMessageThreadSearchRequest, callback?: Function) => {
                post('/manage/deleteMessageThreadSearch', request, callback);
            },
            createTag: (request: CreateTagRequest, callback?: Function) => {
                post('/manage/createTag', request, callback);
            },
            createHashtag: (request: CreateHashtagRequest, callback?: Function) => {
                post('/manage/createHashtag', request, callback);
            },
            updateHashtagDescription: (request: UpdateHashtagDescriptionRequest, callback?: Function) => {
                post('/manage/updateHashtagDescription', request, callback);
            },
            updateHashtagAdminUserIdList: (request: UpdateHashtagAdminUserIdListRequest, callback?: Function) => {
                post('/manage/updateHashtagAdminUserIdList', request, callback);
            },
            createUserHashtagRequest: (request: CreateUserHashtagRequestRequest, callback?: Function) => {
                post('/manage/createUserHashtagRequest', request, callback);
            },
            createUserHashtagInvite: (request: CreateUserHashtagInviteRequest, callback?: Function) => {
                post('/manage/createUserHashtagInvite', request, callback);
            },
            approveUserHashtagRequest: (request: ApproveUserHashtagRequestRequest, callback?: Function) => {
                post('/manage/approveUserHashtagRequest', request, callback);
            },
            denyUserHashtagRequest: (request: DenyUserHashtagRequestRequest, callback?: Function) => {
                post('/manage/denyUserHashtagRequest', request, callback);
            },
            acceptUserHashtagInvite: (request: AcceptUserHashtagInviteRequest, callback?: Function) => {
                post('/manage/acceptUserHashtagInvite', request, callback);
            },
            declineUserHashtagInvite: (request: DeclineUserHashtagInviteRequest, callback?: Function) => {
                post('/manage/declineUserHashtagInvite', request, callback);
            },
            unsubscribeHashtag: (request: UnsubscribeHashtagRequest, callback?: Function) => {
                post('/manage/unsubscribeHashtag', request, callback);
            },
            toggleHashtagNotification: (request: ToggleHashtagNotificationRequest, callback?: Function) => {
                post('/manage/toggleHashtagNotification', request, callback);
            },
            toggleStarredUser: (request: ToggleStarredUserRequest, callback?: Function) => {
                post('/manage/toggleStarredUser', request, callback);
            },
            updateAvatar: (request: UpdateAvatarRequest, callback?: Function) => {
                post('/manage/updateAvatar', request, callback);
            },
            getUserHashtagList: (request: GetUserHashtagListRequest, callback?: Function) => {
                post('/manage/getUserHashtagList', request, callback);
            }
        };
    }, [post]);
    return <ApiContext.Provider value={api}>{apiProps.children}</ApiContext.Provider>;
});

export const useApi = () => React.useContext(ApiContext);
