import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import { useSelector } from '../../_redux/_Store';

interface TagAutocompleteProps {
    setTagIdList: Function;
}

const TagAutocomplete = React.memo<TagAutocompleteProps>(({ setTagIdList }) => {
    const apiTagList = [...Object.values(useSelector((state) => state.data.apiTagList))];
    return (
        <Autocomplete
            multiple
            id="tags-outlined"
            options={apiTagList}
            onChange={(_, value) => {
                setTagIdList(
                    value.reduce((tagIdList, apiTag) => {
                        tagIdList.push(apiTag.tagId);
                        return tagIdList;
                    }, [] as Array<string>)
                );
            }}
            filterSelectedOptions
            getOptionLabel={(option) => option.name}
            renderInput={(params) => <TextField {...params} placeholder="Begin typing to add one or more topics" />}
        />
    );
});

export default TagAutocomplete;
