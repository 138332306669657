import { ApiFile, ApiGif, ApiMessage, ApiMessageThread, ApiMessageThreadObject, ApiReaction } from '../_api/_ApiModels';
import produce from 'immer';
import moment from 'moment';

const GROUP_MESSAGE_SECONDS = 300;

interface Message {
    messageId: string;
    createTime: number;
    createdUserId: string;
    text: string;
    editTime?: number;
    apiFileList: Array<ApiFile>;
    fileIdList?: Array<string>;
    apiGifList: Array<ApiGif>;
    apiReactionList: Array<ApiReaction>;
    isDeleted: boolean;
    isSent: boolean;
    isDisplayDateSeparator: boolean;
    isFirstInGroup: boolean;
    isInGroup: boolean;
    isLastInGroup: boolean;
    isSeen: boolean;
}

interface NewMessage {
    messageId: string;
    createTime: number;
    createdUserId: string;
    text: string;
    fileIdList: Array<string>;
    apiGifList: Array<ApiGif>;
}

export enum MESSAGE_LIST_ACTION_TYPE {
    CLEAR_MESSAGE_LIST = 'CLEAR_MESSAGE_LIST',
    SET_API_MESSAGE_THREAD = 'SET_API_MESSAGE_THREAD',
    ADD_NEW_MESSAGE = 'ADD_NEW_MESSAGE',
    ADD_SEEN_MESSAGE_ID = 'ADD_SEEN_MESSAGE_ID',
    SET_IS_STARRED = 'SET_IS_STARRED',
    SET_IS_LOADING = 'SET_IS_LOADING'
}

export interface MessageListAction {
    type: MESSAGE_LIST_ACTION_TYPE;
    payload?: ApiMessageThread | NewMessage | boolean | string;
}

interface MessageListState {
    isLoading: boolean;
    messageThreadId: string;
    messageList: Array<Message>;
    seenMessageIdList: Array<string>;
    subject: string;
    isStarred: boolean;
    isInbox: boolean;
    isSeen: boolean;
    isNotification: boolean;
    daySeparatorList: Array<number>;
    fileCount: number;
    apiMessageThreadObject?: ApiMessageThreadObject;
    userIdList: Array<string>;
    tagIdList: Array<string>;
    notificationUserIdList: Array<string>;
}

export const initMessageListState: MessageListState = {
    isLoading: true,
    messageThreadId: '',
    messageList: [] as Array<Message>,
    seenMessageIdList: [] as Array<string>,
    subject: '',
    isStarred: false,
    isInbox: false,
    isSeen: false,
    isNotification: false,
    daySeparatorList: [] as Array<number>,
    fileCount: 0,
    userIdList: [],
    tagIdList: [],
    notificationUserIdList: []
};

export const clearMessageList = () => {
    return {
        type: MESSAGE_LIST_ACTION_TYPE.CLEAR_MESSAGE_LIST
    };
};

export const setApiMessageThread = (apiMessageThread: ApiMessageThread) => {
    return {
        type: MESSAGE_LIST_ACTION_TYPE.SET_API_MESSAGE_THREAD,
        payload: apiMessageThread
    };
};

export const addNewMessage = (newMessage: NewMessage) => {
    return {
        type: MESSAGE_LIST_ACTION_TYPE.ADD_NEW_MESSAGE,
        payload: newMessage
    };
};

export const setIsStarred = (isStarred: boolean) => {
    return {
        type: MESSAGE_LIST_ACTION_TYPE.SET_IS_STARRED,
        payload: isStarred
    };
};

export const setIsLoading = () => {
    return {
        type: MESSAGE_LIST_ACTION_TYPE.SET_IS_LOADING
    };
};

export const addSeenMessageId = (messageId: string) => {
    return {
        type: MESSAGE_LIST_ACTION_TYPE.ADD_SEEN_MESSAGE_ID,
        payload: messageId
    };
};

const getIsInGroup = (apiMessage: ApiMessage, prevApiMessage: ApiMessage | null, nextApiMessage: ApiMessage | null) => {
    let isInGroup = false;
    if (prevApiMessage) {
        const seconds = moment.duration(moment.unix(apiMessage.createTime).diff(moment.unix(prevApiMessage.createTime))).asSeconds();
        isInGroup = prevApiMessage.createdUserId === apiMessage.createdUserId && seconds < GROUP_MESSAGE_SECONDS;
    }
    if (!isInGroup && nextApiMessage) {
        const seconds = moment.duration(moment.unix(nextApiMessage.createTime).diff(moment.unix(apiMessage.createTime))).asSeconds();
        return nextApiMessage.createdUserId === apiMessage.createdUserId && seconds < GROUP_MESSAGE_SECONDS;
    }
    return isInGroup;
};

const getIsFirstInGroup = (isInGroup: boolean, apiMessage: ApiMessage, prevApiMessage: ApiMessage | null) => {
    if (!isInGroup) return false;
    if (!prevApiMessage) return true;
    const seconds = moment.duration(moment.unix(apiMessage.createTime).diff(moment.unix(prevApiMessage.createTime))).asSeconds();
    return prevApiMessage.createdUserId !== apiMessage.createdUserId || seconds > GROUP_MESSAGE_SECONDS;
};

const getIsLastInGroup = (isInGroup: boolean, apiMessage: ApiMessage, nextApiMessage: ApiMessage | null) => {
    if (!isInGroup) return false;
    if (!nextApiMessage) return true;
    const seconds = moment.duration(moment.unix(nextApiMessage.createTime).diff(moment.unix(apiMessage.createTime))).asSeconds();
    return nextApiMessage.createdUserId !== apiMessage.createdUserId || seconds > GROUP_MESSAGE_SECONDS;
};

const getMessage = (apiMessage: ApiMessage, prevApiMessage: ApiMessage | null, nextApiMessage: ApiMessage | null) => {
    const isInGroup = getIsInGroup(apiMessage, prevApiMessage, nextApiMessage);
    const isFirstInGroup = getIsFirstInGroup(isInGroup, apiMessage, prevApiMessage);
    const isLastInGroup = getIsLastInGroup(isInGroup, apiMessage, nextApiMessage);
    return {
        messageId: apiMessage.messageId,
        createTime: apiMessage.createTime,
        createdUserId: apiMessage.createdUserId,
        text: apiMessage.text,
        editTime: apiMessage.editTime,
        apiFileList: apiMessage.apiFileList,
        apiGifList: apiMessage.apiGifList,
        apiReactionList: apiMessage.apiReactionList,
        isDeleted: apiMessage.deleted,
        isSent: true,
        isDisplayDateSeparator: false,
        isFirstInGroup: isFirstInGroup,
        isInGroup: isInGroup,
        isLastInGroup: isLastInGroup,
        isSeen: apiMessage.isSeen
    } as Message;
};

const sortApiMessageList = (a: ApiMessage, b: ApiMessage) => {
    const sort = a.createTime - b.createTime;
    if (sort) return sort;
    return b.messageId.localeCompare(a.messageId);
};

const sortMessageList = (a: Message, b: Message) => {
    const sort = a.createTime - b.createTime;
    if (sort) return sort;
    return b.messageId.localeCompare(a.messageId);
};

export const MessageListReducer = (state = initMessageListState, action: MessageListAction) => {
    switch (action.type) {
        case MESSAGE_LIST_ACTION_TYPE.CLEAR_MESSAGE_LIST: {
            return initMessageListState;
        }
        case MESSAGE_LIST_ACTION_TYPE.SET_API_MESSAGE_THREAD: {
            const apiMessageThread = action.payload as ApiMessageThread;
            return produce(state, (draft) => {
                draft.messageList = [];
                draft.seenMessageIdList = [];
                draft.daySeparatorList = [];
                draft.messageThreadId = apiMessageThread.messageThreadId;
                draft.apiMessageThreadObject = apiMessageThread.apiMessageThreadObject;
                const apiMessageList = [...apiMessageThread.apiMessageList].sort(sortApiMessageList);
                let prevApiMessage: ApiMessage | null = null;
                for (let i = 0; i < apiMessageList.length; i++) {
                    const apiMessage = apiMessageList[i];
                    let nextApiMessage: ApiMessage | null = i < apiMessageList.length - 1 ? apiMessageList[i + 1] : null;
                    const j = draft.messageList.findIndex((obj) => obj.messageId === apiMessage.messageId);
                    if (j > -1) draft.messageList.splice(j, 1);
                    const message = getMessage(apiMessage, prevApiMessage, nextApiMessage);
                    const startOfDay = moment.unix(apiMessage.createTime).startOf('day').unix();
                    if (!draft.daySeparatorList.includes(startOfDay)) {
                        draft.daySeparatorList.push(startOfDay);
                        message.isDisplayDateSeparator = true;
                    }
                    draft.messageList.push(message);
                    if (apiMessage.isSeen) {
                        draft.seenMessageIdList.push(apiMessage.messageId);
                    }
                    prevApiMessage = apiMessage;
                }
                draft.fileCount = apiMessageThread.fileCount;
                draft.subject = apiMessageThread.subject;
                draft.isStarred = apiMessageThread.isStarred;
                draft.isSeen = apiMessageThread.isSeen;
                draft.isInbox = apiMessageThread.isInbox;
                draft.isNotification = apiMessageThread.isNotification;
                draft.userIdList = [...apiMessageList].reverse().reduce((idList, apiMessage) => {
                    if (!idList.includes(apiMessage.createdUserId)) idList.push(apiMessage.createdUserId);
                    return idList;
                }, [] as Array<string>);
                draft.tagIdList = apiMessageThread.tagIdList;
                draft.notificationUserIdList = apiMessageThread.notificationUserIdList;
                draft.isLoading = false;
            });
        }
        case MESSAGE_LIST_ACTION_TYPE.ADD_NEW_MESSAGE: {
            const newMessage = action.payload as NewMessage;
            return produce(state, (draft) => {
                const message: Message = {
                    messageId: newMessage.messageId,
                    createTime: newMessage.createTime,
                    createdUserId: newMessage.createdUserId,
                    text: newMessage.text,
                    apiFileList: [],
                    fileIdList: newMessage.fileIdList,
                    apiGifList: newMessage.apiGifList,
                    apiReactionList: [],
                    isDeleted: false,
                    isSent: false,
                    isDisplayDateSeparator: false,
                    isFirstInGroup: false,
                    isInGroup: false,
                    isLastInGroup: false,
                    isSeen: true
                };
                const startOfDay = moment.unix(message.createTime).startOf('day').unix();
                if (!draft.daySeparatorList.includes(startOfDay)) {
                    draft.daySeparatorList.push(startOfDay);
                    message.isDisplayDateSeparator = true;
                }
                draft.messageList.push(message);
                draft.messageList.sort(sortMessageList);
                draft.seenMessageIdList.push(newMessage.messageId);
            });
        }
        case MESSAGE_LIST_ACTION_TYPE.SET_IS_STARRED: {
            const isStarred = action.payload as boolean;
            return produce(state, (draft) => {
                draft.isStarred = isStarred;
            });
        }
        case MESSAGE_LIST_ACTION_TYPE.SET_IS_LOADING: {
            return produce(state, (draft) => {
                draft.isLoading = true;
            });
        }
        case MESSAGE_LIST_ACTION_TYPE.ADD_SEEN_MESSAGE_ID: {
            const messageId = action.payload as string;
            return produce(state, (draft) => {
                draft.seenMessageIdList.push(messageId);
            });
        }
        default: {
            break;
        }
    }
};
