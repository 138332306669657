import React from 'react';
import { Button, Modal, Slider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import AvatarEditor from 'react-avatar-editor';
import { useApi } from '../../providers';
import { AxiosResponse } from 'axios';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';

interface CropProfileProps {
    isOpen: boolean;
    onCloseCallback: () => void;
    userProfileImgSrc: File | null;
    base64: string;
}

const CropProfile = React.memo(({ isOpen, onCloseCallback, userProfileImgSrc, base64 }: CropProfileProps) => {
    const api = useApi();
    const [sliderValue, setSliderValue] = React.useState(1);
    const [isOpenSureModal, setIsOpenSureModal] = React.useState<boolean>(false);

    const onSliderChange = React.useCallback((event: Event, newValue: number | number[]) => {
        if (typeof newValue === 'number') {
            setSliderValue(newValue);
        }
    }, []);

    const onChangeAvatar = React.useCallback(() => {
        if (!!userProfileImgSrc && base64) {
            api.updateAvatar(
                {
                    base64Avatar: base64,
                    contentType: userProfileImgSrc.type
                },
                (axiosResponse: AxiosResponse) => {
                    onCloseCallback();
                }
            );
        }
    }, [userProfileImgSrc, base64]);

    return (
        <Modal
            open={isOpen}
            onClose={() => {
                onCloseCallback();
            }}
        >
            <div className="commonModal_wrapper CropProfile-wrapper">
                <div className="CropProfile-Header ">
                    <h3>Edit Profile Picture</h3>
                    <div
                        onClick={() => {
                            setIsOpenSureModal(!isOpenSureModal);
                        }}
                    >
                        <CloseIcon />
                    </div>
                </div>
                <div className="modalBody">
                    <AvatarEditor image={userProfileImgSrc as File | string} width={200} height={200} border={50} color={[40, 38, 68, 0.3]} scale={sliderValue} rotate={0} borderRadius={100} />
                    <div className="AvatarScaleSlider">
                        <div className="AvatarScaleSlider-action-btn">
                            <RemoveIcon />
                        </div>
                        <Slider min={1} max={2} step={0.1} value={sliderValue} onChange={onSliderChange} />
                        <div className="AvatarScaleSlider-action-btn">
                            <AddIcon />
                        </div>
                    </div>
                </div>
                <Modal
                    open={isOpenSureModal}
                    onClose={() => {
                        setIsOpenSureModal(isOpenSureModal);
                    }}
                >
                    <div className="commonModal_wrapper are-you-sure-modal">
                        <h2>Are you sure?</h2>
                        <p>Are you sure you want to discard changes?</p>
                        <div className="modalFooter">
                            <Button
                                color={'secondary'}
                                sx={{ '&:hover': { boxShadow: 'none' } }}
                                variant="contained"
                                onClick={() => {
                                    setIsOpenSureModal(false);
                                }}
                            >
                                No
                            </Button>
                            <Button
                                sx={{ '&:hover': { boxShadow: 'none' } }}
                                className="action-btn"
                                variant="contained"
                                color={'primary'}
                                onClick={() => {
                                    onCloseCallback();
                                    setIsOpenSureModal(false);
                                }}
                            >
                                Yes
                            </Button>
                        </div>
                    </div>
                </Modal>

                <div className="modalFooter">
                    <Button
                        color={'secondary'}
                        className="action-btn"
                        variant="contained"
                        onClick={() => {
                            setIsOpenSureModal(!isOpenSureModal);
                        }}
                    >
                        Cancel
                    </Button>
                    <Button
                        className="action-btn"
                        variant="contained"
                        color={'primary'}
                        onClick={() => {
                            onChangeAvatar();
                        }}
                    >
                        Save
                    </Button>
                </div>
            </div>
        </Modal>
    );
});

export default CropProfile;
