import React from 'react';
import { getMessageText } from '../_global/Helpers';
import { SCREEN_TYPE, LAYOUT_TYPE } from '../_global/Route';
import { WebSocketPayloadNewMessageNotification } from '../_redux/WebSocketReducer';

interface Notification {
    requestPermission: () => void;
    displayApiMessage: (webSocketPayloadNewMessageNotification: WebSocketPayloadNewMessageNotification) => void;
}

const NotificationContext = React.createContext<Notification>({
    requestPermission: () => {
        return null;
    },
    displayApiMessage: () => {
        return null;
    }
});

export interface NotificationProps {
    children?: React.ReactNode;
}

export const NotificationProvider = React.memo<NotificationProps>((notificationProps) => {
    const notification = React.useMemo<Notification>(() => {
        return {
            requestPermission: () => {
                Notification.requestPermission();
            },
            displayApiMessage: (webSocketPayloadNewMessageNotification: WebSocketPayloadNewMessageNotification) => {
                new Audio('/notification.mp3').play();
                let notificationBody = webSocketPayloadNewMessageNotification.text ? webSocketPayloadNewMessageNotification.text : getMessageText(webSocketPayloadNewMessageNotification.apiMessage);
                const title = webSocketPayloadNewMessageNotification.apiMessageThread.subject ? webSocketPayloadNewMessageNotification.apiMessageThread.subject : 'No Subject';
                new Notification(title, {
                    body: notificationBody,
                    icon: '/favicon.svg'
                }).onclick = () => {
                    window.location.href = `/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.MAIN}/${webSocketPayloadNewMessageNotification.apiMessageThread.messageThreadId}`;
                };
            }
        };
    }, []);
    return <NotificationContext.Provider value={notification}>{notificationProps.children}</NotificationContext.Provider>;
});

export const useNotification = () => React.useContext(NotificationContext);
