import React from 'react';
import Highlighter from 'react-highlight-words';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { useSelector } from '../../_redux/_Store';
import { CALLOUT_KEY } from '../../_global/Constants';
import UserAvatar from './UserAvatar';

interface UserAutocompleteProps {
    setUserIdList: Function;
    initUserIdList?: Array<string>;
}

const UserAutocomplete = React.memo<UserAutocompleteProps>(({ setUserIdList, initUserIdList = [] }) => {
    const apiUserList = useSelector((state) => state.data.apiUserList);
    React.useEffect(() => {
        setUserIdList(initUserIdList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <Autocomplete
            multiple
            id="tags-outlined"
            className="user_autocomplete"
            options={Object.values(apiUserList).map((obj) => obj.userId)}
            defaultValue={[...initUserIdList]}
            onChange={(_, value) => {
                setUserIdList(value);
            }}
            filterSelectedOptions
            getOptionLabel={(option) => apiUserList[option].callout}
            renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                    <Chip
                        label={
                            <>
                                <UserAvatar userId={option} size={18} displayOnlineIndicator={false} />
                                {`${CALLOUT_KEY}${apiUserList[option].callout}`}
                            </>
                        }
                        {...getTagProps({ index })}
                    />
                ))
            }
            renderOption={(props, option, { inputValue }) => {
                return (
                    <li {...props} key={option}>
                        <div className="hashtagCalloutInput_List">
                            <UserAvatar userId={option as string} size={18} displayOnlineIndicator={false} />
                            {`${CALLOUT_KEY}`}
                            {apiUserList[option].callout && (
                                <Highlighter
                                    searchWords={[inputValue]}
                                    autoEscape={true}
                                    textToHighlight={apiUserList[option].callout}
                                    highlightClassName="hashtagCalloutInput_markHighlight"
                                    className="hashtagCalloutInput_highlighterWrap"
                                >
                                    {apiUserList[option].callout}
                                </Highlighter>
                            )}
                        </div>
                    </li>
                );
            }}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        InputProps={{
                            ...params.InputProps,
                            type: 'search'
                        }}
                    />
                );
            }}
        />
    );
});

export default UserAutocomplete;
