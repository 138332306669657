import React from 'react';
import { Button, Chip, Modal } from '@mui/material';
import {
    InfoOutlined as InfoOutlinedIcon,
    NotificationsActive,
    NotificationsOffOutlined,
    PeopleOutline,
    RemoveCircleOutline as RemoveCircleOutlineIcon,
    ThumbDownOutlined,
    ThumbUpOutlined,
    TimerOutlined,
    PersonAddAlt,
    SellOutlined,
    Edit
} from '@mui/icons-material';
import { useSelector } from '../../_redux/_Store';
import { ApiUserHashtag } from '../../_api/_ApiModels';
import { USER_HASHTAG_STATE_TYPE } from '../../_global/_Enums';
import UserAvatar from '../common/UserAvatar';
import UserName from '../common/UserName';
import { HASHTAGS_PARAM_TYPE } from '../../_global/Route';
import { useParams } from 'react-router-dom';
import { useDrawer } from '../../providers/Drawer';
import { useApi } from '../../providers';
import UserListModal from '../common/UserListModal';
import ModalPrompt from '../common/ModalPrompt';
import remove from '../../assets/images/remove.svg';
import greenCheck from '../../assets/images/greenCheck.svg';
import invitationsSent from '../../assets/images/invitationsSent.svg';
import NotificationModal from '../common/NotificationModal';
import Hashtag from '../common/Hashtag';

const HashtagInfo = React.memo(() => {
    const { apiHashtagList, apiTagList, apiUserList, apiUserHashtagList } = useSelector((state) => state.data);
    const api = useApi();
    const drawer = useDrawer();
    const params = useParams<HASHTAGS_PARAM_TYPE>();
    const [hashtagId, setHashtagId] = React.useState(params.HASHTAG_ID);
    const [name, setName] = React.useState<string>('');
    const [description, setDescription] = React.useState<string>('');
    const [tagIdList, setTagIdList] = React.useState<Array<string>>([]);
    const [adminUserIdList, setAdminUserIdList] = React.useState<Array<string>>([]);
    const [notificationUserIdList, setNotificationUserIdList] = React.useState<Array<string>>([]);
    const [subscriptionUserIdList, setSubscriptionUserIdList] = React.useState<Array<string>>([]);
    const [requestApiUserHashtagList, setRequestApiUserHashtagList] = React.useState<Array<ApiUserHashtag>>([]);
    const [isEditAdminUserIdListOpen, setIsEditAdminUserIdListOpen] = React.useState<boolean>(false);
    const [isInviteUsersOpen, setIsInviteUsersOpen] = React.useState<boolean>(false);
    const [isEditDescriptionOpen, setIsEditDescriptionOpen] = React.useState<boolean>(false);
    const [isConfirmUnsubscribeOpen, setIsConfirmUnsubscribeOpen] = React.useState<boolean>(false);
    const [isConfirmApproveAllOpen, setIsConfirmApproveAllOpen] = React.useState<boolean>(false);
    const [isInvitationsSentOpen, setIsInvitationsSentOpen] = React.useState<boolean>(false);
    const [unsubscribeUserId, setUnsubscribeUserId] = React.useState<string>();
    const [inviteCount, setInviteCount] = React.useState<number>(0);
    const onApproveAllClicked = React.useCallback(() => {
        for (let i = 0; i < requestApiUserHashtagList.length; i++) {
            const apiUserHashtag = requestApiUserHashtagList[i];
            api.approveUserHashtagRequest({
                userHashtagId: apiUserHashtag.userHashtagId
            });
            setIsConfirmApproveAllOpen(true);
        }
    }, [api, requestApiUserHashtagList]);
    const onApproveClicked = React.useCallback(
        (userHashtagId: string) => {
            api.approveUserHashtagRequest({
                userHashtagId: userHashtagId
            });
        },
        [api]
    );
    const onDenyClicked = React.useCallback(
        (userHashtagId: string) => {
            api.denyUserHashtagRequest({
                userHashtagId: userHashtagId
            });
        },
        [api]
    );
    const onUnsubscribeClicked = React.useCallback(() => {
        if (!hashtagId || !unsubscribeUserId) return;
        api.unsubscribeHashtag({
            userId: unsubscribeUserId,
            hashtagId: hashtagId
        });
        setUnsubscribeUserId(undefined);
        setIsConfirmUnsubscribeOpen(false);
    }, [api, hashtagId, unsubscribeUserId]);
    const onDescriptionSaveClicked = React.useCallback(() => {
        if (!hashtagId) return;
        api.updateHashtagDescription({
            hashtagId: hashtagId,
            description: description
        });
        setIsEditDescriptionOpen(false);
    }, [api, description, hashtagId]);
    const onAdminUserIdListSaveClicked = React.useCallback(
        (adminUserIdList: Array<string>) => {
            if (!hashtagId) return;
            api.updateHashtagAdminUserIdList({
                hashtagId: hashtagId,
                adminUserIdList: adminUserIdList
            });
            setIsEditAdminUserIdListOpen(false);
        },
        [api, hashtagId]
    );
    const onInviteUserIdListClicked = React.useCallback(
        (userIdList: Array<string>) => {
            if (!hashtagId) return;
            for (let i = 0; i < userIdList.length; i++) {
                api.createUserHashtagInvite({
                    hashtagId: hashtagId,
                    userId: userIdList[i]
                });
            }
            setInviteCount(userIdList.length);
            setIsInviteUsersOpen(false);
            setIsInvitationsSentOpen(true);
        },
        [api, hashtagId]
    );
    React.useEffect(() => {
        setHashtagId(params.HASHTAG_ID);
    }, [params.HASHTAG_ID]);
    React.useEffect(() => {
        if (!hashtagId) return;
        const apiHashtag = apiHashtagList[hashtagId];
        if (!apiHashtag) return;
        setName(apiHashtag.name);
        setDescription(apiHashtag.description);
        setTagIdList(apiHashtag.tagIdList);
        setAdminUserIdList(apiHashtag.adminUserIdList);
        setNotificationUserIdList(apiHashtag.notificationUserIdList);
        setSubscriptionUserIdList(apiHashtag.subscriptionUserIdList);
        setRequestApiUserHashtagList(Object.values(apiUserHashtagList).filter((obj) => obj.hashtagId === hashtagId && obj.stateType === USER_HASHTAG_STATE_TYPE.REQUESTED));
    }, [hashtagId, apiHashtagList, apiTagList, apiUserList, apiUserHashtagList]);
    return (
        <>
            <div className="commonHashtagDrawer_wrapper">
                <div className="commonHashtagDrawer_messageHeader">
                    <div className="headerTitle">Hashtag Details</div>
                </div>
                <div className="commonHashtagDrawer_header">
                    <h2>#{name}</h2>
                </div>

                <div className="commonHashtagDrawer_body">
                    <div className="commonHashtagDrawer_info">
                        <div className="commonHashtagDrawer_heading">
                            <InfoOutlinedIcon /> Info
                        </div>
                        <div className="commonHashtagDrawer_title">Topic(s)</div>
                        <ul className="commonHashtagDrawer_tagList">
                            {tagIdList.map((tagId) => (
                                <li key={tagId} className={apiTagList[tagId]?.name === 'emergency' ? 'commonHashtagDrawer_label emergency' : 'commonHashtagDrawer_label'}>
                                    <SellOutlined className="sidebar_sellIcon" />
                                    <span>{apiTagList[tagId]?.name}</span>
                                </li>
                            ))}
                        </ul>
                        <div className="commonHashtagDrawer_title mt8">
                            Description{' '}
                            <Button
                                variant="text"
                                onClick={() => {
                                    setIsEditDescriptionOpen(true);
                                }}
                            >
                                <Edit />
                            </Button>
                        </div>
                        <div className="commonHashtagDrawer_text">{description}</div>
                        <div className="commonHashtagDrawer_title mt8">
                            Admins{' '}
                            <Button
                                variant="text"
                                onClick={() => {
                                    setIsEditAdminUserIdListOpen(true);
                                }}
                            >
                                <Edit />
                            </Button>
                        </div>
                        <div className="commonHashtagDrawer_callouts">
                            {adminUserIdList.map((userId) => (
                                <div className="admin_hashtag_card" key={userId}>
                                    <UserAvatar size={18} userId={userId} displayOnlineIndicator={false} />
                                    &#64;<UserName userId={userId} />
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="commonHashtagDrawer_subscriber">
                        {requestApiUserHashtagList.length > 0 && (
                            <div className="commonHashtagDrawer_headingInner">
                                <div className="commonHashtagDrawer_headingInnerLeft">
                                    <TimerOutlined /> Pending Requests ({requestApiUserHashtagList.length})
                                </div>
                                <div className="commonHashtagDrawer_headingInnerRight">
                                    <Button variant="text" onClick={onApproveAllClicked}>
                                        <ThumbUpOutlined /> Approve All
                                    </Button>
                                </div>
                            </div>
                        )}
                        <ul className="commonList">
                            {requestApiUserHashtagList.map((apiUserHashtag) => (
                                <li key={apiUserHashtag.userHashtagId}>
                                    <div className="commonHashtagDrawer_nameAvatar" onClick={() => drawer.openUserProfile(apiUserHashtag.userId)}>
                                        <div>
                                            <UserAvatar userId={apiUserHashtag.userId} displayOnlineIndicator={false} />
                                        </div>
                                        <div className="commonHashtagDrawer_userName">
                                            <UserName userId={apiUserHashtag.userId} />
                                        </div>
                                    </div>
                                    <div className="commonHashtagDrawer_actions pending_action">
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                onApproveClicked(apiUserHashtag.userHashtagId);
                                            }}
                                        >
                                            <ThumbDownOutlined />
                                        </Button>
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                onDenyClicked(apiUserHashtag.userHashtagId);
                                            }}
                                        >
                                            <ThumbUpOutlined className="active" />
                                        </Button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div className="commonHashtagDrawer_subscriber mt24">
                        <div className="commonHashtagDrawer_headingInner">
                            <div className="commonHashtagDrawer_headingInnerLeft">
                                <PeopleOutline /> Subscribers ({subscriptionUserIdList.length})
                            </div>
                            <div className="commonHashtagDrawer_headingInnerRight">
                                <Button
                                    variant="text"
                                    onClick={() => {
                                        setIsInviteUsersOpen(true);
                                    }}
                                >
                                    <PersonAddAlt /> Invite
                                </Button>
                            </div>
                        </div>
                        <ul className="commonList">
                            {subscriptionUserIdList.map((userId) => (
                                <li key={userId}>
                                    <div className="commonHashtagDrawer_nameAvatar" onClick={() => drawer.openUserProfile(userId)}>
                                        <div>
                                            <UserAvatar userId={userId} displayOnlineIndicator={false} />
                                        </div>
                                        <div className="commonHashtagDrawer_userName">
                                            <UserName userId={userId} />
                                        </div>
                                    </div>
                                    <div className="commonHashtagDrawer_date">Jan 2 2021</div>
                                    <div className="commonHashtagDrawer_actions subscription_action">
                                        <div>{notificationUserIdList.includes(userId) ? <NotificationsActive /> : <NotificationsOffOutlined />}</div>
                                        <Button
                                            variant="text"
                                            onClick={() => {
                                                setUnsubscribeUserId(userId);
                                                setIsConfirmUnsubscribeOpen(true);
                                            }}
                                        >
                                            <RemoveCircleOutlineIcon />
                                        </Button>
                                    </div>
                                </li>
                            ))}
                        </ul>
                    </div>
                </div>
            </div>
            <Modal
                open={isEditDescriptionOpen}
                onClose={() => {
                    setIsEditDescriptionOpen(false);
                }}
            >
                <div className="commonModal_wrapper">
                    <div className="commonModal_header">
                        <span className="commonModal_headerText">Edit Description</span>
                    </div>
                    <div className="commonModal_body">
                        <div className="common_mentionWrap">
                            <textarea
                                onChange={(e) => {
                                    setDescription(e.target.value);
                                }}
                                value={description}
                                aria-label="Edit Description"
                            />
                        </div>
                    </div>
                    <div className="commonModal_footer">
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsEditDescriptionOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button color="primary" variant="contained" onClick={onDescriptionSaveClicked}>
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>
            <UserListModal
                isOpen={isEditAdminUserIdListOpen}
                initUserIdList={adminUserIdList}
                title="Edit Admins"
                onSaveCallback={onAdminUserIdListSaveClicked}
                onCancelCallback={() => setIsEditAdminUserIdListOpen(false)}
            />
            {hashtagId && (
                <>
                    <UserListModal
                        isOpen={isInviteUsersOpen}
                        title="Invite Users"
                        saveText="Send Invites"
                        prompt={
                            <>
                                Invite additional users to the hashtag
                                <Chip label={<Hashtag hashtagId={hashtagId} />} />
                                <br />
                                Subscriber Names
                            </>
                        }
                        onSaveCallback={onInviteUserIdListClicked}
                        onCancelCallback={() => setIsInviteUsersOpen(false)}
                        disableSubmitOnEmpty={true}
                    />
                    <ModalPrompt
                        isOpen={isConfirmUnsubscribeOpen}
                        title="Remove Subscriber"
                        image={remove}
                        prompt={
                            <>
                                Are you sure you want to remove{' '}
                                <strong>
                                    <UserName userId={unsubscribeUserId} />
                                </strong>{' '}
                                from #{apiHashtagList[hashtagId].name}?
                            </>
                        }
                        onYesCallback={onUnsubscribeClicked}
                        onCloseCallback={() => {
                            setUnsubscribeUserId(undefined);
                            setIsConfirmUnsubscribeOpen(false);
                        }}
                    />
                    <NotificationModal
                        isOpen={isConfirmApproveAllOpen}
                        title="Requests Approved"
                        imgSrc={greenCheck}
                        onCloseCallback={() => {
                            setIsConfirmApproveAllOpen(false);
                        }}
                        content={
                            <>
                                Users have been notified that their requests to subscribe to <strong>#{apiHashtagList[hashtagId].name}</strong> have been approved.
                            </>
                        }
                    />
                    <NotificationModal
                        isOpen={isInvitationsSentOpen}
                        title="Invitations Sent!"
                        imgSrc={invitationsSent}
                        onCloseCallback={() => {
                            setIsInvitationsSentOpen(false);
                            setInviteCount(0);
                        }}
                        content={
                            <>
                                Invitations to subscribe to <strong>#{apiHashtagList[hashtagId].name}</strong> have just been sent to {inviteCount} user(s).
                            </>
                        }
                    />
                </>
            )}
        </>
    );
});

export default HashtagInfo;
