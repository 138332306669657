import React from 'react';
import { PauseSharp, PlayArrowSharp } from '@mui/icons-material';
import { formatSizeUnits } from '../../_global/FileUtils';

interface AudioPlayerProp {
    fileName?: string;
    byteCount?: number;
    url?: string;
}

const AudioPlayer = React.memo<AudioPlayerProp>(({ fileName, byteCount, url }) => {
    const audioRef: any = React.useRef();
    const [isPlaying, setIsPlaying] = React.useState(false);
    const [currentTime, setCurrentTime] = React.useState('0:00');
    const [percentage, setPercentage] = React.useState(0);

    const playAudio = React.useCallback((play: boolean) => {
        const audio: any = audioRef.current;
        audio.volume = 1;
        if (!play) {
            setIsPlaying(true);
            audio.play();
        } else {
            setIsPlaying(false);
            audio.pause();
        }
    }, []);
    const updateVideoPlayTime = React.useCallback(
        (e) => {
            let playTime = e.currentTarget.currentTime;
            let timeRemaining = e.currentTarget.duration - playTime;
            const percent = ((e.currentTarget.currentTime / e.currentTarget.duration) * 100).toFixed(0);
            setPercentage(parseInt(percent));
            const time = (timeRemaining - (timeRemaining %= 60)) / 60 + (9 < timeRemaining ? ':' : ':0') + timeRemaining.toFixed(0);
            setCurrentTime(time);
        },
        []
    );
    const onChange = React.useCallback(
        (e) => {
            const audio: any = audioRef.current;
            let playTime = audio.currentTime;
            let timeRemaining = e.currentTarget.duration - playTime;
            const time = (timeRemaining - (timeRemaining %= 60)) / 60 + (9 < timeRemaining ? ':' : ':0') + timeRemaining.toFixed(0);
            audio.currentTime = (audio.duration / 100) * e.target.value;
            setCurrentTime(time);
            setPercentage(e.target.value);
        },
        []
    );
    return (
        <>
            <div className="audioPlayer">
                <div className="play_pause_button">
                    <button className="play_pause_button" onClick={() => playAudio(isPlaying)}>
                        {!isPlaying ? <PlayArrowSharp className="button_icons" /> : <PauseSharp className="button_icons" />}
                    </button>
                </div>
                <div className="name_progressbar">
                    <p className="file_name">{fileName}</p>
                    <p className="file_size">{formatSizeUnits(byteCount)}</p>
                    <div className="input_container">
                        <div className="input_container_range">
                            <div className="progress_bar" style={{ width: `${percentage / 1.74}%` }} />
                            <input type="range" className="audio_progressbar range" onChange={(e) => onChange(e)} value={percentage}></input>
                        </div>
                        <span className="file_size">{currentTime}</span>
                    </div>
                    <audio ref={audioRef} src={url} onTimeUpdate={(e) => updateVideoPlayTime(e)}></audio>
                </div>
            </div>
        </>
    );
});
export default AudioPlayer;
