import React from 'react';
import { LinkProps, NavLink } from 'react-router-dom';

const PathNavLink = React.memo<LinkProps & React.RefAttributes<HTMLAnchorElement>>((props) => {
    const [isActive, setIsActive] = React.useState(false);
    React.useEffect(() => {
        setIsActive(props.to === `${window.location.pathname}${window.location.search}`);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.to, window.location.search, window.location.pathname]);
    return (
        <NavLink
            {...props}
            className={() => {
                return isActive ? 'active' : '';
            }}
        />
    );
});

export default PathNavLink;
