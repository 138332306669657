import axios, { AxiosInstance, AxiosResponse, CancelTokenSource } from 'axios';
import ObjectID from 'bson-objectid';
import React from 'react';
import { getFileThumb, isPreviewableImageType } from '../_global/FileUtils';
import { useApi, GetUrlResponse } from './Api';

export enum FILE_STATE_TYPE {
    NEW = 'NEW',
    READY_FOR_UPLOAD = 'READY_FOR_UPLOAD',
    UPLOADING = 'UPLOADING',
    UPLOADED_TO_S3 = 'UPLOADED_TO_S3',
    UPLOADED = 'UPLOADED',
    ERROR = 'ERROR',
    CANCELED = 'CANCELED'
}

interface FileUpload {
    upload: (file: File, messageThreadId: string, messageId: string) => void;
    fileInTransitList: Array<FileInTransit>;
    clear: (messageId: string) => void;
    setMessageSent: (messageId: string) => void;
    cancel: (fileId: string) => void;
}

export interface FileInTransit {
    file: File;
    fileId: string;
    messageThreadId: string;
    messageId?: string;
    byteCount: number;
    uploadedByteCount: number;
    name: string;
    contentType: string;
    thumbUrl?: string;
    uploadUrl?: string;
    cancelToken?: CancelTokenSource;
    stateType: FILE_STATE_TYPE;
    messageSent: boolean;
}

const FileUploadContext = React.createContext<FileUpload>({
    fileInTransitList: [],
    upload: () => {},
    clear: () => {},
    setMessageSent: () => {},
    cancel: () => {}
});

export interface FileUploadProps {
    children?: React.ReactNode;
}

export const FileUploadProvider = React.memo<FileUploadProps>((fileUploadProps) => {
    const api = useApi();
    const fileUploadAxios = React.useMemo<AxiosInstance>(() => {
        return axios.create();
    }, []);
    const [fileInTransitList, setFileInTransitList] = React.useState<Array<FileInTransit>>([]);
    const updateFileInTransitState = React.useCallback((fileId: string, fileStateType: FILE_STATE_TYPE) => {
        setFileInTransitList((state) => [
            ...state.map((obj) => {
                if (obj.fileId === fileId) {
                    obj.stateType = fileStateType;
                }
                return obj;
            })
        ]);
    }, []);
    const getImageDataUrl = React.useCallback((file: Blob, maxSize: number, callback: Function) => {
        if (isPreviewableImageType(file.type)) {
            const fileReader = new FileReader();
            fileReader.onload = (e) => {
                if (e.target && e.target.result) {
                    var img = document.createElement('img');
                    img.src = e.target.result as string;
                    img.onload = () => {
                        var canvas = document.createElement('canvas');
                        var ctx = canvas.getContext('2d');
                        if (ctx) {
                            ctx.drawImage(img, 0, 0);
                            var width = img.width;
                            var height = img.height;
                            if (width > height) {
                                if (width > maxSize) {
                                    height *= maxSize / width;
                                    width = maxSize;
                                }
                            } else {
                                if (height > maxSize) {
                                    width *= maxSize / height;
                                    height = maxSize;
                                }
                            }
                            canvas.width = width;
                            canvas.height = height;
                            ctx = canvas.getContext('2d');
                            if (ctx) {
                                ctx.drawImage(img, 0, 0, width, height);
                                const dataUrl = canvas.toDataURL('image/png');
                                callback(dataUrl);
                            }
                        }
                    };
                }
            };
            fileReader.readAsDataURL(file);
        } else {
            callback(getFileThumb(file.type));
        }
    }, []);
    const fileUpload = React.useMemo<FileUpload>(() => {
        return {
            fileInTransitList: fileInTransitList,
            upload: (file, messageThreadId, messageId) => {
                const fileId = ObjectID().toHexString();
                const fileInTransit: FileInTransit = {
                    file: file,
                    fileId: fileId,
                    messageThreadId: messageThreadId,
                    messageId: messageId,
                    byteCount: file.size,
                    uploadedByteCount: 0,
                    name: file.name,
                    contentType: file.type,
                    stateType: FILE_STATE_TYPE.NEW,
                    messageSent: false
                };
                getImageDataUrl(file, 200, (dataUrl: string) => {
                    fileInTransit.thumbUrl = dataUrl;
                    setFileInTransitList((state) => [...state, fileInTransit]);
                    api.getUrl(
                        {
                            fileId: fileId,
                            contentType: file.type,
                            method: 'PUT',
                            name: file.name
                        },
                        (axiosResponse: AxiosResponse) => {
                            const getUrlResponse: GetUrlResponse = axiosResponse.data;
                            setFileInTransitList((state) => [
                                ...state.map((obj) => {
                                    if (obj.fileId === fileInTransit.fileId) {
                                        return { ...obj, uploadUrl: getUrlResponse.url, cancelToken: axios.CancelToken.source(), stateType: FILE_STATE_TYPE.READY_FOR_UPLOAD };
                                    }
                                    return obj;
                                })
                            ]);
                        }
                    );
                });
            },
            clear: (messageId) => {
                setFileInTransitList((state) => [
                    ...state.filter((fileInTransit) => {
                        return fileInTransit.messageId !== messageId;
                    })
                ]);
            },
            setMessageSent: (messageId) => {
                setFileInTransitList((state) => [
                    ...state.map((fileInTransit) => {
                        if (fileInTransit.messageId === messageId) {
                            return { ...fileInTransit, messageSent: true };
                        } else return fileInTransit;
                    })
                ]);
            },
            cancel: (fileId) => {
                const fileInTransit = fileInTransitList.find((obj) => {
                    return obj.fileId === fileId;
                });
                if (fileInTransit && fileInTransit.cancelToken) {
                    fileInTransit.cancelToken.cancel('Upload cancelled by user');
                }
                api.deleteFile({ fileId: fileId });
                setFileInTransitList((state) => [
                    ...state.filter((fileInTransit) => {
                        return fileInTransit.fileId !== fileId;
                    })
                ]);
            }
        };
    }, [api, fileInTransitList, getImageDataUrl]);
    React.useEffect(() => {
        for (let i = 0; i < fileInTransitList.length; i++) {
            const fileInTransit = [...fileInTransitList][i];
            switch (fileInTransit.stateType) {
                case FILE_STATE_TYPE.READY_FOR_UPLOAD: {
                    if (fileInTransit.uploadUrl && fileInTransit.cancelToken) {
                        updateFileInTransitState(fileInTransit.fileId, FILE_STATE_TYPE.UPLOADING);
                        fileUploadAxios
                            .put(fileInTransit.uploadUrl, fileInTransit.file, {
                                headers: {
                                    'Content-Type': fileInTransit.file.type
                                },
                                onUploadProgress: (progressEvent: { loaded: number; total: number }) => {
                                    setFileInTransitList((state) => [
                                        ...state.map((obj) => {
                                            if (obj.fileId === fileInTransit.fileId) {
                                                obj.byteCount = progressEvent.total;
                                                obj.uploadedByteCount = progressEvent.loaded;
                                            }
                                            return obj;
                                        })
                                    ]);
                                },
                                cancelToken: fileInTransit.cancelToken.token
                            })
                            .then(() => {
                                updateFileInTransitState(fileInTransit.fileId, FILE_STATE_TYPE.UPLOADED_TO_S3);
                                api.fileUploaded(
                                    {
                                        fileId: fileInTransit.fileId
                                    },
                                    () => {
                                        updateFileInTransitState(fileInTransit.fileId, FILE_STATE_TYPE.UPLOADED);
                                    }
                                );
                            });
                    }
                    break;
                }
                case FILE_STATE_TYPE.CANCELED: {
                    break;
                }
                case FILE_STATE_TYPE.ERROR: {
                    break;
                }
                case FILE_STATE_TYPE.UPLOADED: {
                    break;
                }
            }
        }
    }, [api, fileInTransitList, fileUploadAxios, updateFileInTransitState]);
    return <FileUploadContext.Provider value={fileUpload}>{fileUploadProps.children}</FileUploadContext.Provider>;
});

export const useFileUpload = () => React.useContext(FileUploadContext);
