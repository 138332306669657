import React from 'react';
import { useSelector } from '../../_redux/_Store';

interface CalloutProps {
    userId: string;
}

const Callout = React.memo<CalloutProps>(({ userId }) => {
    const apiUser = useSelector((state) => state.data.apiUserList[userId]);
    return <>{apiUser && <span className="linkText">@{apiUser.callout}</span>}</>;
});

export default Callout;
