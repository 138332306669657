import { createSelectorHook } from 'react-redux';
import { combineReducers, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { DataReducer } from './DataReducer';
import { TypingReducer } from './TypingReducer';
import { WebSocketReducer } from './WebSocketReducer';

export type IRootState = ReturnType<typeof rootReducer>;
export const useSelector = createSelectorHook<IRootState>();

const rootReducer = combineReducers({
    data: DataReducer,
    typing: TypingReducer,
    webSocket: WebSocketReducer
});

const store = createStore(rootReducer, composeWithDevTools());

export default store;
