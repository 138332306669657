export enum LAYOUT_TYPE {
    AUTH = 'auth',
    LOADING = 'loading',
    INBOX = 'inbox'
}

export enum SCREEN_TYPE {
    MAIN = 'main',
    SEARCH = 'search',
    DRAFTS = 'drafts',
    STARRED = 'starred',
    CONTACTS = 'contacts',
    SUBSCRIPTIONS = 'subscriptions',
    HASHTAGS = 'hashtags',
    TAGS = 'tags'
}

export enum INBOX_PARAM_TYPE {
    MESSAGE_THREAD_ID = 'MESSAGE_THREAD_ID'
}

export enum SUBSCRIPTION_PARAM_TYPE {
    HASHTAG_ID = 'HASHTAG_ID'
}

export enum HASHTAGS_PARAM_TYPE {
    HASHTAG_ID = 'HASHTAG_ID'
}

export enum TAGS_PARAM_TYPE {
    TAG_ID = 'TAG_ID'
}

export enum QUERY_STRING_TYPE {
    FILE_ID = 'fid',
    FILTER = 'filter',
    MESSAGE_THREAD_SEARCH_ID = 'mtsid',

    //DRAWER
    DRAWER_TYPE = 'dt',
    MESSAGE_THREAD_ID = 'mtid',
    MESSAGE_ID = 'mid',
    USER_ID = 'uid',
    TAG_ID = 'tid'
}
