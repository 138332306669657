import React from 'react';
import { ApiMessageThreadObject } from '../../_api/_ApiModels';
import MessageThreadObjectUserHashtag from './messageThreadObjects/MessageThreadObjectUserHashtag';

interface MessageThreadObjectProps {
    apiMessageThreadObject?: ApiMessageThreadObject;
}

const MessageThreadObject = React.memo<MessageThreadObjectProps>(({ apiMessageThreadObject }) => {
    return <>{apiMessageThreadObject ? <>{apiMessageThreadObject.apiUserHashtag && <MessageThreadObjectUserHashtag apiUserHashtag={apiMessageThreadObject.apiUserHashtag} />}</> : <></>}</>;
});

export default MessageThreadObject;
