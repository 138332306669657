import React from 'react';
import { Avatar } from '@mui/material';
import { ApiUser } from '../../_api/_ApiModels';
import { getCloudfrontUrl, getInitials } from '../../_global/Helpers';
import { useSelector } from '../../_redux/_Store';
import { AvatarColor, AVATAR_COLOR_LIST } from '../../_global/Constants';
import { Circle } from '@mui/icons-material';

interface UserAvatarProps {
    userId: string;
    size?: number;
    displayOnlineIndicator?: boolean;
}

const UserAvatar = React.memo<UserAvatarProps>(({ userId, size = 41, displayOnlineIndicator = true }) => {
    const cloudfrontUrl = useSelector((state) => state.data.apiOrganization?.cloudfrontUrl);
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const [apiUser, setApiUser] = React.useState<ApiUser>();
    const [url, setUrl] = React.useState<string>();
    const [initials, setInitials] = React.useState<string>('');
    const [isOnline, setIsOnline] = React.useState(false);
    const [fontSize] = React.useState(size / 2);
    const [avatarColor, setAvatarColor] = React.useState<AvatarColor>(AVATAR_COLOR_LIST[0]);
    React.useEffect(() => {
        if (userId) {
            const tempApiUser = apiUserList[userId];
            if (tempApiUser) {
                setApiUser(tempApiUser);
                if (cloudfrontUrl && tempApiUser.avatarKey) {
                    setUrl(getCloudfrontUrl(cloudfrontUrl, tempApiUser.avatarKey));
                }
                const tempInitials = getInitials(tempApiUser);
                setInitials(tempInitials);
                if (tempInitials.length > 0) {
                    let index = 0;
                    if (tempInitials.length === 2) {
                        index = tempInitials.charCodeAt(0) + tempInitials.charCodeAt(1);
                    } else if (tempInitials.length === 1) {
                        index = tempInitials.charCodeAt(0);
                    }
                    setAvatarColor(AVATAR_COLOR_LIST[index % AVATAR_COLOR_LIST.length]);
                }
                setIsOnline(tempApiUser.online);
            }
        }
    }, [cloudfrontUrl, apiUserList, userId]);
    return (
        <div className="userAvatar_avatar">
            {displayOnlineIndicator ? (
                <>
                    {url ? (
                        <Avatar alt="user avatar" src={url} sx={{ width: size, height: size }} />
                    ) : (
                        apiUser && <Avatar sx={{ width: size, height: size, bgcolor: avatarColor.background, color: avatarColor.text, fontSize: { fontSize } }}>{initials}</Avatar>
                    )}
                    <span className={isOnline ? 'userAvatar_onlineIndicator' : 'userAvatar_offlineIndicator'}>{isOnline ? <Circle sx={{ fontSize: 14, color: '#33cc33' }} /> : null}</span>
                </>
            ) : (
                <>
                    {url ? (
                        <Avatar alt="user avatar" src={url} sx={{ width: size, height: size }} />
                    ) : (
                        apiUser && <Avatar sx={{ width: size, height: size, bgcolor: avatarColor.background, color: avatarColor.text, fontSize: { fontSize } }}>{initials}</Avatar>
                    )}
                </>
            )}
        </div>
    );
});

export default UserAvatar;
