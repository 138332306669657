export const getApiUrl = () => {
    let url = '';
    if (process.env.REACT_APP_API_URL) {
        url = process.env.REACT_APP_API_URL;
    }
    return url;
}

export const getWebSocketUrl = () => {
    let url = '';
    if (process.env.REACT_APP_WEBSOCKET_URL) {
        url = process.env.REACT_APP_WEBSOCKET_URL;
    }
    return url;
}

export const getGiphyApiKey = () => {
    let url = '';
    if (process.env.REACT_APP_GIPHY_API_KEY) {
        url = process.env.REACT_APP_GIPHY_API_KEY;
    }
    return url;
}
