import React from 'react';
import { Modal, Typography, Button } from '@mui/material';
import UserAutocomplete from './UserAutocomplete';

interface UserListModalProps {
    isOpen: boolean;
    initUserIdList?: Array<string>;
    title: string;
    prompt?: string | React.ReactNode;
    saveText?: string;
    cancelText?: string;
    onSaveCallback: Function;
    onCancelCallback: Function;
    disableSubmitOnEmpty?: boolean;
}

const UserListModal = React.memo<UserListModalProps>(
    ({ isOpen, initUserIdList = [], title, prompt, saveText = 'Save', cancelText = 'Cancel', onSaveCallback, onCancelCallback, disableSubmitOnEmpty = false }) => {
        const [userIdList, setUserIdList] = React.useState<Array<string>>(initUserIdList);
        const [isSaveDisabled, setIsSaveDisabled] = React.useState<boolean>(false);
        React.useEffect(() => {
            if (!disableSubmitOnEmpty) {
                setIsSaveDisabled(false);
                return;
            }
            setIsSaveDisabled(userIdList.length === 0);
        }, [disableSubmitOnEmpty, userIdList.length]);
        return (
            <Modal
                open={isOpen}
                onClose={() => {
                    onCancelCallback();
                }}
            >
                <div className="commonModal_wrapper">
                    <div className="commonModal_header">
                        <span className="commonModal_headerText">{title}</span>
                    </div>
                    {prompt && (
                        <Typography id="transition-modal-title" variant="body1" component="p" className="textCenter">
                            {prompt}
                        </Typography>
                    )}
                    <UserAutocomplete setUserIdList={setUserIdList} initUserIdList={initUserIdList} />
                    <div className="commonModal_footer">
                        <Button color="primary" variant="contained" className="ml16" onClick={() => onSaveCallback(userIdList)} disabled={isSaveDisabled}>
                            {saveText}
                        </Button>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => {
                                onCancelCallback();
                            }}
                        >
                            {cancelText}
                        </Button>
                    </div>
                </div>
            </Modal>
        );
    }
);

export default UserListModal;
