import React from 'react';
import { useSelector } from '../../_redux/_Store';
import HashtagTooltip from './HashtagTooltip';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import { MESSAGE_THREAD_SEARCH_FILTER_TYPE } from '../../_api/_ApiModels';
import { useNavigate } from 'react-router-dom';
import HoverPopover from 'material-ui-popup-state/HoverPopover';
import PopupState, { bindHover, bindPopover } from 'material-ui-popup-state';

interface HashtagProps {
    hashtagId: string;
}

const Hashtag = React.memo<HashtagProps>(({ hashtagId }) => {
    const messageThreadFilter = useMessageThreadFilter();
    const navigate = useNavigate();
    const apiHashtag = useSelector((state) => state.data.apiHashtagList[hashtagId]);
    const hashtagFilterApplied = React.useCallback(() => {
        navigate(
            messageThreadFilter.getUrl([
                {
                    type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.HASHTAG_ID_LIST,
                    value: [hashtagId]
                }
            ])
        );
    }, [hashtagId, messageThreadFilter, navigate]);
    return (
        <>
            {apiHashtag && (
                <>
                    <PopupState variant="popover" popupId={`hashtag-${hashtagId}`}>
                        {(popupState) => (
                            <>
                                <span className="linkText" {...bindHover(popupState)}>
                                    #{apiHashtag.name}
                                </span>
                                <HoverPopover
                                    {...bindPopover(popupState)}
                                    className="commonPopover"
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'center'
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'center'
                                    }}
                                >
                                    <HashtagTooltip apiHashtag={apiHashtag} onClickHandler={hashtagFilterApplied} />
                                </HoverPopover>
                            </>
                        )}
                    </PopupState>
                </>
            )}
        </>
    );
});

export default Hashtag;
