import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import Auth from './components/auth/Auth';
import { INBOX_PARAM_TYPE, SCREEN_TYPE, LAYOUT_TYPE, SUBSCRIPTION_PARAM_TYPE, HASHTAGS_PARAM_TYPE, TAGS_PARAM_TYPE } from './_global/Route';
import Loading from './components/Loading';
import { useApi } from './providers/Api';
import { useDispatch } from 'react-redux';
import { clear, setBootstrap, setHasFocus, setIsOnline } from './_redux/DataReducer';
import { useAuth } from './providers/Auth';
import { useSelector } from './_redux/_Store';
import { AxiosError, AxiosResponse } from 'axios';
import Inbox from './components/inbox/Inbox';
import { useWebSocket } from './providers';
import MessageThread from './components/inbox/MessageThread';
import SearchMessageThreadList from './components/inbox/SearchMessageThreadList';
import InboxMessageThreadList from './components/inbox/InboxMessageThreadList';
import { useNotification } from './providers/Notification';
import Subscriptions from './components/subscriptions/Subscriptions';
import UserHashtagInfo from './components/subscriptions/UserHashtagInfo';
import HashtagInfo from './components/hashtags/HashtagInfo';
import ManageHashtags from './components/hashtags/ManageHashtags';
import TagInfo from './components/hashtags/TagInfo';

export default function App() {
    const api = useApi();
    const auth = useAuth();
    const webSocket = useWebSocket();
    const notification = useNotification();
    const dispatch = useDispatch();
    const isBootstrapped = useSelector((state) => state.data.isBootstrapped);
    const bootstrap = React.useCallback(() => {
        api.bootstrap(
            {},
            (axiosResponse: AxiosResponse) => {
                dispatch(setBootstrap(axiosResponse.data));
            },
            (axiosError: AxiosError) => {
                if (axiosError.response) {
                    if (axiosError.response.status === 401) {
                        auth.clearToken();
                        dispatch(clear());
                    }
                }
            }
        );
    }, [api, auth, dispatch]);
    const onWindowFocus = React.useCallback(() => {
        dispatch(setHasFocus(true));
    }, [dispatch]);
    const onWindowBlur = React.useCallback(() => {
        dispatch(setHasFocus(false));
    }, [dispatch]);
    const onIsOnline = React.useCallback(() => {
        dispatch(setIsOnline(true));
    }, [dispatch]);
    const onIsOffline = React.useCallback(() => {
        dispatch(setIsOnline(false));
    }, [dispatch]);
    //TODO: Need to figure out how to handle to prevent double-bootstrapping
    React.useEffect(() => {
        if (!auth.isTokenSet || isBootstrapped) return;
        bootstrap();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isBootstrapped, auth.isTokenSet]);
    React.useEffect(() => {
        webSocket.addOnConnectCallback(bootstrap);
        return () => {
            webSocket.removeOnConnectCallback(bootstrap);
        };
    }, [bootstrap, webSocket]);
    React.useEffect(() => {
        notification.requestPermission();
    }, [notification]);
    React.useEffect(() => {
        if (window.location.pathname.startsWith(`/${LAYOUT_TYPE.INBOX}`)) {
            document.title = `Inbox`;
        } else {
            document.title = `Cake`;
        }
    }, []);
    React.useEffect(() => {
        window.addEventListener('focus', onWindowFocus);
        window.addEventListener('blur', onWindowBlur);
        window.addEventListener('online', onIsOnline);
        window.addEventListener('offline', onIsOffline);
        return () => {
            window.removeEventListener('focus', onWindowFocus);
            window.removeEventListener('blur', onWindowBlur);
            window.addEventListener('online', onIsOnline);
            window.addEventListener('offline', onIsOffline);
        };
    }, [onIsOffline, onIsOnline, onWindowBlur, onWindowFocus]);
    return (
        <BrowserRouter>
            <Routes>
                {!auth.getToken() ? (
                    <Route path="/*" element={<Navigate replace to={`/${LAYOUT_TYPE.AUTH}`} />} />
                ) : !isBootstrapped ? (
                    <Route path="/*" element={<Loading />} />
                ) : (
                    <>
                        <Route path="/" element={<Navigate replace to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.MAIN}`} />} />
                        <Route path={`/${LAYOUT_TYPE.LOADING}`} element={<Navigate replace to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.MAIN}`} />} />
                        <Route path={`/${LAYOUT_TYPE.INBOX}`} element={<Navigate replace to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.MAIN}`} />} />

                        <Route path={`/${LAYOUT_TYPE.INBOX}`} element={<Inbox />}>
                            <Route path={`${SCREEN_TYPE.MAIN}`} element={<InboxMessageThreadList />}>
                                <Route path={`:${INBOX_PARAM_TYPE.MESSAGE_THREAD_ID}`} element={<MessageThread />} />
                            </Route>
                            <Route path={`${SCREEN_TYPE.SUBSCRIPTIONS}`} element={<Subscriptions />}>
                                <Route path={`:${SUBSCRIPTION_PARAM_TYPE.HASHTAG_ID}`} element={<UserHashtagInfo />} />
                            </Route>
                            <Route path={`${SCREEN_TYPE.HASHTAGS}`} element={<ManageHashtags isHashtags={true} />}>
                                <Route path={`:${HASHTAGS_PARAM_TYPE.HASHTAG_ID}`} element={<HashtagInfo />} />
                            </Route>
                            <Route path={`${SCREEN_TYPE.TAGS}`} element={<ManageHashtags isHashtags={false} />}>
                                <Route path={`:${TAGS_PARAM_TYPE.TAG_ID}`} element={<TagInfo />} />
                            </Route>
                            <Route path={`${SCREEN_TYPE.SEARCH}`} element={<SearchMessageThreadList />}>
                                <Route path={`:${INBOX_PARAM_TYPE.MESSAGE_THREAD_ID}`} element={<MessageThread />} />
                            </Route>
                        </Route>
                        {/*
                            <Route path={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.DRAFTS}`} element={<Inbox />} />
                            <Route path={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.DRAFTS}/:${INBOX_PARAM_TYPE.MESSAGE_THREAD_ID}`} element={<Inbox />} />
                            */}
                        {/*The below route needs to always be last. This will redirect any unknown route back to the inbox*/}
                        <Route path="/*" element={<Navigate replace to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.MAIN}`} />} />
                    </>
                )}
                <Route path={`/${LAYOUT_TYPE.AUTH}`} element={<Auth />} />
            </Routes>
        </BrowserRouter>
    );
}
