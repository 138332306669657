import React from 'react';
import { useSelector } from '../../_redux/_Store';
import { ApiTag, ApiUser, MESSAGE_THREAD_SEARCH_FILTER_TYPE } from '../../_api/_ApiModels';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import PathNavLink from './PathNavLink';
import { sortApiTagCategoryList } from '../../_global/Helpers';

interface SideNavApiTagCategory {
    tagCategoryId: string;
    name: string;
    apiTagList: Array<ApiTag>;
}

const UserTagList = React.memo(() => {
    const messageThreadFilter = useMessageThreadFilter();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const apiHashtagList = useSelector((state) => state.data.apiHashtagList);
    const apiTagList = useSelector((state) => state.data.apiTagList);
    const apiTagCategoryList = useSelector((state) => state.data.apiTagCategoryList);
    const [sideNavApiTagCategoryList, setSideNavApiTagCategoryList] = React.useState<Array<SideNavApiTagCategory>>([]);
    const [loggedInApiUser, setLoggedInApiUser] = React.useState<ApiUser>();
    React.useEffect(() => {
        if (!loggedInUserId) {
            return;
        }
        setLoggedInApiUser(apiUserList[loggedInUserId]);
    }, [apiUserList, loggedInUserId]);
    React.useEffect(() => {
        if (!loggedInApiUser) {
            return;
        }
        const apiTagIdList = [] as Array<string>;
        Object.values(apiHashtagList)
            .filter((obj) => {
                return obj.notificationUserIdList.includes(loggedInApiUser.userId) || obj.subscriptionUserIdList.includes(loggedInApiUser.userId);
            })
            .forEach((obj) => {
                for (let i = 0; i < obj.tagIdList.length; i++) {
                    const tagId = obj.tagIdList[i];
                    if (!apiTagIdList.includes(tagId)) {
                        apiTagIdList.push(tagId);
                    }
                }
            });
        const tempApiTagList = [] as Array<ApiTag>;
        for (let i = 0; i < apiTagIdList.length; i++) {
            const apiTag = apiTagList[apiTagIdList[i]];
            tempApiTagList.push(apiTag);
        }
        const sortedApiTagCategoryList = sortApiTagCategoryList(apiTagCategoryList, loggedInApiUser.sideNavTagCategoryIdList);
        const tempSideNavApiTagCategoryList = [] as Array<SideNavApiTagCategory>;
        for (let i = 0; i < sortedApiTagCategoryList.length; i++) {
            const apiTagCategory = sortedApiTagCategoryList[i];
            const apiTagCategoryApiTagList = tempApiTagList.filter((obj) => obj.tagCategoryId === apiTagCategory.tagCategoryId).sort((a, b) => a.name.localeCompare(b.name));
            if (apiTagCategoryApiTagList.length > 0) {
                tempSideNavApiTagCategoryList.push({
                    tagCategoryId: apiTagCategory.tagCategoryId,
                    name: apiTagCategory.name,
                    apiTagList: apiTagCategoryApiTagList
                });
            }
        }
        setSideNavApiTagCategoryList(tempSideNavApiTagCategoryList);
    }, [apiTagList, apiHashtagList, apiTagCategoryList, loggedInApiUser]);
    return (
        <React.Fragment>
            <ul className="subscription_labelList">
                {sideNavApiTagCategoryList.map((sideNavApiTagCategory, index) => (
                    <React.Fragment key={sideNavApiTagCategory.tagCategoryId}>
                        {index > 0 && <div className="dashedBorder" />}
                        {sideNavApiTagCategory.apiTagList.map((apiTag) => (
                            <PathNavLink
                                key={apiTag.tagId}
                                to={messageThreadFilter.getUrl([
                                    {
                                        type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.TAG_ID_LIST,
                                        value: [apiTag.tagId]
                                    }
                                ])}
                            >
                                <li>
                                    {/* <SellOutlined className="subscription_tagIcon" /> */}
                                    <span className="subscription_label">{apiTag.name}</span>
                                </li>
                            </PathNavLink>
                        ))}
                    </React.Fragment>
                ))}
            </ul>
        </React.Fragment>
    );
});

export default UserTagList;
