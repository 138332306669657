import React from 'react';
import { ApiFile } from '../../../_api/_ApiModels';
import { getCloudfrontUrl } from '../../../_global/Helpers';
import { FILE_IMAGE_TYPE } from '../../../_global/_Enums';
import { useSelector } from '../../../_redux/_Store';

interface ImageViewerProps {
    apiFile: ApiFile;
}

const ImageViewer = React.memo<ImageViewerProps>(({ apiFile }) => {
    const cloudfrontUrl = useSelector((state) => state.data.apiOrganization?.cloudfrontUrl);
    const [isLoaded, setIsLoaded] = React.useState(false);
    const [tempImageUrl, setTempImageUrl] = React.useState('');
    const [imageUrl, setImageUrl] = React.useState('');
    React.useEffect(() => {
        if (cloudfrontUrl) {
            const apiFileImage = apiFile.imageList.find((obj) => {
                return obj.fileImageType === FILE_IMAGE_TYPE.MESSAGE_LIST;
            });
            if (apiFileImage) {
                setTempImageUrl(getCloudfrontUrl(cloudfrontUrl, apiFileImage.key));
            }
            setImageUrl(getCloudfrontUrl(cloudfrontUrl, apiFile.key));
        }
    }, [apiFile.imageList, apiFile.key, cloudfrontUrl]);
    return (
        <>
            {imageUrl && tempImageUrl && (
                <div className="imageViewer_highlowWrapper">
                    {/* <div className="imageViewer_blurBack" style={{ backgroundImage: url(tempImageUrl) }}></div> */}
                    <img src={tempImageUrl} alt={apiFile.displayName} style={{ opacity: isLoaded ? '0' : '1', filter: 'blur(2px)', transition: 'opacity 500ms ease-in 50ms' }} height={'100%'} />
                    <img
                        src={imageUrl}
                        alt={apiFile.displayName}
                        onLoad={() => {
                            setIsLoaded(true);
                        }}
                        style={{ opacity: isLoaded ? '1' : '0', transition: 'opacity 500ms ease-out 50ms' }}
                        height={'100%'}
                    />
                </div>
            )}
        </>
    );
});

export default ImageViewer;
