import React from 'react';
import { useDrawer } from '../../providers/Drawer';
import CloseIcon from '@mui/icons-material/Close';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select, TextField, ToggleButton } from '@mui/material';
import { AlternateEmailOutlined, AttachFileOutlined, StarOutlineOutlined, MarkEmailUnreadOutlined, CalendarToday, Tag, EmailOutlined, Sync, SellOutlined, SendOutlined } from '@mui/icons-material';
import InboxOff from '../../assets/images/icons/inbox_slash.svg';
import ManageSubscriptionIcon from '../../assets/images/subscriptions.svg';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment, { Moment } from 'moment';
import { useSelector } from '../../_redux/_Store';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import { DATE_RANGE_TYPE, MESSAGE_THREAD_SEARCH_FILTER_TYPE } from '../../_api/_ApiModels';

const MessageThreadFilters = React.memo(() => {
    const drawer = useDrawer();
    const messageThreadFilter = useMessageThreadFilter();
    const apiTagList = useSelector((state) => state.data.apiTagList);
    const apiHashtagList = useSelector((state) => state.data.apiHashtagList);
    const [isNotSeenSelected, setIsNotSeenSelected] = React.useState(false);
    const [isStarredSelected, setIsStarredSelected] = React.useState(false);
    const [hasAttachmentsSelected, setHasAttachmentsSelected] = React.useState(false);
    const [isCalledOutSelected, setIsCalledOutSelected] = React.useState(false);
    const [isNotInboxSelected, setIsNotInboxSelected] = React.useState(false);
    const [isSentByUserSelected, setIsSentByUserSelected] = React.useState(false);
    const [isUserHashtag, setIsUserHashtag] = React.useState(false);
    const [dateRangeType, setDateRangeType] = React.useState<DATE_RANGE_TYPE | ''>('');
    const [startDate, setStartDate] = React.useState<Moment | null>(null);
    const [endDate, setEndDate] = React.useState<Moment | null>(null);
    const [selectedTagIdList, setSelectedTagIdList] = React.useState<Array<string>>([]);
    const [selectedHashtagIdList, setSelectedHashtagIdList] = React.useState<Array<string>>([]);
    const [isFilterSelected, setIsFilterSelected] = React.useState(false);
    const resetFilters = React.useCallback(() => {
        setIsNotSeenSelected(false);
        setIsStarredSelected(false);
        setHasAttachmentsSelected(false);
        setIsCalledOutSelected(false);
        setDateRangeType('');
        setStartDate(null);
        setEndDate(null);
        setSelectedTagIdList([]);
        setSelectedHashtagIdList([]);
        setIsNotInboxSelected(false);
        setIsSentByUserSelected(false);
        setIsUserHashtag(false);
    }, []);
    const applyFilters = React.useCallback(() => {
        const newFilterList = messageThreadFilter.filterList ? messageThreadFilter.filterList.filter((obj) => obj.type === MESSAGE_THREAD_SEARCH_FILTER_TYPE.USER_ID_LIST) : [];
        const text = messageThreadFilter.filterList?.find((obj) => obj.type === MESSAGE_THREAD_SEARCH_FILTER_TYPE.TEXT);
        if (text) {
            newFilterList.push({
                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.TEXT,
                value: text.value
            });
        }
        if (isNotSeenSelected === true) {
            newFilterList.push({
                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_NOT_SEEN,
                value: true
            });
        }
        if (isStarredSelected === true) {
            newFilterList.push({
                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_STARRED,
                value: true
            });
        }
        if (hasAttachmentsSelected === true) {
            newFilterList.push({
                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.HAS_ATTACHMENTS,
                value: true
            });
        }
        if (isCalledOutSelected === true) {
            newFilterList.push({
                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_CALLED_OUT,
                value: true
            });
        }
        if (isNotInboxSelected === true) {
            newFilterList.push({
                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_NOT_INBOX,
                value: true
            });
        }
        if (isSentByUserSelected === true) {
            newFilterList.push({
                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_SENT_BY_USER,
                value: true
            });
        }
        if (isUserHashtag === true) {
            newFilterList.push({
                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_USER_HASHTAG,
                value: true
            });
        }
        if (dateRangeType !== '') {
            newFilterList.push({
                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.DATE_RANGE_TYPE,
                value: dateRangeType
            });
        }
        if (startDate) {
            newFilterList.push({
                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.START_DATE,
                value: startDate.valueOf()
            });
        }
        if (endDate) {
            newFilterList.push({
                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.END_DATE,
                value: endDate.valueOf()
            });
        }
        if (selectedTagIdList.length > 0) {
            newFilterList.push({
                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.TAG_ID_LIST,
                value: selectedTagIdList
            });
        }
        if (selectedHashtagIdList.length > 0) {
            newFilterList.push({
                type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.HASHTAG_ID_LIST,
                value: selectedHashtagIdList
            });
        }
        messageThreadFilter.navigateToSearch(newFilterList);
    }, [
        dateRangeType,
        endDate,
        hasAttachmentsSelected,
        isCalledOutSelected,
        isNotInboxSelected,
        isNotSeenSelected,
        isSentByUserSelected,
        isStarredSelected,
        isUserHashtag,
        messageThreadFilter,
        selectedHashtagIdList,
        selectedTagIdList,
        startDate
    ]);
    const onTagCheckChanged = React.useCallback((tagId: string, checked: boolean) => {
        if (checked) {
            setSelectedTagIdList((prevState) => [...prevState, tagId]);
            return;
        }
        setSelectedTagIdList((prevState) => prevState.filter((obj) => obj !== tagId));
    }, []);
    const onHashtagCheckChanged = React.useCallback((hashtagId: string, checked: boolean) => {
        if (checked) {
            setSelectedHashtagIdList((prevState) => [...prevState, hashtagId]);
            return;
        }
        setSelectedHashtagIdList((prevState) => prevState.filter((obj) => obj !== hashtagId));
    }, []);
    const onDateRangeTypeChanged = React.useCallback((dateRangeType: DATE_RANGE_TYPE) => {
        setDateRangeType(dateRangeType);
        switch (dateRangeType) {
            case DATE_RANGE_TYPE.LAST_7_DAYS: {
                setStartDate(moment().subtract(7, 'day').startOf('day'));
                setEndDate(moment().startOf('day'));
                break;
            }
            case DATE_RANGE_TYPE.LAST_30_DAYS: {
                setStartDate(moment().subtract(30, 'day').startOf('day'));
                setEndDate(moment().startOf('day'));
                break;
            }
            case DATE_RANGE_TYPE.LAST_3_MONTHS: {
                setStartDate(moment().subtract(3, 'month').startOf('day'));
                setEndDate(moment().startOf('day'));
                break;
            }
            case DATE_RANGE_TYPE.THIS_YEAR: {
                setStartDate(moment().startOf('year'));
                setEndDate(moment().startOf('day'));
                break;
            }
            default: {
                setStartDate(moment());
                setEndDate(moment());
                break;
            }
        }
    }, []);
    React.useEffect(() => {
        if (!messageThreadFilter.filterList) return;
        for (let i = 0; i < messageThreadFilter.filterList.length; i++) {
            const filter = messageThreadFilter.filterList[i];
            switch (filter.type) {
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_NOT_SEEN: {
                    setIsNotSeenSelected(filter.value as boolean);
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_STARRED: {
                    setIsStarredSelected(filter.value as boolean);
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.HAS_ATTACHMENTS: {
                    setHasAttachmentsSelected(filter.value as boolean);
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_CALLED_OUT: {
                    setIsCalledOutSelected(filter.value as boolean);
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_NOT_INBOX: {
                    setIsNotInboxSelected(filter.value as boolean);
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_SENT_BY_USER: {
                    setIsSentByUserSelected(filter.value as boolean);
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_USER_HASHTAG: {
                    setIsSentByUserSelected(filter.value as boolean);
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.DATE_RANGE_TYPE: {
                    setDateRangeType(filter.value as DATE_RANGE_TYPE);
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.START_DATE: {
                    setStartDate(moment(filter.value as number));
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.END_DATE: {
                    setEndDate(moment(filter.value as number));
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.TAG_ID_LIST: {
                    setSelectedTagIdList(filter.value as Array<string>);
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.HASHTAG_ID_LIST: {
                    setSelectedHashtagIdList(filter.value as Array<string>);
                    break;
                }
            }
        }
    }, [messageThreadFilter]);
    React.useEffect(() => {
        setIsFilterSelected(
            isNotSeenSelected ||
                isStarredSelected ||
                isUserHashtag ||
                hasAttachmentsSelected ||
                isCalledOutSelected ||
                isNotInboxSelected ||
                isSentByUserSelected ||
                dateRangeType !== '' ||
                selectedHashtagIdList.length !== 0 ||
                selectedTagIdList.length !== 0
        );
    }, [
        dateRangeType,
        hasAttachmentsSelected,
        isCalledOutSelected,
        isNotInboxSelected,
        isNotSeenSelected,
        isSentByUserSelected,
        isStarredSelected,
        isUserHashtag,
        selectedHashtagIdList.length,
        selectedTagIdList.length
    ]);
    return (
        <div className="messageThreadFilter">
            <div className="messageThreadFilter_header">
                <button
                    onClick={() => {
                        drawer.close();
                    }}
                    className="drawerCloseBtn"
                >
                    <CloseIcon />
                </button>
                <h2>Filters</h2>
                <Button
                    variant="outlined"
                    disabled={!isFilterSelected}
                    onClick={() => {
                        resetFilters();
                    }}
                    className="messageThreadFilter_filterBtn"
                >
                    <Sync />
                    Reset
                </Button>
            </div>
            <div className="messageThreadFilter_body">
                <div className="messageThreadFilter_item">
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <EmailOutlined />
                            <h2>Attributes</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="messageThreadFilter_attributesPill">
                                <ToggleButton
                                    value={isNotSeenSelected}
                                    selected={isNotSeenSelected}
                                    onChange={() => {
                                        setIsNotSeenSelected((state) => !state);
                                    }}
                                >
                                    <MarkEmailUnreadOutlined />
                                    Unread
                                </ToggleButton>
                                <ToggleButton
                                    value={isStarredSelected}
                                    selected={isStarredSelected}
                                    onChange={() => {
                                        setIsStarredSelected((state) => !state);
                                    }}
                                >
                                    <StarOutlineOutlined />
                                    Starred
                                </ToggleButton>
                                <ToggleButton
                                    value={hasAttachmentsSelected}
                                    selected={hasAttachmentsSelected}
                                    onChange={() => {
                                        setHasAttachmentsSelected((state) => !state);
                                    }}
                                >
                                    <AttachFileOutlined />
                                    Attachments
                                </ToggleButton>
                                <ToggleButton
                                    value={isCalledOutSelected}
                                    selected={isCalledOutSelected}
                                    onChange={() => {
                                        setIsCalledOutSelected((state) => !state);
                                    }}
                                >
                                    <AlternateEmailOutlined />
                                    My Callouts
                                </ToggleButton>
                                <ToggleButton
                                    value={isSentByUserSelected}
                                    selected={isSentByUserSelected}
                                    onChange={() => {
                                        setIsSentByUserSelected((state) => !state);
                                    }}
                                >
                                    <SendOutlined />
                                    Sent by Me
                                </ToggleButton>
                                <ToggleButton
                                    value={isNotInboxSelected}
                                    selected={isNotInboxSelected}
                                    onChange={() => {
                                        setIsNotInboxSelected((state) => !state);
                                    }}
                                >
                                    <img src={InboxOff} alt="InboxOff" />
                                    Not in Inbox
                                </ToggleButton>
                                <ToggleButton
                                    value={isUserHashtag}
                                    selected={isUserHashtag}
                                    onChange={() => {
                                        setIsUserHashtag((state) => !state);
                                    }}
                                >
                                    <img src={ManageSubscriptionIcon} alt="subscription" />
                                    Subscriptions
                                </ToggleButton>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="messageThreadFilter_item">
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <CalendarToday />
                            <h2>Date Range</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="messageThreadFilter_dateRange">
                                <FormControl>
                                    <div className="commonDropdownWrap">
                                        <Select
                                            color="info"
                                            onChange={(e) => {
                                                onDateRangeTypeChanged(e.target.value as DATE_RANGE_TYPE);
                                            }}
                                            displayEmpty
                                            value={dateRangeType}
                                            size="small"
                                            className="commonDropdown"
                                        >
                                            <MenuItem value="">Select One</MenuItem>
                                            <MenuItem value={DATE_RANGE_TYPE.LAST_7_DAYS}>Last 7 Days</MenuItem>
                                            <MenuItem value={DATE_RANGE_TYPE.LAST_30_DAYS}>Last 30 Days</MenuItem>
                                            <MenuItem value={DATE_RANGE_TYPE.LAST_3_MONTHS}>Last 3 Months</MenuItem>
                                            <MenuItem value={DATE_RANGE_TYPE.THIS_YEAR}>This Year</MenuItem>
                                            <MenuItem value={DATE_RANGE_TYPE.CUSTOM}>Custom</MenuItem>
                                        </Select>
                                    </div>
                                </FormControl>
                                {dateRangeType === DATE_RANGE_TYPE.CUSTOM ? (
                                    <div className="common_date_range mt24">
                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                            <DatePicker
                                                className='common_date_picker mr8'
                                                label="Start Date"
                                                value={startDate}
                                                onChange={(newStartDate) => {
                                                    setStartDate(newStartDate);
                                                }}
                                                disableFuture={true}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                            <DatePicker
                                                className='common_date_picker'
                                                label="End Date"
                                                value={endDate}
                                                onChange={(newEndDate) => {
                                                    setEndDate(newEndDate);
                                                }}
                                                disableFuture={true}
                                                minDate={startDate}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>
                                ) : (
                                    <></>
                                )}
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="messageThreadFilter_item">
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <SellOutlined className="rotateIcon" />
                            <h2>Topics</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="messageThreadFilter_labels">
                                <div className="commonCheckbox">
                                    <FormGroup>
                                        {Object.values(apiTagList)
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((apiTag) => (
                                                <div key={apiTag.tagId}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedTagIdList.includes(apiTag.tagId)}
                                                                onChange={(e) => {
                                                                    onTagCheckChanged(apiTag.tagId, e.target.checked);
                                                                }}
                                                            />
                                                        }
                                                        label={apiTag.name}
                                                    />
                                                </div>
                                            ))}
                                    </FormGroup>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="messageThreadFilter_item">
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <Tag />
                            <h2>Hashtags</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="messageThreadFilter_tags">
                                <div className="commonCheckbox">
                                    <FormGroup>
                                        {Object.values(apiHashtagList)
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((apiHashtag) => (
                                                <div key={apiHashtag.hashtagId}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedHashtagIdList.includes(apiHashtag.hashtagId)}
                                                                onChange={(e) => {
                                                                    onHashtagCheckChanged(apiHashtag.hashtagId, e.target.checked);
                                                                }}
                                                            />
                                                        }
                                                        label={apiHashtag.name}
                                                    />
                                                </div>
                                            ))}
                                    </FormGroup>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="messageThreadFilter_actions">
                    <Button color="primary" variant="contained" disabled={!isFilterSelected} onClick={applyFilters}>
                        Apply Filters
                    </Button>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                            drawer.close();
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
});

export default MessageThreadFilters;
