import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SCREEN_TYPE, QUERY_STRING_TYPE, LAYOUT_TYPE } from '../_global/Route';
import isEqual from 'lodash/isEqual';
import { USER_HASHTAG_STATE_FILTER_TYPE } from '../_global/_Enums';

export enum USER_HASHTAG_FILTER_TYPE {
    STATE_FILTER_TYPE = 'STATE_FILTER_TYPE',
    TAG_ID_LIST = 'TAG_ID_LIST',
    TEXT = 'TEXT'
}

export interface UserHashtagFilter {
    type: USER_HASHTAG_FILTER_TYPE;
    value: USER_HASHTAG_STATE_FILTER_TYPE | Array<string> | string;
}

interface IUserHashtagFilter {
    filterList: Array<UserHashtagFilter> | null;
    displayCount?: number;
    navigateToSearch: (filterList: Array<UserHashtagFilter>) => void;
    addFilterListValue: (filterType: USER_HASHTAG_FILTER_TYPE.TAG_ID_LIST, value: string) => void;
    removeFilter: (typeList: Array<USER_HASHTAG_FILTER_TYPE>) => void;
    removeFilterListValue: (filterType: USER_HASHTAG_FILTER_TYPE.TAG_ID_LIST, value: string) => void;
    removeAllFilters: () => void;
    getUrl: (filterList: Array<UserHashtagFilter>) => string;
}

const UserHashtagFilterContext = React.createContext<IUserHashtagFilter>({
    filterList: null,
    navigateToSearch: () => {},
    addFilterListValue: () => {},
    removeFilter: () => {},
    removeFilterListValue: () => {},
    removeAllFilters: () => {},
    getUrl: () => ''
});

export interface UserHashtagFilterProps {
    children?: React.ReactNode;
}

export const UserHashtagFilterProvider = React.memo<UserHashtagFilterProps>((props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [filterList, setFilterList] = React.useState<Array<UserHashtagFilter> | null>(null);
    const [displayCount, setDisplayCount] = React.useState<number>();
    const navToSearch = React.useCallback(
        (newFilterList: Array<UserHashtagFilter>) => {
            const queryParams = new URLSearchParams(location.search);
            queryParams.delete(QUERY_STRING_TYPE.FILTER);
            queryParams.delete(QUERY_STRING_TYPE.DRAWER_TYPE);
            if (newFilterList.length > 0) {
                queryParams.set(QUERY_STRING_TYPE.FILTER, JSON.stringify(newFilterList));
            }
            navigate({ pathname: `/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SUBSCRIPTIONS}`, search: queryParams.toString() });
        },
        [location.search, navigate]
    );
    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const filterQueryString = queryParams.get(QUERY_STRING_TYPE.FILTER);
        setFilterList((state) => {
            const newFilterList = filterQueryString ? JSON.parse(decodeURIComponent(filterQueryString)) : ([] as Array<UserHashtagFilter>);
            if (isEqual(state, newFilterList)) return state;
            return newFilterList;
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.search]);
    React.useEffect(() => {
        if (!filterList) {
            setDisplayCount(0);
            return;
        }
        const count = filterList.reduce((count, obj) => {
            switch (obj.type) {
                case USER_HASHTAG_FILTER_TYPE.STATE_FILTER_TYPE:
                case USER_HASHTAG_FILTER_TYPE.TAG_ID_LIST: {
                    count += (obj.value as Array<string>).length;
                    break;
                }
            }
            return count;
        }, 0);
        setDisplayCount(count);
    }, [filterList]);
    const userHashtagFilter = React.useMemo<IUserHashtagFilter>(() => {
        return {
            filterList: filterList,
            displayCount: displayCount,
            navigateToSearch: (newFilterList: Array<UserHashtagFilter>) => {
                navToSearch(newFilterList);
            },
            addFilterListValue: (filterType: USER_HASHTAG_FILTER_TYPE.TAG_ID_LIST, value: string) => {
                const newFilterList = filterList ? (JSON.parse(JSON.stringify([...filterList])) as UserHashtagFilter[]) : []; //need a deep copy here; can't use only spread
                let i = newFilterList.findIndex((obj) => obj.type === filterType);
                if (i === -1) {
                    newFilterList.push({
                        type: filterType,
                        value: []
                    });
                    i = newFilterList.findIndex((obj) => obj.type === filterType);
                }
                if (!(newFilterList[i].value as Array<string>).includes(value)) {
                    (newFilterList[i].value as Array<string>).push(value);
                }
                navToSearch(newFilterList);
            },
            removeFilter: (typeList: Array<USER_HASHTAG_FILTER_TYPE>) => {
                if (!filterList) return;
                navToSearch([...filterList].filter((obj) => !typeList.some((x) => x === obj.type)));
            },
            removeFilterListValue: (filterType: USER_HASHTAG_FILTER_TYPE.TAG_ID_LIST, value: string) => {
                if (!filterList) return;
                const newFilterList = JSON.parse(JSON.stringify([...filterList])) as UserHashtagFilter[]; //need a deep copy here; can't use only spread
                const i = newFilterList.findIndex((obj) => obj.type === filterType);
                if (i > -1) {
                    const j = (newFilterList[i].value as Array<string>).findIndex((obj) => obj === value);
                    if (j > -1) {
                        (newFilterList[i].value as Array<string>).splice(j, 1);
                    }
                    if ((newFilterList[i].value as Array<string>).length === 0) {
                        newFilterList.splice(i, 1);
                    }
                }
                navToSearch(newFilterList);
            },
            removeAllFilters: () => {
                setFilterList(null);
            },
            getUrl: (filterList: Array<UserHashtagFilter>) => {
                return `/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SUBSCRIPTIONS}?${QUERY_STRING_TYPE.FILTER}=${encodeURIComponent(JSON.stringify(filterList))}`;
            }
        };
    }, [filterList, displayCount, navToSearch]);
    return <UserHashtagFilterContext.Provider value={userHashtagFilter}>{props.children}</UserHashtagFilterContext.Provider>;
});

export const useUserHashtagFilter = () => React.useContext(UserHashtagFilterContext);
