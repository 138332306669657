import { AxiosResponse } from 'axios';
import React from 'react';
import { GetPresignedDownloadUrlResponse, useApi } from '../../../providers/Api';
import { ApiFile } from '../../../_api/_ApiModels';
import { log } from '../../../_global/Helpers';

interface OfficeViewerProps {
    apiFile: ApiFile;
}

const OfficeViewer = React.memo<OfficeViewerProps>(({ apiFile }) => {
    const api = useApi();
    const officeBaseUrl = 'https://view.officeapps.live.com/op/embed.aspx?src=';
    const [url, setUrl] = React.useState<string>();
    React.useEffect(() => {
        api.getPresignedDownloadUrl({ fileId: apiFile.fileId }, (axiosResponse: AxiosResponse) => {
            const getPresignedDownloadUrlResponse: GetPresignedDownloadUrlResponse = axiosResponse.data;
            setUrl(getPresignedDownloadUrlResponse.url);
            log(officeBaseUrl + getPresignedDownloadUrlResponse.url);
        });
    }, [api, apiFile.fileId, officeBaseUrl]);
    return (
        <>
            {url && (
                <div className="officeViewer">
                    <iframe title={apiFile.displayName} src={`${officeBaseUrl}${encodeURIComponent(url)}`} />
                </div>
            )}
        </>
    );
});

export default OfficeViewer;
