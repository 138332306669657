import React from 'react';
import { ApiHashtag } from '../../_api/_ApiModels';
import { useSelector } from '../../_redux/_Store';
import Callout from '../common/Callout';
import UserAvatar from '../common/UserAvatar';

export interface HashtagDetailsProps {
    hashtagId: string;
}

const HashtagDetails = React.memo<HashtagDetailsProps>(({ hashtagId }) => {
    const apiHashtagList = useSelector((state) => state.data.apiHashtagList);
    const [isDisplayDetails, setIsDisplayDetails] = React.useState<boolean>(false);
    const [apiHashtag, setApiHashtag] = React.useState<ApiHashtag>();
    React.useEffect(() => {
        setApiHashtag(apiHashtagList[hashtagId]);
    }, [apiHashtagList, hashtagId]);
    return (
        <div className="hashtagDetails_wrapper">
            <button
                className="unStyledButton hashtagDetails_toggle"
                onClick={() => {
                    setIsDisplayDetails((state) => !state);
                }}
            >
                {isDisplayDetails ? <>-Hide Hashtag Details</> : <>+View Hashtag Details</>}
            </button>
            {isDisplayDetails && (
                <div className="hashtagDetails_infoData">
                    <div className="hashtagDetails_title">Description:</div>
                    <div className="hashtagDetails_text">{apiHashtag?.description}</div>
                    <div className="hashtagDetails_title mt8">Admin(s):</div>
                    <div className="hashtagDetails_callouts">
                        {apiHashtag?.adminUserIdList.map((userId) => (
                            <span key={userId}>
                                <UserAvatar userId={userId} size={12} displayOnlineIndicator={false} />
                                <Callout userId={userId} />
                            </span>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
});

export default HashtagDetails;
