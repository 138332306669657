import React from 'react';
import { Button } from '@mui/material';
import { useSelector } from '../../../_redux/_Store';
import Callout from '../../common/Callout';
import Hashtag from '../../common/Hashtag';
import { ThumbUpOffAlt, ThumbDownOffAlt, EmailOutlined } from '@mui/icons-material';
import { useApi } from '../../../providers';
import HashtagDetails from '../HashtagDetails';
import ConfirmNotificationsModal from '../ConfirmNotificationsModal';

export interface UserHashtagControlInvitedProps {
    userHashtagId: string;
    hashtagId: string;
    userId: string;
}

const UserHashtagControlInvited = React.memo<UserHashtagControlInvitedProps>(({ userHashtagId, hashtagId, userId }) => {
    const api = useApi();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const [displayControls, setDisplayControls] = React.useState<boolean>(false);
    const [isConfirmOpen, setIsConfirmOpen] = React.useState<boolean>(false);
    const onAcceptClicked = React.useCallback(
        (notifications: boolean) => {
            setIsConfirmOpen(false);
            api.acceptUserHashtagInvite({
                userHashtagId: userHashtagId,
                notifications: notifications
            });
        },
        [api, userHashtagId]
    );
    const onDeclineClicked = React.useCallback(() => {
        api.declineUserHashtagInvite({
            userHashtagId: userHashtagId
        });
    }, [api, userHashtagId]);
    React.useEffect(() => {
        if (!loggedInUserId) return;
        setDisplayControls(userId === loggedInUserId);
    }, [loggedInUserId, userId]);
    return (
        <div className="commonSubscriptionBox">
            <div className="commonSubscriptionBox_heading">
                <EmailOutlined /> Subscription Pending
            </div>
            <div className="commonSubscriptionBox_message">
                Subscription to <Hashtag hashtagId={hashtagId} /> pending acceptance by <Callout userId={userId} />.
            </div>
            <div className="commonSubscriptionBox_hashtags">
                <HashtagDetails hashtagId={hashtagId} />
            </div>
            {displayControls && (
                <div className="commonSubscriptionBox_action">
                    <Button
                        color="primary"
                        size="small"
                        startIcon={<ThumbUpOffAlt />}
                        variant="contained"
                        className="ml16"
                        onClick={() => {
                            setIsConfirmOpen(true);
                        }}
                    >
                        Accept Invite
                    </Button>
                    <Button size="small" variant="contained" startIcon={<ThumbDownOffAlt />} color="secondary" onClick={onDeclineClicked}>
                        Decline Invite
                    </Button>
                </div>
            )}
            <ConfirmNotificationsModal
                isOpen={isConfirmOpen}
                hashtagId={hashtagId}
                onCancelCallback={() => {
                    setIsConfirmOpen(false);
                }}
                onNoCallback={() => {
                    onAcceptClicked(false);
                }}
                onYesCallback={() => {
                    onAcceptClicked(true);
                }}
            />
        </div>
    );
});

export default UserHashtagControlInvited;
