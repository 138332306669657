import React, { ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { SCREEN_TYPE, LAYOUT_TYPE } from '../../_global/Route';
import { useSelector } from '../../_redux/_Store';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import { Chip, SvgIcon } from '@mui/material';
import { AttachFileOutlined, SellOutlined, StarOutline } from '@mui/icons-material';
import MarkEmailUnreadOutlinedIcon from '@mui/icons-material/MarkEmailUnreadOutlined';
import AlternateEmailOutlinedIcon from '@mui/icons-material/AlternateEmailOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import CloseIcon from '@mui/icons-material/Close';
import TagIcon from '@mui/icons-material/Tag';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { ReactComponent as InboxSlash } from '../../assets/images/icons/inbox_slash_blue.svg';
import { MESSAGE_THREAD_SEARCH_FILTER_TYPE, DATE_RANGE_TYPE, ApiMessageThreadSearchFilter } from '../../_api/_ApiModels';
import UserAvatar from '../common/UserAvatar';

interface MessageThreadFilterPillListProps {
    apiMessageThreadSearchFilterList: Array<ApiMessageThreadSearchFilter>;
    onDelete?: Function;
    displayClearAll?: boolean;
    displaySearchText?: boolean;
}

interface FilterPill {
    icon?: React.ReactElement;
    label: ReactNode;
    onDelete?: Function;
}

export const FilterChipLabels = {
    starred: 'Starred',
    mentionMe: 'Mentions Me',
    unRead: 'Unread',
    notInInbox: 'Not in Inbox',
    isSentByUser: 'Sent by Me',
    isUserHashtag: 'Subscriptions',
    notificationOff: 'Notifications Off',
    attachments: 'Attachments'
};

const MessageThreadFilterPillList = React.memo<MessageThreadFilterPillListProps>(({ apiMessageThreadSearchFilterList, onDelete, displayClearAll = false, displaySearchText = false }) => {
    const navigate = useNavigate();
    const messageThreadFilter = useMessageThreadFilter();
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const apiHashtagList = useSelector((state) => state.data.apiHashtagList);
    const apiTagList = useSelector((state) => state.data.apiTagList);
    const [filterPillList, setFilterPillList] = React.useState<Array<FilterPill>>([]);
    const [searchText, setSearchText] = React.useState<string>();
    const getDateRangeLabel = React.useCallback(() => {
        if (!apiMessageThreadSearchFilterList) return;
        const dateRangeType = apiMessageThreadSearchFilterList.find((obj) => obj.type === MESSAGE_THREAD_SEARCH_FILTER_TYPE.DATE_RANGE_TYPE);
        const startDate = apiMessageThreadSearchFilterList.find((obj) => obj.type === MESSAGE_THREAD_SEARCH_FILTER_TYPE.START_DATE);
        const endDate = apiMessageThreadSearchFilterList.find((obj) => obj.type === MESSAGE_THREAD_SEARCH_FILTER_TYPE.END_DATE);
        if (!dateRangeType || !startDate || !endDate) return;
        switch (dateRangeType?.value) {
            case DATE_RANGE_TYPE.THIS_YEAR: {
                return 'This Year';
            }
            case DATE_RANGE_TYPE.LAST_7_DAYS: {
                return 'Last 7 Days';
            }
            case DATE_RANGE_TYPE.LAST_3_MONTHS: {
                return 'Last 3 Months';
            }
            case DATE_RANGE_TYPE.LAST_30_DAYS: {
                return 'Last 30 Days';
            }
            case DATE_RANGE_TYPE.CUSTOM: {
                return `${moment(startDate.value as number).format('M/D/YY')} - ${moment(endDate.value as number).format('M/D/YY')}`;
            }
        }
    }, [apiMessageThreadSearchFilterList]);
    React.useEffect(() => {
        if (!apiMessageThreadSearchFilterList) return;
        const tempFilterPillList = [] as Array<FilterPill>;
        for (var i = 0; i < apiMessageThreadSearchFilterList.length; i++) {
            const filter = apiMessageThreadSearchFilterList[i];
            switch (filter.type) {
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.TAG_ID_LIST: {
                    const tagIdList = filter.value as Array<string>;
                    for (let i = 0; i < tagIdList.length; i++) {
                        const tagId = tagIdList[i];
                        tempFilterPillList.push({
                            icon: <SellOutlined className="rotateIcon" />,
                            label: apiTagList[tagId].name,
                            onDelete: () => {
                                if (onDelete) onDelete(filter.type, tagId);
                            }
                        });
                    }
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.HASHTAG_ID_LIST: {
                    const hashtagIdList = filter.value as Array<string>;
                    for (let i = 0; i < hashtagIdList.length; i++) {
                        const hashtagId = hashtagIdList[i];
                        tempFilterPillList.push({
                            icon: <TagIcon />,
                            label: apiHashtagList[hashtagId].name,
                            onDelete: () => {
                                if (onDelete) onDelete(filter.type, hashtagId);
                            }
                        });
                    }
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.USER_ID_LIST: {
                    const userIdList = filter.value as Array<string>;
                    for (let i = 0; i < userIdList.length; i++) {
                        const userId = userIdList[i];
                        tempFilterPillList.push({
                            icon: <UserAvatar userId={userId} displayOnlineIndicator={false} size={18} />,
                            label: `@${apiUserList[userId].callout}`,
                            onDelete: () => {
                                if (onDelete) onDelete(filter.type, userId);
                            }
                        });
                    }
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_STARRED: {
                    tempFilterPillList.push({
                        icon: <StarOutline />,
                        label: FilterChipLabels.starred,
                        onDelete: () => {
                            if (onDelete) onDelete(filter.type);
                        }
                    });
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_CALLED_OUT: {
                    tempFilterPillList.push({
                        icon: <AlternateEmailOutlinedIcon />,
                        label: FilterChipLabels.mentionMe,
                        onDelete: () => {
                            if (onDelete) onDelete(filter.type);
                        }
                    });
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_NOT_SEEN: {
                    tempFilterPillList.push({
                        icon: <MarkEmailUnreadOutlinedIcon />,
                        label: FilterChipLabels.unRead,
                        onDelete: () => {
                            if (onDelete) onDelete(filter.type);
                        }
                    });
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_NOT_INBOX: {
                    tempFilterPillList.push({
                        icon: <SvgIcon component={InboxSlash} inheritViewBox />,
                        label: FilterChipLabels.notInInbox,
                        onDelete: () => {
                            if (onDelete) onDelete(filter.type);
                        }
                    });
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_SENT_BY_USER: {
                    tempFilterPillList.push({
                        icon: <SvgIcon component={SendOutlinedIcon} inheritViewBox />,
                        label: FilterChipLabels.isSentByUser,
                        onDelete: () => {
                            if (onDelete) onDelete(filter.type);
                        }
                    });
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_USER_HASHTAG: {
                    tempFilterPillList.push({
                        icon: <SvgIcon component={SendOutlinedIcon} inheritViewBox />,
                        label: FilterChipLabels.isUserHashtag,
                        onDelete: () => {
                            if (onDelete) onDelete(filter.type);
                        }
                    });
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.HAS_ATTACHMENTS: {
                    tempFilterPillList.push({
                        icon: <AttachFileOutlined />,
                        label: FilterChipLabels.attachments,
                        onDelete: () => {
                            if (onDelete) onDelete(filter.type);
                        }
                    });
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.DATE_RANGE_TYPE: {
                    tempFilterPillList.push({
                        icon: <CalendarTodayIcon />,
                        label: getDateRangeLabel(),
                        onDelete: () => {
                            if (onDelete) onDelete(filter.type);
                        }
                    });
                    break;
                }
                case MESSAGE_THREAD_SEARCH_FILTER_TYPE.TEXT: {
                    setSearchText(filter.value as string);
                    break;
                }
            }
        }
        setFilterPillList(tempFilterPillList);
    }, [apiHashtagList, apiMessageThreadSearchFilterList, apiTagList, apiUserList, getDateRangeLabel, onDelete]);
    const onClearAllClicked = React.useCallback(() => {
        messageThreadFilter.removeAllFilters();
        navigate({ pathname: `/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SEARCH}` });
    }, [messageThreadFilter, navigate]);
    return (
        <>
            {filterPillList.map((filterPill, index) => (
                <React.Fragment key={index}>
                    {onDelete ? (
                        <Chip
                            icon={filterPill.icon}
                            label={filterPill.label}
                            onDelete={() => {
                                if (filterPill.onDelete) {
                                    filterPill.onDelete();
                                }
                            }}
                            deleteIcon={<CloseIcon />}
                        />
                    ) : (
                        <Chip key={index} icon={filterPill.icon} label={filterPill.label} />
                    )}
                </React.Fragment>
            ))}
            {filterPillList.length > 0 && displayClearAll && <Chip key="clear_all" label="Clear All" onClick={onClearAllClicked} variant="outlined" className="filterPillList_clearAll" />}
            {displaySearchText && searchText && <>{`"${searchText}"`}</>}
        </>
    );
});

export default MessageThreadFilterPillList;
