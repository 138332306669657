import React from 'react';
import { Paper, Table, TableContainer, TableBody } from '@mui/material';
import { useSelector } from '../../_redux/_Store';
import { USER_HASHTAG_FILTER_TYPE, useUserHashtagFilter } from '../../providers/UserHashtagFilter';
import { ApiHashtag } from '../../_api/_ApiModels';
import { USER_HASHTAG_STATE_FILTER_TYPE, USER_HASHTAG_STATE_TYPE } from '../../_global/_Enums';
import TableHeader, { CellProps, OrderCellProps, sortString, sortNumber } from '../common/TableHeader';
import UserHashtag from './UserHashtag';

export interface SubscriptionTableData {
    hashtagId: string;
    hashtagName: string;
    updateTime?: number;
    isNotification: boolean;
    stateType?: USER_HASHTAG_STATE_TYPE;
}

const USER_HASHTAG_SORT_ORDER: Array<USER_HASHTAG_STATE_TYPE> = [
    USER_HASHTAG_STATE_TYPE.INVITED,
    USER_HASHTAG_STATE_TYPE.REQUESTED,
    USER_HASHTAG_STATE_TYPE.SUBSCRIBED,
    USER_HASHTAG_STATE_TYPE.DENIED,
    USER_HASHTAG_STATE_TYPE.DEACTIVATED,
    USER_HASHTAG_STATE_TYPE.DECLINED
];

const sortHashtagStateType = (a?: USER_HASHTAG_STATE_TYPE, b?: USER_HASHTAG_STATE_TYPE) => {
    const aVal = a ? a : USER_HASHTAG_STATE_TYPE.DEACTIVATED;
    const bVal = b ? b : USER_HASHTAG_STATE_TYPE.DEACTIVATED;
    return USER_HASHTAG_SORT_ORDER.indexOf(aVal) - USER_HASHTAG_SORT_ORDER.indexOf(bVal);
};

const cellArray: Array<CellProps<SubscriptionTableData>> = [
    {
        cellId: 'hashtagName',
        displayName: 'Title',
        sortFn: sortString
    },
    {
        cellId: 'updateTime',
        displayName: 'Date',
        sortFn: sortNumber
    },
    {
        cellId: 'isNotification',
        sx: { minWidth: '60px', textAlign: 'center' }
    },
    {
        cellId: 'stateType',
        displayName: 'Status',
        sortFn: sortHashtagStateType,
        sx: { width: '100px', textAlign: 'center' }
    }
];

const UserHashtagList = React.memo(() => {
    const userHashtagFilter = useUserHashtagFilter();
    const { apiHashtagList, apiUserHashtagList, userId: loggedInUserId } = useSelector((state) => state.data);
    const [subscriptionTableDataList, setSubscriptionTableDataList] = React.useState<Array<SubscriptionTableData>>([]);
    const [orderCell, setOrderCell] = React.useState<OrderCellProps<SubscriptionTableData>>({ orderBy: 'stateType', isAsc: true });
    const filterUserHashtagList = React.useCallback(
        (apiHashtag: ApiHashtag) => {
            if (!userHashtagFilter.filterList || !loggedInUserId) return true;
            const apiUserHashtag = Object.values(apiUserHashtagList).find((obj) => obj.hashtagId === apiHashtag.hashtagId && obj.userId === loggedInUserId);
            let include = true;
            for (let i = 0; i < userHashtagFilter.filterList.length; i++) {
                const filter = userHashtagFilter.filterList[i];
                switch (filter.type) {
                    case USER_HASHTAG_FILTER_TYPE.STATE_FILTER_TYPE: {
                        if (!include) break;
                        switch (filter.value as USER_HASHTAG_STATE_FILTER_TYPE) {
                            case USER_HASHTAG_STATE_FILTER_TYPE.INVITED: {
                                include = apiUserHashtag !== undefined && apiUserHashtag.stateType === USER_HASHTAG_STATE_TYPE.INVITED;
                                break;
                            }
                            case USER_HASHTAG_STATE_FILTER_TYPE.REQUESTED: {
                                include = apiUserHashtag !== undefined && apiUserHashtag.stateType === USER_HASHTAG_STATE_TYPE.REQUESTED;
                                break;
                            }
                            case USER_HASHTAG_STATE_FILTER_TYPE.SUBSCRIBED: {
                                include = apiHashtag.subscriptionUserIdList.includes(loggedInUserId);
                                break;
                            }
                            case USER_HASHTAG_STATE_FILTER_TYPE.NOT_SUBSCRIBED: {
                                include = !apiHashtag.subscriptionUserIdList.includes(loggedInUserId);
                                break;
                            }
                            case USER_HASHTAG_STATE_FILTER_TYPE.DEACTIVATED: {
                                include = apiUserHashtag !== undefined && apiUserHashtag.stateType === USER_HASHTAG_STATE_TYPE.DEACTIVATED;
                                break;
                            }
                            case USER_HASHTAG_STATE_FILTER_TYPE.DECLINED: {
                                include =
                                    apiUserHashtag !== undefined && (apiUserHashtag.stateType === USER_HASHTAG_STATE_TYPE.DECLINED || apiUserHashtag.stateType === USER_HASHTAG_STATE_TYPE.DENIED);
                                break;
                            }
                        }
                        break;
                    }
                    case USER_HASHTAG_FILTER_TYPE.TAG_ID_LIST: {
                        if (!include) break;
                        const tagIdList = filter.value as Array<string>;
                        include = tagIdList.every((tagId) => apiHashtag.tagIdList.includes(tagId));
                        break;
                    }
                    case USER_HASHTAG_FILTER_TYPE.TEXT: {
                        if (!include) break;
                        include = apiHashtag.name.toUpperCase().includes((filter.value as string).toUpperCase());
                        break;
                    }
                }
            }
            return include;
        },
        [apiUserHashtagList, loggedInUserId, userHashtagFilter.filterList]
    );
    React.useEffect(() => {
        const subscriptionListSortAndFilter: Array<SubscriptionTableData> = [];
        Object.values(apiHashtagList)
            .filter(filterUserHashtagList)
            .forEach((apiHashtag) => {
                const mappedUserHashtag = Object.values(apiUserHashtagList).find((apiUserHashtag) => apiUserHashtag.hashtagId === apiHashtag.hashtagId && apiUserHashtag.userId === loggedInUserId);
                const stateType =
                    loggedInUserId && apiHashtag.subscriptionUserIdList.includes(loggedInUserId) ? USER_HASHTAG_STATE_TYPE.SUBSCRIBED : mappedUserHashtag ? mappedUserHashtag.stateType : undefined;
                subscriptionListSortAndFilter.push({
                    hashtagId: apiHashtag.hashtagId,
                    hashtagName: apiHashtag.name,
                    updateTime: mappedUserHashtag ? mappedUserHashtag.updateTime : undefined,
                    isNotification: loggedInUserId && apiHashtag.notificationUserIdList.includes(loggedInUserId) ? true : false,
                    stateType
                });
            });
        const orderByCell = cellArray.find((obj) => obj.cellId === orderCell.orderBy);
        const orderByFn = orderByCell ? orderByCell.sortFn : undefined;
        if (orderByCell && orderByFn) {
            subscriptionListSortAndFilter.sort((a, b) => {
                return orderCell.isAsc ? orderByFn(a[orderCell.orderBy], b[orderCell.orderBy]) : orderByFn(b[orderCell.orderBy], a[orderCell.orderBy]);
            });
        }
        setSubscriptionTableDataList(subscriptionListSortAndFilter);
    }, [apiHashtagList, apiUserHashtagList, loggedInUserId, filterUserHashtagList, orderCell]);

    const onSort = React.useCallback(
        (cellId: keyof SubscriptionTableData) => {
            if (cellId === orderCell.orderBy) {
                setOrderCell((oldState) => {
                    return { ...oldState, isAsc: !oldState.isAsc };
                });
            } else {
                setOrderCell({ orderBy: cellId, isAsc: true });
            }
        },
        [orderCell]
    );

    return (
        <div className="userHashtagsList_wrapper">
            <TableContainer component={Paper} className="userHashtagsList_table">
                <Table stickyHeader>
                    <TableHeader cellArray={cellArray} orderCell={orderCell} onSort={onSort} />
                    <TableBody className="userHashtagsList_body">
                        <UserHashtag subscriptionTableDataList={subscriptionTableDataList} />
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
});

export default UserHashtagList;
