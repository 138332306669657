import React from 'react';
import { Divider } from '@mui/material';
import Sidebar from '../common/Sidebar';
import { Outlet } from 'react-router-dom';
import FileViewer from './FileViewer';
import { MessageThreadNavProvider } from '../../providers/MessageThreadNav';
import { DrawerProvider } from '../../providers/Drawer';
import { MessageThreadFilterProvider } from '../../providers/MessageThreadFilter';
import { UserHashtagFilterProvider } from '../../providers/UserHashtagFilter';

const Inbox = React.memo(() => {
    return (
        <MessageThreadNavProvider>
            <MessageThreadFilterProvider>
                <UserHashtagFilterProvider>
                    <DrawerProvider>
                        <div className="inbox_mainWrapper">
                            <Sidebar />
                            <Divider orientation="vertical" />
                            <Outlet />
                        </div>
                        <FileViewer />
                    </DrawerProvider>
                </UserHashtagFilterProvider>
            </MessageThreadFilterProvider>
        </MessageThreadNavProvider>
    );
});

export default Inbox;
