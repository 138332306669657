import React from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { NotificationsActive, NotificationsOffOutlined } from '@mui/icons-material';
import { Chip, Fab, TableCell, TableRow } from '@mui/material';
import { useApi } from '../../providers';
import { LAYOUT_TYPE, SCREEN_TYPE } from '../../_global/Route';
import { USER_HASHTAG_STATE_TYPE } from '../../_global/_Enums';
import { MONTH_DAY_FORMAT } from '../../_global/Constants';
import ConfirmNotificationsModal from './ConfirmNotificationsModal';
import { SubscriptionTableData } from './UserHashtagList';

const UserHashtag = React.memo(({ subscriptionTableDataList }: { subscriptionTableDataList: Array<SubscriptionTableData> }) => {
    const api = useApi();
    const [isConfirmNotificationsOpen, setIsConfirmNotificationsOpen] = React.useState<boolean>(false);
    const [hashtagId, setHashtagId] = React.useState<string>();
    const onSubscribeClicked = React.useCallback(
        (notifications: boolean) => {
            if (!hashtagId) return;
            api.createUserHashtagRequest({ hashtagId: hashtagId, notifications: notifications });
            setIsConfirmNotificationsOpen(false);
            setHashtagId(undefined);
        },
        [api, hashtagId]
    );
    const onNotificationClicked = React.useCallback(
        (hashtagId: string) => {
            api.toggleHashtagNotification({
                hashtagId: hashtagId
            });
        },
        [api]
    );
    return (
        <>
            {subscriptionTableDataList.map(({ hashtagId, hashtagName, updateTime, isNotification, stateType }: SubscriptionTableData) => (
                <TableRow key={hashtagId}>
                    <TableCell component="td" scope="row" className="userHashtagsList_name">
                        <NavLink to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SUBSCRIPTIONS}/${hashtagId}`}>#{hashtagName}</NavLink>
                    </TableCell>
                    <TableCell sx={{ width: '100px' }} className="userHashtagsList_time">
                        {updateTime ? moment.unix(updateTime).format(MONTH_DAY_FORMAT) : null}{' '}
                        {stateType === USER_HASHTAG_STATE_TYPE.DECLINED ? 'Declined' : stateType === USER_HASHTAG_STATE_TYPE.DEACTIVATED ? 'Deactivated' : null}
                    </TableCell>
                    <TableCell
                        sx={{ width: '100px' }}
                        className="userHashtagsList_notification"
                        onClick={() => {
                            onNotificationClicked(hashtagId);
                        }}
                    >
                        {stateType === USER_HASHTAG_STATE_TYPE.SUBSCRIBED && <>{isNotification ? <NotificationsActive className="active" /> : <NotificationsOffOutlined />}</>}
                    </TableCell>
                    <TableCell sx={{ width: '100px' }} className="userHashtagsList_action">
                        {stateType === USER_HASHTAG_STATE_TYPE.SUBSCRIBED ? (
                            <Chip label="Subscribed" className="userHashtag_subscribed" />
                        ) : stateType === USER_HASHTAG_STATE_TYPE.REQUESTED ? (
                            <Chip label="Requested" className="userHashtag_requested" />
                        ) : stateType === USER_HASHTAG_STATE_TYPE.INVITED ? (
                            <Chip label="Invited" className="userHashtag_invited" />
                        ) : stateType === USER_HASHTAG_STATE_TYPE.DECLINED ? (
                            <Chip label="Declined" className="userHashtag_declined" />
                        ) : (
                            <Fab
                                className="userHashtag_fabButton"
                                variant="extended"
                                size="small"
                                color="primary"
                                onClick={() => {
                                    setIsConfirmNotificationsOpen(true);
                                    setHashtagId(hashtagId);
                                }}
                            >
                                Request
                            </Fab>
                        )}
                    </TableCell>
                </TableRow>
            ))}
            {hashtagId && (
                <ConfirmNotificationsModal
                    isOpen={isConfirmNotificationsOpen}
                    hashtagId={hashtagId}
                    onCancelCallback={() => {
                        setIsConfirmNotificationsOpen(false);
                    }}
                    onNoCallback={() => {
                        onSubscribeClicked(false);
                    }}
                    onYesCallback={() => {
                        onSubscribeClicked(true);
                    }}
                />
            )}
        </>
    );
});

export default UserHashtag;
