import React from 'react';
import { Button } from '@mui/material';
import { USER_ROLE_TYPE } from '../../../_global/_Enums';
import { useSelector } from '../../../_redux/_Store';
import Callout from '../../common/Callout';
import Hashtag from '../../common/Hashtag';
import { ThumbUpOffAlt, ThumbDownOffAlt, ForwardToInboxOutlined } from '@mui/icons-material';
import { useApi } from '../../../providers';
import HashtagDetails from '../HashtagDetails';

export interface UserHashtagControlRequestedProps {
    userHashtagId: string;
    hashtagId: string;
    userId: string;
}

const UserHashtagControlRequested = React.memo<UserHashtagControlRequestedProps>(({ userHashtagId, hashtagId, userId }) => {
    const api = useApi();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const apiHashtagList = useSelector((state) => state.data.apiHashtagList);
    const [displayControls, setDisplayControls] = React.useState<boolean>(false);
    const onApproveClicked = React.useCallback(() => {
        api.approveUserHashtagRequest({
            userHashtagId: userHashtagId
        });
    }, [api, userHashtagId]);
    const onDenyClicked = React.useCallback(() => {
        api.denyUserHashtagRequest({
            userHashtagId: userHashtagId
        });
    }, [api, userHashtagId]);
    React.useEffect(() => {
        if (!loggedInUserId) return;
        const loggedInApiUser = apiUserList[loggedInUserId];
        const hashtag = apiHashtagList[hashtagId];
        if (hashtag.adminUserIdList.includes(loggedInUserId) || USER_ROLE_TYPE.ADMIN === loggedInApiUser.roleType) {
            setDisplayControls(true);
            return;
        }
        setDisplayControls(false);
    }, [apiHashtagList, hashtagId, apiUserList, loggedInUserId]);
    return (
        <div className="commonSubscriptionBox">
            <div className="commonSubscriptionBox_heading">
                <ForwardToInboxOutlined /> Subscription Pending
            </div>
            <div className="commonSubscriptionBox_message">
                Request by <Callout userId={userId} /> to subscribe to <Hashtag hashtagId={hashtagId} /> pending approval by admins.
            </div>
            <div className="commonSubscriptionBox_hashtags">
                <HashtagDetails hashtagId={hashtagId} />
            </div>
            {displayControls && (
                <div className="commonSubscriptionBox_action">
                    <Button color="primary" size="small" startIcon={<ThumbUpOffAlt />} variant="contained" onClick={onApproveClicked}>
                        Approve Request
                    </Button>
                    <Button color="secondary" variant="contained" size="small" className="ml16" startIcon={<ThumbDownOffAlt />} onClick={onDenyClicked}>
                        Deny Request
                    </Button>
                </div>
            )}
        </div>
    );
});

export default UserHashtagControlRequested;
