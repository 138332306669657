import { BootstrapResponse } from '../providers/Api';
import { ApiHashtag, ApiOrganization, ApiTag, ApiTagCategory, ApiUser, ApiUserHashtag } from '../_api/_ApiModels';
import produce from 'immer';
import { calculateHashtagName } from '../_global/Helpers';
import { USER_ROLE_TYPE } from '../_global/_Enums';

export enum DATA_ACTION_TYPE {
    DATA_SET_BOOTSTRAP = 'DATA_SET_BOOTSTRAP',
    DATA_CLEAR = 'DATA_CLEAR',
    DATA_UPDATE_USER = 'DATA_UPDATE_USER',
    DATA_UPDATE_HASHTAG = 'DATA_UPDATE_HASHTAG',
    DATA_UPDATE_TAG = 'DATA_UPDATE_TAG',
    DATA_UPDATE_USER_HASHTAG = 'DATA_UPDATE_USER_HASHTAG',
    DATA_USER_ID_IS_ONLINE = 'DATA_USER_ID_IS_ONLINE',
    DATA_USER_ID_IS_OFFLINE = 'DATA_USER_ID_IS_OFFLINE',
    DATA_SET_HAS_FOCUS = 'DATA_SET_HAS_FOCUS',
    DATA_SET_IS_ONLINE = 'DATA_SET_IS_ONLINE'
}

export interface DataAction {
    type: DATA_ACTION_TYPE;
    payload: BootstrapResponse | number | ApiHashtag | ApiTag | ApiUserHashtag | ApiUser | string | boolean;
}

export const setBootstrap = (bootstrapResponse: BootstrapResponse) => {
    return {
        type: DATA_ACTION_TYPE.DATA_SET_BOOTSTRAP,
        payload: bootstrapResponse
    };
};

export const clear = () => {
    return {
        type: DATA_ACTION_TYPE.DATA_CLEAR
    };
};

export const updateUser = (apiUser: ApiUser) => {
    return {
        type: DATA_ACTION_TYPE.DATA_UPDATE_USER,
        payload: apiUser
    };
};

export const updateHashtag = (apiHashtag: ApiHashtag) => {
    return {
        type: DATA_ACTION_TYPE.DATA_UPDATE_HASHTAG,
        payload: apiHashtag
    };
};

export const updateTag = (apiTag: ApiTag) => {
    return {
        type: DATA_ACTION_TYPE.DATA_UPDATE_TAG,
        payload: apiTag
    };
};

export const updateUserHashtag = (apiUserHashtag: ApiUserHashtag) => {
    return {
        type: DATA_ACTION_TYPE.DATA_UPDATE_USER_HASHTAG,
        payload: apiUserHashtag
    };
};

export const userIdIsOnline = (userId: string) => {
    return {
        type: DATA_ACTION_TYPE.DATA_USER_ID_IS_ONLINE,
        payload: userId
    };
};

export const userIdIsOffline = (userId: string) => {
    return {
        type: DATA_ACTION_TYPE.DATA_USER_ID_IS_OFFLINE,
        payload: userId
    };
};

export const setHasFocus = (hasFocus: boolean) => {
    return {
        type: DATA_ACTION_TYPE.DATA_SET_HAS_FOCUS,
        payload: hasFocus
    };
};

export const setIsOnline = (isOnline: boolean) => {
    return {
        type: DATA_ACTION_TYPE.DATA_SET_IS_ONLINE,
        payload: isOnline
    };
};

export interface DataState {
    isBootstrapped: boolean;
    hasFocus: boolean;
    isOnline: boolean;
    userId?: string;
    isAdmin: boolean;
    apiOrganization?: ApiOrganization;
    apiUserList: Record<string, ApiUser>;
    apiTagCategoryList: Record<string, ApiTagCategory>;
    apiTagList: Record<string, ApiTag>;
    apiHashtagList: Record<string, ApiHashtag>;
    apiUserHashtagList: Record<string, ApiUserHashtag>;
}

const initDataState: DataState = {
    isBootstrapped: false,
    hasFocus: true,
    isOnline: true,
    isAdmin: false,
    apiUserList: {},
    apiTagCategoryList: {},
    apiTagList: {},
    apiHashtagList: {},
    apiUserHashtagList: {}
};

export const DataReducer = (state = initDataState, action: DataAction) => {
    switch (action.type) {
        case DATA_ACTION_TYPE.DATA_SET_BOOTSTRAP: {
            const bootstrapResponse = action.payload as BootstrapResponse;
            const apiUser = bootstrapResponse.apiUserList.find((obj) => obj.userId === bootstrapResponse.userId);
            const apiTagCategoryList = bootstrapResponse.apiTagCategoryList.reduce((map, obj) => {
                map[obj.tagCategoryId] = obj;
                return map;
            }, {} as Record<string, ApiTagCategory>);
            return {
                ...state,
                isBootstrapped: true,
                userId: bootstrapResponse.userId,
                apiOrganization: bootstrapResponse.apiOrganization,
                apiUserList: bootstrapResponse.apiUserList.reduce((map, obj) => {
                    map[obj.userId] = obj;
                    return map;
                }, {} as Record<string, ApiUser>),
                isAdmin: apiUser ? apiUser.roleType === USER_ROLE_TYPE.ADMIN : false,
                apiTagCategoryList: apiTagCategoryList,
                apiTagList: bootstrapResponse.apiTagList.reduce((map, obj) => {
                    map[obj.tagId] = obj;
                    return map;
                }, {} as Record<string, ApiTag>),
                apiHashtagList: bootstrapResponse.apiHashtagList.reduce((map, obj) => {
                    obj.name = calculateHashtagName(bootstrapResponse.apiTagList, apiTagCategoryList, obj, apiUser);
                    map[obj.hashtagId] = obj;
                    return map;
                }, {} as Record<string, ApiHashtag>),
                apiUserHashtagList: bootstrapResponse.apiUserHashtagList.reduce((map, obj) => {
                    map[obj.userHashtagId] = obj;
                    return map;
                }, {} as Record<string, ApiUserHashtag>)
            };
        }
        case DATA_ACTION_TYPE.DATA_CLEAR: {
            return initDataState;
        }
        case DATA_ACTION_TYPE.DATA_UPDATE_USER: {
            const apiUser = action.payload as ApiUser;
            return produce(state, (draft) => {
                draft.apiUserList[apiUser.userId] = apiUser;
                if (apiUser.userId === draft.userId) draft.isAdmin = apiUser.roleType === USER_ROLE_TYPE.ADMIN;
            });
        }
        case DATA_ACTION_TYPE.DATA_UPDATE_HASHTAG: {
            const apiHashtag = action.payload as ApiHashtag;
            return produce(state, (draft) => {
                const apiTagList = Object.values(draft.apiTagList);
                const apiUser = draft.apiUserList[draft.userId!];
                apiHashtag.name = calculateHashtagName(apiTagList, draft.apiTagCategoryList, apiHashtag, apiUser);
                draft.apiHashtagList[apiHashtag.hashtagId] = apiHashtag;
            });
        }
        case DATA_ACTION_TYPE.DATA_UPDATE_TAG: {
            const apiTag = action.payload as ApiTag;
            return produce(state, (draft) => {
                draft.apiTagList[apiTag.tagId] = apiTag;
            });
        }
        case DATA_ACTION_TYPE.DATA_UPDATE_USER_HASHTAG: {
            const apiUserHashtag = action.payload as ApiUserHashtag;
            return produce(state, (draft) => {
                draft.apiUserHashtagList[apiUserHashtag.userHashtagId] = apiUserHashtag;
            });
        }
        case DATA_ACTION_TYPE.DATA_USER_ID_IS_ONLINE: {
            const userId = action.payload as string;
            return produce(state, (draft) => {
                if (draft.apiUserList[userId]) {
                    draft.apiUserList[userId].online = true;
                }
            });
        }
        case DATA_ACTION_TYPE.DATA_USER_ID_IS_OFFLINE: {
            const userId = action.payload as string;
            return produce(state, (draft) => {
                if (draft.apiUserList[userId]) {
                    draft.apiUserList[userId].online = false;
                }
            });
        }
        case DATA_ACTION_TYPE.DATA_SET_HAS_FOCUS: {
            const hasFocus = action.payload as boolean;
            return produce(state, (draft) => {
                draft.hasFocus = hasFocus;
            });
        }
        case DATA_ACTION_TYPE.DATA_SET_IS_ONLINE: {
            const isOnline = action.payload as boolean;
            return produce(state, (draft) => {
                draft.isOnline = isOnline;
            });
        }
        default:
            return state;
    }
};
