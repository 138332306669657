import React from 'react';
import { Checkbox, Switch, Radio, FormControlLabel, RadioGroup, Button, Alert } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useDrawer } from '../../providers/Drawer';
import ConfirmationModal from '../common/NotificationModal';
import UserAutocomplete from '../common/UserAutocomplete';
import TagAutocomplete from '../common/TagAutocomplete';
import { useSelector } from '../../_redux/_Store';
import { isEqual } from 'lodash';
import SuccessSvg from '../../assets/images/success.svg';
import { useApi } from '../../providers';

const CreateHashtag = React.memo(() => {
    const drawer = useDrawer();
    const api = useApi();
    const { apiHashtagList, apiTagList, apiUserList, userId } = useSelector((state) => state.data);
    const [isInviteAllUsers, setIsInviteAllUsers] = React.useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [isSubscribe, setIsSubscribe] = React.useState(false);
    const [isNotifications, setIsNotifications] = React.useState(false);
    const [adminUserIdList, setAdminUserIdList] = React.useState<Array<string>>([]);
    const [inviteUserIdList, setInviteUserIdList] = React.useState<Array<string>>([]);
    const [tagIdList, setTagIdList] = React.useState<Array<string>>([]);
    const [description, setDescription] = React.useState<string>('');
    const [isDuplicate, setIsDuplicate] = React.useState<boolean>(false);
    const [isSaveDisabled, setIsSaveDisabled] = React.useState<boolean>(true);
    const onSaveClicked = React.useCallback(() => {
        if (!userId) return;
        api.createHashtag({
            tagIdList: tagIdList,
            description: description,
            adminUserIdList: adminUserIdList,
            inviteUserIdList: isInviteAllUsers
                ? Object.values(apiUserList).reduce((userIdList, obj) => {
                      if (userId !== obj.userId) userIdList.push(obj.userId);
                      return userIdList;
                  }, [] as Array<string>)
                : inviteUserIdList,
            subscriptionUserIdList: isSubscribe ? [userId] : [],
            notificationUserIdList: isNotifications ? [userId] : []
        });
        setIsModalOpen(true);
    }, [adminUserIdList, api, apiUserList, description, inviteUserIdList, isInviteAllUsers, isNotifications, isSubscribe, tagIdList, userId]);
    const onModalCloseClicked = React.useCallback(() => {
        setIsModalOpen(false);
        drawer.close();
    }, [drawer]);
    React.useEffect(() => {
        const tempApiHashtagList = Object.values(apiHashtagList);
        for (let i = 0; i < tempApiHashtagList.length; i++) {
            const apiHashtag = tempApiHashtagList[i];
            if (isEqual(apiHashtag.tagIdList, tagIdList)) {
                setIsDuplicate(true);
                return;
            }
            setIsDuplicate(false);
        }
    }, [apiHashtagList, tagIdList]);
    React.useEffect(() => {
        setIsSaveDisabled(adminUserIdList.length === 0 || tagIdList.length === 0 || isDuplicate);
    }, [adminUserIdList.length, isDuplicate, tagIdList.length]);
    return (
        <div className="createHashtagsDrawer_wrapper">
            <div className="createHashtagsDrawer_wrapper_header">
                <button
                    onClick={() => {
                        drawer.close();
                    }}
                    className="drawerCloseBtn"
                >
                    <CloseIcon />
                </button>
                <h2>Create Hashtag</h2>
            </div>
            <div className="createHashtagsDrawer_body">
                <div className="createHashtagsDrawer_topic">
                    <div className="commonAutoComplete">
                        <label className="common_form_label">Topic(s) *</label>
                        {isDuplicate && (
                            <Alert severity="error" className="error">
                                A hashtag already exists with the following topic(s)
                            </Alert>
                        )}
                        <TagAutocomplete setTagIdList={setTagIdList} />
                    </div>
                </div>

                <div className="mt16">
                    <div className="common_form_label">
                        Description <span className="optional">(optional)</span>
                    </div>
                    <div className="commonTextarea">
                        <textarea
                            cols={30}
                            rows={10}
                            placeholder="Add a short description of how this hashtag should be used."
                            onChange={(e) => {
                                setDescription(e.target.value);
                            }}
                        />
                    </div>
                </div>

                <div className="mt16">
                    <div className="common_form_label">Admin(s)</div>
                    <p className="createHashtagsDrawer_infoText">Cake administrators in your organization will also have permission to modify settings for this hashtag.</p>
                    <div className="commonAutoComplete mt16">
                        <UserAutocomplete setUserIdList={setAdminUserIdList} />
                    </div>
                </div>

                <div className="mt16">
                    <label className="common_form_label" id="Subscribers-radio-buttons-group-label">
                        Subscribers
                    </label>
                    <p className="createHashtagsDrawer_infoText">Subscribers will get notified of new messages that use this hashtag and be able to view all messages that use this tag.</p>
                    <div className="commonRadio mt8">
                        <RadioGroup
                            aria-labelledby="Subscribers-radio-buttons-group-label"
                            defaultValue={isInviteAllUsers}
                            onChange={(e) => setIsInviteAllUsers(e.target.value === 'true')}
                            name="radio-buttons-group"
                        >
                            <FormControlLabel value={true} control={<Radio />} label="Add all users in my organization" />
                            <FormControlLabel value={false} control={<Radio />} label="Select individual users" />
                        </RadioGroup>
                    </div>
                </div>
                {!isInviteAllUsers && (
                    <div className="commonAutoComplete">
                        <UserAutocomplete setUserIdList={setInviteUserIdList} />
                    </div>
                )}
                <label className="common_form_label mt16">My Subscription & Delivery Preferences</label>
                <div>
                    <FormControlLabel control={<Checkbox color="info" value={isSubscribe} onChange={(e) => setIsSubscribe(e.target.checked)} />} label="Subscribe me" />
                    {isSubscribe && (
                        <FormControlLabel
                            control={
                                <Switch
                                    size="small"
                                    color="info"
                                    onChange={(e) => {
                                        setIsNotifications(e.target.checked);
                                    }}
                                />
                            }
                            label="Notifications On"
                        />
                    )}
                </div>
                <div className="createHashtagsDrawer_actions">
                    <Button color="primary" variant="contained" disabled={isSaveDisabled} onClick={() => onSaveClicked()}>Save</Button>
                    <Button color="secondary" variant="outlined" onClick={() => { drawer.close(); }}>Cancel</Button>
                </div>
            </div>
            <ConfirmationModal
                isOpen={isModalOpen}
                imgSrc={SuccessSvg}
                content={
                    <div>
                        <p>A new hashtag has been created with the topics:</p>
                        <div>
                            {tagIdList.map((tagId) => (
                                <span>{apiTagList[tagId].name}</span>
                            ))}
                        </div>
                        <p>Invites have been sent to {inviteUserIdList.length} users.</p>
                    </div>
                }
                title="Hashtag Created!"
                onCloseCallback={onModalCloseClicked}
            />
        </div>
    );
});

export default CreateHashtag;
