import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApiFile } from '../../_api/_ApiModels';
import { GetFileListResponse, GetPresignedDownloadUrlResponse, useApi } from '../../providers/Api';
import { AxiosResponse } from 'axios';
import { Backdrop } from '@mui/material';
import { getCloudfrontUrl } from '../../_global/Helpers';
import { useSelector } from '../../_redux/_Store';
import { Close, ForwardToInboxOutlined, FileDownloadOutlined, DeleteOutlined, ArrowBackIos, ArrowForwardIos } from '@mui/icons-material';
import { getReadableBytes, isDirectDownloadableFile, isImage, isOffice, isPdf, isVideo } from '../../_global/FileUtils';
import PdfViewer from './fileViewers/PdfViewer';
import VideoViewer from './fileViewers/VideoViewer';
import ImageViewer from './fileViewers/ImageViewer';
import OfficeViewer from './fileViewers/OfficeViewer';
import { INBOX_PARAM_TYPE, QUERY_STRING_TYPE } from '../../_global/Route';
import UserName from '../common/UserName';
import moment from 'moment';
import ModalPrompt from '../common/ModalPrompt';
import { FILE_DAY_FORMAT_FORMAT, FILE_DAY_YEAR_FORMAT_FORMAT, TIME_ONLY_FORMAT } from '../../_global/Constants';

interface FileViewerProps {}

const FileViewer = React.memo<FileViewerProps>(() => {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams<INBOX_PARAM_TYPE>();
    const api = useApi();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const cloudfrontUrl = useSelector((state) => state.data.apiOrganization?.cloudfrontUrl);
    const downloadElement = React.useRef() as React.MutableRefObject<HTMLAnchorElement>;
    const [apiFileList, setApiFileList] = React.useState<Array<ApiFile>>([]);
    const [apiFile, setApiFile] = React.useState<ApiFile>();
    const [url, setUrl] = React.useState<string>();
    const [userId, setUserId] = React.useState<string>();
    const [displayFileCreateTime, setDisplayFileCreateTime] = React.useState<string>('');
    const [displayName, setDisplayName] = React.useState('');
    const [displayByteCount, setDisplayByteCount] = React.useState('');
    const containerRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
    const [isFileDeleteModalOpen, setIsFileDeleteModalOpen] = React.useState(false);
    const [index, setIndex] = React.useState<number>(-1);
    const [isActionItemsVisible, setIsActionItemsVisible] = React.useState<boolean>(true);
    const onClose = React.useCallback(() => {
        const queryParams = new URLSearchParams(location.search);
        queryParams.delete(QUERY_STRING_TYPE.FILE_ID);
        navigate({ search: queryParams.toString() });
    }, [location.search, navigate]);
    const downloadFile = React.useCallback(() => {
        if (!apiFile) return;
        api.getPresignedDownloadUrl({ fileId: apiFile.fileId }, (axiosResponse: AxiosResponse) => {
            const getPresignedDownloadUrlResponse: GetPresignedDownloadUrlResponse = axiosResponse.data;
            downloadElement.current.href = getPresignedDownloadUrlResponse.url;
            downloadElement.current.download = apiFile.name;
            downloadElement.current.click();
        });
    }, [apiFile, api]);
    const onDeleteFile = React.useCallback(() => {
        const queryParams = new URLSearchParams(location.search);
        if (!apiFile || !apiFileList) return;
        const isNext = index !== apiFileList.length - 1;
        const nextFileInfo = isNext ? apiFileList[index + 1] : apiFileList[index - 1];
        api.deleteFile({
            messageThreadId: params.MESSAGE_THREAD_ID,
            messageId: apiFile.messageId,
            fileId: apiFile.fileId
        });
        setIsFileDeleteModalOpen(false);
        setApiFile(undefined);
        if (nextFileInfo?.fileId) {
            queryParams.set(QUERY_STRING_TYPE.FILE_ID, nextFileInfo.fileId);
        } else {
            queryParams.delete(QUERY_STRING_TYPE.FILE_ID);
        }
        navigate({ search: queryParams.toString() });
    }, [api, apiFile, apiFileList, index, location.search, navigate, params.MESSAGE_THREAD_ID]);
    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const messageThreadId = params[INBOX_PARAM_TYPE.MESSAGE_THREAD_ID];
        const fileId = queryParams.get(QUERY_STRING_TYPE.FILE_ID);
        if (messageThreadId && fileId && cloudfrontUrl) {
            api.getFileList(
                {
                    messageThreadId: messageThreadId
                },
                (axiosResponse: AxiosResponse) => {
                    const getFileListResponse: GetFileListResponse = axiosResponse.data;
                    const apiFileList = getFileListResponse.apiFileList.filter((file) => !isDirectDownloadableFile(file.contentType));
                    setApiFileList(apiFileList);
                    const fileIndex = apiFileList.findIndex((obj: ApiFile) => {
                        return obj.fileId === fileId;
                    });
                    if (fileIndex > -1) {
                        const apiFile: ApiFile = apiFileList[fileIndex];
                        setApiFile(apiFile);
                        setIndex(fileIndex);
                        setUrl(getCloudfrontUrl(cloudfrontUrl, apiFile.key));
                        setDisplayByteCount(getReadableBytes(apiFile.byteCount));
                        setDisplayName(apiFile.displayName);
                        setUserId(apiFile.userId);
                        const apiFileCreateTime = moment.unix(apiFile.createTime);
                        setDisplayFileCreateTime(
                            apiFileCreateTime.format(
                                apiFileCreateTime.isSame(moment(), 'year') ? (apiFileCreateTime.isSame(moment(), 'day') ? TIME_ONLY_FORMAT : FILE_DAY_FORMAT_FORMAT) : FILE_DAY_YEAR_FORMAT_FORMAT
                            )
                        );
                    }
                }
            );
        } else {
            setApiFile(undefined);
            setApiFileList([]);
            setUrl(undefined);
            setIndex(-1);
        }
    }, [api, cloudfrontUrl, location.search, params]);

    const goPrevious = React.useCallback(() => {
        const queryParams = new URLSearchParams(location.search);
        if (!apiFileList) return;
        if (index > 0) {
            const i = index - 1;
            const files = apiFileList[i];
            queryParams.set(QUERY_STRING_TYPE.FILE_ID, files?.fileId);
            navigate({ search: queryParams.toString() });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, apiFileList]);

    const goNext = React.useCallback(() => {
        const queryParams = new URLSearchParams(location.search);
        if (!apiFileList) return;
        if (index !== apiFileList?.length - 1) {
            const i = index + 1;
            const files = apiFileList[i];
            queryParams.set(QUERY_STRING_TYPE.FILE_ID, files?.fileId);
            navigate({ search: queryParams.toString() });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [index, apiFileList]);

    return (
        <>
            <Backdrop open={url !== undefined} className="fileViewer_modal">
                <div className="fileViewer_wrapper">
                    {isActionItemsVisible && (
                        <div className="fileViewer_header">
                            <div className="fullFlex">
                                <div className="fileViewer_header_meta">
                                    <div className="fileViewer_filename ellip">{displayName}</div>
                                    <div className="fileViewer_filesize">{displayByteCount}</div>
                                </div>
                                <div className="fileViewer_header_meta mt8">
                                    <div className="fileViewer_filesize">
                                        <UserName userId={userId} /> &bull; {displayFileCreateTime}
                                    </div>
                                </div>
                            </div>
                            <div className="fileViewer_header_actions">
                                <button aria-label="Close" type="button" onClick={onClose}>
                                    <Close />
                                </button>
                            </div>
                        </div>
                    )}
                    <div className="fileViewer_body" style={{ overflow: apiFile && isVideo(apiFile.contentType) ? 'hidden' : '' }}>
                        {isActionItemsVisible && index > 0 && (
                            <button onClick={goPrevious} className="fileViewer_prevBtn">
                                <ArrowBackIos />
                            </button>
                        )}
                        <div
                            ref={containerRef}
                            className="fileViewer_docs"
                            onClick={() => {
                                setIsActionItemsVisible((isVisible) => !isVisible);
                            }}
                        >
                            {apiFile && url && (
                                <>
                                    {isPdf(apiFile.contentType) ? (
                                        <PdfViewer url={url} width={containerRef.current.clientWidth} height={containerRef.current.clientHeight} />
                                    ) : isImage(apiFile.contentType) ? (
                                        <ImageViewer apiFile={apiFile} />
                                    ) : isVideo(apiFile.contentType) ? (
                                        <VideoViewer url={url}></VideoViewer>
                                    ) : isOffice(apiFile.contentType) ? (
                                        <OfficeViewer apiFile={apiFile} />
                                    ) : (
                                        <></>
                                    )}
                                </>
                            )}
                        </div>
                        {isActionItemsVisible && apiFileList?.length !== index + 1 && (
                            <button onClick={goNext} className="fileViewer_nextBtn">
                                <ArrowForwardIos />
                            </button>
                        )}
                    </div>
                    {isActionItemsVisible && (
                        <div className="fileViewer_footer">
                            <div className="fullFlex">
                                <div className="fileViewer_footer_actions">
                                    <button aria-label="Message" type="button">
                                        <ForwardToInboxOutlined />
                                    </button>
                                    <button
                                        aria-label="Download"
                                        type="button"
                                        onClick={() => {
                                            downloadFile();
                                        }}
                                    >
                                        <FileDownloadOutlined />
                                    </button>
                                    {loggedInUserId === apiFile?.userId && (
                                        <button aria-label="Remove" type="button" onClick={() => setIsFileDeleteModalOpen(true)}>
                                            <DeleteOutlined />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                <a ref={downloadElement} hidden={true} href="https://app.teamcake.com" download="download">
                    download
                </a>
            </Backdrop>
            <ModalPrompt
                isOpen={isFileDeleteModalOpen}
                title="Delete File"
                prompt="Are you sure you want to delete this file?"
                onYesCallback={onDeleteFile}
                onCloseCallback={() => {
                    setIsFileDeleteModalOpen(false);
                }}
            />
        </>
    );
});

export default FileViewer;
