import React, { useReducer } from 'react';
import { AxiosResponse } from 'axios';
import { SearchMessageThreadListResponse, useApi } from '../../providers/Api';
import { useWebSocket } from '../../providers/WebSocket';
import { useLocation, useNavigate, useOutlet, useParams } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import NoMessageIcon from '../../assets/images/no_message.svg';
import PlaceholderIcon from '../../assets/images/placeholder-thread.svg';
import NoSearchResult from '../../assets/images/no_search.svg';
import { Create } from '@mui/icons-material';
import { Divider } from '@mui/material';
import MessageThreadList from './MessageThreadList';
import { INBOX_PARAM_TYPE, SCREEN_TYPE } from '../../_global/Route';
import { MESSAGE_THREAD_NAV_TYPE, useMessageThreadNav } from '../../providers/MessageThreadNav';
import { appendApiMessageThreadList, initSearchMessageThreadListState, MessageThreadListReducer, setApiMessageThreadList, webSocketMessageThread } from '../../_reducers/MessageThreadListReducer';
import { useSelector } from '../../_redux/_Store';
import InboxHeader from './InboxHeader';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import { useDrawer } from '../../providers/Drawer';

const SearchMessageThreadList = React.memo(() => {
    const api = useApi();
    const webSocket = useWebSocket();
    const location = useLocation();
    const navigate = useNavigate();
    const drawer = useDrawer();
    const messageThreadFilter = useMessageThreadFilter();
    const params = useParams<INBOX_PARAM_TYPE>();
    const messageThreadNav = useMessageThreadNav();
    const outlet = useOutlet();
    const webSocketPayloadMessageThread = useSelector((state) => state.webSocket?.webSocketPayloadMessageThread);
    const [messageThreadListReducer, dispatch] = useReducer(MessageThreadListReducer, initSearchMessageThreadListState);
    const [hasMore, setHasMore] = React.useState(true);
    const [loading, setLoading] = React.useState(true);
    const [noResults, setNoResults] = React.useState(false);
    const initMessageThreadList = React.useCallback(() => {
        setLoading(true);
        api.searchMessageThreadList(messageThreadFilter.getSearchMessageThreadListRequest(), (axiosResponse: AxiosResponse) => {
            const searchMessageThreadListResponse: SearchMessageThreadListResponse = axiosResponse.data;
            setHasMore(searchMessageThreadListResponse.hasMore);
            dispatch(setApiMessageThreadList(searchMessageThreadListResponse.apiMessageThreadList));
            setLoading(false);
            setNoResults(searchMessageThreadListResponse.apiMessageThreadList.length === 0);
        });
    }, [api, messageThreadFilter]);
    const loadMoreMessageThreads = React.useCallback(() => {
        if (!hasMore || !messageThreadListReducer) return;
        api.searchMessageThreadList(messageThreadFilter.getSearchMessageThreadListRequest(messageThreadListReducer.minSortTime), (axiosResponse: AxiosResponse) => {
            const searchMessageThreadListResponse: SearchMessageThreadListResponse = axiosResponse.data;
            setHasMore(searchMessageThreadListResponse.hasMore);
            dispatch(appendApiMessageThreadList(searchMessageThreadListResponse.apiMessageThreadList));
            setLoading(false);
        });
    }, [hasMore, messageThreadListReducer, api, messageThreadFilter]);
    const navigateToMessageThread = React.useCallback(
        (type: MESSAGE_THREAD_NAV_TYPE) => {
            if (!messageThreadListReducer) return;
            messageThreadNav.navigateToMessageThread(navigate, type, messageThreadListReducer.apiMessageThreadList, params.MESSAGE_THREAD_ID, SCREEN_TYPE.SEARCH, location.search);
        },
        [location.search, messageThreadListReducer, messageThreadNav, navigate, params.MESSAGE_THREAD_ID]
    );
    React.useEffect(() => {
        if (messageThreadFilter.filterList) {
            initMessageThreadList();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageThreadFilter.filterList]);
    React.useEffect(() => {
        webSocket.addOnConnectCallback(initMessageThreadList);
        return () => {
            webSocket.removeOnConnectCallback(initMessageThreadList);
        };
    }, [initMessageThreadList, webSocket]);
    React.useEffect(() => {
        messageThreadNav.registerCallback(navigateToMessageThread);
        return () => {
            messageThreadNav.unregisterCallback(navigateToMessageThread);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [messageThreadListReducer, params.MESSAGE_THREAD_ID, location.search]);
    React.useEffect(() => {
        if (!webSocketPayloadMessageThread) return;
        dispatch(webSocketMessageThread(webSocketPayloadMessageThread));
    }, [webSocketPayloadMessageThread]);
    React.useEffect(() => {
        return () => {
            messageThreadFilter.removeAllFilters();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <>
            <div className="messageThreadList_inboxWrapper">
                <InboxHeader isSearch={true} />
                <div className="messageThreadList_ItemWrapper">
                    {loading || !messageThreadListReducer ? (
                        <div className="commonProgress">
                            <CircularProgress />
                        </div>
                    ) : noResults ? (
                        <div className="messageThreadList_noResult">
                            <img src={NoSearchResult} alt="search_icon" />
                            <h2>No Messages Found</h2>
                            <div className="messageThreadList_noResultInfo">No messages match filters/search. Try again.</div>
                        </div>
                    ) : (
                        <>
                            {messageThreadListReducer.length > 0 ? (
                                <MessageThreadList isSearch={true} apiMessageThreadList={messageThreadListReducer.apiMessageThreadList} loadMoreCallback={loadMoreMessageThreads} />
                            ) : (
                                <div className="messageThreadList_noMessage">
                                    <div className="textCenter">
                                        <img src={NoMessageIcon} alt="no_message" />
                                        <div className="messageThreadList_noMessageInner">
                                            <h2>Congratulations!</h2>
                                            <p className="messageThreadList_noMessage_subInfo">You have no messages in your inbox.</p>
                                        </div>
                                        <div>
                                            <button
                                                className="commonComposeBtn"
                                                onClick={() => {
                                                    drawer.openCompose();
                                                }}
                                            >
                                                <Create /> Compose Message
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            )}
                        </>
                    )}
                </div>
            </div>
            <Divider orientation="vertical" />
            <div className="messageThreadList_messageThreadWrapper">
                {outlet || (
                    <div className="messageThreadList_placeholder">
                        <img src={PlaceholderIcon} alt="placeholder-message-thread" />
                    </div>
                )}
            </div>
        </>
    );
});

export default SearchMessageThreadList;
