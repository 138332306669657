import React from 'react';
import { GetMessageThreadResponse, useApi } from '../../providers/Api';
import { useSnackbar } from '../../providers/Snackbar';
import { ApiGif } from '../../_api/_ApiModels';
import { useSelector } from '../../_redux/_Store';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import InsertPhotoOutlined from '@mui/icons-material/InsertPhotoOutlined';
import AddPhotoAlternateOutlined from '@mui/icons-material/AddPhotoAlternateOutlined';
import DocumentScannerOutlined from '@mui/icons-material/DocumentScannerOutlined';
import { Button, CircularProgress, IconButton, Modal, Tooltip, Typography } from '@mui/material';
import { AxiosResponse } from 'axios';
import { useWebSocket, WEBSOCKET_PAYLOAD_TYPE } from '../../providers/WebSocket';
import { useDropzone } from 'react-dropzone';
import { useFileUpload } from '../../providers/FileUpload';
import { useMessage } from '../../providers/Message';
import moment from 'moment';
import InView from 'react-intersection-observer';
import { CALENDAR_FORMATS, TOOLTIP_TEXTS, TYPING_SEND_MILLISECONDS } from '../../_global/Constants';
import ScrollToBottom from 'react-scroll-to-bottom';
import MessageReply from './MessageReply';
import MessageThreadMessage from './MessageThreadMessage';
import Typing from './Typing';
import { Star, AttachFileOutlined, ArrowBackOutlined, ArrowForwardOutlined, EditOutlined, NotificationsActive, Inbox, Close, NotificationsOffOutlined } from '@mui/icons-material';
import { ReactComponent as PeopleTags } from '../../assets/images/tagsnpeople.svg';
import InboxOff from '../../assets/images/icons/inbox_slash.svg';
import { useLocation, useParams } from 'react-router-dom';
import { INBOX_PARAM_TYPE, SCREEN_TYPE, LAYOUT_TYPE } from '../../_global/Route';
import { useMessageThreadNav } from '../../providers/MessageThreadNav';
import { addNewMessage, initMessageListState, MessageListReducer, setApiMessageThread, setIsStarred, setIsLoading } from '../../_reducers/MessageListReducer';
import { useDrawer } from '../../providers/Drawer';
import MessageThreadObject from './MessageThreadObject';
import ObjectID from 'bson-objectid';
import MessageThreadHeader from './MessageThreadHeader';

const MessageThread = React.memo(() => {
    const location = useLocation();
    const api = useApi();
    const message = useMessage();
    const drawer = useDrawer();
    const snackbar = useSnackbar();
    const params = useParams<INBOX_PARAM_TYPE>();
    const { state }: any = location;
    const fileUpload = useFileUpload();
    const webSocket = useWebSocket();
    const messageThreadNav = useMessageThreadNav();
    const loggedInUserId = useSelector((state) => state.data.userId);
    const hasFocus = useSelector((state) => state.data.hasFocus);
    const webSocketPayloadMessageThread = useSelector((state) => state.webSocket?.webSocketPayloadMessageThread);
    const [messageListReducer, dispatchMessageListReducer] = React.useReducer(MessageListReducer, initMessageListState);
    const bottomRef = React.useRef<null | HTMLDivElement>(null);
    const [messageThreadId, setMessageThreadId] = React.useState(params.MESSAGE_THREAD_ID);
    const [typing, setTyping] = React.useState(false);
    const [isEditSubjectOpen, setIsEditSubjectOpen] = React.useState(false);
    const [editedSubject, setEditedSubject] = React.useState('');
    const [newMessageId, setNewMessageId] = React.useState<string>(ObjectID().toHexString());
    const [typingInterval, setTypingInterval] = React.useState<NodeJS.Timeout>();
    const onNotificationClicked = React.useCallback(() => {
        if (!messageListReducer) return;
        api.toggleNotification({
            messageThreadId: messageListReducer.messageThreadId,
            notification: !messageListReducer.isNotification
        });
    }, [api, messageListReducer]);
    const toggleStar = React.useCallback(() => {
        if (!messageThreadId || !messageListReducer) return;
        api.updateStarred({
            messageThreadId: messageThreadId,
            starred: !messageListReducer.isStarred
        });
        dispatchMessageListReducer(setIsStarred(!messageListReducer.isStarred));
    }, [api, messageListReducer, messageThreadId]);
    const onUndoInboxClick = React.useCallback(
        (event) => {
            event.preventDefault();
            if (!messageListReducer) return;
            api.toggleInbox({ messageThreadId: messageListReducer.messageThreadId, inbox: messageListReducer.isInbox });
        },
        [api, messageListReducer]
    );
    const onInboxClicked = React.useCallback(() => {
        if (!messageListReducer) return;
        api.toggleInbox(
            {
                messageThreadId: messageListReducer.messageThreadId,
                inbox: !messageListReducer.isInbox
            },
            () => {
                if (location.pathname.match(`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.MAIN}`)) {
                    snackbar.displayInfo({
                        message: 'Thread Removed from Inbox',
                        leftIcon: <img src={InboxOff} alt="InboxOff" />,
                        action: {
                            label: 'Undo',
                            callback: onUndoInboxClick
                        }
                    });
                    messageThreadNav.next();
                }
            }
        );
    }, [api, location.pathname, messageListReducer, messageThreadNav, onUndoInboxClick, snackbar]);
    const onEditedSubjectChanged = React.useCallback((value: string) => {
        if (value.length > 60) return;
        setEditedSubject(value);
    }, []);
    const onDrop = React.useCallback(
        (fileList: Array<File>) => {
            if (!messageThreadId || !messageListReducer) return;
            for (const file of fileList) {
                fileUpload.upload(file, messageThreadId, newMessageId);
            }
        },
        [fileUpload, messageListReducer, messageThreadId, newMessageId]
    );
    const { getRootProps, isDragActive } = useDropzone({ onDrop, noClick: true });
    const scrollToBottom = React.useCallback(() => {
        bottomRef.current?.scrollIntoView({ behavior: 'auto' });
    }, [bottomRef]);
    const onCreateMessage = React.useCallback(
        (messageThreadId: string, text: string, fileIdList: Array<string>, apiGifList: Array<ApiGif>) => {
            if (!loggedInUserId || !messageListReducer) return;
            const createTime = moment().unix();
            fileUpload.setMessageSent(newMessageId);
            dispatchMessageListReducer(
                addNewMessage({
                    messageId: newMessageId,
                    createdUserId: loggedInUserId,
                    createTime: createTime,
                    text: text,
                    fileIdList: fileIdList,
                    apiGifList: apiGifList
                })
            );
            if (typingInterval) {
                clearInterval(typingInterval);
                setTypingInterval(undefined);
            }
            setTyping(false);
            message.createMessage(messageThreadId, newMessageId, text, fileIdList, apiGifList);
            setNewMessageId(ObjectID().toHexString());
            // This is to scroll the message list to bottom once the new message sent and added to reducer
            setTimeout(() => {
                scrollToBottom();
            }, 50)
        },
        [fileUpload, loggedInUserId, message, messageListReducer, newMessageId, typingInterval, scrollToBottom]
    );
    const onSaveEditedSubjectClicked = React.useCallback(() => {
        if (!messageThreadId) return;
        api.updateSubject({
            messageThreadId: messageThreadId,
            subject: editedSubject
        });
        setIsEditSubjectOpen(false);
    }, [api, editedSubject, messageThreadId]);
    const onMessageVisibilityChanged = React.useCallback(
        (messageId: string, isSent: boolean, isVisible: boolean) => {
            if (!messageThreadId || !messageListReducer) return;
            if (loggedInUserId && isVisible && !messageListReducer.seenMessageIdList.includes(messageId)) {
                api.messageSeen({
                    messageThreadId: messageThreadId,
                    messageId: messageId
                });
            }
        },
        [api, loggedInUserId, messageListReducer, messageThreadId]
    );
    const onTyping = React.useCallback(
        (value) => {
            if (!typing) {
                webSocket.webSocket?.send(
                    JSON.stringify({
                        type: WEBSOCKET_PAYLOAD_TYPE.TYPING,
                        messageThreadId: messageThreadId
                    })
                );
                setTyping(true);
                setTypingInterval(
                    setTimeout(() => {
                        setTyping(false);
                        setTypingInterval(undefined);
                    }, TYPING_SEND_MILLISECONDS)
                );
            }
        },
        [messageThreadId, typing, webSocket.webSocket]
    );
    const loadMessageThread = React.useCallback(
        (messageThreadId) => {
            if (!messageThreadId) return;
            dispatchMessageListReducer(setIsLoading());
            api.getMessageThread({ messageThreadId: messageThreadId }, (axiosResponse: AxiosResponse) => {
                const getMessageThreadResponse: GetMessageThreadResponse = axiosResponse.data;
                dispatchMessageListReducer(setApiMessageThread(getMessageThreadResponse.apiMessageThread));
                if (!getMessageThreadResponse.apiMessageThread.isSeen) {
                    api.messageThreadSeen({ messageThreadId: messageThreadId });
                }
                setEditedSubject(getMessageThreadResponse.apiMessageThread.subject);
            });
            fileUpload.clear(newMessageId);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [api]
    );
    React.useEffect(() => {
        setMessageThreadId(params.MESSAGE_THREAD_ID);
        loadMessageThread(params.MESSAGE_THREAD_ID);
        const reloadMessageThread = () => {
            loadMessageThread(params.MESSAGE_THREAD_ID);
        };
        webSocket.addOnConnectCallback(reloadMessageThread);
        return () => {
            webSocket.removeOnConnectCallback(reloadMessageThread);
        };
    }, [loadMessageThread, params.MESSAGE_THREAD_ID, webSocket]);
    React.useEffect(() => {
        if (!webSocketPayloadMessageThread || webSocketPayloadMessageThread.apiMessageThread.messageThreadId !== messageThreadId) {
            return;
        }
        if (hasFocus && !webSocketPayloadMessageThread.apiMessageThread.isSeen) {
            api.messageThreadSeen({ messageThreadId: messageThreadId });
        }
        dispatchMessageListReducer(setApiMessageThread(webSocketPayloadMessageThread.apiMessageThread));
    }, [api, hasFocus, messageThreadId, webSocketPayloadMessageThread]);
    return (
        <>
            {messageThreadId && messageListReducer && (
                <>
                    <div className="messageThread_Wrapper" {...getRootProps()}>
                        <div
                            className="common_uploadWrap"
                            style={{
                                opacity: isDragActive ? 1 : 0,
                                visibility: isDragActive ? 'visible' : 'hidden',
                                transition: 'opacity 90ms ease-in'
                            }}
                        >
                            <div className="common_uploadInner">
                                <div className="uploadIcons">
                                    <InsertPhotoOutlined fontSize="large" />
                                    <AddPhotoAlternateOutlined fontSize="large" />
                                    <DocumentScannerOutlined fontSize="large" />
                                </div>
                                <Typography variant="h2">Upload Files</Typography>
                            </div>
                        </div>
                        <div className="messageThread_headerWithNavs">
                            <div className="messageThread_headerAction">
                                <Tooltip title={messageListReducer.isNotification ? TOOLTIP_TEXTS.turnOffNotification : TOOLTIP_TEXTS.turnOnNotification} placement="bottom-start" arrow>
                                    <IconButton size="small" onClick={onNotificationClicked}>
                                        {messageListReducer.isNotification ? <NotificationsActive className="active" /> : <NotificationsOffOutlined />}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={messageListReducer.isInbox ? TOOLTIP_TEXTS.removeThread : TOOLTIP_TEXTS.moveThread} placement="bottom-start" arrow>
                                    <IconButton size="small" onClick={onInboxClicked}>
                                        {messageListReducer.isInbox ? <Inbox className="active" /> : <img src={InboxOff} alt="InboxOff" />}
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={messageListReducer.isStarred ? TOOLTIP_TEXTS.removeStar : TOOLTIP_TEXTS.addStar} placement="bottom-start" arrow>
                                    <IconButton size="small" onClick={toggleStar}>
                                        {messageListReducer.isStarred ? <Star className="starred" /> : <StarOutlineIcon />}
                                    </IconButton>
                                </Tooltip>
                            </div>
                            <div className="messageThread_headerNavs">
                                {messageListReducer.fileCount === 0 ? (
                                    <IconButton size="small" disabled={true}>
                                        <AttachFileOutlined />
                                    </IconButton>
                                ) : (
                                    <Tooltip title={TOOLTIP_TEXTS.viewAttachment} placement="bottom-start" arrow>
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                drawer.openMessageThreadFileList(messageThreadId);
                                            }}
                                        >
                                            <span className="messageThread_attachments">{messageListReducer.fileCount}</span>
                                            <AttachFileOutlined />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                <Tooltip title={TOOLTIP_TEXTS.viewPeopleInThread} placement="bottom-start" arrow>
                                    <IconButton size="small" onClick={() => drawer.openMessageThreadInfo(messageThreadId)}>
                                        <PeopleTags className="peopleTag" />
                                    </IconButton>
                                </Tooltip>
                                {state?.isFirstMessageThread ? (
                                    <IconButton size="small" className="messageThread_prev" disabled>
                                        <ArrowBackOutlined />
                                    </IconButton>
                                ) : (
                                    <Tooltip title={TOOLTIP_TEXTS.viewPreviousThread} placement="bottom-end" arrow>
                                        <IconButton size="small" className="messageThread_prev" onClick={() => messageThreadNav.prev()}>
                                            <ArrowBackOutlined />
                                        </IconButton>
                                    </Tooltip>
                                )}
                                {state?.isLastMessageThread ? (
                                    <IconButton size="small" disabled onClick={() => messageThreadNav.next()}>
                                        <ArrowForwardOutlined />
                                    </IconButton>
                                ) : (
                                    <Tooltip title={TOOLTIP_TEXTS.viewNextThread} placement="bottom-end" arrow>
                                        <IconButton size="small" onClick={() => messageThreadNav.next()}>
                                            <ArrowForwardOutlined />
                                        </IconButton>
                                    </Tooltip>
                                )}
                            </div>
                        </div>
                        {messageListReducer.isLoading ? (
                            <div className="commonProgress">
                                <CircularProgress />
                            </div>
                        ) : (
                            <>
                                <div className="messageThread_header">
                                    <h2>{messageListReducer.subject} </h2>
                                    <div className="messageThread_headerEdit">
                                        <EditOutlined
                                            onClick={() => {
                                                setIsEditSubjectOpen(true);
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="messageThread_subscriptionPreview">
                                    <MessageThreadObject apiMessageThreadObject={messageListReducer.apiMessageThreadObject} />
                                </div>
                                <div className="messageThread_calloutPreview">
                                    <MessageThreadHeader
                                        messageThreadId={messageThreadId}
                                        userIdList={messageListReducer.userIdList}
                                        tagIdList={messageListReducer.tagIdList}
                                        notificationUserIdList={messageListReducer.notificationUserIdList}
                                    />
                                </div>
                                <div className="messageThread_datanReply">
                                    <div className="messageThread_contentArea">
                                        <ScrollToBottom initialScrollBehavior="auto" className="messageThread_scrollArea">
                                            {messageListReducer.messageList.map((message) => (
                                                <InView
                                                    as="div"
                                                    /*TODO: SK: Review below logic. Better way of doing this with styles?*/
                                                    onChange={(inView) => {
                                                        onMessageVisibilityChanged(message.messageId, message.isSent, inView);
                                                    }}
                                                    key={message.messageId}
                                                    className={
                                                        message.isInGroup
                                                            ? message.isFirstInGroup
                                                                ? ' messageThread_chatFirstInGroup'
                                                                : message.isLastInGroup
                                                                ? ' messageThread_chatLastInGroup'
                                                                : 'messageThread_chatInGroup'
                                                            : ''
                                                    }
                                                >
                                                    {message.isDisplayDateSeparator && (
                                                        <div className="messagThread_seperator">
                                                            <div className="messagThread_borderLine"></div>
                                                            <div className="messagThread_messageUpdateTime">{moment.unix(message.createTime).calendar(CALENDAR_FORMATS)}</div>
                                                        </div>
                                                    )}
                                                    <div className="messageThread_chatBubble">
                                                        <MessageThreadMessage
                                                            messageThreadId={messageThreadId}
                                                            messageId={message.messageId}
                                                            createTime={message.createTime}
                                                            createdUserId={message.createdUserId}
                                                            text={message.text}
                                                            editTime={message.editTime}
                                                            apiFileList={message.apiFileList}
                                                            fileIdList={message.fileIdList}
                                                            apiGifList={message.apiGifList}
                                                            apiReactionList={message.apiReactionList}
                                                            isDeleted={message.isDeleted}
                                                            isSent={message.isSent}
                                                            isSameUser={message.createdUserId === loggedInUserId}
                                                            displayMenuButton={true}
                                                            isFirstInGroup={message.isFirstInGroup}
                                                            isInGroup={message.isInGroup}
                                                            isLastInGroup={message.isLastInGroup}
                                                            isSeen={message.isSeen}
                                                        />
                                                    </div>
                                                </InView>
                                            ))}
                                            <div ref={bottomRef} style={{ height: 0 }}></div>
                                        </ScrollToBottom>
                                        <Typing messageThreadId={messageThreadId} />
                                        <MessageReply
                                            messageThreadId={messageThreadId}
                                            messageId={newMessageId}
                                            placeholder="Type Response"
                                            sendMessageCallback={onCreateMessage}
                                            onChange={onTyping}
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                    <Modal
                        open={isEditSubjectOpen}
                        onClose={() => {
                            setIsEditSubjectOpen(false);
                        }}
                    >
                        <div className="commonModal_wrapper">
                            <div className="commonModal_header">
                                <span className="commonModal_headerText">Edit Subject</span>
                                <button
                                    className="commonModal_headerClose"
                                    type="button"
                                    onClick={() => {
                                        setIsEditSubjectOpen(false);
                                    }}
                                >
                                    <Close />
                                </button>
                            </div>
                            <div className="commonModal_body">
                                <div className="commonTextarea">
                                    <textarea
                                        onChange={(e) => {
                                            onEditedSubjectChanged(e.target.value);
                                        }}
                                        value={editedSubject}
                                        aria-label="Edit Subject"
                                    />
                                </div>
                            </div>
                            <div className="commonModal_footer">
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        setIsEditSubjectOpen(false);
                                    }}
                                >
                                    Cancel
                                </Button>
                                <Button color="primary" variant="contained" onClick={onSaveEditedSubjectClicked}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </Modal>
                </>
            )}
        </>
    );
});

export default MessageThread;
