import React from 'react';
import { ApiFile, ApiGif } from '../../_api/_ApiModels';
import { FILE_IMAGE_TYPE } from '../../_global/_Enums';
import { getCloudfrontUrl } from '../../_global/Helpers';
import {
    getFileThumb,
    isThumbnailable,
    UNKNOWN_FILE_THUMB
} from '../../_global/FileUtils';
import MessageFile from './MessageFile';
import MessageFileSent from './MessageFileSent';
import MessageGifImage from './MessageGifImage';
import { useSelector } from '../../_redux/_Store';

interface MessageThreadMessageAttachmentsProps {
    isMessageSent: boolean;
    isMessageSeen: boolean;
    apiFileList: Array<ApiFile>;
    apiGifList: Array<ApiGif>;
    notUploadedFileList: Array<string>;
    messageId: string;
    messageThreadId: string;
}

interface FileDisplay {
    fileId: string;
    previewUrl: string;
    contentType: string;
    url: string;
    displayName: string;
    byteCount: number;
    userId: string;
    showAsThumbnail: boolean;
}

const MessageThreadMessageAttachments = React.memo<MessageThreadMessageAttachmentsProps>((props) => {

    const [fileList, setFileList] = React.useState<Array<FileDisplay>>([]);
    const [thumbnailList, setThumbnailList] = React.useState<Array<FileDisplay>>([]);
    const [isSingleFile, setIsSingleFile] = React.useState(false);
    const cloudfrontUrl = useSelector((state) => state.data.apiOrganization?.cloudfrontUrl) ?? '';

    React.useEffect(() => {

        const filesToDisplay = props.apiFileList.map<FileDisplay>((apiFile) => {
            const messageListApiFileImage = apiFile.imageList.find((file) => file.fileImageType === FILE_IMAGE_TYPE.MESSAGE_LIST);
            const messageListImageUrl = messageListApiFileImage ? getCloudfrontUrl(cloudfrontUrl, messageListApiFileImage.key) : null;
            const fileThumb = getFileThumb(apiFile.contentType);
            const previewUrl = messageListImageUrl ? messageListImageUrl : fileThumb ? fileThumb : UNKNOWN_FILE_THUMB;
            return {
                fileId: apiFile.fileId,
                previewUrl: previewUrl,
                contentType: apiFile.contentType,
                url: getCloudfrontUrl(cloudfrontUrl, apiFile.key),
                displayName: apiFile.displayName,
                byteCount: apiFile.byteCount,
                userId: apiFile.userId,
                showAsThumbnail: isThumbnailable(apiFile.contentType)
            };
        });

        setFileList(filesToDisplay.filter((f) => !f.showAsThumbnail));
        setThumbnailList(filesToDisplay.filter((f) => f.showAsThumbnail));
        setIsSingleFile((filesToDisplay.length + props.apiGifList.length) === 1);

    }, [props, cloudfrontUrl]);

    return <>
        <div className="messageThreadMessage_attachedImage">

            {props.apiGifList.map((apiGif) => (
                <MessageGifImage gif={apiGif} autoPlay={!props.isMessageSeen}  key={apiGif.gifId} />
            ))}

            {!props.isMessageSent && props.notUploadedFileList.length > 0 ? (
                <ul className={`commonImageList ${isSingleFile ? 'singleFile' : ''}`}>
                    {props.notUploadedFileList.map((fileId) => (
                        <li key={fileId}>
                            <MessageFile fileId={fileId} />
                        </li>
                    ))}
                </ul>
            ) : null}

            {thumbnailList.length > 0 ? (
                <ul className={`commonImageList ${isSingleFile ? 'singleFile' : ''}`}>
                    {thumbnailList.map((fileDisplay) => (
                        <li key={fileDisplay.fileId}>
                            <MessageFileSent
                                contentType={fileDisplay.contentType}
                                key={fileDisplay.fileId}
                                fileId={fileDisplay.fileId}
                                src={fileDisplay.previewUrl}
                                displayName={fileDisplay.displayName}
                                byteCount={fileDisplay.byteCount}
                                url={fileDisplay.url}
                                userId={fileDisplay.userId}
                                messageId={props.messageId}
                                messageThreadId={props.messageThreadId} />
                        </li>
                    ))}
                </ul>
            ) : null}

            {fileList.map((fileDisplay) => (
                <ul className="commonImageList singleFile" key={fileDisplay.fileId}>
                    <li>
                        <MessageFileSent
                            contentType={fileDisplay.contentType}
                            key={fileDisplay.fileId}
                            fileId={fileDisplay.fileId}
                            src={fileDisplay.previewUrl}
                            displayName={fileDisplay.displayName}
                            byteCount={fileDisplay.byteCount}
                            url={fileDisplay.url}
                            userId={fileDisplay.userId}
                            messageId={props.messageId}
                            messageThreadId={props.messageThreadId} />
                    </li>
                </ul>
            ))}
        </div>
    </>;


});

export default MessageThreadMessageAttachments;