import React from 'react';
import { Fab, IconButton } from '@mui/material';
import { Create, Inbox, Star, People, Settings, Support, Logout, AlternateEmailOutlined, MenuOpenOutlined, Menu, Sell, OpenInNewOutlined, LabelOutlined, MessageOutlined } from '@mui/icons-material';
import { useSelector } from '../../_redux/_Store';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { SCREEN_TYPE, LAYOUT_TYPE } from '../../_global/Route';
import UserAvatar from './UserAvatar';
import LogoImg from '../../assets/images/cake-logo-horizontal.svg';
import SortIcon from '../../assets/images/icons/sort.svg';
import UserTagList from './UserTagList';
import { useAuth } from '../../providers';
import { useDrawer } from '../../providers/Drawer';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import TagCategoryMenu from '../inbox/TagCategoryMenu';
import PathNavLink from './PathNavLink';
import { MESSAGE_THREAD_SEARCH_FILTER_TYPE } from '../../_api/_ApiModels';
import SidebarMessageThreadSearchList from './SidebarMessageThreadSearchList';
import Send from '@mui/icons-material/Send';

const Sidebar = React.memo(() => {
    const auth = useAuth();
    const drawer = useDrawer();
    const location = useLocation();
    const messageThreadFilter = useMessageThreadFilter();
    const loggedInUserId = useSelector((state) => state.data.userId) || '';
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const apiHashtagList = useSelector((state) => state.data.apiHashtagList);
    const isAdmin = useSelector((state) => state.data.isAdmin);
    const [isCollapseSidebar, setIsCollapseSidebar] = React.useState(false);
    const [inboxNotSeenCount, setInboxNotSeenCount] = React.useState<number>(0);
    const [starredCount, setStarredCount] = React.useState<number>(0);
    const [isTagCategoryMenuOpen, setIsTagCategoryMenuOpen] = React.useState<boolean>(false);
    const [isShortcutsVisible, setIsShortcutsVisible] = React.useState<boolean>(false);
    const [isManageHashtagsVisible, setIsManageHashtagsVisible] = React.useState<boolean>(false);
    const [tagCategoryMenuAnchor, setTagCategoryMenuAnchor] = React.useState<HTMLElement | null>(null);
    const onToggleSidebar = React.useCallback(() => {
        setIsCollapseSidebar((oldState) => !oldState);
    }, []);
    const onLogoutClicked = React.useCallback(() => {
        auth.clearToken();
    }, [auth]);
    React.useEffect(() => {
        if (!loggedInUserId) return;
        const loggedInApiUser = apiUserList[loggedInUserId];
        if (!loggedInApiUser) return;
        setInboxNotSeenCount(loggedInApiUser.inboxNotSeenCount);
        setStarredCount(loggedInApiUser.starredCount);
        setIsShortcutsVisible(loggedInApiUser.apiMessageThreadSearchList.length > 0);
        setIsManageHashtagsVisible(isAdmin || Object.values(apiHashtagList).filter((obj) => obj.adminUserIdList.includes(loggedInUserId)).length > 0);
    }, [apiHashtagList, apiUserList, isAdmin, loggedInUserId]);
    return (
        <>
            <div className={'sidebarWrapper' + (isCollapseSidebar ? ' sidebarCollapsed' : '')}>
                <div className="sidebar_header">
                    <div className="sidebar_logo">
                        <a href="/">
                            <img src={LogoImg} alt="Cake Logo" />
                        </a>
                        <IconButton onClick={onToggleSidebar}>{isCollapseSidebar ? <Menu /> : <MenuOpenOutlined />}</IconButton>
                    </div>
                    <div className="inbox_fabButtonWrap">
                        <Fab
                            className="inbox_fabButton"
                            variant="extended"
                            size="small"
                            color="primary"
                            onClick={() => {
                                drawer.openCompose();
                            }}
                        >
                            <Create />
                            <span>Compose</span>
                        </Fab>
                    </div>
                </div>
                <nav className="sidebar_panel">
                    <ul>
                        <NavLink to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.MAIN}`}>
                            <li>
                                <div className="sidebar_option">
                                    <Inbox />
                                    <span className="sidebar_link">Inbox</span>
                                </div>
                                <div className="sidebar_countWrap">{inboxNotSeenCount !== 0 && <span className="sidebar_count">{inboxNotSeenCount}</span>}</div>
                            </li>
                        </NavLink>
                        {/*
                            <NavLink to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.DRAFTS}`}>
                                <li>
                                    <div className="sidebar_option">
                                        <Drafts />
                                        <span className="sidebar_link">Drafts</span>
                                    </div>
                                </li>
                            </NavLink>
                        */}
                        <PathNavLink
                            to={messageThreadFilter.getUrl([
                                {
                                    type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_STARRED,
                                    value: true
                                }
                            ])}
                        >
                            <li>
                                <div className="sidebar_option">
                                    <Star />
                                    {isCollapseSidebar ? <span className="sidebar_link">Starred</span> : <span className="sidebar_link">Starred Threads</span>}
                                </div>
                                <div className="sidebar_countWrap">{starredCount !== 0 && <span className="sidebar_count">{starredCount}</span>}</div>
                            </li>
                        </PathNavLink>
                        <PathNavLink
                            to={messageThreadFilter.getUrl([
                                {
                                    type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_CALLED_OUT,
                                    value: true
                                }
                            ])}
                        >
                            <li>
                                <div className="sidebar_option">
                                    <AlternateEmailOutlined />
                                    {isCollapseSidebar ? <span className="sidebar_link">Callouts</span> : <span className="sidebar_link">My Callouts</span>}
                                </div>
                            </li>
                        </PathNavLink>
                        <PathNavLink
                            to={messageThreadFilter.getUrl([
                                {
                                    type: MESSAGE_THREAD_SEARCH_FILTER_TYPE.IS_SENT_BY_USER,
                                    value: true
                                }
                            ])}
                        >
                            <li>
                                <div className="sidebar_option">
                                    <Send />
                                    <span className="sidebar_link">Sent Messages</span>
                                </div>
                            </li>
                        </PathNavLink>
                        {isShortcutsVisible && (
                            <>
                                <div className="solidBorder"></div>
                                <li
                                    className="sidebar-subscrptionMenuAction"
                                    onClick={(e) => {
                                        drawer.openMessageThreadSearchList();
                                    }}
                                >
                                    <div className="sidebar_option">
                                        <OpenInNewOutlined />
                                        <span className="sidebar_link">Shortcuts</span>
                                    </div>
                                    <div className="sidebar_rightIcon pointer">
                                        <Settings />
                                    </div>
                                </li>
                                <li className="sidebar-subscrptionMenu">
                                    <div className="subscription_Wrapper">
                                        <SidebarMessageThreadSearchList />
                                    </div>
                                </li>
                            </>
                        )}
                        <li className="sidebar-subscrptionMenuAction">
                            <div className="sidebar_option">
                                <Sell className="sidebar_sellIcon" />
                                <span className="sidebar_link">Topics</span>
                            </div>
                            <div
                                className="sidebar_rightIcon pointer"
                                onClick={(e) => {
                                    setTagCategoryMenuAnchor(e.currentTarget);
                                    setIsTagCategoryMenuOpen(true);
                                }}
                            >
                                <img src={SortIcon} alt="sorticon" />
                            </div>
                        </li>
                        <li className="sidebar-subscrptionMenu">
                            <div className="subscription_Wrapper">
                                <UserTagList />
                            </div>
                        </li>
                        <NavLink to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.SUBSCRIPTIONS}`}>
                            <li>
                                <div className="sidebar_option">
                                    {/* <SubscriptionsOutlined /> */}
                                    <span className="ManageTagIocn">
                                        <Settings className="settingIocn" />
                                        <MessageOutlined />
                                    </span>
                                    {isCollapseSidebar ? <span className="sidebar_link">Subscriptions</span> : <span className="sidebar_link">Manage Subscriptions</span>}
                                </div>
                            </li>
                        </NavLink>
                        {isManageHashtagsVisible && (
                            <Link
                                to={`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.HASHTAGS}`}
                                className={
                                    location.pathname.startsWith(`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.HASHTAGS}`) || location.pathname.startsWith(`/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.TAGS}`)
                                        ? 'active'
                                        : ''
                                }
                            >
                                <li>
                                    <div className="sidebar_option">
                                        <span className="ManageTagIocn">
                                            <Settings className="settingIocn" />
                                            <LabelOutlined />
                                        </span>
                                        <span className="sidebar_link">Manage Hashtags</span>
                                    </div>
                                </li>
                            </Link>
                        )}
                        <li
                            onClick={() => {
                                drawer.openContacts();
                            }}
                        >
                            <div className="sidebar_option">
                                <People />
                                <span className="sidebar_link">Contacts</span>
                            </div>
                        </li>
                        <li
                            onClick={() => {
                                drawer.openSupport();
                            }}
                        >
                            <div className="sidebar_option">
                                <Support />
                                <span className="sidebar_link">Support</span>
                            </div>
                        </li>
                        <li>
                            <div className="sidebar_profileLink">
                                <div className="sidebar_profile" onClick={() => drawer.openUserProfile(loggedInUserId)}>
                                    <div className="sidebar_userInfoAvatar">{loggedInUserId && <UserAvatar userId={loggedInUserId} displayOnlineIndicator={false} />}</div>
                                    <span className="sidebar_link">My Profile</span>
                                </div>
                                <div className="sidebar_settings">
                                    <Settings />
                                </div>
                            </div>
                        </li>
                        <li onClick={onLogoutClicked} className="logout_link">
                            <div className="sidebar_option">
                                <Logout />
                                <span className="sidebar_link">Log Out</span>
                            </div>
                        </li>
                    </ul>
                </nav>
            </div>
            <TagCategoryMenu
                open={isTagCategoryMenuOpen}
                onClose={() => {
                    setIsTagCategoryMenuOpen(false);
                }}
                anchorEl={tagCategoryMenuAnchor}
            />
        </>
    );
});

export default Sidebar;
