import React from 'react';
import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { ApiMessage, ApiMessageThread } from '../../_api/_ApiModels';
import InboxMessageThread, { DATE_SEPARATOR_TYPE, InboxMessageThreadProps } from './InboxMessageThread';
import { getMessageText } from '../../_global/Helpers';
import moment from 'moment';

interface MessageThreadListProps {
    apiMessageThreadList: Array<ApiMessageThread>;
    loadMoreCallback: (startIndex: number, stopIndex: number) => Promise<void> | void;
    isSearch: boolean;
}

const MessageThreadList = React.memo<MessageThreadListProps>(({ apiMessageThreadList, loadMoreCallback, isSearch }) => {
    const [inboxMessageThreadPropsList, setInboxMessageThreadPropsList] = React.useState<Array<InboxMessageThreadProps>>([]);
    const [length, setLength] = React.useState(0);
    const isMessageThreadLoaded = React.useCallback(
        (index) => {
            if (inboxMessageThreadPropsList) {
                return index < inboxMessageThreadPropsList.length;
            } else return false;
        },
        [inboxMessageThreadPropsList]
    );
    const getInboxMessageThreadProps = React.useCallback(
        (apiMessageThread: ApiMessageThread, lastApiMessage: ApiMessage, dateSeparatorType: DATE_SEPARATOR_TYPE | undefined, userIdList: Array<string>, tagIdList: Array<string>) => {
            return {
                messageThreadId: apiMessageThread.messageThreadId,
                subject: apiMessageThread.subject,
                isStarred: apiMessageThread.isStarred,
                isSeen: apiMessageThread.isSeen,
                isInbox: apiMessageThread.isInbox,
                isNotification: apiMessageThread.isNotification,
                sortTime: apiMessageThread.sortTime,
                messageCount: apiMessageThread.apiMessageList.length,
                fileCount: apiMessageThread.fileCount,
                lastMessageId: lastApiMessage.messageId,
                lastMessageCreatedUserId: lastApiMessage.createdUserId,
                lastMessageCreateTime: lastApiMessage.createTime,
                lastMessageText: getMessageText(lastApiMessage),
                lastMessageIsDeleted: lastApiMessage.deleted,
                dateSeparatorType: dateSeparatorType,
                userIdList: userIdList,
                tagIdList: tagIdList
            } as InboxMessageThreadProps;
        },
        []
    );
    const getInboxMessageThreadPropsList = React.useCallback(
        (apiMessageThreadList: Array<ApiMessageThread>) => {
            const inboxMessageThreadPropsList = [] as Array<InboxMessageThreadProps>;
            const dateSeparatorList = [] as Array<number>;
            const today = moment().startOf('day');
            const yesterday = moment().subtract(1, 'days').startOf('day');
            const lastWeek = moment().subtract(7, 'days').startOf('week');
            for (let i = 0; i < apiMessageThreadList.length; i++) {
                const apiMessageThread = apiMessageThreadList[i];
                let dateSeparatorType: DATE_SEPARATOR_TYPE | undefined;
                if (i === 0 && moment.unix(apiMessageThread.sortTime).isSame(today, 'day')) {
                    dateSeparatorType = DATE_SEPARATOR_TYPE.TODAY;
                    dateSeparatorList.push(today.unix());
                }
                if (moment.unix(apiMessageThread.sortTime).isSame(yesterday, 'day') && !dateSeparatorList.includes(yesterday.unix())) {
                    dateSeparatorType = DATE_SEPARATOR_TYPE.YESTERDAY;
                    dateSeparatorList.push(yesterday.unix());
                }
                if (moment.unix(apiMessageThread.sortTime).isSame(lastWeek, 'week') && !dateSeparatorList.includes(lastWeek.unix())) {
                    dateSeparatorType = DATE_SEPARATOR_TYPE.LAST_WEEK;
                    dateSeparatorList.push(lastWeek.unix());
                }
                if (moment.unix(apiMessageThread.sortTime).isBefore(lastWeek, 'week')) {
                    const startOfMonth = moment.unix(apiMessageThread.sortTime).startOf('month');
                    if (!dateSeparatorList.includes(startOfMonth.unix())) {
                        dateSeparatorType = DATE_SEPARATOR_TYPE.MONTH;
                        dateSeparatorList.push(startOfMonth.unix());
                    }
                }
                const userIdList = [] as Array<string>;
                const reversedApiMessageList = [...apiMessageThread.apiMessageList].reverse();
                for (let i = 0; i < reversedApiMessageList.length; i++) {
                    const apiMessage = reversedApiMessageList[i];
                    if (!userIdList.includes(apiMessage.createdUserId)) {
                        userIdList.push(apiMessage.createdUserId);
                    }
                }
                inboxMessageThreadPropsList.push(
                    getInboxMessageThreadProps(apiMessageThread, apiMessageThread.apiMessageList[apiMessageThread.apiMessageList.length - 1], dateSeparatorType, userIdList, apiMessageThread.tagIdList)
                );
            }
            return inboxMessageThreadPropsList;
        },
        [getInboxMessageThreadProps]
    );
    const getItemKey = React.useCallback((index, data: Array<InboxMessageThreadProps>) => {
        return data && data.length > index ? data[index].messageThreadId : index;
    }, []);
    React.useEffect(() => {
        if (inboxMessageThreadPropsList) {
            setLength(inboxMessageThreadPropsList.length);
        }
    }, [inboxMessageThreadPropsList]);
    React.useEffect(() => {
        setInboxMessageThreadPropsList(getInboxMessageThreadPropsList(apiMessageThreadList));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiMessageThreadList]);
    return (
        <>
            <div className="messageThreadList_ItemWrapper">
                <ReactVirtualizedAutoSizer>
                    {({ height, width }) => (
                        <InfiniteLoader isItemLoaded={isMessageThreadLoaded} itemCount={Number.MAX_VALUE} loadMoreItems={loadMoreCallback} threshold={5}>
                            {({ onItemsRendered, ref }) => (
                                <FixedSizeList
                                    ref={ref}
                                    itemCount={length}
                                    onItemsRendered={onItemsRendered}
                                    itemKey={getItemKey}
                                    itemData={inboxMessageThreadPropsList}
                                    height={height}
                                    width={width}
                                    itemSize={109}
                                    overscanCount={5}
                                >
                                    {InboxMessageThread}
                                </FixedSizeList>
                            )}
                        </InfiniteLoader>
                    )}
                </ReactVirtualizedAutoSizer>
            </div>
        </>
    );
});

export default MessageThreadList;
