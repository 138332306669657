import React from 'react';
import { Button, Modal, Typography } from '@mui/material';

interface NotificationModalProps {
    isOpen: boolean;
    title: string;
    imgSrc: string;
    content: React.ReactNode;
    onCloseCallback: Function;
}

const NotificationModal = React.memo<NotificationModalProps>(({ isOpen, imgSrc, title, content, onCloseCallback }) => {
    return (
        <Modal
            open={isOpen}
            onClose={() => {
                onCloseCallback();
            }}
        >
            <div className="commonModal_wrapper confirm">
                <div className="commonModal_body">
                    <div className="commonConfirmBox">
                        <img src={imgSrc} alt={title} />
                        <Typography variant="h2">{title}</Typography>
                        {content}
                    </div>
                </div>
                <div className="commonModal_footer textCenter">
                    <Button color="primary" variant="contained" className="ml16" onClick={() => onCloseCallback()}> Close</Button>
                </div>
            </div>
        </Modal>
    );
});

export default NotificationModal;
