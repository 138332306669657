import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Accordion, AccordionDetails, AccordionSummary, Button, Checkbox, FormControl, FormControlLabel, FormGroup, MenuItem, Select } from '@mui/material';
import { Sync, SellOutlined, ExpandMore } from '@mui/icons-material';
import { useDrawer } from '../../providers/Drawer';
import { UserHashtagFilter, USER_HASHTAG_FILTER_TYPE, useUserHashtagFilter } from '../../providers/UserHashtagFilter';
import { useSelector } from '../../_redux/_Store';
import { USER_HASHTAG_STATE_FILTER_TYPE } from '../../_global/_Enums';

const UserHashtagFilters = React.memo(() => {
    const drawer = useDrawer();
    const userHashtagFilter = useUserHashtagFilter();
    const apiTagList = useSelector((state) => state.data.apiTagList);
    const [selectedTagIdList, setSelectedTagIdList] = React.useState<Array<string>>([]);
    const [userHashtagStateFilterType, setUserHashtagStateFilterType] = React.useState<USER_HASHTAG_STATE_FILTER_TYPE | ''>('');
    const [isFilterSelected, setIsFilterSelected] = React.useState(false);
    const resetFilters = React.useCallback(() => {
        setUserHashtagStateFilterType('');
        setSelectedTagIdList([]);
    }, []);
    const applyFilters = React.useCallback(() => {
        const newFilterList = [] as Array<UserHashtagFilter>;
        const text = userHashtagFilter.filterList?.find((obj) => obj.type === USER_HASHTAG_FILTER_TYPE.TEXT);
        if (text) {
            newFilterList.push({
                type: USER_HASHTAG_FILTER_TYPE.TEXT,
                value: text.value
            });
        }
        if (userHashtagStateFilterType) {
            newFilterList.push({
                type: USER_HASHTAG_FILTER_TYPE.STATE_FILTER_TYPE,
                value: userHashtagStateFilterType
            });
        }
        if (selectedTagIdList.length > 0) {
            newFilterList.push({
                type: USER_HASHTAG_FILTER_TYPE.TAG_ID_LIST,
                value: selectedTagIdList
            });
        }
        userHashtagFilter.navigateToSearch(newFilterList);
    }, [selectedTagIdList, userHashtagFilter, userHashtagStateFilterType]);
    const onTagCheckChanged = React.useCallback((tagId: string, checked: boolean) => {
        if (checked) {
            setSelectedTagIdList((prevState) => [...prevState, tagId]);
            return;
        }
        setSelectedTagIdList((prevState) => prevState.filter((obj) => obj !== tagId));
    }, []);
    React.useEffect(() => {
        setIsFilterSelected(userHashtagStateFilterType !== '' || selectedTagIdList.length !== 0);
    }, [selectedTagIdList.length, userHashtagStateFilterType]);
    return (
        <div className="messageThreadFilter">
            <div className="messageThreadFilter_header">
                <button
                    onClick={() => {
                        drawer.close();
                    }}
                    className="drawerCloseBtn"
                >
                    <CloseIcon />
                </button>
                <h2>Subscription Filters</h2>
                <Button
                    variant="outlined"
                    disabled={!isFilterSelected}
                    onClick={() => {
                        resetFilters();
                    }}
                    className="messageThreadFilter_filterBtn"
                >
                    <Sync />
                    Reset
                </Button>
            </div>
            <div className="messageThreadFilter_body">
                <div className="messageThreadFilter_item">
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <h2>Status</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="messageThreadFilter_dateRange">
                                <FormControl>
                                    <div className="commonDropdownWrap">
                                        <Select
                                            color="info"
                                            onChange={(e) => {
                                                setUserHashtagStateFilterType(e.target.value as USER_HASHTAG_STATE_FILTER_TYPE);
                                            }}
                                            displayEmpty
                                            value={userHashtagStateFilterType}
                                            size="small"
                                            className="commonDropdown"
                                        >
                                            <MenuItem value="">Select One</MenuItem>
                                            <MenuItem value={USER_HASHTAG_STATE_FILTER_TYPE.INVITED}>Invited</MenuItem>
                                            <MenuItem value={USER_HASHTAG_STATE_FILTER_TYPE.REQUESTED}>Requested</MenuItem>
                                            <MenuItem value={USER_HASHTAG_STATE_FILTER_TYPE.SUBSCRIBED}>Subscribed</MenuItem>
                                            <MenuItem value={USER_HASHTAG_STATE_FILTER_TYPE.NOT_SUBSCRIBED}>Not Subscribed</MenuItem>
                                            <MenuItem value={USER_HASHTAG_STATE_FILTER_TYPE.DEACTIVATED}>Deactivated</MenuItem>
                                            <MenuItem value={USER_HASHTAG_STATE_FILTER_TYPE.DECLINED}>Declined</MenuItem>
                                        </Select>
                                    </div>
                                </FormControl>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="messageThreadFilter_item">
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary expandIcon={<ExpandMore />}>
                            <SellOutlined className="rotateIcon" />
                            <h2>Topics</h2>
                        </AccordionSummary>
                        <AccordionDetails>
                            <div className="messageThreadFilter_labels">
                                <div className="commonCheckbox">
                                    <FormGroup>
                                        {Object.values(apiTagList)
                                            .sort((a, b) => a.name.localeCompare(b.name))
                                            .map((apiTag) => (
                                                <div key={apiTag.tagId}>
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                checked={selectedTagIdList.includes(apiTag.tagId)}
                                                                onChange={(e) => {
                                                                    onTagCheckChanged(apiTag.tagId, e.target.checked);
                                                                }}
                                                            />
                                                        }
                                                        label={apiTag.name}
                                                    />
                                                </div>
                                            ))}
                                    </FormGroup>
                                </div>
                            </div>
                        </AccordionDetails>
                    </Accordion>
                </div>
                <div className="messageThreadFilter_actions">
                    <Button color="primary" variant="contained" disabled={!isFilterSelected} onClick={applyFilters}>
                        Apply Filters
                    </Button>
                    <Button
                        color="secondary"
                        variant="outlined"
                        onClick={() => {
                            drawer.close();
                        }}
                    >
                        Cancel
                    </Button>
                </div>
            </div>
        </div>
    );
});

export default UserHashtagFilters;
