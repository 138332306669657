import React from 'react';
import { Button, Snackbar as MuiSnackbar } from '@mui/material';

interface IAction {
    label: string;
    callback: Function;
}

interface IDisplayInfo {
    message: React.ReactNode;
    leftIcon?: React.ReactNode;
    action?: IAction;
}

interface Snackbar {
    displayInfo: (displayInfoObject: IDisplayInfo) => void;
}

const SnackbarContext = React.createContext<Snackbar>({
    displayInfo: () => {
        return null;
    }
});

export interface SnackbarProps {
    children?: React.ReactNode;
}

export const SnackbarProvider = React.memo<SnackbarProps>((snackbarProps) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [message, setMessage] = React.useState<React.ReactNode>();
    const [leftIcon, setLeftIcon] = React.useState<React.ReactNode>();
    const [action, setAction] = React.useState<IAction>();

    const snackbar = React.useMemo<Snackbar>(() => {
        return {
            displayInfo: (displayInfoObject) => {
                setIsOpen(true);
                setMessage(displayInfoObject.message);
                if (displayInfoObject.leftIcon) {
                    setLeftIcon(displayInfoObject.leftIcon);
                }
                if (displayInfoObject.action) {
                    setAction(displayInfoObject.action);
                }
            }
        };
    }, []);

    const handleClick = React.useCallback(
        (event) => {
            setIsOpen(false);
            action?.callback(event);
        },
        [action]
    );

    return (
        <SnackbarContext.Provider value={snackbar}>
            <MuiSnackbar
                open={isOpen}
                message={
                    <>
                        {leftIcon}
                        {message}
                    </>
                }
                action={
                    action && (
                        <>
                            <span className="snackbar_pipe" /> <Button onClick={handleClick}>{action.label}</Button>
                        </>
                    )
                }
                autoHideDuration={10000}
                onClose={() => {
                    setIsOpen(false);
                    setMessage(null);
                    setLeftIcon(null);
                    setAction(undefined);
                }}
                anchorOrigin={{
                    horizontal: 'center',
                    vertical: 'bottom'
                }}
            />
            {snackbarProps.children}
        </SnackbarContext.Provider>
    );
});

export const useSnackbar = () => React.useContext(SnackbarContext);
