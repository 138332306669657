import React from 'react';
import { Button, IconButton, Menu, MenuItem, ListItemIcon, ListItemText, Modal, Typography, Tooltip } from '@mui/material';
import moment from 'moment';
import { ApiFile, ApiGif, ApiReaction } from '../../_api/_ApiModels';
import { MESSAGE_CALENDAR_FORMATS, Reaction, ReactionList, READ_TIME_FORMAT } from '../../_global/Constants';
import { capitalizeFirstLetter } from '../../_global/Helpers';
import { REACTION_TYPE } from '../../_global/_Enums';
import { useSelector } from '../../_redux/_Store';
import MessageText from './MessageText';
import UserAvatar from '../common/UserAvatar';
import { countEmojis } from '../../_global/FileUtils';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { useApi } from '../../providers';
import HashtagCalloutInput from '../common/HashtagCalloutInput';
import UserName from '../common/UserName';
import produce from 'immer';
import { useDrawer } from '../../providers/Drawer';
import { Close } from '@mui/icons-material';
import ModalPrompt from '../common/ModalPrompt';
import MessageThreadMessageAttachments from './MessageThreadMessageAttachments'

interface MessageThreadMessageProps {
    messageThreadId: string;
    messageId: string;
    createTime: number;
    createdUserId: string;
    text: string;
    editTime?: number;
    apiReactionList: Array<ApiReaction>;
    apiFileList: Array<ApiFile>;
    fileIdList?: Array<string>;
    apiGifList: Array<ApiGif>;
    isDeleted: boolean;
    isSent: boolean;
    isSameUser: boolean;
    displayMenuButton: boolean;
    isFirstInGroup: boolean;
    isInGroup: boolean;
    isLastInGroup: boolean;
    isSeen: boolean;
}

interface ReactionDisplay extends Reaction {
    display: string;
    hasLoggedInUser: boolean;
    userNameList: Array<string>;
    count: number;
}

interface EmojiCountType {
    onlyEmoji: boolean;
    emojiCount: number;
}

const initReactionDisplayList = [...ReactionList].map((obj) => {
    return {
        ...obj,
        display: capitalizeFirstLetter(obj.type),
        userNameList: [],
        hasLoggedInUser: false,
        count: 0
    };
}) as Array<ReactionDisplay>;

const MessageThreadMessage = React.memo<MessageThreadMessageProps>((messageThreadMessageProps) => {
    const api = useApi();
    const drawer = useDrawer();
    const loggedInUserId = useSelector((state) => state.data.userId);

    const apiUserList = useSelector((state) => state.data.apiUserList);
    const [isMenuOpen, setIsMenuOpen] = React.useState(false);
    const [isEditMessageOpen, setIsEditMessageOpen] = React.useState(false);
    const [isDeleteMessageModalOpen, setIsMessageDeleteModalOpen] = React.useState(false);
    const [editedText, setEditedText] = React.useState(messageThreadMessageProps.text);
    const [menuAnchor, setMenuAnchor] = React.useState<HTMLElement | null>(null);
    const [reactionDisplayList, setReactionDisplayList] = React.useState(initReactionDisplayList);
    const [countEmoji, SetCountEmoji] = React.useState<EmojiCountType>();

    const onMoreClicked = React.useCallback((e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        e.nativeEvent.stopImmediatePropagation();
        setMenuAnchor(e.currentTarget);
        setIsMenuOpen(true);
    }, []);

    const onReactionClicked = React.useCallback(
        (type: REACTION_TYPE) => {
            //TODO: Update local state as well to make the UI feel more snappy
            api.toggleReaction({ messageThreadId: messageThreadMessageProps.messageThreadId, messageId: messageThreadMessageProps.messageId, type: type });
            setIsMenuOpen(false);
        },
        [api, messageThreadMessageProps.messageId, messageThreadMessageProps.messageThreadId]
    );

    const onViewDetailsClicked = React.useCallback(() => {
        drawer.openMessageProfile(messageThreadMessageProps.messageThreadId, messageThreadMessageProps.messageId);
        setIsMenuOpen(false);
    }, [drawer, messageThreadMessageProps.messageId, messageThreadMessageProps.messageThreadId]);

    const onEditMessageClicked = React.useCallback(() => {
        setIsEditMessageOpen(true);
        setIsMenuOpen(false);
    }, []);

    const onDeleteMessageClicked = React.useCallback(() => {
        api.deleteMessage({
            messageThreadId: messageThreadMessageProps.messageThreadId,
            messageId: messageThreadMessageProps.messageId
        });
        setIsMenuOpen(false);
        setIsMessageDeleteModalOpen(false);
    }, [api, messageThreadMessageProps.messageId, messageThreadMessageProps.messageThreadId]);

    const onSaveEditedMessageClicked = React.useCallback(() => {
        api.editMessage({
            messageThreadId: messageThreadMessageProps.messageThreadId,
            messageId: messageThreadMessageProps.messageId,
            text: editedText
        });
        setIsEditMessageOpen(false);
    }, [api, editedText, messageThreadMessageProps.messageId, messageThreadMessageProps.messageThreadId]);

    React.useEffect(() => {
        setReactionDisplayList(
            produce((draft) => {
                draft.map((obj) => {
                    obj.count = 0;
                    obj.hasLoggedInUser = false;
                    obj.userNameList = [];
                    return obj;
                });
                for (let i = 0; i < messageThreadMessageProps.apiReactionList.length; i++) {
                    const apiReaction = messageThreadMessageProps.apiReactionList[i];
                    let j = draft.findIndex((obj) => obj.type === apiReaction.type);
                    if (j === -1) continue;
                    draft[j].count++;
                    if (!draft[j].hasLoggedInUser && apiReaction.userId === loggedInUserId) {
                        draft[j].hasLoggedInUser = true;
                    }
                    const apiUser = apiUserList[apiReaction.userId];
                    if (apiUser) {
                        draft[j].userNameList.push(`${apiUser.firstName} ${apiUser.lastName}`);
                    }
                }
            })
        );
    }, [apiUserList, loggedInUserId, messageThreadMessageProps.apiReactionList]);


    React.useEffect(() => {
        countEmojis(messageThreadMessageProps.text).then((res: any) => {
            SetCountEmoji(res);
        });
    }, [messageThreadMessageProps.text]);

    return (
        <>
            <div
                className="messageThreadMessage_avatarWrap"
                style={{
                    backgroundColor: messageThreadMessageProps.isSameUser ? '#E6EFFC' : '#EDEEEF',
                    opacity: !messageThreadMessageProps.isSent ? 0.8 : 1
                }}
            >
                <div className="messageThreadMessage_avatarWrapInner">
                    <UserAvatar userId={messageThreadMessageProps.createdUserId} displayOnlineIndicator={true} />
                </div>
            </div>
            <div
                className="messageThreadMessage_chatBody"
                style={{
                    backgroundColor: messageThreadMessageProps.isSameUser ? '#E6EFFC' : '#EDEEEF',
                    opacity: !messageThreadMessageProps.isSent ? 0.8 : 1
                }}
            >
                <div className="messageThreadMessage_userInfo">
                    <div className="messageThreadMessage_userInfoLeft">
                        <span className="messageThreadMessage_username">
                            <UserName userId={messageThreadMessageProps.createdUserId} />
                        </span>
                        <span className="messageThreadMessage_usertime">{moment.unix(messageThreadMessageProps.createTime).calendar(MESSAGE_CALENDAR_FORMATS)}</span>
                    </div>
                    {messageThreadMessageProps.displayMenuButton && !messageThreadMessageProps.isDeleted && (
                        <span className="messageThreadMessage_userActionIcon">
                            <IconButton
                                onClick={(e) => {
                                    onMoreClicked(e);
                                }}
                            >
                                <MoreHorizOutlinedIcon />
                            </IconButton>
                        </span>
                    )}
                </div>
                <Typography variant="body2">
                    {messageThreadMessageProps.isDeleted ? (
                        <span className="messageThreadMessage_deleted">This message has been deleted</span>
                    ) : (
                        <>
                            <span className={`${countEmoji && countEmoji.emojiCount <= 3 && countEmoji.onlyEmoji ? 'emoji_size' : ''}`}>
                                <MessageText text={messageThreadMessageProps.text} linkify={true} />
                            </span>
                            {messageThreadMessageProps.editTime && (
                                <Tooltip title={moment.unix(messageThreadMessageProps.editTime).format(READ_TIME_FORMAT)} placement="bottom-start">
                                    <Typography variant="body2" component="span" className="editedText">
                                        &nbsp;(edited)
                                    </Typography>
                                </Tooltip>
                            )}
                        </>
                    )}
                </Typography>
                <MessageThreadMessageAttachments isMessageSent={messageThreadMessageProps.isSent}
                                                 apiFileList={messageThreadMessageProps.apiFileList}
                                                 apiGifList={messageThreadMessageProps.apiGifList}
                                                 notUploadedFileList={messageThreadMessageProps.fileIdList ?? []}
                                                 messageId={messageThreadMessageProps.messageId}
                                                 messageThreadId={messageThreadMessageProps.messageThreadId}
                                                 isMessageSeen={messageThreadMessageProps.isSeen} />


                {reactionDisplayList.filter((obj) => obj.count > 0).length > 0 && (
                    <div className="messageThreadMessage_reactionToMessage">
                        <div className="messageThreadMessage_emotionListSelf">
                            {reactionDisplayList
                                .filter((obj) => obj.count > 0)
                                .map((reactionDisplay) => (
                                    <Tooltip
                                        key={reactionDisplay.type}
                                        title={
                                            <div className="messageThreadMessage_emojiTooltip">
                                                <div className="messageThreadMessage_emojiIcon">
                                                    <img src={reactionDisplay.loggedInUserImage} alt="emoji" />
                                                    <span>{reactionDisplay.display}</span>
                                                </div>
                                                <ul className="commonList messageThreadMessage_usersReacted">
                                                    {reactionDisplay?.userNameList
                                                        .slice()
                                                        .sort((a, b) => a.localeCompare(b))
                                                        .map((userName) => {
                                                            return <li key={userName}>{userName}</li>;
                                                        })}
                                                </ul>
                                            </div>
                                        }
                                        placement="bottom-start"
                                        arrow
                                    >
                                        <div
                                            onClick={() => {
                                                onReactionClicked(reactionDisplay.type);
                                            }}
                                            style={{ backgroundColor: reactionDisplay.hasLoggedInUser ? reactionDisplay.defaultBackground : '' }}
                                            className={reactionDisplay.hasLoggedInUser ? 'messageThreadMessage_emotionWrapper messageThreadMessage_selfReacted' : 'messageThreadMessage_emotionWrapper'}
                                        >
                                            <div className="messageThreadMessage_emoji">
                                                <img src={reactionDisplay.loggedInUserImage} alt="emoji" />
                                            </div>
                                            <span className="messageThreadMessage_reactionCount">{reactionDisplay.count}</span>
                                        </div>
                                    </Tooltip>
                                ))}
                        </div>
                    </div>
                )}
            </div>
            <Menu
                open={isMenuOpen}
                onClose={() => {
                    setIsMenuOpen(false);
                }}
                anchorEl={menuAnchor}
                className="commonPopoverDropdown"
            >
                <div className="messageThreadMessage_emotionList">
                    {reactionDisplayList.map((reactionDisplay) => (
                        <div key={reactionDisplay.type} className="messageThreadMessage_emoji">
                            <img
                                src={reactionDisplay.hasLoggedInUser ? reactionDisplay.loggedInUserImage : reactionDisplay.image}
                                alt="emoji"
                                onClick={() => {
                                    onReactionClicked(reactionDisplay.type);
                                }}
                            />
                        </div>
                    ))}
                </div>
                <MenuItem onClick={onViewDetailsClicked}>
                    <ListItemIcon>
                        <VisibilityIcon />
                    </ListItemIcon>
                    <ListItemText>View Details</ListItemText>
                </MenuItem>
                {/* doing the below logic twice resolves a material ui error since Menu can't accept a fragment <>...</> */}
                {messageThreadMessageProps.isSameUser && (
                    <MenuItem onClick={onEditMessageClicked}>
                        <ListItemIcon>
                            <EditIcon />
                        </ListItemIcon>
                        <ListItemText>Edit Message</ListItemText>
                    </MenuItem>
                )}
                {messageThreadMessageProps.isSameUser && (
                    <MenuItem onClick={() => setIsMessageDeleteModalOpen(true)}>
                        <ListItemIcon>
                            <DeleteOutlinedIcon />
                        </ListItemIcon>
                        <ListItemText>Delete Message</ListItemText>
                    </MenuItem>
                )}
            </Menu>
            <ModalPrompt
                isOpen={isDeleteMessageModalOpen}
                title="Delete Message"
                prompt="Are you sure you want to delete this message?"
                onYesCallback={onDeleteMessageClicked}
                onCloseCallback={() => {
                    setIsMessageDeleteModalOpen(false);
                }}
            />
            <Modal
                open={isEditMessageOpen}
                onClose={() => {
                    setIsEditMessageOpen(false);
                }}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="commonModal_wrapper editMessage">
                    <div className="commonModal_header">
                        <span className="commonModal_headerText">Edit Message</span>
                        <button
                            className="commonModal_headerClose"
                            type="button"
                            onClick={() => {
                                setIsEditMessageOpen(false);
                            }}
                        >
                            <Close />
                        </button>
                    </div>
                    <div className="commonModal_body">
                        <div className="common_mentionWrap">
                            <HashtagCalloutInput
                                value={editedText}
                                onChange={(e) => {
                                    setEditedText(e.target.value);
                                }}
                                autoFocus={true}
                                allowSuggestionsAboveCursor={true}
                                className="messageThreadMessage_editMessageCallout"
                            />
                        </div>
                    </div>
                    <div className="commonModal_footer">
                        <Button
                            color="secondary"
                            variant="contained"
                            onClick={() => {
                                setIsEditMessageOpen(false);
                            }}
                        >
                            Cancel
                        </Button>
                        <Button color="primary" variant="contained" onClick={onSaveEditedMessageClicked}>
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
});

export default MessageThreadMessage;
