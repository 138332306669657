import React from 'react';
import { Divider } from '@mui/material';
import ManageHashtagIcon from '../../assets/images/manage-hashtag.svg';
import { useSelector } from '../../_redux/_Store';
import PlaceholderIcon from '../../assets/images/placeholder-thread.svg';
import HashtagList from './HashtagList';
import { useNavigate, useOutlet } from 'react-router-dom';
import { LAYOUT_TYPE, SCREEN_TYPE } from '../../_global/Route';
import TagList from './TagList';

interface ManageHashtagsProps {
    isHashtags: boolean;
}

const ManageHashtags = React.memo<ManageHashtagsProps>(({ isHashtags }) => {
    const navigate = useNavigate();
    const outlet = useOutlet();
    const { apiTagList, apiHashtagList, isAdmin } = useSelector((state) => state.data);
    return (
        <>
            <div className="hashtags_Wrapper">
                <div className="commonTop_headingInfo">
                    <div className="commonTop_heading">
                        <img src={ManageHashtagIcon} alt="manage-hashtag-icon" />
                        <h2>Manage Hashtags ({Object.keys(apiHashtagList).length})</h2>
                    </div>
                </div>
                {isAdmin ? (
                    <div className="hashtags_contents">
                        <div className="hashtags_tabWrap">
                            <div className={`tab ${isHashtags ? 'active' : ''}`} onClick={() => navigate({ pathname: `/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.HASHTAGS}` })}>
                                Hashtags ({Object.keys(apiHashtagList).length})
                            </div>
                            <div className={`tab ${isHashtags ? '' : 'active'}`} onClick={() => navigate({ pathname: `/${LAYOUT_TYPE.INBOX}/${SCREEN_TYPE.TAGS}` })}>
                                Topics ({Object.keys(apiTagList).length})
                            </div>
                        </div>
                        {isHashtags ? <HashtagList /> : <TagList />}
                    </div>
                ) : (
                    <div className="hashtags_contents">
                        <HashtagList />
                    </div>
                )}
            </div>
            <Divider orientation="vertical" />
            <div className="messageThreadList_messageThreadWrapper">
                {outlet || (
                    <div className="messageThreadList_placeholder">
                        <img src={PlaceholderIcon} alt="placeholder-message-thread" />
                    </div>
                )}
            </div>
        </>
    );
});

export default ManageHashtags;
