import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { QUERY_STRING_TYPE } from '../_global/Route';
import { Drawer as MaterialDrawer } from '@mui/material';
import MessageProfile from '../components/drawer/MessageProfile';
import MessageThreadInfo from '../components/drawer/MessageThreadInfo';
import ComposeNewMessageThread from '../components/drawer/ComposeNewMessageThread';
import MessageThreadFilters from '../components/drawer/MessageThreadFilters';
import MessageThreadFileList from '../components/drawer/MessageThreadFileList';
import SubscriptionThreadFilters from '../components/drawer/UserHashtagFilters';
import CreateHashtag from '../components/drawer/CreateHashtag';
import MessageThreadSearchList from '../components/drawer/MessageThreadSearchList';
import Contacts from '../components/drawer/Contacts';
import UserProfile from '../components/drawer/UserProfile';
import Support from '../components/drawer/Support';
export const DRAWER_QUERY_STRING_LIST = [QUERY_STRING_TYPE.DRAWER_TYPE, QUERY_STRING_TYPE.MESSAGE_THREAD_ID, QUERY_STRING_TYPE.MESSAGE_ID, QUERY_STRING_TYPE.USER_ID];

export enum DRAWER_TYPE {
    COMPOSE = 'compose',
    MESSAGE_PROFILE = 'messageProfile',
    MESSAGE_THREAD_INFO = 'MessageThreadInfo',
    MESSAGE_THREAD_FILTER = 'messageThreadFilter',
    MESSAGE_THREAD_FILE_LIST = 'messageThreadFileList',
    MESSAGE_THREAD_SEARCH_LIST = 'messageThreadSearchList',
    SUBSCRIPTION_THREAD_FILTER = 'subscriptionThreadFilter',
    CREATE_HASHTAG = 'createHashtag',
    CONTACTS = 'contacts',
    USER_PROFILE = 'userProfile',
    SUPPORT = 'support'
}

interface Drawer {
    close: () => void;
    openCompose: (userId?: string) => void;
    openMessageProfile: (messageThreadId: string, messageId: string) => void;
    openMessageThreadInfo: (messageThreadId: string) => void;
    openUserProfile: (userId: string) => void;
    openMessageThreadFilter: () => void;
    openMessageThreadSearchList: () => void;
    openMessageThreadFileList: (messageThreadId: string) => void;
    openSubscriptionThreadFilter: () => void;
    openCreateHashtag: () => void;
    openContacts: () => void;
    openSupport: () => void;
}

const DrawerContext = React.createContext<Drawer>({
    close: () => {
        return null;
    },
    openCompose: () => {
        return null;
    },
    openMessageProfile: () => {
        return null;
    },
    openMessageThreadInfo: () => {
        return null;
    },
    openUserProfile: () => {
        return null;
    },
    openMessageThreadFilter: () => {
        return null;
    },
    openMessageThreadSearchList: () => {
        return null;
    },
    openMessageThreadFileList: () => {
        return null;
    },
    openSubscriptionThreadFilter: () => {
        return null;
    },
    openCreateHashtag: () => {
        return null;
    },
    openContacts: () => {
        return null;
    },
    openSupport: () => {
        return null;
    }
});

export interface DrawerProps {
    children?: React.ReactNode;
}

export const DrawerProvider = React.memo<DrawerProps>((drawerProps) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [drawerType, setDrawerType] = React.useState<DRAWER_TYPE | null>();
    const [messageId, setMessageId] = React.useState<string>();
    const [userId, setUserId] = React.useState<string>('');
    const [messageThreadId, setMessageThreadId] = React.useState<string>();
    const getClearQueryParams = React.useCallback(() => {
        const queryParams = new URLSearchParams(location.search);
        DRAWER_QUERY_STRING_LIST.forEach((queryString) => {
            queryParams.delete(queryString);
        });
        return queryParams;
    }, [location.search]);
    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        for (let i = 0; i < DRAWER_QUERY_STRING_LIST.length; i++) {
            const param = DRAWER_QUERY_STRING_LIST[i];
            const queryString = queryParams.get(param);
            switch (param) {
                case QUERY_STRING_TYPE.DRAWER_TYPE: {
                    setDrawerType(queryString as DRAWER_TYPE);
                    break;
                }
                case QUERY_STRING_TYPE.MESSAGE_THREAD_ID: {
                    setMessageThreadId(queryString as string);
                    break;
                }
                case QUERY_STRING_TYPE.MESSAGE_ID: {
                    setMessageId(queryString as string);
                    break;
                }
                case QUERY_STRING_TYPE.USER_ID: {
                    setUserId(queryString as string);
                    break;
                }
            }
        }
    }, [location.search]);
    const drawer = React.useMemo<Drawer>(() => {
        return {
            close: () => {
                navigate({ search: getClearQueryParams().toString() });
            },
            openCompose: (userId?: string) => {
                const queryParams = getClearQueryParams();
                queryParams.append(QUERY_STRING_TYPE.DRAWER_TYPE, DRAWER_TYPE.COMPOSE);
                if (userId) queryParams.append(QUERY_STRING_TYPE.USER_ID, userId);
                navigate({ search: queryParams.toString() });
            },
            openMessageProfile: (messageThreadId: string, messageId: string) => {
                const queryParams = getClearQueryParams();
                queryParams.append(QUERY_STRING_TYPE.DRAWER_TYPE, DRAWER_TYPE.MESSAGE_PROFILE);
                queryParams.append(QUERY_STRING_TYPE.MESSAGE_THREAD_ID, messageThreadId);
                queryParams.append(QUERY_STRING_TYPE.MESSAGE_ID, messageId);
                navigate({ search: queryParams.toString() });
            },
            openMessageThreadInfo: (messageThreadId: string) => {
                const queryParams = getClearQueryParams();
                queryParams.append(QUERY_STRING_TYPE.DRAWER_TYPE, DRAWER_TYPE.MESSAGE_THREAD_INFO);
                queryParams.append(QUERY_STRING_TYPE.MESSAGE_THREAD_ID, messageThreadId);
                navigate({ search: queryParams.toString() });
            },
            openMessageThreadFilter: () => {
                const queryParams = getClearQueryParams();
                queryParams.append(QUERY_STRING_TYPE.DRAWER_TYPE, DRAWER_TYPE.MESSAGE_THREAD_FILTER);
                navigate({ search: queryParams.toString() });
            },
            openUserProfile: (userId: string) => {
                const queryParams = getClearQueryParams();
                queryParams.append(QUERY_STRING_TYPE.DRAWER_TYPE, DRAWER_TYPE.USER_PROFILE);
                queryParams.append(QUERY_STRING_TYPE.USER_ID, userId);
                navigate({ search: queryParams.toString() });
            },
            openMessageThreadSearchList: () => {
                const queryParams = getClearQueryParams();
                queryParams.append(QUERY_STRING_TYPE.DRAWER_TYPE, DRAWER_TYPE.MESSAGE_THREAD_SEARCH_LIST);
                navigate({ search: queryParams.toString() });
            },
            openMessageThreadFileList: (messageThreadId: string) => {
                const queryParams = getClearQueryParams();
                queryParams.append(QUERY_STRING_TYPE.DRAWER_TYPE, DRAWER_TYPE.MESSAGE_THREAD_FILE_LIST);
                queryParams.append(QUERY_STRING_TYPE.MESSAGE_THREAD_ID, messageThreadId);
                navigate({ search: queryParams.toString() });
            },
            openSubscriptionThreadFilter: () => {
                const queryParams = getClearQueryParams();
                queryParams.append(QUERY_STRING_TYPE.DRAWER_TYPE, DRAWER_TYPE.SUBSCRIPTION_THREAD_FILTER);
                navigate({ search: queryParams.toString() });
            },
            openCreateHashtag: () => {
                const queryParams = getClearQueryParams();
                queryParams.append(QUERY_STRING_TYPE.DRAWER_TYPE, DRAWER_TYPE.CREATE_HASHTAG);
                navigate({ search: queryParams.toString() });
            },
            openContacts: () => {
                const queryParams = getClearQueryParams();
                queryParams.append(QUERY_STRING_TYPE.DRAWER_TYPE, DRAWER_TYPE.CONTACTS);
                navigate({ search: queryParams.toString() });
            },
            openSupport: () => {
                const queryParams = getClearQueryParams();
                queryParams.append(QUERY_STRING_TYPE.DRAWER_TYPE, DRAWER_TYPE.SUPPORT);
                navigate({ search: queryParams.toString() });
            }
        };
    }, [getClearQueryParams, navigate]);
    return (
        <DrawerContext.Provider value={drawer}>
            {drawerProps.children}
            <MaterialDrawer
                open={drawerType !== null}
                anchor="right"
                onClose={() => {
                    drawer.close();
                }}
                className="common_Drawer"
            >
                <div className="common_DrawerInner">
                    {drawerType === DRAWER_TYPE.COMPOSE ? (
                        <ComposeNewMessageThread userId={userId} />
                    ) : drawerType === DRAWER_TYPE.MESSAGE_PROFILE && messageThreadId && messageId ? (
                        <MessageProfile messageId={messageId} messageThreadId={messageThreadId} />
                    ) : drawerType === DRAWER_TYPE.MESSAGE_THREAD_INFO && messageThreadId ? (
                        <MessageThreadInfo messageThreadId={messageThreadId} />
                    ) : drawerType === DRAWER_TYPE.MESSAGE_PROFILE && messageThreadId && messageId ? (
                        <MessageProfile messageId={messageId} messageThreadId={messageThreadId} />
                    ) : drawerType === DRAWER_TYPE.MESSAGE_THREAD_FILTER ? (
                        <MessageThreadFilters />
                    ) : drawerType === DRAWER_TYPE.MESSAGE_THREAD_SEARCH_LIST ? (
                        <MessageThreadSearchList />
                    ) : drawerType === DRAWER_TYPE.MESSAGE_THREAD_FILE_LIST && messageThreadId ? (
                        <MessageThreadFileList messageThreadId={messageThreadId} />
                    ) : drawerType === DRAWER_TYPE.SUBSCRIPTION_THREAD_FILTER ? (
                        <SubscriptionThreadFilters />
                    ) : drawerType === DRAWER_TYPE.CREATE_HASHTAG ? (
                        <CreateHashtag />
                    ) : drawerType === DRAWER_TYPE.USER_PROFILE && userId ? (
                        <UserProfile userId={userId} />
                    ) : drawerType === DRAWER_TYPE.CONTACTS ? (
                        <Contacts />
                    ) : drawerType === DRAWER_TYPE.SUPPORT ? (
                        <Support />
                    ) : (
                        <></>
                    )}
                </div>
            </MaterialDrawer>
        </DrawerContext.Provider>
    );
});

export const useDrawer = () => React.useContext(DrawerContext);
