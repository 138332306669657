import React from 'react';
import { useApi } from '../../providers/Api';
import { useSelector } from '../../_redux/_Store';
import { DeleteOutline, Edit, OpenInNewOutlined } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';
import { useDrawer } from '../../providers/Drawer';
import { Link } from 'react-router-dom';
import { ApiMessageThreadSearch } from '../../_api/_ApiModels';
import { useMessageThreadFilter } from '../../providers/MessageThreadFilter';
import { QUERY_STRING_TYPE } from '../../_global/Route';
import EditMessageThreadSearchModal from '../inbox/EditMessageThreadSearchModal';
import ModalPrompt from '../common/ModalPrompt';
import MessageThreadFilterPillList from '../inbox/MessageThreadFilterPillList';

const MessageThreadSearchList = React.memo(() => {
    const loggedInUserId = useSelector((state) => state.data.userId);
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const drawer = useDrawer();
    const [apiMessageThreadSearchList, setApiMessageThreadSearchList] = React.useState<Array<ApiMessageThreadSearch>>([]);
    React.useEffect(() => {
        if (!loggedInUserId) {
            return;
        }
        setApiMessageThreadSearchList([...apiUserList[loggedInUserId].apiMessageThreadSearchList].sort((a, b) => a.name.localeCompare(b.name)));
    }, [apiUserList, loggedInUserId]);
    return (
        <>
            <div className="messageThreadSearch_Shortcuts">
                <div className="messageThreadSearch_messageHeader">
                    <button
                        onClick={() => {
                            drawer.close();
                        }}
                        className="drawerCloseBtn"
                    >
                        <CloseIcon />
                    </button>
                    <h2>Shortcuts</h2>
                </div>
                <div className="messageThreadSearch_body">
                    {apiMessageThreadSearchList.map((apiMessageThreadSearch) => (
                        <div key={apiMessageThreadSearch.messageThreadSearchId} className="messageThreadSearch_tagRow">
                            <MessageThreadSearch apiMessageThreadSearch={apiMessageThreadSearch} />
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
});

interface MessageThreadSearchProps {
    apiMessageThreadSearch: ApiMessageThreadSearch;
}

const MessageThreadSearch = React.memo<MessageThreadSearchProps>(({ apiMessageThreadSearch }) => {
    const api = useApi();
    const messageThreadFilter = useMessageThreadFilter();
    const [messageThreadSearchId, setMessageThreadSearchId] = React.useState<string>();
    const [isEditShortcutOpen, setIsEditShortcutOpen] = React.useState(false);
    const [isConfirmDeleteDialogOpen, setIsConfirmDeleteDialogOpen] = React.useState(false);
    const onDelete = React.useCallback(() => {
        if (!messageThreadSearchId) return;
        api.deleteMessageThreadSearch({
            messageThreadSearchId: messageThreadSearchId
        });
        setIsConfirmDeleteDialogOpen(false);
        setIsEditShortcutOpen(false);
    }, [api, messageThreadSearchId]);
    return (
        <>
            <div className="messageThreadSearch_tagNameWrap">
                <Link
                    to={`${messageThreadFilter.getUrl(apiMessageThreadSearch.apiMessageThreadSearchFilterList)}&${QUERY_STRING_TYPE.MESSAGE_THREAD_SEARCH_ID}=${
                        apiMessageThreadSearch.messageThreadSearchId
                    }`}
                    className="messageThreadSearch_tagName"
                >
                    <OpenInNewOutlined /> {apiMessageThreadSearch.name}
                </Link>
                <div className="messageThreadSearch_tagNameAction">
                    <button
                        type="button"
                        onClick={() => {
                            setMessageThreadSearchId(apiMessageThreadSearch.messageThreadSearchId);
                            setIsEditShortcutOpen(true);
                        }}
                        className="unStyledButton"
                    >
                        <Edit />
                    </button>
                    <button
                        aria-label="Remove"
                        type="button"
                        onClick={() => {
                            setMessageThreadSearchId(apiMessageThreadSearch.messageThreadSearchId);
                            setIsConfirmDeleteDialogOpen(true);
                        }}
                        className="unStyledButton"
                    >
                        <DeleteOutline />
                    </button>
                </div>
            </div>
            <div className="messageThreadSearch_chipList">
                <MessageThreadFilterPillList apiMessageThreadSearchFilterList={apiMessageThreadSearch.apiMessageThreadSearchFilterList} displaySearchText={true} />
            </div>
            <EditMessageThreadSearchModal
                messageThreadSearchId={apiMessageThreadSearch.messageThreadSearchId}
                isOpen={isEditShortcutOpen}
                setIsOpen={setIsEditShortcutOpen}
                onClose={() => {
                    setMessageThreadSearchId(undefined);
                    setIsEditShortcutOpen(false);
                }}
            />
            <ModalPrompt
                isOpen={isConfirmDeleteDialogOpen}
                title="Are you sure?"
                prompt={
                    <>
                        Are you sure you want to delete
                        <br />
                        <b>"{apiMessageThreadSearch.name}"</b>
                    </>
                }
                onYesCallback={onDelete}
                onCloseCallback={() => {
                    setMessageThreadSearchId(undefined);
                    setIsConfirmDeleteDialogOpen(false);
                }}
            />
        </>
    );
});

export default MessageThreadSearchList;
