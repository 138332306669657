import React from 'react';
import { Close } from '@mui/icons-material';
import { FileInTransit } from '../../providers/FileUpload';
import MessageFile from './MessageFile';

interface MessageReplyFileListProps {
    fileInTransitList: Array<FileInTransit>;
    onDeleteFileClicked: Function;
}
const MessageReplyFileList = React.memo<MessageReplyFileListProps>(({ fileInTransitList, onDeleteFileClicked }) => {

    return (
        <div className="messageReply_attachedImage">
            <ul className="commonImageList">
                {fileInTransitList.map((fileInTransit) => (
                    <li key={fileInTransit.fileId}>
                        <span
                            className="messageReply_removeAttached"
                            onClick={() => {
                                onDeleteFileClicked(fileInTransit.fileId);
                            }}
                        >
                            <Close />
                        </span>
                        <MessageFile fileId={fileInTransit.fileId} />
                    </li>
                ))}
            </ul>
        </div>
    );
});

export default MessageReplyFileList;
