import React from 'react';
import { useSelector } from '../../_redux/_Store';
import { MentionsInput, Mention, SuggestionDataItem, MentionsInputProps } from 'react-mentions';
import Highlighter from 'react-highlight-words';
import UserAvatar from './UserAvatar';
import { CALLOUT_KEY, HASHTAG_KEY } from '../../_global/Constants';

export enum MENTION_TYPE {
    HASHTAG_ID,
    CALLOUT_USER_ID
}

interface HashtagCalloutInputProps extends Omit<MentionsInputProps, 'children'> {
    onAddCallback?: (id: string, type: MENTION_TYPE) => void;
}

const HashtagCalloutInput = React.memo<HashtagCalloutInputProps>((hashtagCalloutInputProps) => {
    const { onAddCallback, ...mentionsInputProps } = hashtagCalloutInputProps; // separates out mentionsInputProps to avoid adding onAddCallback to the MentionsInput
    const apiUserList = useSelector((state) => state.data.apiUserList);
    const apiHashtagList = useSelector((state) => state.data.apiHashtagList);
    const [hashtagDataItemList, setHashtagDataItemList] = React.useState<Array<SuggestionDataItem>>([]);
    const [userDataItemList, setUserDataItemList] = React.useState<Array<SuggestionDataItem>>([]);
    const onAdd = React.useCallback(
        (id: string, type: MENTION_TYPE) => {
            if (hashtagCalloutInputProps.onAddCallback) {
                hashtagCalloutInputProps.onAddCallback(id, type);
            }
        },
        [hashtagCalloutInputProps]
    );
    React.useEffect(() => {
        const list: Array<SuggestionDataItem> = [];
        Object.values(apiHashtagList).forEach((apiHashtag) => {
            list.push({
                id: apiHashtag.hashtagId,
                display: `${apiHashtag.name}`
            });
        });
        setHashtagDataItemList(list);
    }, [apiHashtagList]);
    React.useEffect(() => {
        const list: Array<SuggestionDataItem> = [];
        Object.values(apiUserList).forEach((apiUser) => {
            list.push({
                id: apiUser.userId,
                display: `${apiUser.callout}`
            });
        });
        setUserDataItemList(list);
    }, [apiUserList]);
    return (
        <>
            <MentionsInput {...mentionsInputProps}>
                <Mention
                    trigger={`${HASHTAG_KEY}`}
                    markup="{{hid=__id__}}"
                    displayTransform={(id) => `${HASHTAG_KEY}${apiHashtagList[id].name}`}
                    data={hashtagDataItemList}
                    renderSuggestion={(suggestion, search) => {
                        return <HashtagSuggestion suggestionDataItem={suggestion} search={search} />;
                    }}
                    appendSpaceOnAdd={true}
                    onAdd={(e) => {
                        onAdd(e as string, MENTION_TYPE.HASHTAG_ID);
                    }}
                />
                <Mention
                    trigger={`${CALLOUT_KEY}`}
                    markup="{{cid=__id__}}"
                    displayTransform={(id) => `${CALLOUT_KEY}${apiUserList[id].callout}`}
                    data={userDataItemList}
                    renderSuggestion={(suggestion, search) => {
                        return <CalloutSuggestion suggestionDataItem={suggestion} search={search} />;
                    }}
                    appendSpaceOnAdd={true}
                    onAdd={(e) => {
                        onAdd(e as string, MENTION_TYPE.CALLOUT_USER_ID);
                    }}
                />
            </MentionsInput>
        </>
    );
});

interface SuggestionProps {
    suggestionDataItem: SuggestionDataItem;
    search: string;
}

const CalloutSuggestion = React.memo<SuggestionProps>(({ suggestionDataItem, search }) => {
    return (
        <div className="hashtagCalloutInput_List">
            <UserAvatar userId={suggestionDataItem.id as string} size={12} displayOnlineIndicator={false} />
            {`${CALLOUT_KEY}`}
            {suggestionDataItem.display && (
                <Highlighter
                    searchWords={[search]}
                    autoEscape={true}
                    textToHighlight={suggestionDataItem.display}
                    highlightClassName="hashtagCalloutInput_markHighlight"
                    className="hashtagCalloutInput_highlighterWrap"
                >
                    {suggestionDataItem.display}
                </Highlighter>
            )}
        </div>
    );
});

const HashtagSuggestion = React.memo<SuggestionProps>(({ suggestionDataItem, search }) => {
    return (
        <div className="hashtagCalloutInput_List">
            {`${HASHTAG_KEY}`}
            {suggestionDataItem.display && (
                <>
                    <Highlighter
                        searchWords={[search]}
                        autoEscape={true}
                        textToHighlight={suggestionDataItem.display}
                        highlightClassName="hashtagCalloutInput_markHighlight"
                        className="hashtagCalloutInput_highlighterWrap"
                    >
                        {suggestionDataItem.display}
                    </Highlighter>
                </>
            )}
        </div>
    );
});

export default HashtagCalloutInput;
