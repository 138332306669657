import React from 'react';
import { Modal, Typography, Button } from '@mui/material';

interface ModalPromptProps {
    isOpen: boolean;
    title: string;
    image?: string;
    prompt: string | React.ReactNode;
    yesPrompt?: string;
    noPrompt?: string;
    onYesCallback: Function;
    onCloseCallback: Function;
}

const ModalPrompt = React.memo<ModalPromptProps>(({ isOpen, title, image, prompt, yesPrompt = 'Yes', noPrompt = 'No', onYesCallback, onCloseCallback }) => {
    return (
        <Modal
            open={isOpen}
            onClose={() => {
                onCloseCallback();
            }}
        >
            <div className="commonModal_wrapper">
                <div className="commonModal_header">
                    <span className="commonModal_headerText">
                        {image && (
                            <div>
                                <img src={image} alt={title} />
                            </div>
                        )}
                        {title}
                    </span>
                </div>
                <Typography id="transition-modal-title" variant="body1" component="p" className="textCenter">
                    {prompt}
                </Typography>
                <div className="commonModal_footer">
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => {
                            onCloseCallback();
                        }}
                    >
                        {noPrompt}
                    </Button>
                    <Button color="primary" variant="contained" className="ml16" onClick={() => onYesCallback()}>
                        {yesPrompt}
                    </Button>
                </div>
            </div>
        </Modal>
    );
});

export default ModalPrompt;
